

<template>
         
    <!-- partial:partials/_navbar.html -->
    <!-- partial -->
      <!-- partial:partials/_sidebar.html -->
    
      <!-- partial -->
        <!---content wrapper start--->
        <editV :idt="id"  v-if="editer"/>

              
        <div class="content-wrapper" v-if="!editer">

                  <div class="row" >
            <div class="col-md-12 grid-margin">
              <div class="d-flex justify-content-between flex-wrap">
                <div class="d-flex align-items-end flex-wrap">
                
               
                <div class="mt-3">
                  <a href="#" class="link-secondary text-dark link-hover"><u>Vendeur</u> </a> >
                   <a href="#" class="auth-link text-dark"><u>Chercher un vendeur</u> </a> >
                   <a href="#" class="auth-link text-dark"><u>Details</u> </a> 
                    
           </div>


                </div>
                <div class="d-flex justify-content-between align-items-end flex-wrap">
                   <button type="button" class="btn btn-info  btn-rounded btn-icon mr-3 d-none d-md-block " title="Afficher tous les vendeurs" v-on:click="afficherVendeurs()">
                    <i class="mdi mdi-format-list-bulleted text-white"></i>
                  </button>
                
                
                  <button type="button" class="btn btn-light  btn-rounded btn-icon mr-3 mt-2 mt-xl-0" style="background : darkred;" title="Afficher les vendeurs archivés"  v-on:click="afficherVendeursArchives()">
                    <i class="mdi mdi-archive text-white"></i>
                  </button>
                 
                  <button type="button" class="btn btn-light bg-success btn-rounded btn-icon  mr-3 mt-2 mt-xl-0"  v-on:click="edit(vendeur.id)" title="Ajouter un vendeur">
                    <i class="mdi mdi-pencil text-white"></i>
                  </button>
                  
                </div>
              </div>
            </div>
          </div>
          
       <!--------------->

   
 
       <!--------------->
          <div class="row">
            <div class=" stretch-card col-lg-12 m-1 p-0">
              <div class="card p-0">
                    <div class="row">
            <div class="col-md-12 grid-margin stretch-card">
              <div class="card">
                <div class="card-body dashboard-tabs p-0">
                  <ul class="nav nav-tabs px-4" role="tablist">
                    <li class="nav-item">
                      <a class="nav-link active" id="overview-tab" data-toggle="tab" href="#overview" role="tab" aria-controls="overview" aria-selected="true">Informations</a>
                    </li>
                    <li class="nav-item">
                      <a class="nav-link" id="sales-tab" data-toggle="tab" href="#sales" role="tab" aria-controls="sales" aria-selected="false">Contact</a>
                    </li>
                    <li class="nav-item">
                      <a class="nav-link" id="purchases-tab" data-toggle="tab" href="#purchases" role="tab" aria-controls="purchases" aria-selected="false">Paiement et compte</a>
                    </li>
                    <li class="nav-item">
                      <a class="nav-link" id="purchases-tab" data-toggle="tab" href="#history" role="tab" aria-controls="histrory" aria-selected="false">Historique</a>
                    </li>
                  </ul>
                  <div class="tab-content py-0 px-0">
                    <div class="tab-pane fade show active" id="overview" role="tabpanel" aria-labelledby="overview-tab">
                        <!---------------->
                      <div class="d-flex flex-wrap justify-content-xl-between">
                    <!--------------here------------------->
                        <div class="table-responsive   m-4 ">
                   <table class="table table-borderless ">

                      <tbody>
                        <tr class="" >
                            <th  style="padding-left : 5%; padding-bottom : 2%; width : 20%;">Nom  </th>
                             <th   class="text-muted" style="padding-bottom : 2%;">  {{vendeur.nom}}</th>
                            
                          </tr >
                            <tr class="" >
                            <th  style="padding-left : 5%; padding-bottom : 2%;">Raison sociale </th>
                             <th class="text-muted" style="padding-bottom : 2%;">{{vendeur.raison_sociale}}</th>
                            
                          </tr>
                           <tr >
                            <th  style="padding-left : 5%; padding-bottom : 2%;">Site web  </th>
                             <th class=" text-muted" style="padding-bottom : 2%;"> {{vendeur.site_web}}</th>
                            
                          </tr>
                           <tr >
                            <th style="padding-left : 5%; padding-bottom : 2%;">Registre commercial </th>
                             <th class=" text-muted" style="padding-bottom : 2%;"> {{vendeur.rc}}</th>
                            
                          </tr>
                          <tr >
                            <th  style="padding-left : 5%; padding-bottom : 2%;">Nom responsable </th>
                             <th class="text-muted" style="padding-bottom : 2%;"> {{vendeur.nom_responsable}}</th>
                            
                          </tr>
                            
                            
                      
                      </tbody>
                    
                    </table>
                   
                  </div>
                      
                       
                      
                      </div>
                    </div>
                    <div class="tab-pane fade" id="sales" role="tabpanel" aria-labelledby="sales-tab">
                      <div class="d-flex flex-wrap justify-content-xl-between">
                          <div class="table-responsive   m-4 ">
                   <table class="table table-borderless ">

                      <tbody>
                         <tr class="" >
                            <th  style="padding-left : 5%; padding-bottom : 2%; width : 20%;">Adresse  </th>
                             <th   class="text-muted" style="padding-bottom : 2%;">  {{vendeur.adresse}}</th>
                            
                          </tr >
                            <tr class="" >
                            <th  style="padding-left : 5%; padding-bottom : 2%;">Ville </th>
                             <th class="text-muted" style="padding-bottom : 2%;">{{vendeur.ville}}</th>
                            
                          </tr>
                           <tr >
                            <th  style="padding-left : 5%; padding-bottom : 2%;">Pays  </th>
                             <th class=" text-muted" style="padding-bottom : 2%;"> {{vendeur.pays}}</th>
                            
                          </tr>
                           <tr >
                            <th style="padding-left : 5%; padding-bottom : 2%;"> Mobile </th>
                             <th class=" text-muted" style="padding-bottom : 2%;"> {{vendeur.mobile}}</th>
                            
                          </tr>
                          <tr >
                            <th  style="padding-left : 5%; padding-bottom : 2%;">Telephone </th>
                             <th class="text-muted" style="padding-bottom : 2%;"> {{vendeur.telephone}}</th>
                            
                          </tr>
                          <tr>
                          <th  style="padding-left : 5%; padding-bottom : 2%;">Fax </th>
                             <th class="text-muted" style="padding-bottom : 2%;"> {{vendeur.fax}}</th>
                            
                          </tr>
                           
                            
                         
                      </tbody>
                     
                    </table>
                   
                  </div>
                       
                     
                      
                      
                      </div>
                    </div>
                    <div class="tab-pane fade" id="purchases" role="tabpanel" aria-labelledby="purchases-tab">
                      <div class="d-flex flex-wrap justify-content-xl-between">
                    <!--------------here------------------->
                        <div class="table-responsive   m-4 ">
                   <table class="table table-borderless ">

                      <tbody>
                        <tr class="" >
                            <th  style="padding-left : 5%; padding-bottom : 2%; width : 20%;">Mode de paiement  </th>
                             <th   class="text-muted" style="padding-bottom : 2%;">  {{vendeur.nom}}</th>
                            
                          </tr >
                            <tr class="" >
                            <th  style="padding-left : 5%; padding-bottom : 2%;">Compte </th>
                             <th  v-if="vendeur.is_actived ==0" class="text-muted" style="padding-bottom : 2%;">
                              <i class="mdi mdi-account-off text-secondary " style="font-size : 20px;"></i> Non inscrit</th>
                               
                             <th  v-else-if="vendeur.is_actived == 1 &&  vendeur.user != null && vendeur.user.is_archived == 0">
                            <i class="mdi mdi-account-check text-success " style="font-size : 20px;"></i>  Activé
                            </th>
                            <th class="text-muted" style="padding-bottom : 2%;"  v-else-if="vendeur.is_actived == 1 &&  vendeur.user != null && vendeur.user.is_archived == 1">
                               <i class="mdi mdi-account-remove text-danger " style="font-size : 20px;"></i> Désactivé
                            </th>
                            <th class="text-muted" style="padding-bottom : 2%;"  v-else-if="vendeur.is_actived == 1 &&  vendeur.user == null ">
                              Error
                            </th>
                            
                          </tr>
                           <tr  v-if="vendeur.is_actived == 1 &&  vendeur.user != null && vendeur.user.is_archived == 0">
                            <th  style="padding-left : 5%; padding-bottom : 2%;">Date de création  </th>
                             <th class="text-muted" style="padding-bottom : 2%;" >
                              {{format_date(vendeur.user.created_at)}}
                            </th>
                            
                          </tr>
                            <tr  v-if="vendeur.is_actived == 1 &&  vendeur.user != null && vendeur.user.is_archived == 1">
                            <th  style="padding-left : 5%; padding-bottom : 2%;">Date de désactivation  </th>
                             <th class="text-muted" style="padding-bottom : 2%;" >
                              {{format_date(vendeur.user.created_at)}}
                            </th>
                            
                          </tr>
                         
                            
                            
                      
                      </tbody>
                    
                    </table>
                   
                  </div>
                      
                       
                      
                      </div>
                    </div>
                    <!---------------------here--------------->
                                        <div class="tab-pane fade" id="history" role="tabpanel" aria-labelledby="history-tab">

                                   <div class="d-flex flex-wrap justify-content-xl-between">
                          <div class="table-responsive   m-4 ">
                   <table class="table table-borderless ">

                      <tbody>
                         <tr class="" >
                            <th  style="padding-left : 5%; padding-bottom : 2%; width : 20%;">Adresse  </th>
                             <th   class="text-muted" style="padding-bottom : 2%;">  {{vendeur.adresse}}</th>
                            
                          </tr >
                            <tr class="" >
                            <th  style="padding-left : 5%; padding-bottom : 2%;">Ville </th>
                             <th class="text-muted" style="padding-bottom : 2%;">{{vendeur.ville}}</th>
                            
                          </tr>
                         
                          
                       
                        
                           
                            
                         
                      </tbody>
                     
                    </table>
                   
                  </div>
                       
                     
                      
                      
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
                
                <div class="card-body p-0">
                                                     
                   

                 
                </div>
              </div>
            </div>
          </div>
        
        </div>

        <!-- content-wrapper ends -->
        <!-- partial:partials/_footer.html -->
        <!-- partial -->
      <!-- main-panel ends -->
    <!-- page-body-wrapper ends -->
        

</template>

<script>

import axios from 'axios';
import moment from 'moment';
import editV from '@/components/editVendeur.vue'; 




export default {
 // name : detailsV,
   components : {
     editV,
   },

 props: {
    id: Number,
  },
 
  data()  {
    return {
   vendeur : {},
   error : "",
  
   count : '',
   editer : false,
  // idt : 11,
    }
  },

  mounted(){
    console.log("mounted")
      axios.create({
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer '+localStorage.getItem("token")
        }
    })
     .get("http://127.0.0.1:8000/api/getsellerbyid/"+this.id)
     .then(res => {
       this.vendeur = res.data;
       console.log(this.vendeur)
      //console.log(JSON.parse(JSON.stringify(this.res.data)))
    //   console.log(JSON.parse(res.data))
    this.count = res.data.length;

     })

  },


 methods: {
    format_date(value){
         if (value) {
           return moment(String(value)).format('YYYYMMDD hh:mm')
          }
      },
      edit(){
        
        this.editer = true;
      }
     
         

  

    //
   

  }
}
</script>
<style >

.home-section{
  position: relative;
  background: #E4E9F7;
  min-height: 100vh;
  top: 0;
  left: 78px;
  width: calc(100% - 78px);
  transition: all 0.5s ease;
  z-index: 2;
}
.sidebar.open ~ .home-section{
  left: 250px;
  width: calc(100% - 250px);
}
.home-section .text{
  display: inline-block;
  color: #11101d;
  font-size: 25px;
  font-weight: 500;
  margin: 0px;
}
/* serachbar */

.search-box{
  width: fit-content;
  height: fit-content;
  position: relative;
}
.input-search{
  height: 50px;
  width: 50px;
  border-style: none;
  padding: 10px;
  font-size: 18px;
  outline: none;
  border-radius: 25px;
  transition: all .5s ease-in-out;
  background-color: transparent;
  padding-right: 40px;
  color:black;
}
.input-search::placeholder{
  color: #6C757D;
  font-size: 18px;
  font-weight: 100;
  font-style: italic;
}
.btn-search{
  width: 50px;
  height: 50px;
  border-style: none;
  font-size: 25px;
  font-weight: bold;
  outline: none;
  cursor: pointer;
  border-radius: 50%;
  position: absolute;
  right: 0px;
  color:white ;
  pointer-events: painted;  
}
.btn-search ~ .input-search{
  width: 80%;
  border-radius: 0px;
  background-color: transparent;
  border-bottom:1px solid #6C757D;
  transition: all 500ms cubic-bezier(0, 0.110, 0.35, 2);
}
.input-search:focus{
  width: 80%;
  border-radius: 0px;
  background-color: transparent;
   box-shadow: 0  0.2rem #6C757D;
  transition: all 500ms cubic-bezier(0, 0.110, 0.35, 2);
}



</style>
import { Script } from "vm";