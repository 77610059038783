<template> 
<div class="bg-dark container">
    <div class="row m-4">
            <div class="col-md-12 grid-margin">
              <div class="d-flex justify-content-between flex-wrap" >
                <div class="d-flex align-items-end flex-wrap">
                  <div class="mr-md-3 mr-xl-5">
                     <div class="d-flex flex-row align-items-center">
  <a class="btn btn-light  btn-icon mr-3 d-none d-lg-block" onclick="javascript:window.history.back(-1);return false;" style="background : transparent;" >
                    <i class="mdi mdi-arrow-left-bold icon-lg icon-md hover-cursor" style="color : white;"></i>
                  </a>
                           
                          </div>
                  </div>
                 
                </div>
                <div class="d-flex justify-content-between align-items-end flex-wrap">
                    
                  <button type="button" class="btn btn-light  btn-icon mr-3 d-none d-lg-block " @click="exportPdf" style="background : transparent;">
                    <i class="mdi mdi-download text-danger" style="color : #E6E6E6;"></i>
                  </button>
                 
                 
                </div>
              </div>
            </div>
          </div>
<div class="row" >
               <div class="col-12 grid-margin stretch-card">
              <div class="card ">
                <div class="card-body ">
                                      <h2 class="card-title text-center text-danger font-weight-bold"> 
                                         <p class="text-danger text-center font-weight-bold" style="font-size : 20px; margin : auto ;">
                                                  Bons de commandes numero {{ shipment.id }} 

                            </p>   </h2>

<div class="row d-flex justify-content-center" id="pdf">
 
     <div class="col-lg-5 grid-margin stretch-card " >
              <div class="card border-0 ">
                <div class="card-body   " style="height : 12%;" >
                  <div class="row" >
                    <div class="col-md-12 " style="width : 100%;">
                 <div class="row">
                          <div class="col-md-12">
                          <address>
                        <p class="font-weight-bold  text-dark text-capitalize">{{warehouse.nom}}</p>
                        <p class=" text-dark">
                         {{warehouse.adresse}},
                        </p>
                        <p class=" text-dark">
                         {{warehouse.ville}}  
                        </p>
                      </address>
                      </div>
                     
                 </div>
                 <div class="row">
                   <div class="col-12">
                     Date d'enlevement :  <b>{{shipment.date_enlevement_demandee}}</b>
                   </div>
                 </div>
                    </div>
                   
                  </div>
                </div>
                <div class="card-body" style="height : 80%; margin-top : 8%;" >
                                  <div class="table-responsive  " >
                    <table  class="table" style="border : 1px solid #cccccc; margin-top : 0%; margin-bottom : 0%;">
                      <thead>
                        <tr>
                            <th style="border : 1px solid #cccccc;">Destinataire    </th>

                         
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                            <td style="border : 1px solid #cccccc;">
                  <ul>
                    <li>Nom : <b>{{client.nom}} {{client.prenom}}</b> </li>
                    <li>Adresse :
                           <address>
                        <p class="font-weight-bold"> {{relay.nom}} </p>
                      <p>  {{relay.adresse}} </p>
                       <p> {{relay.ville}}</p>
                     
                      </address>
                    </li>
              
                  </ul>

                    </td>
                            
                        </tr>
                                  
                      </tbody>
                    </table>
                  </div>
                            <div class="table-responsive" style="margin-top : 2%;">
                    <table  class="table table-bordered "  style="border : 1px solid #cccccc;">
                          <thead>
                        <tr>
                            <th  colspan="2" class="text-center">Informations colis     </th>

                         
                        </tr>
                      </thead>
                      <tbody>
                           <tr>
                      <td>Poids</td>
                      <td> {{shipment.poids}} Kg</td>
                  </tr>
                  <tr>
                      <td>Numero d'expedition</td>
                      <td>{{shipment.n_expedition}}</td>
                  </tr>
                   <tr>
                      <td>Nombre de colis</td>
                      <td>1</td>
                  </tr>
                   <tr>
                      <td>Reference client</td>
                      <td>{{client.id}}</td>
                  </tr>
                   <tr>
                      <td>Numero de suivi</td>
                      <td>{{shipment.id}}</td>
                  </tr>
             
                                  
                      </tbody>
                    </table>
                  </div>
              
                </div>
              </div>
            </div>
     <div class="col-lg-5 grid-margin stretch-card">
              <div class="card border-0">
                <div class="card-body">
                 <div class="navbar-brand brand-logo-mini p-0" style="margin-top : -5%;"><img src="../../public/images/jibLogo.png" alt="logo"/></div>
                  <blockquote class="blockquote blockquote-light  w-100 ">
                  <vue-qrcode v-bind:value="qrValue"  scale="3"/>
                   <!-- <footer class="blockquote-footer">Someone famous in <cite title="Source Title">Source Title</cite></footer>-->
                  </blockquote>
                 
               
                  <div class="row"> <blockquote class="col-lg-9 blockquote blockquote-secondary" >
                    <p class="mb-0 text-dark d-inline">
   Encaissement : <span class="d-inline font-weight-bold" v-if="shipment.cod == 0" >Payé</span>
                 <span  class="font-weight-bold" v-else >A payer</span>
                        </p>
                  </blockquote>
                </div>
                <div class="row">
                    <blockquote class="col-lg-6 blockquote blockquote-light ">
                   <div class="form-check">
  <input class="form-check-input" type="checkbox" value="1" id="flexCheckDefault" style="font-size: 30px;">
  <label class="form-check-label font-weight-bold" for="flexCheckDefault">
    Retour
  </label>
</div>
                  </blockquote>
                </div>
                  
                </div>
               
              </div>
            </div>
           
</div>
 
    <br>
                </div>
              </div>
               </div>
</div>
</div>
    


</template>



<script>
import axios from 'axios';
import VueQrcode from 'vue3-qrcode';
import html2canvas from "html2canvas"
import jspdf from "jspdf"

export default {
  props: {
   // id: Number,
  },
   components: {
    VueQrcode,
  },
      data(){
    return{
    //  id : this.$route.params.id,
    id : 24,
      qrValue :  "",
      shipment :{},
      warehouse : {},
      client : {},
      seller : {},
       relay : {},
   
    }
   },
   mounted(){
     console.log("qr code ")
       axios.create({
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer '+localStorage.getItem("token")
                  }
                 })
     .get("http://127.0.0.1:8000/api/getshipment/"+this.$route.params.id)
     .then(res => {
        // this.warehouse = res.data["warehouse"];
         this.shipment = res.data;
         this.client = res.data["client"];
         this.relay = res.data["relay"];
       console.log(this.shipment);
       //console.log(this.shipment);

     })
   this.getCode();
       
      
   },

   methods : {
       getInfos(id){
              axios.create({
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer '+localStorage.getItem("token")
                  }
                 })
     .get("http://127.0.0.1:8000/api/getshipment/"+id)
     .then(res => {
         this.warehouse = res.data["warehouse"];
         this.shipment = res.data;
         this.client = res.data["client"];
         this.relay = res.data["relay"];
       console.log(this.warehouse);
       //console.log(this.shipment);

     })
       },
           getCode(){
              axios.create({
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer '+localStorage.getItem("token")
                  }
                 })
     .get("http://127.0.0.1:8000/api/ITlv1/"+this.$route.params.id)
     .then(response => {
         this.qrValue = response.data.ciphertext;
         const k =  localStorage.setItem('kp',response.data.pass);//response.data.pass;
          const sa = localStorage.setItem('Sa',response.data.salt);//response.data.salt;
         const i =localStorage.setItem('I',response.data.iv);// response.data.iv;
       console.log(this.qrValue);

     })
       },
       exportPdf(){
          // var pdf = new jspdf('p', 'mm', [950, 1189]);
          var pdf = new jspdf('p', 'pt', [2000, 1189]);
    var element = document.getElementById('pdf');
    var width= element.style.width;
    var height = element.style.height;
    html2canvas(element).then(canvas => {
        var image = canvas.toDataURL('../../public/images/jiblili.png');
        pdf.addImage(image, 'JPEG', 15, 40, width, height);
        pdf.save('facture.pdf');
    });
       }
   }

}
</script>

<style scoped>
@import url("../../public/css/style.css");
@import url("https://unpkg.com/boxicons@2.0.7/css/boxicons.min.css");
@import url("../../public/vendors/mdi/css/materialdesignicons.min.css");
@import url("../../public/vendors/base/vendor.bundle.base.css");
@import url("../../public/vendors/datatables.net-bs4/dataTables.bootstrap4.css");
@import url("https://fonts.googleapis.com/css?family=Roboto:300,400&display=swap");
@import url("https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css");
@import url("../../public/login/css/style.css");
@import url("../../public/login/css/bootstrap.min.css");
@import url("../../public/login/css/style.css");
body{
  background: black;
}


</style>

import { Script } from "vm";