<template>
 <h1> TEST QR </h1>
 <button @click="test">
       {{ !testVar ? 'Scan a qr code' : 'Close qr scann'}}
 </button>
  <div class="stream" v-if="testVar">
     
  <qr-stream @decode="onDecode" class="mb">
    <div style="color: red;" class="frame"></div>
  </qr-stream>
</div>
<div class="result" v-if="testVar">
  Result: {{data}}
</div>

</template>

<script >
import { defineComponent } from 'vue';
//import { QrcodeStream } from 'vue-qrcode-reader';
//import QrReader from 'vue3-qr-reader';
import { QrStream } from 'vue3-qr-reader';
//import { reactive } from 'vue';
//import { toRefs } from 'vue';




export default defineComponent({
  name: 'pageQr',
  components: {
    QrStream,
  },
  data()
{
    return {
        data : "",
        testVar : false,
        //isShowingCamera:false,
    }
},


methods : {
    test(){
//test
console.log("clicked");
this.testVar = !this.testVar;
    },
    onDecode(data){
        this.data = data;


    }
   /* onDecode(test ){
        console.log(test)
    },
     async onInit(promise){
         try{
             console.log(" at onint function")
            await promise
             console.log("init", promise);
         }
         catch(error){
             console.log(error);
             if(error.name === 'NotAllowedError'){
                 //user denied camera access
                 console.log("user denied camera acces");
                 
             }
             else if(error.name === 'NotFoundError'){
                 // no sutable camera device detected
                 console.log("no camera detected");
             }
             else if(error.name === 'NotSupportedError'){
                 // page is not served over https or localhost
                 console.log("page is not served over https or localhost");
             }
             else if(error.name === 'NotReadableError'){
                 // no sutable camera device detected
                 console.log("no camera detected");
             }
             else if(error.name === 'OverconstrainedError'){
                 // did you request the front camera although ther is none
                 console.log("error overconstrained");
             }
             else if(error.name === 'StreamApiNotSupportedError'){
                 // browser seems to be lacking features
                 console.log("error browser");
             }

         }
         finally{
             //hide lading indicator
         }
     },*/
    
    
}
  
});
</script>
<style scoped>
.stream{
    width: 40%;
    height : 40%;
    margin: auto auto;
}
</style>

<!-- Add "scoped" attribute to limit CSS to this component only -->
