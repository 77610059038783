

<template>
              

      <section class="home-section" >
         
         <div class="container-scroller" >
    <!-- partial:partials/_navbar.html -->
    <Header title="Ajout colis" />
    <!-- partial -->
    <div class="container-fluid page-body-wrapper bg-light"    >
      <!-- partial:partials/_sidebar.html -->
   
    
      <!-- partial -->
      <div class="main-panel" style="width : 100%;">
        <!---content wrapper start--->
              
        <div class="content-wrapper">

                  <div class="row" >
            <div class="col-md-12 grid-margin m-1">
              <div class="d-flex justify-content-between flex-wrap row ">
                <div class="d-flex align-items-end flex-wrap col-lg-8">
                
               
              <div class="mr-auto p-2"> <div class="mt-3">
                      <p><i class="mdi mdi-home icon-md" style="color: #6c757d; font-size: 30px;"></i></p>
                    </div>
                    </div>


      


                </div>
                 <div class="col-lg-4 d-flex justify-content-end align-items-end flex-wrap t">
             <div class="dropdown " >
  <button class="btn btn-light  btn-icon-text text-dark dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
    <i class="mdi mdi-calendar-clock text-dark btn-icon-prepend"></i>
    Date
  </button>
  <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
        <li><a class="dropdown-item" href="#" v-on:click="afficherClientsArchives()"> 

  <input class="mr-2" type="radio" name="flexRadioDefault" id="flexRadioDefault1" value=7 v-model="select" :checked="select == 7" @change="changer">
  <label class="form-check-label font-weight-bold" for="flexRadioDefault1" >
    Last 7 days (default)
  </label>
  </a>
        </li>
     <li><a class="dropdown-item" href="#" v-on:click="afficherClientsArchives()" > 

  <input class="mr-2" type="radio" name="flexRadioDefault" id="flexRadioDefault1" value=30 v-model="select" @change="changer">
  <label class="form-check-label font-weight-bold" for="flexRadioDefault1" >
    Last Month
  </label>
  </a>
        </li>
         <li><a class="dropdown-item" href="#" v-on:click="afficherClientsArchives()"> 

  <input class="mr-2" type="radio" name="flexRadioDefault" id="flexRadioDefault1" value=365 v-model="select" @change="changer">
  <label class="form-check-label font-weight-bold" for="flexRadioDefault1" >
    Last Year
  </label>
  </a>
        </li>
         <li><a class="dropdown-item" href="#" v-on:click="afficherClientsArchives()"> 

  <input class="mr-2" type="radio" name="flexRadioDefault" id="flexRadioDefault1" value=1 v-model="select" @change="changer">
  <label class="form-check-label font-weight-bold" for="flexRadioDefault1" >
    Custome date
  </label>
  </a>
        </li>
  </ul>
</div>
                  
                  
                </div>
             
          
              </div>
            </div>
          </div>

          <!----->
          
       <!--------------->
       <div class="row m-0 p-0" v-show="select == 1">
                         <form class="forms-sample d-flex justify-content-end p-0 m-0 "> 
                <div class="form-group  p-0 mx-1 col-lg-2">
<!--                   <label for="input_from" class="p-0 m-0 text-primary font-weight-bold" > From </label>
 -->                  <input type="text" onfocus="(this.type='date')" class="form-control d-inline bg-white " id="input_from" placeholder="Start date " v-model="from" @change="changerDate">
                </div>
                <div class="form-group  p-0 mx-1 col-lg-2">
<!--                   <label for="input_to" class="p-0 m-0 text-primary font-weight-bold" > To </label>
 -->                  <input type="text" onfocus="  (this.type='date')" class="form-control d-inline bg-white" id="input_to" placeholder="End date" v-model="to" @change="changerDate">
                </div>


          </form>
  
    
     </div>
     <div class="row">
       
                       <p class="alert alert-danger text-center font-weight-bold" v-show="error">{{error}}</p>

     </div>
  
 
       <!--------------->
     
          <div class="row">
            <div class=" stretch-card col-lg-12  p-0">
              <div class="card  bg-transparent border border-0">
                <div class="card-body p-0">
                    <div class="d-flex justify-content-end py-2">
                     


                    
                     </div>
                    
  <div class="row mt-2">
    <div class="col-lg-12 ">
    <div class="row px-3 h-100">
        <div class="col-lg-3 mb-1">
            <div class="card l-bg-cherry h-100">
                <a class="card-statistic-3 p-4 data-card h-100" href="#" v-on:click="changerDash(arr1,'Livre')">
                    <!-- <div class="card-icon card-icon-large">
                      <i class="mdi mdi-check-circle"></i></div> -->
                    <div class="mb-0 h-50">
                        <h5 class="card-title mb-0"> 
                        <i class="mdi mdi-check-circle text-success"></i>

                          Livré
                          </h5>
                        <p > Tous les colis livrés</p>
                    </div>
                    <div class="row align-items-center mb-2 d-flex">
                        <div class="col-8">
                            <h2 class="d-flex align-items-center mb-0 text-success">
                                 {{total.livre ? total.livre : 0}}
                            </h2>
                        </div>
                        <div class="col-4 text-right text-dark">
                            <span>{{total.livre ? percentage(total.livre, sum) : 0}}% <i class="fa fa-arrow-up text-success"></i></span>
                        </div>
                    </div>
                    <div class="progress mt-1 " data-height="8" style="height: 8px;">
  <div class="progress-bar bg-success" role="progressbar" v-bind:style="{ width: percentage(total.livre, sum) + '%'}" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"></div>
                    </div>
                </a>
            </div>
        </div>
          <div class="col-lg-3 mb-1">
            <div class="card l-bg-cherry h-100">
                     <a class="card-statistic-3 p-4 data-card h-100" href="#" @click="changerDash(arr2, 'preparation')">
                    <!-- <div class="card-icon card-icon-large">
                      <i class="mdi mdi-check-circle"></i></div> -->
                    <div class="mb-0 h-50">
                        <h5 class="card-title mb-0"> 
                        <i class="mdi mdi-cart" style="color:  #FF007F;"></i>
                          En préparation
                          </h5>
                        <p > Colis en cours de préparation</p>
                    </div>
                    <div class="row align-items-center mb-2 d-flex">
                        <div class="col-8">
                            <h2 class="d-flex align-items-center mb-0" style="color:  #FF007F;">
                                 {{total.prep ? total.prep : 0}}
                            </h2>
                        </div>
                        <div class="col-4 text-right text-dark">
                            <span>{{total.prep ? percentage(total.prep, sum) : 0}}% <i class="fa fa-arrow-up" style="color:  #FF007F;"></i></span>
                        </div>
                    </div>
                    <div class="progress mt-1 " data-height="8" style="height: 8px; ">
  <div class="progress-bar " role="progressbar" style="background: #FF007F;" v-bind:style="{ width: percentage(total.prep, sum)  + '%'}" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"></div>
                    </div>
                </a>
            </div>
        </div>
            <div class="col-lg-3 mb-1">
            <div class="card l-bg-cherry h-100">
                     <a class="card-statistic-3 p-4 data-card h-100" href="#" @click="changerDash(arr3, 'relais')">
                    <!-- <div class="card-icon card-icon-large">
                      <i class="mdi mdi-check-circle"></i></div> -->
                    <div class="mb-0 h-50">
                        <h5 class="card-title mb-0"> 
                        <i class="mdi mdi-map-marker" style="color:  #8031A7;" ></i>
                          Au point relais
                          </h5>
                        <p > Disponibles au point relais</p>
                    </div>
                    <div class="row align-items-center mb-2 d-flex">
                        <div class="col-8">
                            <h2 class="d-flex align-items-center mb-0" style="color:  #8031A7;" >
                               {{total.relay ? total.relay : 0}}
                            </h2>
                        </div>
                        <div class="col-4 text-right text-dark">
                            <span>{{total.relay ? percentage(total.relay, sum) : 0}}% <i class="fa fa-arrow-up" style="color:  #8031A7;" ></i></span>
                        </div>
                    </div>
                    <div class="progress mt-1 " data-height="8" style="height: 8px; ">
  <div class="progress-bar " role="progressbar" style="background: #8031A7;" v-bind:style="{ width: percentage(total.relay, sum) + '%'}" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"></div>
                    </div>
                </a>
            </div>
        </div>
            <div class="col-lg-3 mb-1">
            <div class="card l-bg-cherry h-100">
               <a class="card-statistic-3 p-4 data-card h-100"  href="#" @click="changerDash(arr4, 'depot')">
                    <!-- <div class="card-icon card-icon-large">
                      <i class="mdi mdi-check-circle"></i></div> -->
                    <div class="mb-0 h-50">
                        <h5 class="card-title mb-0"> 
                        <i class="mdi mdi-home-variant text-primary" ></i>
                          Au Centre de tri
                          </h5>
                        <p > Disponibles au depots </p>
                    </div>
                    <div class="row align-items-center mb-2 d-flex">
                        <div class="col-8">
                            <h2 class="d-flex align-items-center mb-0 text-primary" >
                                 {{total.warehouse ? total.warehouse : 0}}
                            </h2>
                        </div>
                        <div class="col-4 text-right text-dark">
                            <span>{{total.warehouse ? percentage(total.warehouse, sum) : 0}}% <i class="fa fa-arrow-up text-primary" ></i></span>
                        </div>
                    </div>
                    <div class="progress mt-1 " data-height="8" style="height: 8px; ">
  <div class="progress-bar bg-primary" role="progressbar" style="width: 25%; background: #FFDE00;" v-bind:style="{ width: percentage(total.warehouse, sum)  + '%'}" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"></div>
                    </div>
                </a>
            </div>
        </div>
    </div>
</div>
  </div>

  <div class="row mt-2">
    <div class="col-lg-12 ">
    <div class="row px-3">
      <div class="col-lg-4 mb-1">
            <div class="card l-bg-cherry h-100">
               <a class="card-statistic-3 p-4 data-card h-100" @click="changerDash(arr5, 'tous')">
                    <!-- <div class="card-icon card-icon-large">
                      <i class="mdi mdi-check-circle"></i></div> -->
                    <div class="mb-0 h-50">
                        <h5 class="card-title mb-0"> 
                        <i class="mdi mdi-package-variant text-secondary"></i>
                          Tous les colis
                          </h5>
                        <p > la totalité des colis</p>
                    </div>
                    <div class="row align-items-center mb-2 d-flex">
                        <div class="col-8">
                            <h2 class="d-flex align-items-center mb-0 text-secondary">
                                {{sum}}
                            </h2>
                        </div>
                        <div class="col-4 text-right">
                            <span>100% <i class="fa fa-arrow-up text-secondary"></i></span>
                        </div>
                    </div>
                    <div class="progress mt-1 " data-height="8" style="height: 8px;">
  <div class="progress-bar bg-secondary" role="progressbar" style="width: 100%;" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"></div>
                    </div>
                </a>
            </div>
        </div>
          <div class="col-lg-4 mb-1">
            <div class="card l-bg-cherry h-100">
                     <a class="card-statistic-3 p-4 data-card h-100" @click="changerDash(arr6, 'encours')">
                    <!-- <div class="card-icon card-icon-large">
                      <i class="mdi mdi-check-circle"></i></div> -->
                    <div class="mb-0 h-50">
                        <h5 class="card-title mb-0"> 
                        <i class="mdi mdi-transfer text-warning"></i>

                          En cours
                          </h5>
                        <p > Les colis pris en charge par nos chauffeurs</p>
                    </div>
                    <div class="row align-items-center mb-2 d-flex">
                        <div class="col-8">
                            <h2 class="d-flex align-items-center mb-0 text-warning">
                                {{total.encours ? total.encours : 0}}
                            </h2>
                        </div>
                        <div class="col-4 text-right">
                            <span> {{total.encours ? percentage(total.encours, sum) : 0}}% <i class="fa fa-arrow-up text-warning"></i></span>
                        </div>
                    </div>
                    <div class="progress mt-1 " data-height="8" style="height: 8px;">
  <div class="progress-bar bg-warning" role="progressbar" style="width: 25%; background: #FFDE00;" v-bind:style="{ width: percentage(total.encours, sum)  + '%'}" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"></div>
                    </div>
                </a>
            </div>
        </div>
        <div class="col-lg-4 mb-1">
            <div class="card l-bg-cherry h-100">
               <a class="card-statistic-3 p-4 data-card h-100" @click="changerDash(arr7, 'retourne')">
                    <!-- <div class="card-icon card-icon-large">
                      <i class="mdi mdi-check-circle"></i></div> -->
                    <div class="mb-0 h-50">
                        <h5 class="card-title mb-0"> 
                        <i class="mdi mdi-close-circle text-danger"></i>
                          Retourné
                          </h5>
                        <p > Les colis  récupérés par l'éxpéditeur</p>
                    </div>
                    <div class="row align-items-center mb-2 d-flex">
                        <div class="col-8">
                            <h2 class="d-flex align-items-center mb-0 text-danger">
                                {{total.retourne ? total.retourne : 0}}
                            </h2>
                        </div>
                        <div class="col-4 text-right">
                            <span>{{total.retourne ? percentage(total.retourne, sum) : 0}} % <i class="fa fa-arrow-up text-danger"></i></span>
                        </div>
                    </div>
                    <div class="progress mt-1 " data-height="8" style="height: 8px;">
  <div class="progress-bar bg-danger" role="progressbar" v-bind:style="{ width: percentage(total.retourne, sum) + '%'}" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"></div>
                    </div>
                </a>
            </div>
        </div>
           
    </div>
</div>
  </div>
                 
                                                     
                   
<!--                                   
  <div class="row m-3">
    <div class="col-lg-3">
      <a href="#" class="data-card  p-3 ">
    <h3 class="my-2 ">270</h3>
    <h4 class="text-right">Care Facilities</h4>
    <p>Aenean lacinia bibendum nulla sed consectetur.</p>
    <span class="link-text text-right">
      View All Providers
      <svg width="25" height="16" viewBox="0 0 25 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M17.8631 0.929124L24.2271 7.29308C24.6176 7.68361 24.6176 8.31677 24.2271 8.7073L17.8631 15.0713C17.4726 15.4618 16.8394 15.4618 16.4489 15.0713C16.0584 14.6807 16.0584 14.0476 16.4489 13.657L21.1058 9.00019H0.47998V7.00019H21.1058L16.4489 2.34334C16.0584 1.95281 16.0584 1.31965 16.4489 0.929124C16.8394 0.538599 17.4726 0.538599 17.8631 0.929124Z" fill="#753BBD"/>
</svg>
    </span>
  </a>
    </div>
      <div class="col-lg-3">
      <a href="#" class="data-card  p-3 ">
    <h3 class="my-2">270</h3>
    <h4 class="text-right">Care Facilities</h4>
    <p>Aenean lacinia bibendum nulla sed consectetur.</p>
    <span class="link-text text-right">
      View All Providers
      <svg width="25" height="16" viewBox="0 0 25 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M17.8631 0.929124L24.2271 7.29308C24.6176 7.68361 24.6176 8.31677 24.2271 8.7073L17.8631 15.0713C17.4726 15.4618 16.8394 15.4618 16.4489 15.0713C16.0584 14.6807 16.0584 14.0476 16.4489 13.657L21.1058 9.00019H0.47998V7.00019H21.1058L16.4489 2.34334C16.0584 1.95281 16.0584 1.31965 16.4489 0.929124C16.8394 0.538599 17.4726 0.538599 17.8631 0.929124Z" fill="#753BBD"/>
</svg>
    </span>
  </a>
    </div>
     <div class="col-lg-3">
      <a href="#" class="data-card  p-3 ">
    <h3 class="my-2">270</h3>
    <h4 class="text-right">Care Facilities</h4>
    <p>Aenean lacinia bibendum nulla sed consectetur.</p>
    <span class="link-text text-right">
      View All Providers
      <svg width="25" height="16" viewBox="0 0 25 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M17.8631 0.929124L24.2271 7.29308C24.6176 7.68361 24.6176 8.31677 24.2271 8.7073L17.8631 15.0713C17.4726 15.4618 16.8394 15.4618 16.4489 15.0713C16.0584 14.6807 16.0584 14.0476 16.4489 13.657L21.1058 9.00019H0.47998V7.00019H21.1058L16.4489 2.34334C16.0584 1.95281 16.0584 1.31965 16.4489 0.929124C16.8394 0.538599 17.4726 0.538599 17.8631 0.929124Z" fill="#753BBD"/>
</svg>
    </span>
  </a>
    </div>
     <div class="col-lg-3">
      <a href="#" class="data-card  p-3 ">
    <h3 class="my-2">270</h3>
    <h4 class="text-right">Care Facilities</h4>
    <p>Aenean lacinia bibendum nulla sed consectetur.</p>
    <span class="link-text text-right">
      View All Providers
      <svg width="25" height="16" viewBox="0 0 25 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M17.8631 0.929124L24.2271 7.29308C24.6176 7.68361 24.6176 8.31677 24.2271 8.7073L17.8631 15.0713C17.4726 15.4618 16.8394 15.4618 16.4489 15.0713C16.0584 14.6807 16.0584 14.0476 16.4489 13.657L21.1058 9.00019H0.47998V7.00019H21.1058L16.4489 2.34334C16.0584 1.95281 16.0584 1.31965 16.4489 0.929124C16.8394 0.538599 17.4726 0.538599 17.8631 0.929124Z" fill="#753BBD"/>
</svg>
    </span>
  </a>
    </div>
    
          
     </div> -->
     <!------------------>
     <!-- <div class="row mr-3">
        <div class="col-lg-6">
      <a href="#" class="data-card  p-3 ">
    <h3 class="my-2">270</h3>
    <h4 class="text-right">Care Facilities</h4>
    <p>Aenean lacinia bibendum nulla sed consectetur.</p>
    <span class="link-text text-right">
      View All Providers
      <svg width="25" height="16" viewBox="0 0 25 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M17.8631 0.929124L24.2271 7.29308C24.6176 7.68361 24.6176 8.31677 24.2271 8.7073L17.8631 15.0713C17.4726 15.4618 16.8394 15.4618 16.4489 15.0713C16.0584 14.6807 16.0584 14.0476 16.4489 13.657L21.1058 9.00019H0.47998V7.00019H21.1058L16.4489 2.34334C16.0584 1.95281 16.0584 1.31965 16.4489 0.929124C16.8394 0.538599 17.4726 0.538599 17.8631 0.929124Z" fill="#753BBD"/>
</svg>
    </span>
  </a>
    </div>
     <div class="col-lg-6">
      <a href="#" class="data-card  p-3 ">
    <h3 class="my-2">270</h3>
    <h4 class="text-right">Care Facilities</h4>
    <p>Aenean lacinia bibendum nulla sed consectetur.</p>
    <span class="link-text text-right">
      View All Providers
      <svg width="25" height="16" viewBox="0 0 25 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M17.8631 0.929124L24.2271 7.29308C24.6176 7.68361 24.6176 8.31677 24.2271 8.7073L17.8631 15.0713C17.4726 15.4618 16.8394 15.4618 16.4489 15.0713C16.0584 14.6807 16.0584 14.0476 16.4489 13.657L21.1058 9.00019H0.47998V7.00019H21.1058L16.4489 2.34334C16.0584 1.95281 16.0584 1.31965 16.4489 0.929124C16.8394 0.538599 17.4726 0.538599 17.8631 0.929124Z" fill="#753BBD"/>
</svg>
    </span>
  </a>
    </div>
     </div> -->
     

 


                </div>
              </div>
            </div>
          </div>
          <!--------->
            
          <!------->
          <div class="row mx-2 mt-3 mb-0" >
            <p v-if="chargement" class="text-primary text-center font-weight-bold">Chargement en cours...</p>
            <div class=" stretch-card col-lg-4 p-0">
              <div class="card  bg-transparent border border-0 ">
                     <div class="card-body">
                  <p class="card-title">Livraison</p>
                  <p class="mb-4">Les pourcentages de chaque mode de livraison changent en fonction  du statut choisi. </p>
  
<label for="">
 A domicile
</label>
<div class="progress">
  <div class="progress-bar " role="progressbar" v-bind:style="{ width: compt.d + '%'}" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">{{compt.d}}%</div>
</div>
<br>


<label for="">
 Au point relais
</label>
<div class="progress">
  <div class="progress-bar" role="progressbar"  v-bind:style="{ width:  compt.r + '%'}" aria-valuenow="50" aria-valuemin="0" aria-valuemax="100"> {{compt.r}}%</div>
</div>

                </div>
              </div>
            </div>
            
            <div class=" stretch-card col-lg-4 p-0 pre-scrollable">
              <div class="card  bg-transparent border border-0 ">
                 <div class="card-body">
                  <p class="card-title">Classement des points relais de livraison</p>
  
                  <div class="container">
  <table class="table">
    <!-- <thead>
      <th colspan="2" class="text-center text-danger" >Aucune ville</th>
    </thead> -->
 
    <tbody>
      <tr  v-for="(item, index) in r" :key="item.id" v-bind:index="index">
        <td>{{item.relay.nom}}</td>
        <td>{{item.relay.ville.city}}</td>
        <td class="text-right">{{percentage(item.count, resul)}} %</td>
      </tr>
  
   
    </tbody>
  </table>
</div>                 
                </div>
              </div>
            </div>
            
            <div class=" stretch-card col-lg-4 p-0" v-if="statut == 'tous'">
              <div class="card  bg-transparent border border-0 ">
                     <div class="card-body">
                        <p class="card-title">Clois annulés</p>
                        <label for=""> Perdu </label>
                        <div class="progress">
                          <div class="progress-bar " role="progressbar" v-bind:style="{ width: percentage(total.perdu, sum) + '%'}" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">{{percentage(total.perdu, sum)}}%</div>
                        </div>
                        <br>
                        <label for="">
                        Annulé
                        </label>
                        <div class="progress">
                          <div class="progress-bar" role="progressbar"  v-bind:style="{ width:  percentage(total.annule, sum) + '%'}" :aria-valuenow="percentage(total.annule, sum) " aria-valuemin="0" aria-valuemax="100"> {{percentage(total.annule, sum)}}%</div>
                        </div>
                     </div>
              </div>
            </div>
             <div class=" stretch-card col-lg-4 p-0" v-if="statut == 'preparation'">
              <div class="card  bg-transparent border border-0 ">
                     <div class="card-body">
                        <p class="card-title">Clois chez l'expéditeur</p>
                        <label for=""> En preparation </label>
                        <div class="progress">
                          <div class="progress-bar " role="progressbar" style="background: #FF007F;"  v-bind:style="{ width: percentage(count.preparation, sum) + '%'}" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">{{percentage(count.preparation, sum)}}%</div>
                        </div>
                        <br>
                        <label for=""> En attente enlevement </label>
                        <div class="progress">
                          <div class="progress-bar " role="progressbar"  style="background: #FF007F;" v-bind:style="{ width:  percentage(count.enlv, sum) + '%'}" aria-valuenow="50" aria-valuemin="0" aria-valuemax="100"> {{percentage(count.enlv, sum)}}%</div>
                        </div>
                     </div>
              </div>
            </div>

            <div class=" stretch-card col-lg-4 p-0" v-if="statut == 'depot'">
              <div class="card  bg-transparent border border-0 ">
                     <div class="card-body">
                        <p class="card-title">Clois au centre de tri</p>
                        <label for=""> Colis à livrer </label>
                        <div class="progress">
                           <div class="progress-bar" role="progressbar"   v-bind:style="{ width: percentage(count.warehouse_e, sum) + '%'}" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">{{percentage(count.warehouse_e, sum)}}%</div>
                        </div>
                        <br>
                        <label for="">  Colis retournés </label>
                        <div class="progress">
                           <div class="progress-bar " role="progressbar"   v-bind:style="{ width:  percentage(count.warehouse_r, sum) + '%'}" aria-valuenow="50" aria-valuemin="0" aria-valuemax="100"> {{percentage(count.warehouse_r, sum)}}%</div>
                        </div>
                     </div>
              </div>
            </div>
    <!--------------------------------->
          <div class=" stretch-card col-lg-4 p-0" v-if="statut == 'relais'">
              <div class="card  bg-transparent border border-0 ">
                     <div class="card-body">
                        <p class="card-title">Colis au points relais</p>
                        <label for=""> Disponible au point relais </label>
                        <div class="progress">
                           <div class="progress-bar " role="progressbar" style="background: #8031A7;"  v-bind:style="{ width: percentage(count.dispo, sum) + '%'}" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">{{percentage(count.dispo, sum)}}%</div>
                        </div>
                        <br>
                        <label for=""> Non recuperé </label>
                        <div class="progress">
                        <div class="progress-bar" role="progressbar"  style="background: #8031A7;" v-bind:style="{ width:  percentage(count.non, sum) + '%'}" aria-valuenow="50" aria-valuemin="0" aria-valuemax="100"> {{percentage(count.non, sum)}}%</div>
                        </div>
                        <br>
                        <label for="">Refusé par le client</label>
                      <div class="progress">
                        <div class="progress-bar" role="progressbar" style="background: #8031A7;"  v-bind:style="{ width:  percentage(count.refus, sum) + '%'}" aria-valuenow="50" aria-valuemin="0" aria-valuemax="100"> {{percentage(count.refus, sum)}}%</div>
                      </div>
                      <br>
                      <label for="">
                        Recepetion retour client
                        </label>
                        <div class="progress">
                          <div class="progress-bar" role="progressbar"  style="background: #8031A7;" v-bind:style="{ width:  percentage(count.retourclient, sum) + '%'}" aria-valuenow="50" aria-valuemin="0" aria-valuemax="100"> {{percentage(count.retourclient, sum)}}%</div>
                        </div>
                     </div>
              </div>
            </div>
    <!--------------------------------->
    
            <div class=" stretch-card col-lg-4 p-0" v-if="statut == 'encours'">
              <div class="card  bg-transparent border border-0 ">
                     <div class="card-body">
                        <p class="card-title">Clois chez les livreurs</p>
                             <label for=""> Ramassage en cours</label>
                              <div class="progress">
                                <div class="progress-bar bg-warning" role="progressbar"   v-bind:style="{ width: percentage(count.ramassage, sum) + '%'}" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">{{percentage(count.ramassage, sum)}}%</div>
                              </div>
                              <br>
                              <label for="">
                              Distribution en cours
                              </label>
                              <div class="progress">
                                <div class="progress-bar bg-warning" role="progressbar"   v-bind:style="{ width:  percentage(count.distr, sum) + '%'}" aria-valuenow="50" aria-valuemin="0" aria-valuemax="100"> {{percentage(count.distr, sum)}}%</div>
                              </div>
                              <br>
                              <label for="">
                              Retour centre de tri en cours
                              </label>
                              <div class="progress">
                                <div class="progress-bar bg-warning" role="progressbar"  v-bind:style="{ width:  percentage(count.retour1, sum) + '%'}" aria-valuenow="50" aria-valuemin="0" aria-valuemax="100"> {{percentage(count.retour1, sum)}}%</div>
                              </div>
                              <br>
                              <label for="">
                              Retour cexpediteur en cours
                              </label>
                              <div class="progress">
                                <div class="progress-bar bg-warning" role="progressbar"   v-bind:style="{ width:  percentage(count.retour2, sum) + '%'}" aria-valuenow="50" aria-valuemin="0" aria-valuemax="100"> {{percentage(count.retour2, sum)}}%</div>
                              </div>
                              <br>
                              <label for="">
                              Livraison en cours
                              </label>
                              <div class="progress">
                                <div class="progress-bar bg-warning" role="progressbar"   v-bind:style="{ width:  percentage(count.livr, sum) + '%'}" aria-valuenow="50" aria-valuemin="0" aria-valuemax="100"> {{percentage(count.livr, sum)}}%</div>
                              </div>
                        
                     </div>
              </div>
            </div>
             <div class=" stretch-card col-lg-4 p-0" v-if="statut == 'Livre'">
              <div class="card  bg-transparent border border-0 ">
                     <div class="card-body">
                       <p class="card-title">Classement des vendeurs</p>
                         <div class="container">
                            <table class="table">
                              <tbody>
                               <tr  v-for="(item, index) in s" :key="item.id" v-bind:index="index">
                                <td>{{item.seller.nom}}</td>
                                <td class="text-right">{{percentage(item.count, resul)}} %</td>
                              </tr>
                              </tbody>
                            </table>
                          </div>  
                     </div>
              </div>
            </div>
            <div class=" stretch-card col-lg-4 p-0" v-if="statut == 'retourne'">
              <div class="card  bg-transparent border border-0 ">
                     <div class="card-body">
                       <p class="card-title">Classement des vendeurs</p>
                         <div class="container">
                            <table class="table">
                              <tbody>
                               <tr  v-for="(item, index) in s" :key="item.id" v-bind:index="index">
                                <td>{{item.seller.nom}}</td>
                                <td class="text-right">{{percentage(item.count, resul)}} %</td>
                              </tr>
                              </tbody>
                            </table>
                          </div>  
                     </div>
              </div>
            </div>
       
       
          </div>

           <div class="d-flex flex-wrap flex-md-nowrap justify-content-center justify-content-sm-between align-items-center">
          <div class="custom-control custom-checkbox mr-3">
            <!-- <input class="custom-control-input" type="checkbox" id="notify_me" checked="">
            <label class="custom-control-label" for="notify_me">Notify me when order is delivered</label> -->
          </div> 
          <div class="text-left text-sm-right"><a class="btn btn-outline-primary btn-rounded btn-sm"  data-toggle="modal" data-target="#orderDetails">
            <router-link to="/shipment">View Order Details</router-link></a></div>
        </div>
        
        </div>

        <!-- content-wrapper ends -->
        <!-- partial:partials/_footer.html -->
      <Footer />
        <!-- partial -->
      </div>
      <!-- main-panel ends -->
    </div>
    <!-- page-body-wrapper ends -->
  </div>
        
      </section>
     

</template>

<script>
//import Menu from '@/components/Menu.vue'; 
import Header from '@/components/Header.vue'; 
import Footer from '@/components/Footer.vue'; 
import axios from 'axios';
import $ from 'jquery';
import Swal from 'sweetalert2';







export default {

  components : {
    //Menu,
    Header,
    Footer,
    //Datepicker
  },
  data()  {
    return {

    id : 1,
    from : '',
    to : '',
    items: [],
    select: 7,
    count: {},
    compt:{},
    t: {},
    sum : 0,
    search : "",
    r:{},
    s:{},
    resul:0,
    l:{},
    somme:0,
    arr1 : ["Livre"],
    arr2 : ["En cours de preparation", "En attente enlevement"],
    arr3 : ["Disponible au point relais", "Reception retour client", "Non recupere", "Refusé par le client"],
    arr4 : ["Arrive au centre de tri", "Retourne au centre de tri"],
    arr5 : [],
    arr6 : ["Ramassage en cours", "En cours de distribution", "Livraison en cours", "Retour centre de tri en cours", "Retour expediteur en cours"],
    arr7 : ["Retourne"],
    error:"",
    showDate:false,
    chargement : false,
    statut: "tous",
    total: {},
    }
  },
created(){
  //
  this.countStatut();
  this.changerDash(this.arr5, 'tous')
},

mounted(){
 // this.$store.commit('set_loggedIn', false);
 ////var macaddress = require('macaddress')

 
  this.dash("relay_id", "relay");
  this.dash("client_id", "client");
  this.livraison()
  //

  


 
},


methods : {

  changer(){
   console.log(this.select);
   this.countStatut();
    this.dash("relay_id", "relay");
  this.dash("client_id", "client");
  this.livraison()
 },

 countStatut(){
   this.t.select = this.select;
  this.t.from = this.from;
  this.t.to = this.to;
  this.$store.dispatch('postItem', {//data to be sended
  nameRoute : '/api/statut',
  t : this.t
      })    
     .then(res => {
     // this.isLoading = false;
     console.log(res.data)
        this.verificationStatut(res.data)
     })
     .catch(err => {
          // this.isLoading = false;
          if (!err.response) this.error = "Problème réseau, Impossible de joindre le serveur" 
  
         else  this.error = "Une erreur s'est produite ! "

   })
 },
livraison(){
  this.somme= 0;
   this.t.select = this.select;
  this.t.from = this.from;
  this.t.to = this.to;
  this.t.items = this.items;
  this.$store.dispatch('postItem', {//data to be sended
  nameRoute : '/api/livraison',
  t : this.t
      })    
     .then(res => {
     // this.isLoading = false;
     console.log(res.data)
     this.somme = res.data.reduce( ( sum, obj ) => sum + obj.count , 0)
     this.l=res.data
     this.veriflivraison(this.somme);
     })
     .catch(err => {
          // this.isLoading = false;
          if (!err.response) this.error = "Problème réseau, Impossible de joindre le serveur" 
          else  this.error = "Une erreur s'est produite ! "

   })
 },
 veriflivraison(s){
   this.compt.d = 0;
   this.compt.r = 0;
   var l = this.l;
     for(let i in l)
          {
              var type = l[i]["type_expedition"];
              if( type == "domicile") this.compt.d= this.percentage(l[i]["count"], s);
              if( type == "point relais") this.compt.r= this.percentage(l[i]["count"], s);
 
         
          }
          this.chargement = false;

 },
 dash(search, table){
   this.chargement = true;
   this.t.select = this.select;
  this.t.from = this.from;
  this.t.to = this.to;
  this.t.items = this.items;
  this.t.search = search;
  this.t.table = table
  this.$store.dispatch('postItem', {//data to be sended
  nameRoute : '/api/dash',
  t : this.t
      })    
     .then(res => {
     this.chargement = false;
     console.log(res.data)
     this.resul = res.data.reduce( ( sum, obj ) => sum + obj.count , 0)
        if(search == "relay_id") this.r = res.data;
        if(search == "seller_id") this.s = res.data;

       
     })
     .catch(err => {
          this.chargement = false;
          if (!err.response) this.error = "Problème réseau, Impossible de joindre le serveur" 
          else this.error = "Une erreur s'est produite ! "

   })
 },

getItem(element, id){
  var ville ="";
      this.$store.dispatch('getItem', {//data to be sended
      nameRoute : '/api/'+element+'/'+id,
      })    
     .then(res => {
       ville = res.data.ville.city;
       console.log(ville)
            return ville


     });

    },
 verificationStatut(logs){
   console.log("test")
   this.total.relay = 0;
   this.total.encours = 0;
   this.count.preparation = 0;
   this.total.warehouse = 0;
   this.count.warehouse_e = 0;
   this.count.warehouse_r = 0;
   this.total.retourne = 0;
   this.total.livre = 0;
   this.count.dispo = 0;
   this.count.non = 0;
   this.count.refus = 0;
   this.count.retourclient = 0;
   this.count.distr = 0;
   this.count.enlv = 0;
   this.count.livr = 0;
   this.count.ramassage = 0;
   this.count.retour1 = 0;
   this.count.retour2 = 0;
   this.total.perdu = 0;
   this.total.annule = 0;
   this.total.prep = 0;
         
   const reducer = (previousValue, currentValue) => previousValue + currentValue;
     for(let i in logs)
          {
              var statut = logs[i]["statut"];
              console.log(logs[i]["statut"]);
              if( statut == "Livre") this.total.livre = logs[i]["count"];
              if( statut == "Arrive au centre de tri") this.count.warehouse_e =  logs[i]["count"];
              if( statut == "Retourne au centre de tri") this.count.warehouse_r = logs[i]["count"];
              if( statut == "Retourne") this.total.retourne = logs[i]["count"];
              if( statut == "Disponible au point relais") this.count.dispo =  logs[i]["count"];
              if( statut == "Non recupere")  this.count.non = logs[i]["count"];
              if( statut == "Reception retour client") this.count.retourclient = logs[i]["count"];
              if( statut == "Refuse par le client") this.count.refus =  logs[i]["count"];
              if( statut == "En cours de preparation") this.count.preparation = logs[i]["count"];
              if( statut == "En cours de distribution") this.count.distr =  logs[i]["count"];
              if( statut == "En attente enlevement") this.count.enlv =  logs[i]["count"];
              if( statut == "Livraison en cours") this.count.livr =  logs[i]["count"];
              if( statut == "Ramassage en cours") this.count.ramassage =  logs[i]["count"];
              if( statut == "Retour centre de tri en cours") this.count.retour1 =  logs[i]["count"];
              if( statut == "Retour expediteur en cours") this.count.retour2 =  logs[i]["count"];
              if( statut == "Perdu") this.total.perdu =logs[i]["count"];
              //this.total.perdu =10;
              if( statut == "Annule") this.total.annule =  logs[i]["count"];
         
          }
          // console.log(this.count)
          this.total.prep = this.count.enlv + this.count.preparation;
          this.total.warehouse = this.count.warehouse_e + this.count.warehouse_r;
          this.total.relay = this.count.dispo + this.count.non + this.count.refus + this.count.retourclient;
          this.total.encours = this.count.distr + this.count.livr + this.count.ramassage + this.count.retour1 + this.count.retour2
          this.sum = Object.values(this.total).reduce((a, b) => a + b, 0);
          console.log("count", this.count);

          //pourcentages



          
      },

 percentage(partialValue, totalValue) {
   if(totalValue == 0) return 0;
   return ((100 * partialValue) / totalValue).toFixed(2);
},



changerDash(arr, statut){
      this.chargement = true;
  if(arr) this.items= arr;
  this.dash("relay_id", "relay");
  this.dash("seller_id", "seller");
  this.livraison();
  this.statut = statut;
},

changerDate(){
  this.error="";
  var to = this.to;
  var from = this.from;
  if(to){
    if(!from) this.error = "Selectionnez une date de début";
    else if(from>to){
      this.error = "Erreur !! La date de début est supérieure de la date de fin!";
    }
    else{
      this.countStatut();
       this.dash("relay_id", "relay");
    this.dash("client_id", "client");
  this.livraison()
    }
  }
}

     

 
}
}
</script>
<style scoped lang="scss">
.home-section{
  position: relative;
  background: #E4E9F7;
  min-height: 100vh;
  top: 0;
  left: 78px;
  width: calc(100% - 78px);
  transition: all 0.5s ease;
  z-index: 2;
}
.sidebar.open ~ .home-section{
  left: 250px;
  width: calc(100% - 250px);
}




.home-section .text{
  display: inline-block;
  color: #11101d;
  font-size: 25px;
  font-weight: 500;
  margin: 0px;
}
/* serachbar */
 input[type="date"]:before {
    content: attr(placeholder) !important;
    color: gray;

    
  }
  input[type="date"]:focus:before,
  input[type="date"]:valid:before {
    content: "";
  }


.input-search{
  border: white;
  background-color: transparent;
  font-family: ROBOTO;
  color:black;
  font-weight: normal;
    box-shadow: 0px 15px 10px -15px #111;    
}

.input-search::placeholder{
  font-family: ROBOTO;
  color:black;
  font-weight: bolder;
  font-size: 14px;
}
input::placeholder{
  font-family: ROBOTO;
  font-style: normal;
  color:gray;
  font-size: 14px;
}
.input-search:-webkit-autofill:active{
  font-family: ROBOTO;
  color:darkslategray;
  font-weight: normal;
  font-size: 14px;
  background: darkslategray;
  
}
.pre-scrollable {
    max-height: 500px;
    overflow-y: scroll;
}


 
input[type=checkbox]:checked {
  /*CSS after CHECKED*/
  color: #ffd369;
}

.dropdown-toggle:hover, input[type=search].input-search:hover, .dropdown-toggle:active{
  background: #d7dbdc;
  font-weight: normal;
}
a{
  cursor : context-menu;
}
a:hover {
  color : rgb(255, 255, 255) !important;
}

.modal-mask {
     position: fixed;
     z-index: 9998;
     top: 0;
     left: 0;
     width: 100%;
     height: 100%;
     background-color: rgba(0, 0, 0, .5);
     display: table;
     transition: opacity .3s ease;
   }

   .modal-wrapper {
     display: table-cell;
     vertical-align: middle;
   }

   .data-card {
  display: flex;
  flex-direction: column;
  // max-width: 20em;
  max-height: 15em;
  overflow: hidden;
  border-radius: .5em;
  text-decoration: none;
  background: white;
  transition: transform .45s ease, background .45s ease;
  
  h3 {
    color: #2E3C40;
    font-size: 2.2em;
    font-weight: 600;
    transition: color .45s ease, border .45s ease;
  }

  h4 {
    color: #627084;
    text-transform: uppercase;
    font-size: 1em;
    font-weight: 800;
    line-height: 1;
    letter-spacing: 0.1em;
    margin: 0 0 1.2em;
    transition: color .45s ease;
  }

  p {
    opacity: 0;
    color: #E4E9F7;
    font-weight: 600;
  }

  .link-text {
    display: block;
    color: #fbf8ff;
    font-size: 1.125em;
    font-weight: 600;
    line-height: 1.2;
    margin: 0 0;
    transition: color .45s ease;

    svg {
      margin-left: .5em;
      transition: transform .6s ease;
      color: #FFFFFF;
      
      path {
        transition: fill .45s ease;
        color : #FFFFFF;
      }
    }
  }
  
  &:hover {
    background: #2E3C40;
    transform: scale(1.02);
    
    h5 {
      color: #FFFFFF;
    }

    h4 {
      color: #FFFFFF;
    }

    p {
      opacity: 1;
      transform: none;
    }
    span{
      color: #FFFFFF;
    }

    .link-text {
      color: #FFFFFF;

      svg {
        animation: point 1.25s infinite alternate;
        
        path {
          fill: #FFFFFF;
        }
      }
    }
  }
}

@keyframes point {
  0% {
   transform: translateX(0);
  }
  100% {
    transform: translateX(.125em);
  }
}
input[type="date"]:before {
    content: "" !important;
    color: transparent;

    
  }
  input[type="date"]:focus:before,
  input[type="date"]:valid:before {
    content: "";
  }
  input{
    border: none;
    padding: 7%;
  }
  input::placeholder{
  font-family: ROBOTO;
  color:rgb(172, 37, 71);
  font-weight: bolder;
  font-size: 14px;
}
input:focus{
  border: none;
  box-shadow: inherit;
}

</style>
import { Script } from "vm";