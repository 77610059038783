import { createApp } from 'vue'
import App from './App.vue'
import Login from './Login.vue'
import router from './router'
import store from './store'
import VuexPersistence from 'vuex-persist'
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap";
import Notifications from '@kyvg/vue3-notification'
//import VueGoogleMaps from '@fawmi/vue-google-maps'
import { ComboBoxPlugin } from '@syncfusion/ej2-vue-dropdowns';





createApp(App).use(store).use(router).use(Notifications).use(ComboBoxPlugin)/* .use(VueGoogleMaps, {
    load: {
      key: 'API KEY HERE',
    },
  }) */.mount('#app')
