import { createStore } from 'vuex';
import axios from 'axios';
import { resolveComponent } from '@vue/runtime-core';
import VuexPersistence from 'vuex-persist';
import router from '../router'



export default createStore({
  state: {
   loggedIn :false,
   user : null,
   token : null,
   userAcc : null,
   profile : null,
   link : "http://127.0.0.1:8000",
   types : ["admin"] as any,
   ressource : [] as any,
   permissions : null,
   Hote : null,
   menuItem : null,
   login_time:null,
    
  },
  plugins: [new VuexPersistence().plugin],
  mutations: {
   set_user(state, payload){
     state.user = payload;
   },

   set_userAcc(state, payload){
    state.userAcc = payload;
  },
  set_link(state, payload){
    state.link = payload;
  },
  set_profile(state, payload){
    state.profile = payload;
  },

   set_token(state, payload){
    state.token = payload;
  },

  set_loggedIn(state, payload){
    state.loggedIn = payload;
  },
  set_permissions(state, payload){
    state.permissions = payload;
  },
  set_Hote(state, payload){
    state.Hote = payload;
  },
  set_ItemMenu(state, payload){
    state.menuItem = payload;
  },
  set_loginTime(state, payload){
    state.login_time = payload;
  },

  addType(state, type){
    state.types.push(type);

  },

  removeType(state, index){
    state.types.splice(index, 1);
  },

  addResource(state, resource){
    state.ressource.push(resource);

  },

  removeResource(state, index){
    state.ressource.splice(index, 1);
  },
    
  },

  actions: {
    //commit to call mutations
    performLogin({commit, state}, playload){
     //commit('set_link', "https://cargopro-app.herokuapp.com");
     //commit('set_link', "http://jibliliexpress.ma/public");
    // commit('set_link', "http://127.0.0.1:8000");
     commit('set_link', "https://bojibexp.jibliliexpress.ma/public");
     

      //promise to return res and err
      return new  Promise ((resolve, reject) => {
        console.log("the new link is ",state.link)
        //axios.post('https://cargopro-app.herokuapp.com/api/login', {
        axios.post(state.link+ playload.nameRoute, {
          //pauload : les données qui sont envoyées comment arguments
          email: playload.email,
          password : playload.password,
  
        })
        .then( res => {
         // console.log(res.data.profile.permissions);
          commit('set_token', res.data.access_token);
          commit('set_user', res.data.user);
          commit('set_userAcc', res.data.details);
          commit('set_profile', res.data.profile);
          commit('set_Hote', res.data.user.mac_address);
          //console.log(res.data.user.last_cnx)
          commit('set_loginTime', res.data.user.last_cnx);
         // console.log("adresse mac", res.data.user.mac_address)
          if(res.data.profile.type_user == 'admin' || res.data.profile.type_user == 'manager'|| res.data.profile.type_user == 'back office' || res.data.profile.type_user == 'sous back office') commit('set_loggedIn', true);
          commit('set_permissions', res.data.profile.permissions);
          resolve(res);
        

         /* console.log(res.data);
          
           console.log(res.data.user.id)
          const token = localStorage.setItem('token',res.data.access_token)
          const user = localStorage.setItem('userID',res.data.user.id)
         this.$router.push("/d"); */
        })
        /*
        this.$store.dispatch('retrieveToken', {
          email: this.email,
          password: this.password,
        })*/
        .catch(err => {
          console.log(err)
          console.log(this.state.ressource);
          reject(err)
         // reject(JSON.stringify(err.response.status));
           /* if(JSON.stringify(err.response.status)==401){this.error = "L'email ou le mot de passe est incorrect ! ";}
          else{
            this.error = "Une erreur s'est produite lors de la connexion au serveur. Réssayez plus tard, si le probleme persiste veuillez contacter l'administrateur! "
          }
          if (!err.res) {
              // network error
            /*   if(JSON.stringify(err.response.status)==(401)){this.error = "L'email ou le mot de passe est incorrect ! ";}
             else if(JSON.stringify(err.response.status)==(500)){this.error = "Une erreur s'est produite lors de la connexion à la base de données. Réssayez plus tard, si le probleme persiste veuillez contacter l'administrateur.";}
             else {            this.error = "Une erreur s'est produite lors de la connexion au serveur. Réssayez plus tard, si le probleme persiste veuillez contacter l'administrateur. "+"\n"+"net::ERR_CONNECTION_REFUSED";
  } 
          }  */
          
        })
  
      })

    },

    performLogout({commit, state}){

      commit("set_token", null);
          commit("set_user", null);
          commit("set_userAcc", null);
          commit("set_loggedIn", false);
          commit("set_ItemMenu", null);
          router.push("/");
      return new Promise ((resolve, reject) => {
        axios.post(state.link + '/api/logout', {
          token : state.token
          
  
        })
        .then ( res => {
          
          
          resolve(res);
        })
        .catch ( err => {
          reject(err);
        })
      })

    },
    Logout({commit, state}){
      return new Promise ((resolve, reject) => {
        
          commit("set_token", null);
          commit("set_user", null);
          commit("set_userAcc", null);
          commit("set_loggedIn", false);
          
      })

    },

    getItem({commit, state}, playload){
      //promise to return res and err
      return new  Promise ((resolve, reject) => {
        axios.create({
          headers: {
              'Content-Type': 'application/json',
              'Authorization': 'Bearer '+state.token
          }
      })
       .get(state.link+ playload.nameRoute)
        .then( res => {
          resolve(res);
        
        })
      
        .catch(err => {
          reject(err);
      
          
        })
  
      })

    },

    idItem({commit, state}, playload){
      return new  Promise ((resolve, reject) => {
      axios.create({
          headers: {
              'Content-Type': 'application/json',
              'withCredentials': true,
              'Authorization': 'Bearer '+state.token
          }
      })
      .post(state.link+ playload.nameRoute)
      .then( res => {
     
          resolve(res);

        })

        .catch(err => {
          reject(err);
 
          
        })
  
      })

    },

    postItem({commit, state}, playload){
      return new  Promise ((resolve, reject) => {
      axios.create({
          headers: {
              'Content-Type': 'application/json',
              'Authorization': 'Bearer '+state.token
          }
      })
      .post(state.link+ playload.nameRoute, playload.t)
      .then( res => {
     
          resolve(res);

        })

        .catch(err => {
          reject(err);
 
          
        })
  
      })

    },

    postFile({commit, state}, playload){
      return new  Promise ((resolve, reject) => {
      axios.create({
          headers: {
            'Content-Type': 'multipart/form-data',
            'crossDomain': true,
            'Access-Control-Allow-Origin': '*',
              'Authorization': 'Bearer '+state.token
          }
      })
      .post(state.link+ playload.nameRoute, playload.formdata, playload.config)
      .then( res => {
     
          resolve(res);

        })

        .catch(err => {
          reject(err);
 
          
        })
  
      })

    },
    deleteItem({commit, state}, playload){
      return new  Promise ((resolve, reject) => {
      axios.create({
          headers: {
              'Content-Type': 'application/json',
              'Authorization': 'Bearer '+state.token
          }
      })
      .delete(state.link+ playload.nameRoute)
      .then( res => {
     
          resolve(res);

        })

        .catch(err => {
          reject(err);
 
          
        })
  
      })

    },
    getFile({commit, state}, playload){
      //promise to return res and err
      return new  Promise ((resolve, reject) => {
        axios({
          headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer '+state.token
        },
          url: state.link+ playload.nameRoute,
          method: playload.type,
          responseType: 'blob', // important
          data:{
            ids : playload.ids,
          }
        }).then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', playload.name+playload.ref+playload.ext);
          document.body.appendChild(link);
          link.click();
         
          
      })
        .then( res => {
          resolve(res);
        
        })
      
        .catch(err => {
          reject(err);
      
          
        })
  
      })

    },
   

    /* addItem({commit, state}, playload){
      return new  Promise ((resolve, reject) => {
      axios.create({
          headers: {
              'Content-Type': 'application/json',
              'Authorization': 'Bearer '+state.token
          }
      })
      .post(state.link+ playload.nameRoute, playload.t)
      .then( res => {
     
          resolve(res);

        })

        .catch(err => {
          reject(JSON.stringify(err.response.status));
 
          
        })
  
      })

    }, */
  
  
    


    
  },
  getters: {
    // i can not access to attrivutes without getters llike Objects
    get_loggedIn(state){
      return state.loggedIn;
    },

    get_user(state){
      return state.user;
    },

    get_userAcc(state){
      return state.userAcc;
    },
    get_profile(state){
      return state.profile;
    },

    get_Types(state){
      return state.types;
    },
    get_permissions(state){
      return state.permissions;
    },
    get_Hote(state){
      return state.Hote;
    },
    get_menuItem(state){
      return state.menuItem;
    },
    get_link(state){
      return state.link;
    },
    get_token(state){
      return state.token;
    },
    get_loginTime(state){
      return state.login_time;
    },
    get_ressources(state){
      return state.ressource;
    }


    
    
  },
  modules: {
  }
})
