<template>
  <div class="row">
      <div class="">
          <p v-if="!k" class="text-center">Chargement en cours</p>
          <p v-show="sum == 0 && k" class="text-center text-danger fw-bold">No shipment to show</p>
          <div v-show="k"  class="p-1" style="height:300px;width: 300px; " >
    <vue3-chart-js class=" px-3" style="height : 50px;"
        :id="doughnutChart.id"
        :type="doughnutChart.type"
        :data="data"
        @before-render="beforeRenderLogic"
    ></vue3-chart-js>
  </div>
      </div>
  </div>

 
</template>

<script>
import Vue3ChartJs from '@j-t-mcc/vue3-chartjs'
import zoomPlugin from 'chartjs-plugin-zoom'


export default {
  name: 'App',
  components: {
    Vue3ChartJs,
  },
plugins: [zoomPlugin],

  setup () {
    const doughnutChart = {
      id: 'doughnut',
      type: 'doughnut',
      
    }

    const beforeRenderLogic = (event) => {
      //...
      //if(a === b) {
      //  event.preventDefault()
      //}
    }

    return {
      doughnutChart,
      beforeRenderLogic
    }
    
  },

   data(){
    return {
     // select : 30,
     data: {
          // labels: ['Livre', 'En cours de traitement', 'Retour apres réception', 'Non récupéré', 'Retourné', 'Perdu/Annulé'],
           datasets: [
             {
            backgroundColor: [
              '#78BE21',
              '#CF578A',
              '#7BB2DD',
              '#888B8D',
              '#CD001A',
              '#1F2022'
            ],
            data: []
          }
        ]
      },
      statut : "",
      t:{},
      show:false,
      k:false,
      date:[],
      p:[],
      count: [],
      r:[],
 


    }
  },
  props: {
    entred_data:{} ,
  },

  mounted(){


    this.t= this.entred_data;
    /* this.t.select = 1;
    this.t.from = "2022-03-01";
    this.t.to = "2022-03-02"; */
    console.log("**************************",this.t)

  this.$store.dispatch('postItem', {//data to be sended
  nameRoute : '/api/chart2',
  t : this.t
      })    
     .then(res => {
            console.log(res.data[0])
            this.r = res.data[0];
            this.r = Object.values(this.r)
            let sum = Object.values(this.r).reduce((a, b) => a + b, 0);
            this.r = this.r.map(x => this.percentage(x,sum))
            this.showDonut();

            //this.getAllAxis();


     })
     .catch(err => {
          // this.isLoading = false;
          this.error = "Une erreur s'est produite ! "

   })

 },


 methods : {
     percentage(partialValue, totalValue) {
            if(totalValue == 0) return 0;
            return ((100 * partialValue) / totalValue).toFixed(2);
            },
    showDonut(){
            this.data.datasets[0].data = this.r
            this.k = true;
    }
 }

}
</script>
