<template> 

      <section class="home-section" >
         
         <div class="container-scroller" >
    <!-- partial:partials/_navbar.html -->
    <Header title="Ajout colis" />
    <!-- partial -->
    <div class="container-fluid page-body-wrapper bg-light"  >
      <!-- partial:partials/_sidebar.html -->
   
    
      <!-- partial -->
      <div class="main-panel" style="width : 100%;">
        <!---content wrapper start--->
              
    <div class="content-wrapper">
        <div class="bg-light container">
   
<div class="row" >
               <div class="col-12 grid-margin stretch-card">
              <div class="card ">
                <div class="card-body ">
                                      <h2 class="card-title text-center text-danger font-weight-bold"> 
                                         <p class="text-danger text-center font-weight-bold" style="font-size : 20px; margin : auto ;">
                                                  Bons de commandes numero {{ shipment.id }} 

                            </p>   </h2>

<div class="row d-flex justify-content-center" id="pdf">
 
     <div class="col-lg-5 grid-margin stretch-card " >
              <div class="card border-0 ">
                <div class="card-body   " style="height : 12%;" >
                  <div class="row" >
                    <div class="col-md-12 " style="width : 100%;">
                 <div class="row">
                          <div class="col-md-12">
                          <address>
                        <p class="font-weight-bold  text-dark text-capitalize" v-if="warehouse">{{warehouse.nom}}</p>
                        <p class=" text-dark" v-if="warehouse">
                         {{warehouse.adresse}},
                        </p>
                        <p class=" text-dark" v-if="warehouse">
                         {{ville[1]}}  
                        </p>
                      </address>
                      </div>
                     
                 </div>
                 <div class="row">
                   <div class="col-12">
                     Date d'enlevement :  <b>{{shipment.date_enlevement_demandee}}</b>
                   </div>
                 </div>
                    </div>
                   
                  </div>
                </div>
                <div class="card-body" style="height : 80%; margin-top : 8%;" >
                                  <div class="table-responsive  " >
                    <table  class="table" style="border : 1px solid #cccccc; margin-top : 0%; margin-bottom : 0%;">
                      <thead>
                        <tr>
                            <th style="border : 1px solid #cccccc;">Destinataire    </th>

                         
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                            <td style="border : 1px solid #cccccc;">
                  <ul>
                    <li>Nom : <b>{{client.nom}} {{client.prenom}}</b> </li>
                    <li>Adresse :
                           <address>
                        <p class="font-weight-bold"> {{relay.nom}} </p>
                      <p>  {{relay.adresse}} </p>
                       <p> {{ville[0]}}</p>
                     
                      </address>
                    </li>
              
                  </ul>

                    </td>
                            
                        </tr>
                                  
                      </tbody>
                    </table>
                  </div>
                            <div class="table-responsive" style="margin-top : 2%;">
                    <table  class="table table-bordered "  style="border : 1px solid #cccccc;">
                          <thead>
                        <tr>
                            <th  colspan="2" class="text-center">Informations colis     </th>

                         
                        </tr>
                      </thead>
                      <tbody>
                           <tr>
                      <td>Poids</td>
                      <td> {{shipment.poids}} Kg</td>
                  </tr>
                  <tr>
                      <td>Numero d'expedition</td>
                      <td>{{shipment.n_expedition}}</td>
                  </tr>
                   <tr>
                      <td>Nombre de colis</td>
                      <td>1</td>
                  </tr>
                   <tr>
                      <td>Reference client</td>
                      <td>{{client.id}}</td>
                  </tr>
                   <tr>
                      <td>Numero de suivi</td>
                      <td>{{shipment.id}}</td>
                  </tr>
             
                                  
                      </tbody>
                    </table>
                  </div>
              
                </div>
              </div>
            </div>
     <div class="col-lg-5 grid-margin stretch-card">
              <div class="card border-0">
                <div class="card-body">
                 <div class="navbar-brand brand-logo-mini p-0" style="margin-top : -5%;"><img src="../../public/images/jibLogo.png" alt="logo"/></div>
                  <blockquote class="blockquote blockquote-light  w-100 ">
                  <vue-qrcode v-bind:value="qrValue"  scale="3"/>
                   <!-- <footer class="blockquote-footer">Someone famous in <cite title="Source Title">Source Title</cite></footer>-->
                  </blockquote>
                 
               
                  <div class="row"> <blockquote class="col-lg-9 blockquote blockquote-secondary" >
                    <p class="mb-0 text-dark d-inline">
   Encaissement : <span class="d-inline font-weight-bold" v-if="shipment.cod == 0" >Payé</span>
                 <span  class="font-weight-bold" v-else >A payer</span>
                        </p>
                  </blockquote>
                </div>
                <div class="row">
                    <blockquote class="col-lg-6 blockquote blockquote-light ">
                   <div class="form-check">
  <input class="form-check-input" type="checkbox" value="1" id="flexCheckDefault" style="font-size: 30px;">
  <label class="form-check-label font-weight-bold" for="flexCheckDefault">
    Retour
  </label>
</div>
                  </blockquote>
                </div>
                  
                </div>
               
              </div>
            </div>
           
</div>
 
    <br>
                </div>
              </div>
               </div>
</div>
</div>
                  

        </div>  

        <!-- content-wrapper ends -->
        <!-- partial:partials/_footer.html -->
      <Footer />
        <!-- partial -->
      </div>
      <!-- main-panel ends -->
    </div>
    <!-- page-body-wrapper ends -->
  </div>
        
      </section>


</template>



<script>
import axios from 'axios';
import VueQrcode from 'vue3-qrcode';
import html2canvas from "html2canvas";
// import Menu from '@/components/Menu.vue'; 
import Header from '@/components/Header.vue'; 
import Footer from '@/components/Footer.vue'; 
import jspdf from "jspdf"

export default {
  props: {
   // id: Number,
  },
   components: {
    VueQrcode,
    // Menu,
    Header,
    Footer
  },
      data(){
    return{
    //  id : this.$route.params.id,
  id : this.$route.params.id,
  //id:7,
      qrValue :  "",
      shipment :{},
      warehouse : {},
      client : {},
      seller : {},
       relay : {},
       error:false,
       ville:[]
   
    }
   },
   beforeMount(){
     //this.id = 1;
             this.getCode();
         this.$store.dispatch('getItem', {//data to be sended
      nameRoute : '/api/getshipment/'+this.id,
     // nameRoute : '/api/getshipment/1',
      })    
     .then(res => {
       console.log(res.data)
        this.shipment = res.data;
         this.client = res.data["client"];
         this.relay = res.data["relay"];
         this.ville[0]= res.data["relay"]["ville"].city
         this.warehouse = res.data["warehouse"];
         this.ville[1]= res.data["warehouse"]["ville"].city
     })
      
       

   },
   mounted(){
       console.log("mounted")
       if(this.error) {console.log("error");}
       setTimeout(() => {  this.exportPdf(); }, 3500);  
       

     //  else {setTimeout(() => {  this.exportPdf(); }, 500);      
 //}

       //console.log("mounted");
     
      
   },

   methods : {
     
           getCode(){
               this.$store.dispatch('getItem', {//data to be sended
      nameRoute : '/api/ITlv1/'+this.id,
      })    
     .then(response => {
       this.qrValue = response.data.ciphertext;
       const k =  localStorage.setItem('kp',response.data.pass);//response.data.pass;
       const sa = localStorage.setItem('Sa',response.data.salt);//response.data.salt;
       const i =localStorage.setItem('I',response.data.iv);// response.data.iv;
      // this.exportPdf();
       
//console.log(this.qrValue);
     })
     .catch(err =>{
         this.error = true;
     })
       },
       exportPdf(){
          // var pdf = new jspdf('p', 'mm', [950, 1189]);
          var pdf = new jspdf('p', 'pt', [1000, 1189]);
    var element = document.getElementById('pdf');
    var width= element.style.width;
    var height = element.style.height;
    html2canvas(element).then(canvas => {
        var image = canvas.toDataURL('../../public/images/jiblili.png');
        pdf.addImage(image, 'JPEG', 15, 40, width, height);
        pdf.save('bondecommande'+this.id+'.pdf');
    });
    this.$router.push("/shipment")
    //this.$router.go();
    // this.$router.go(-1);
   // window.location.reload(this.$router.push("/d"));
       }
   }

}
</script>

<style scoped>


</style>

import { Script } from "vm";