

<template>
              

      <section class="home-section" >
         
         <div class="container-scroller" >
    <!-- partial:partials/_navbar.html -->
    <Header title="Ajout colis" />
    <!-- partial -->
    <div class="container-fluid page-body-wrapper bg-light"  >
      <!-- partial:partials/_sidebar.html -->
   
    
      <!-- partial -->
      <div class="main-panel" style="width : 100%;">
        <!---content wrapper start--->
              
        <div class="content-wrapper">

                  <div class="row" >
            <div class="col-md-12 grid-margin">
              <div class="d-flex justify-content-between flex-wrap row">
                <div class="d-flex align-items-end flex-wrap col-lg-8">
                
               
                 <div class="mr-auto p-2"> <div class="mt-3">
                      <p><i class="mdi mdi-cube icon-md" style="color: #6c757d; font-size: 30px;"></i></p>
                    </div>
                    </div>
      


                </div>
                <div class="col-lg-4 d-flex justify-content-end align-items-end flex-wrap">
                   <button type="button" class="btn btn-primary mr-2 btn-icon-text" v-on:click="searchShow()">
  <i class="mdi mdi-filter-variant btn-icon-prepend"></i>
  <span class="font-weight-bold">Filtrer</span>
  </button>
  
<!-- <button @click="checker('test')"> test</button>
<button @click="checker('t')"> t</button>
<div class="bg-danger" v-if="!checkrow ||  checkrow ==='test'"> YES </div>  -->
                  
                   

                  

                 
<!-- <button @click="t">tester</button> -->
                  
                  
                </div>
              </div>
            </div>
          </div>
          
       <!--------------->
       <div class="row">
          <div class="row m-2" v-if="reduire">
            <div class="col-lg-12 stretch-card" >
              <div class="card" >
                <div class="card-body pb-0 bg-light" style="background : #d9d9d9;" >
              
                       <form class="forms-sample"> 
                         <!--------------->           
         <div class="row">
              <div class="form-group col-xl-2">
                 
             <label for="exampleInputName1 col-xl-2">Statut</label>

                  <div class="form-control btn-group p-0 " >
   <button type="button" class="btn btn-outline-light text-secondary btn-lg btn-block" data-toggle="dropdown" id="selection">Selectionner
                      <i class="mdi mdi-chevron-down float-right"></i>
                    </button>

                            <div class="dropdown-menu p-3" style="width : 100%; max-height: 390px;overflow-y: auto;" aria-required="true">
               
                        
                          <div>
                            
        <div >
            <table >
                <tr >
    
                  <th>   
      <div class="form-check">
  <input class="form-check-input" type="checkbox" @click="selectAll" v-model="allSelected" @change="changer()">
  <label class="m-2" for="flexCheckDefault">
   Selectionner tout
  </label>
</div></th>

                    
                </tr>
                <tr v-for="user in users" :key="user.id">
                    <td>
                      <div class="form-check">
  <input class="form-check-input" type="checkbox" v-model="userIds" @click="select" :value="user.id" @change="changer()">
  <label class="m-2" for="flexCheckDefault">
  {{ user.name }}
  </label>
</div>
                      </td>
                </tr>
            </table>
        </div>

        </div>
                <!--<span>Selected Ids: {{ userIds }}</span>-->

                             
                      
                    

                      
                           
                            </div>                          
                          </div>  
              </div>
              <!------------------->
               <div class="form-group col-xl-2">
                      <label for="exampleInputName1 col-xl-2">Vendeur</label>
                          <input class="form-control " type="search" onfocus="this.placeholder=''" onblur="this.placeholder='Vendeur'"  placeholder="Vendeur " aria-label="Search" v-model="shipment.v_nom" v-on:keyup="chercher()" v-on:search="chercher()">

                    </div>
           <!----------------->
             <!------------------->
               <div class="form-group col-xl-2">
                      <label for="exampleInputName1 col-xl-2">Point relais</label>
    <input class="form-control" type="search" onfocus="this.placeholder=''" onblur="this.placeholder='Point relais'"  placeholder="Point relais " aria-label="Search" v-model="shipment.r_nom" v-on:keyup="chercher()" v-on:search="chercher()">

                    </div>
           <!----------------->
                
       <!------------------->
               <div class="form-group col-xl-2">
                      <label for="exampleInputName1 col-xl-2">Point D'enlèvement</label>
    <input class="form-control" type="search" onfocus="this.placeholder=''" onblur="this.placeholder='Centre de tri'"  placeholder="Point relais " aria-label="Search" v-model="shipment.w_nom" v-on:keyup="chercher()" v-on:search="chercher()">

                    </div>
           <!----------------->
                <!------------------->
               <div class="form-group col-xl-2">
                      <label for="exampleInputName1 col-xl-2">Client</label>
    <input class="form-control" type="search" onfocus="this.placeholder=''" onblur="this.placeholder='Client'"  placeholder="Client " aria-label="Search" v-model="shipment.c_nom" v-on:keyup="chercher()" v-on:search="chercher()">

                    </div>
                       <div class="form-group col-xl-2">
                      <label for="exampleInputName1 col-xl-1">Numéro de suivi : </label>
  <input class="form-control" type="search" onfocus="this.placeholder=''" onblur="this.placeholder='No de suivi'"  placeholder="No de suivi" aria-label="Search" v-model="shipment.n_suivi" v-on:keyup="chercher()" v-on:search="chercher()">

                    </div>
    
  
  
  
         </div>
      <!---------------------->
      <div class="row">
             <div class="form-group col-xl-2">
                      <label for="exampleInputName1 col-xl-1">Type d'expédition : </label>
                      
    <input class="form-control" type="search" onfocus="this.placeholder=''" onblur="this.placeholder='expedition'"  placeholder="Expedition " aria-label="Search" v-model="shipment.type_exp" v-on:keyup="chercher()" v-on:search="chercher()">

                    </div>
                      <div class="form-group col-xl-2">
                      <label for="exampleInputName1 col-xl-1">Numéro d'expédition : </label>
  <input class="form-control " type="search" onfocus="this.placeholder=''" onblur="this.placeholder='No d'expédition'"  placeholder="No d'expédition" aria-label="Search" v-model="shipment.n_exp" v-on:keyup="chercher()" v-on:search="chercher()">

                    </div>
          <div class="form-group col-xl-2">
                      <label for="exampleInputName1  col-xl-1">Enlevement de : </label>
 <input class="form-control" type="date"   v-model="shipment.date_enlv_from" v-on:change="chercher()"
      >
                    </div>
                          <div class="form-group col-xl-2">
                      <label for="exampleInputName1 col-xl-1">A : </label>
 <input class="form-control " type="date" placeholder="Enlevement"  v-model="shipment.date_enlv_to" v-on:change="chercher()"
      >
                    </div>
                    <!--------------->
     <div class="form-group col-xl-2">
                      <label for="exampleInputName1 col-xl-2">Expiration de : </label>
   <input class=" form-control " type="date"  v-model="shipment.date_exp_from" v-on:change="chercher()"
      >
                    </div>
                          <div class="form-group col-xl-2">
                      <label for="exampleInputName1 col-xl-2">A : </label>
  <input class=" form-control  " type="date" placeholder="Expiration"  v-model="shipment.date_exp_to" v-on:change="chercher()"
      >
                    </div>

      </div>
            <p class="card-title float-lg-right"> 
                  <!--   <button type="button" class="btn btn-light btn-icon-text">
                          <i class="mdi mdi-filter-variant btn-icon-prepend"></i>
                          Filtrer
                        </button>-->
                    <a  class="auth-link text-capitalize " @click="reset" style="color : darkred;"><u>Reset</u> </a> 

                  </p>
     
      <!------->

      <!------------------->



            
                  </form>
                
                </div>
              </div>
            </div>
          </div>
       </div>
     
 
       <!--------------->
          <div class="row">
            <div class=" stretch-card col-lg-12 m-1 p-0">
              <div class="card p-0">
                <div class="card-body p-0">
                  
                    <div class="d-flex justify-content-end py-2">
                      <!-- <div class="dropdown mr-2">
  <button class="btn btn-light btn-icon-text " type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
    <i class="mdi mdi-view-list btn-icon-prepend"></i>
    Liste
  </button>
  <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
    <li><a class="dropdown-item" href="#" v-on:click="afficherVendeurs()">
      
      <i class="mdi mdi-check float-left icon-sm mr-2"></i>
      <p class="mb-0 ml-2 text-dark"> En ligne</p>
      </a></li>
    <li><a class="dropdown-item" href="#" v-on:click="afficherVendeursArchives()"> 
      <i class="mr-2 mdi mdi-close-circle-outline text-danger float-left icon-sm"></i>
      <p class="mb-0 ml-2 text-dark"> Archivés</p>
</a></li>
  </ul>
</div> -->
<button type="button" class="btn btn-light btn-outline-dark mr-2 btn-icon-text" v-on:click="liste()">
  <i class="mdi mdi-view-list btn-icon-prepend"></i>
  Colis non recupérés
  </button>
<button type="button" class="btn btn-success mr-2 btn-icon-text" v-on:click="ajouterColis()"  v-show="role == 'admin' || role == 'back office' "> 
  <i class="mdi mdi-plus-circle btn-icon-prepend"></i>
  Nouveau colis
  </button>
  <button type="button" class="btn btn-light btn-outline-info  mr-2 btn-icon-text" @click="importCsv"  v-show="role == 'admin' || role == 'back office' "> 
  <i class="mdi mdi-upload btn-icon-prepend"></i>
  Importer colis
  </button>
  


                    
                     </div>
                     <div class="row m-2">
       
                       <p class="alert alert-danger text-center font-weight-bold" v-if="error" >{{error}}</p>

     </div>
                      <div class="d-flex justify-content-start p-2">
                   
<button type="button" class="btn  btn-primary" v-if="selection.length !=0  || checkrow"  :disabled="!disableButton " @click="showing" v-show="role == 'admin' || role == 'back office' "> 
     <i class="mdi mdi-check text-white"></i>
     Changer statut</button>

                  </div>
                    
                 
                 
                                                     
                   
                                    <p v-if="count >=0" class="card-title text-center m-2"> <span v-show="!isLoading" class="text-danger">{{ count }}</span>
                                                              <loading v-if="isLoading" :active="true" :width="10" :height="10" color="black"></loading> resultat(s)</p>

  <div class="row m-3">
   <!--    <button class="btn btn-dark" @click="exportExcel">export</button> -->
                    <table class="table table-hover table-stripped">
  
  <tbody>
    <tr class="bg-white text-black" style="height : 50px;">
      <th scope="row" class="text-left" v-show="role == 'admin' || role == 'back office' " >
          <input style="zoom:1.5;" type="checkbox" value="1" id="flexCheckDefault" v-model="select_all" @click="selecting" :disabled="count ==0 " :checked="testcheck = false" @change="selectedAllItem">

      </th>
      <td class="text-center font-weight-bold ">Numéro</td>
      <td class="text-left font-weight-bold ">N° expédition</td>
      <td class="text-left font-weight-bold">Statut</td>
      <td class="text-left font-weight-bold">Date d'enlèvement</td>
      <td class="text-left font-weight-bold"> Point d'enlèvement </td>
      <!-- <td class="text-left font-weight-bold"> Montant de retour </td> -->
      <td class="text-left font-weight-bold"> Date d'expiration </td>
      <td class="text-left font-weight-bold"> Client </td>
      <td class="text-left font-weight-bold"> Livraison </td>
      <td class="text-left font-weight-bold"> Vendeur </td>
      <td class="text-left font-weight-bold"> Point relais </td>
      <td class="text-left font-weight-bold"> Actions </td>
    </tr>
     <tr class="odd text-center" v-if="count == 0">
                           <td valign = "top" colspan="13" class="dataTables_empty text-center text-primary bg-light font-weight-bold">
                             No data available in table
                           </td>
                        </tr>
    
    
     <tr  v-for="(item, index) in colis" :key="item.id" v-bind:index="index" style="cursor: default;" @click="historique(item.id)">
    <th scope="row" class="text-left" v-show="role == 'admin' || role == 'back office' ">
          <input style="zoom:1.5;" type="checkbox" :value="item.id" id="checkbox" v-model="selection"  :disabled="(item.shipment_expired == 1 )" v-if="!checkrow ||  checkrow ==item.checked"  @change="tester(item.checked)" onclick="event.stopPropagation();" >

      </th>
       <td class="text-center "> {{item.id}} </td>
      <td class="text-left "> {{item.n_expedition}} </td>
      <td class="text-left font-weight-bold" v-if="item.latest_log.statut == 'Retourne' || item.latest_log.statut == 'Annule' || item.latest_log.statut == 'Perdu'" style="color : #CD001A ;">  <i class="mdi mdi-close-circle icon-sm"></i> {{item.latest_log.statut}}  </td>
      <td class="text-left font-weight-bold " v-else-if="item.latest_log.statut == 'En cours de preparation'" style="color : #acbccc ;"><i class="mdi mdi-package-variant icon-sm"></i> {{item.latest_log.statut}}  </td>
      <td class="text-left font-weight-bold" v-else-if="item.latest_log.statut == 'En attente enlevement'" style="color : #768692 ;"><i class="mdi mdi-package-variant-closed icon-sm"></i> {{item.latest_log.statut}}  </td>
      <td class="text-left font-weight-bold" v-else-if="item.latest_log.statut == 'Livre'" style="color : #30B700 ;"> <i class="mdi mdi-check-circle icon-sm"></i> {{item.latest_log.statut}}  </td>
      <td class="text-left font-weight-bold" v-else-if="item.latest_log.statut == 'Arrive au centre de tri' || item.latest_log.statut == 'Retourne au centre de tri'" style="color : #008AD8 ;"> <i class="mdi mdi-store icon-sm"></i> {{item.latest_log.statut}}  </td>
      <td class="text-left font-weight-bold" v-else-if="item.latest_log.statut == 'Refuse par le client' || item.latest_log.statut == 'Reception retour client' || item.latest_log.statut == 'Disponible au point relais' || item.latest_log.statut == 'Non recupere'" style="color : #8031A7 ;"> <i class="mdi mdi-map-marker icon-sm"></i> {{item.latest_log.statut}}  </td>
<!--       <td class="text-left font-weight-bold" v-else-if="item.latest_log.statut == 'Ramassage en cours' || item.latest_log.statut == 'Distribution en cours' || item.latest_log.statut == 'Livraison en cours'" style="color : #FE5000 ;"><i class="mdi mdi-truck icon-sm"></i> {{item.latest_log.statut}}  </td>
 -->

      <td class="text-left  font-weight-bold" v-else-if="item.latest_log.statut == 'Retour centre de tri en cours'"  style="color : #008AD8 ;"><i class="mdi mdi-truck icon-sm"></i> {{item.latest_log.statut}}  </td>
      <td class="text-left  font-weight-bold" v-else-if="item.latest_log.statut == 'Retour expediteur en cours'"  style="color : #CD001A ;"><i class="mdi mdi-truck icon-sm"></i> {{item.latest_log.statut}}  </td>
      <td class="text-left  font-weight-bold" v-else  style="color : #FF9913 ;"><i class="mdi mdi-truck icon-sm"></i> {{item.latest_log.statut}}  </td>

      
      <td class="text-left" v-if="item.date_enlevement_demandee"> {{format_date(item.date_enlevement_demandee)}}  </td> 
      <td class="text-center text-secondary" v-else> **** </td> 
      <td class="text-left " v-if="item.warehouse"> {{item.warehouse.nom}}-{{item.warehouse.ville.city}} </td>
      <!-- <td class="text-center "> {{item.montant_retour}} DH </td> -->
       <td class="text-left " v-if="item.date_expiration"><!-- {{item.checked}} --> {{format_date(item.date_expiration)}}</td>
              <td class="text-center " v-else><!-- {{item.checked}} --> ---</td>

      <td class="text-left ">{{item.client.nom}} {{item.client.prenom}}</td>
      <td class="text-left ">
         <a  v-show="role == 'admin' || role == 'back office' "  @click.stop="showMessageConfirmation4Edit(item, 2)"   title="editer" class="px-1" style="cursor: pointer;"><i class="mdi mdi-pencil icon-sm text-success" ></i></a>
        {{item.type_expedition}}               
     </td>
      <td class="text-center ">{{item.seller.nom}} </td>
      <td class="text-center ">{{item.relay.nom}} </td>

      

      <td class="text-left">
              <a @click.stop="openModel(item)" title="details" class="" style="cursor: pointer;" ><i class="mdi mdi-mdi mdi-eye icon-sm" style="color : #7BB2DD;" ></i></a>
               <!--  <div class="btn-group dropright">
  <button class="btn p-0" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="background : transparent; border:none;">
    <i class="mdi mdi-dots-horizontal"></i>
  </button>
  <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
    <a class="dropdown-item mb-3 " href="#" v-if="item.is_actived == 0" @click="activer(item.id, index)">
      <span class="float-left" > Activer</span>
       <i class="mdi mdi-check float-right" style="font-size : 19px;"></i>
      </a>
    <a class="dropdown-item mb-3" href="#" @click="details(item.id)">
      <span class="float-left"> Details</span>
      <i class="mdi mdi-eye float-right" style="font-size : 19px;"></i>
    </a>
    <div class="dropdown-divider my-1"></div>
    <a class="dropdown-item mb-3" href="#" v-if="item.is_actived == 1 && item.is_archived == 0"  @click="d(item.id, index)">
      <span class="float-left"> Désactiver</span>
      <i class="mdi mdi-close float-right" style="font-size : 19px;"></i>
    </a>  </div>
</div> -->
<!--               <a  title="historique " class="ml-1"  @click.stop="historique(item.id)" style="cursor: pointer;" > <i class="mdi mdi mdi-history icon-sm" style="color :  #A6517E;"></i> </a>
 -->              
              <a  v-if="!loaded || loaded != item.id" title="telecharger le bon de commande " class="ml-1"  @click.stop="pdf(item.id, item.ref)" style="cursor: pointer;" > 
              <i class="mdi mdi-file-pdf-box icon-sm" style="color :  red;"></i>
               </a>
               <a href=""  v-else> <i  class=" mdi mdi-check icon-sm " style="color : green;" ></i></a>
              <a v-if="item.shipment_expired == 0" v-show="role == 'admin' || role == 'back office' " @click.stop="showMessageAnnulation(item.id)" title="Annuler le colis" class="ml-1" style="cursor: pointer;" ><i class="mdi mdi-close icon-sm" style="color : black;" ></i></a>

         

            </td>
    </tr>
  </tbody>
</table>
<!-- <button @click="testbdl"> yes </button> -->
     </div>
<!--      <div class="d-flex flex-row  justify-content-center mb-1 p-2">
         <button class="btn btn-light  btn-outline-dark px-3 mx-4 " @click="showing" v-if="selection.length !=0">
           <i class="mdi mdi-truck btn-icon-prepend"></i>
  Associer chauffeur
         </button>
          <button class="btn btn-light btn-outline-primary px-3 mx-4" @click="showing" v-if="selection.length !=0" >
           <i class="mdi mdi-autorenew btn-icon-prepend"></i>
  Forcer statut
         </button>
         <button class="btn btn-success  btn-outline-light px-3 mx-4" @click="showing" v-if="selection.length !=0" >
           <i class="mdi mdi-check btn-icon-prepend"></i>
  Livré(s)
         </button>
         <button class="btn btn-secondary btn-outline-light  px-3 mx-4 " @click="showing" v-if="selection.length !=0" >
           <i class="mdi mdi-close btn-icon-prepend"></i>
  Refusé(s)
         </button>
  <button class="btn btn-dark btn-outline-light px-3 mx-4" @click="showing" v-if="selection.length !=0" >
           <i class="mdi mdi-close-circle-outline btn-icon-prepend"></i>
  Annulé(s)
         </button>
          <button class="btn btn-danger btn-outline-light px-3 mx-4" @click="showing" v-if="selection.length !=0" >
           <i class="mdi mdi-help-circle-outline btn-icon-prepend"></i>
  Perdu(s)
         </button>

          

</div> -->

    <div v-if="showEdit">
    <transition name="model">
     <div class="modal-mask">
      <div class="modal-wrapper ">
       <div class="modal-dialog  modal-dialog-centered modal-lg">
        <div class="modal-content jumbotron my-auto" style="width : 1000px;">
         <div class="modal-header">
                     <h4 class="modal-title text-center text-primary">Changement statut pour {{selection.lenght }}</h4>

          <button type="button"  @click="close"><span aria-hidden="true">&times;</span></button>
         </div>
         <div class="modal-body">
           <div class="d-flex flex-row w-100 my-3" v-if="checkrow == 1">
           <p class="px-3 py-2 text-dark font-weight-bold">Selectionner un chauffeur : </p>
                       <div class="input-group  p-0 " >
                <select class=" border-0 form-control form-select form-select-md bg-light text-dark" aria-label="Default select example" v-model="t.driver_id"   >
                     <option v-for="item in chauffeurs" :value="item.id" :key="item.id" >
      {{ item.nom }} {{ item.prenom }}
    </option>
                      </select>
              
          
            </div>
         </div>
          <div class="d-flex flex-row w-100 my-3" v-if="checkrow == 2">
                      <!--  <div class="input-group  p-0 " >
                <select class=" border-0 form-control form-select form-select-lg bg-light text-dark" aria-label="Default select example" v-model="t.driver_id"   >
                      <option selected value="">Liste des chauffeurs</option>
                     <option v-for="item in chauffeurs" :value="item.id" :key="item.id" >
      {{ item.nom }} {{ item.prenom }}
    </option>
                      </select>
              
          
            </div> -->
         </div>
          <div class="" v-if="checkrow == 4">
            <div class="d-flex flex-row w-100 my-3">
           <p class="px-3 py-2 text-dark font-weight-bold">Selectionner un statut : </p>
                       <div class="input-group  p-0 " >
                <select class=" border-0 form-control form-select form-select-md bg-light text-dark" aria-label="Default select example" v-model="t.statut_shipment" @change="changerStatut($event)"  required >
                     <option  value="Livre"  > Livre </option>
                     <option  value="Retour centre de tri en cours"  > Retour centre de tri en cours </option>
                     
                      </select>
              
          
            </div>
            </div>
          <br>
               <div class="d-flex flex-row w-100 my-3 " v-if="t.shipment_statut == 'Retour centre de tri en cours'" >
                 <div class="input-group  p-0 ">
                          <p class="px-3 py-2 text-dark font-weight-bold">Selectionner un chauffeur : </p>

                <select class=" border-0 form-control form-select form-select-md bg-light text-dark" aria-label="Default select example" v-model="t.driver_id" required  >
                     <option v-for="item in chauffeurs" :value="item.id" :key="item.id" >
      {{ item.nom }} {{ item.prenom }}
    </option>
                      </select>
                      </div>
              
          
            </div>
         </div>
          <div class="" v-if="checkrow == 3">
            <div class="d-flex flex-row w-100 my-3">
           <p class="px-3 py-2 text-dark font-weight-bold ">Selectionner un statut : </p>
                       <div class="input-group  p-0 " >
                <select class=" border-0 form-control form-select form-select-md bg-light text-dark" aria-label="Default select example" v-model="t.statut_shipment" @change="changerStatut($event)" required >
                     <option  value="Livraison en cours"  >Livraison en cours </option>
                     <option  value="Livre"  >Livre </option>
                     <option  value="Refuse par le client"  >Refuse par le client </option>
                     <option  value="Non recupere"   >Non recupere </option>
                      </select>
              
          
            </div>
            </div>
               <div class="d-flex flex-row w-100 my-3" v-if="t.shipment_statut == 'Livraison en cours'" >
                 <div class="input-group  p-0">
                          <p class="px-3 py-2 text-dark font-weight-bold">Selectionner un chauffeur : </p>

                <select class=" border-0 form-control form-select form-select-md bg-light text-dark" aria-label="Default select example" v-model="t.driver_id" required  >
                     <option v-for="item in chauffeurs" :value="item.id" :key="item.id" >
      {{ item.nom }} {{ item.prenom }}
    </option>
                      </select>
                      </div>
              
          
            </div>
         </div>
                    
<p class="px-3 py-2 text-dark font-weight-bold content-justify">                  
    <span class="text-danger">(*)</span> Les modifications seront apportées à tous les elements selectionnés (<span class="text-danger">{{selection.length}} </span> colis selectionné(s))
</p>           
              <div class="flex-row d-flex float-end mt-4 mb-0">
                       <div class="input-group-prepend " >
                <button type="submit" class="btn btn-success  px-4 btn-icon-text" v-on:click="edit()" :disabled="dis" v-show="t.driver_id || t.statut_shipment || checkrow == 2" >
  <i class="mdi mdi-check-circle btn-icon-prepend"></i>
  Valider
  </button>
              </div>
                    </div>

           
         
        
          <br />
          
         </div>
        </div>
       </div>
      </div>
     </div>
    </transition>
   </div> 
<!-- <div v-if="!showEdit">
    <transition name="model">
     <div class="modal-mask">
      <div class="modal-wrapper">
       <div class="modal-dialog">
        <div class="modal-content">
         <div class="modal-header">
                     <h4 class="modal-title text-center text-danger">Informations</h4>

          <button type="button"  @click="myModel=false"><span aria-hidden="true">&times;</span></button>
         </div>
         <div class="modal-body">
         
                           <form class="forms-sample" @submit.prevent="handlesubmit" style="width : 100%;">
                               <div class="form-group col-lg-6">
                      <label for="exampleInputUsername1">Chauufeur</label>
 <select class="form-control form-select form-select-lg " aria-label="Default select example" v-model="t.ville_id"   @change="changer">
                     <option v-for="item in villes" :value="item.id" :key="item.id" >
      {{ item.city }}
    </option>
                      </select>
                    </div>
                           </form>

         </div>
         </div>
         </div>
         </div>
         </div>
    </transition>
         </div> -->


<div v-if="myModel">
    <transition name="model">
     <div class="modal-mask">
      <div class="modal-wrapper">
       <div class="modal-dialog  modal-dialog-centered modal-lg">
        <div class="modal-content">
         <div class="modal-header">
                     <h4 class="modal-title text-center text-danger">Informations</h4>

          <button type="button"  @click="myModel=false"><span aria-hidden="true">&times;</span></button>
         </div>
         <div class="modal-body">
         
         <div class="table-responsive   m-4 ">
                   <table class="table table-borderless ">

                      <tbody>
                        <tr class="" >
                            <th  style="padding-left : 5%; padding-bottom : 2%; width : 30%;">Id : </th>
                             <th   class="text-muted" style="padding-bottom : 2%;"> {{t.id}}  </th>
                            
                          </tr >
                          <tr class="" >
                            <th  style="padding-left : 5%; padding-bottom : 2%; width : 30%;">Type d'expédition : </th>
                             <th   class="text-muted" style="padding-bottom : 2%;"> {{t.type_expedition}}  </th>
                            
                          </tr >
                          <tr class="" >
                            <th  style="padding-left : 5%; padding-bottom : 2%; width : 30%;">Numéro d'expédition : </th>
                             <th   class="text-muted" style="padding-bottom : 2%;"> {{t.n_expedition}}  </th>
                            
                          </tr >
                          <tr class="" >
                            <th  style="padding-left : 5%; padding-bottom : 2%; width : 30%;">Client : </th>
                             <th   class="text-muted text-capitalize" style="padding-bottom : 2%;"> {{t.client.nom}} {{t.client.prenom}} </th>
                            
                          </tr >
                          <tr class="" >
                            <th  style="padding-left : 5%; padding-bottom : 2%; width : 30%;">Vendeur : </th>
                             <th   class="text-muted text-capitalize" style="padding-bottom : 2%;"> {{t.seller.nom}}  </th>
                            
                          </tr >
                          <tr class="" >
                            <th  style="padding-left : 5%; padding-bottom : 2%; width : 30%;">Point relais : </th>
                             <th   class="text-muted text-capitalize" style="padding-bottom : 2%;"> {{t.relay.nom}}--{{t.relay.ville.city}}  </th>
                            
                          </tr >
                        
                           <tr class="" >
                            <th  style="padding-left : 5%; padding-bottom : 2%; width : 30%;">Valeur  :</th>
                             <th   class="text-muted" style="padding-bottom : 2%;"> {{t.valeur}} Dh </th>
                            
                          </tr >
                          <tr class="" >
                            <th  style="padding-left : 5%; padding-bottom : 2%; width : 30%;">Cod  :</th>
                             <th   class="text-muted" style="padding-bottom : 2%;"> {{t.cod}} Dh  </th>
                            
                          </tr >
                          <tr class="" >
                            <th  style="padding-left : 5%; padding-bottom : 2%; width : 30%;">Montant de retour  :</th>
                             <th   class="text-muted" style="padding-bottom : 2%;"> {{t.montant_retour}} Dh  </th>
                            
                          </tr >
                          <tr class="" >
                            <th  style="padding-left : 5%; padding-bottom : 2%; width : 30%;">Date d'expiration  :</th>
                             <th   class="text-muted" style="padding-bottom : 2%;"> {{format_date(t.date_expiration)}}  </th>
                            
                          </tr >
                            <tr class="" >
                            <th  style="padding-left : 5%; padding-bottom : 2%; width : 30%;">Poids : </th>
                             <th   class="text-muted" style="padding-bottom : 2%;"> {{t.poids}} Kg </th>
                            
                          </tr >
                            <tr class="" >
                            <th  style="padding-left : 5%; padding-bottom : 2%; width : 30%;">Prix du vender : </th>
                             <th   class="text-muted" style="padding-bottom : 2%;"> {{t.prix_v}} Dh </th>
                            
                          </tr >
                          <tr class="" >
                            <th  style="padding-left : 5%; padding-bottom : 2%; width : 30%;">Commission point relais : </th>
                             <th   class="text-muted" style="padding-bottom : 2%;"> {{t.prix_r}} Dh </th>
                            
                          </tr >
                          <tr class="" >
                            <th  style="padding-left : 5%; padding-bottom : 2%; width : 30%;">Comission chauffeur : </th>
                             <th   class="text-muted" style="padding-bottom : 2%;"> {{t.prix_c}} Dh </th>
                            
                          </tr >

                    
                          
                            
                            
                      
                      </tbody>
                    
                    </table>
                   
                  </div>
          <br />
          
         </div>
        </div>
       </div>
      </div>
     </div>
    </transition>
   </div>



   <!------>
       <div v-if="modelUpload">
    <transition name="model">
     <div class="modal-mask">
      <div class="modal-wrapper">
       <div class="modal-dialog bg-transparent w-100 modal-dialog-centered modal-lg" style=" overflow-y: initial !important">
         <div class="modal-content bg-light " style="">
            <div class="modal-header bg-light">
                  <h5
                              class="modal-title w-100 text-center text-secondary"
                            >
                              Importation des colis 
                            </h5>
                                  <button
                              type="button"
                              class="border-0 bg-transparent"
                              @click="closeUpload()"
                            >
                              <i class="mdi mdi-close text-secondary fw-bold"></i>
                            </button>
                     </div>
         <div class=" bg-light p-0">
         
        <div class="modal-body row  m-0 w-100 container d-flex justify-content-center">


<!-- Upload  -->
<div class="">
 <!--  <label for="formFile" class="form-label">Default file input example</label>
  <input class="form-control" type="file" id="formFile"> -->
</div>
<form id="file-upload-form" class="uploader bg-light  ">
 

  <label for="file-upload" id="file-drag" class="bg-light">
    <div id="start">
      <i class="fa fa-times-circle text-danger my-5" aria-hidden="true" v-if="showError" style="font-size:5em;"></i>
      <i class="fa fa-download my-5" aria-hidden="true" v-else></i>
   </div>
    <div class="row" >  
        <input class="form-control  my-2" placeholder="choisir" type="file" id="file" ref="file" @change="getFile" v-if="showBtn">
    </div>

   

     <div class="progress mt-2" v-if="showProgress"  >
    <div class="progress-bar progress-bar-striped progress-bar-animated bg-success border border-dark" role="progressbar" :aria-valuenow="10" aria-valuemin="0" aria-valuemax="100" v-bind:style="{width: getProgressBar + '%'}"> {{getProgressBar}} % </div>
    
    </div>
   

     <p class=" d-flex justify-content-center align-self-center flex-column " v-if="showMsg" >
                            <i class="mdi mdi-check icon-lg text-success fw-bold" ></i> 
                         <span class=" py-2 px-2 fw-bolder d-block" >Le téléchargement a été effectué avec succés!</span>
                       </p>
    
    <p class=" d-flex justify-content-center align-self-center " v-if="showEncours" >
                         <span class=" py-2 px-2 fw-bolder " >Vérification en cours ...</span>
                       </p>
    <p class=" d-flex justify-content-start align-self-center alert alert-danger border border-white shadow-sm" v-if="showError" >
                         <span class=" py-2 px-2 fw-bolder " >Le fichier n'a pas pu étre importé, Verifiez les erreurs suivantes</span>
                       </p>
    <table class="table table-danger" v-if="showError">
      
  <thead>
    <tr>
      <th scope="col">#</th>
      <th scope="col">Ligne</th>
      <th scope="col">Erreur</th>
    </tr>
  </thead>
  <tbody>
    <tr  v-for="(item, index) in InvalidShipments" :key="item.row">
      <th scope="row">{{index + 1}}</th>
      <td>{{item.row}}</td>
      <td class="text-left">{{item.errors[0]}}</td>
    </tr>
   
  </tbody>
</table>
  

<div class="d-flex justify-content-center mt-5" v-if="showBtn" >
 <div class="row w-75 " >
   <div class="col-lg-12 my-1">
        <ejs-combobox required id='combobox' :dataSource='vendeurs' v-model:value="sellerSelected" popupHeight="400px"   :fields='fields' placeholder='Selectionner un vendeur' @change="getSeller()"></ejs-combobox>    

   </div>
   <div class="col-lg-12 my-1">
        <ejs-combobox required id='combobox' :dataSource='depots' v-model:value="warehouseSelected" popupHeight="400px"   :fields='fields' placeholder='Selectionner un centre de tri'></ejs-combobox>    

   </div>
   </div>

   
  </div>
    <div class="d-flex justify-content-center mt-2" v-if="showBtn">
  <button class="btn btn-success px-5 my-2" type="button" @click="importFile" :disabled="nofiles || !sellerSelected || !warehouseSelected" >Importer le fichier</button>
  
</div>



  </label>
  
</form>
 <p class=" d-flex justify-content-center align-self-center flex-row" v-if="showMsg" >
                         <span class="mt-3"><button class=" py-2 px-3 fw-bolder btn btn-primary " @click="getMergePdf()" :disabled="isLoading">
                           <i class="mdi mdi-file-pdf-box btn-icon-prepend mr-1"></i><span v-if="!isLoading">Télécharger les bon de commandes</span>
                           <span v-else > Chargement en cours ...</span>
                           </button>
                           </span>
                         
  </p>




           
      
</div>
          
         </div>
        </div>
       </div>
      </div>
     </div>
    </transition>
   </div>

   
                </div>
              </div>
            </div>
          </div>
          <!-- <button class="btn btn-danger" @click="getMergePdf()" >test merge </button> -->
         
        
        </div>

        <!-- content-wrapper ends -->
        <!-- partial:partials/_footer.html -->
      <Footer />
        <!-- partial -->
      </div>
      <!-- main-panel ends -->
    </div>
    <!-- page-body-wrapper ends -->
  </div>
        
      </section>

</template>

<script>
import Header from '@/components/Header.vue'; 
import Footer from '@/components/Footer.vue'; 
import axios from 'axios';
import $ from 'jquery';
import Swal from 'sweetalert2';
import moment from 'moment';
import { notify } from "@kyvg/vue3-notification";
import Loading from 'vue3-loading-overlay';







export default {

  components : {
    Header,
    Footer,
    Loading
  },
  data()  {
    return {
    ex : null,
   colis : null,
   shipment : {},
   InvalidShipments:{},
   showError:'',
   activity:{},
   count : '',
   search : '',
   n_exp : '',
   r_nom  : '',
   c_nom : '',
   c_prenom : '',
   v_nom  : '', 
   n_suivi  : '',
   type_exp  : '',
   date_exp_from  : '',
   date_exp_to  : '',
   date_enlv_from  : '',
   date_enlv_to  : '',
   cod  : '', 
   reduire: false,
   select_all: false,
   selection : [],
   t:{},
   showEdit: false,
   myModel: false,
   modelUpload:false,
   chauffeurs : [],
   checkrow : '',
   isLoading: false,
   all_s : false,
   dis : false,
   error : '',
   bdlSelction:[],
   videoDownloadPercentage: 0,
   testShow : false,
   

   //id : 'y',
   users: [ 
            { "id": "En cours de preparation", "name": "En cours de préparation" }, 
            { "id": "En attente enlevement", "name": "En attente d'enlevement" }, 
            { "id": "Ramassage en cours", "name": "Ramassage en cours" },
            { "id": "Arrive au centre de tri", "name": "Arrivé au centre de tri" }, 
            { "id": "En cours de distribution", "name": "En cours de distribution" }, 
            { "id": "Disponible au point relais", "name": "Disponible au point relais" },
            { "id": "Livraison en cours", "name": "Livraison en cours" },
            { "id": "Refuse par le client", "name": "Refusé par le client" },
            { "id": "Livre", "name": "Livré" },
            { "id": "Reception retour client", "name": "Réception retour client" },
            { "id": "Non recupere", "name": "Non récupéré" },
            { "id": "Retour centre de tri en cours", "name": "Retour centre de tri en cours" },
            { "id": "Retourne au centre de tri", "name": "Retourné au centre de tri" },
            { "id": "Retour expediteur en cours", "name": "Retour expediteur en cours" },
            { "id": "Retourne", "name": "Retourné" },
            { "id": "Perdu", "name": "Perdu" },
            { "id": "Annule", "name": "Annulé" }
        ],
   selected: [],
   allSelected: false,
   userIds: [],
   varChanged:[],
   var : true,
  driver :true,
  dispo : true,
  nonrecupere : true,
  checknormal : true,
  disableButton: true,
  checkAllItems : '',
  showBtn: true,
  showProgress:false,
  showEncours:false,
  showMsg : false,
  loaded : '',
  nofiles:true,
  sellerSelected:'',
  warehouseSelected:'',
  fields:{ text: 'nom', value: 'id' },
  vendeurs:null,
  depots:null,
  ImportedShipments:[],



    }
  },
   computed :{
   getProgressBar() {
       return this.videoDownloadPercentage;
   },

     role(){
          return this.$store.getters.get_profile.type_user;
                  
      },
          user(){
           // console.log(this.$store.getters.get_user)
        return this.$store.getters.get_user;
 },
     },

mounted(){
 //test
 this.afficherSellers();
 this.afficherDepots();
 console.log("this.selection",this.selection.length)
 this.checkrow='';
   this.$store.dispatch('getItem', {//data to be sended
      nameRoute : '/api/getchauffeursactived',
      })
      .then( res => {
        this.error = '';
        this.isLoading = false;
        
        console.log(res.data);
        this.chauffeurs = res.data;
       // this.count = res.data.length;
      })   
      .catch(err => {
        this.isLoading = false
        if (!err.response) this.error = "Problème réseau, Impossible de joindre le serveur" 
        else this.error = "Une erreur s'est produite ! "
      })
      
    },


 methods: {

   checker(id){
     this.selection = [];
     console.log(id)
     document.getElementsByName("checkbox").checked = false;

     
     
   },
   

   showMessageConfirmation(item, i){
     Swal.fire({
      title: 'Attention!',
      text: "Etes vous sur de vouloir forcer ce statut?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: 'green',
      cancelButtonColor: '#d33',
      cancelButtonText: "Annuler",
      confirmButtonText: 'Ok'
      })
      .then((result) => {
       if (result.isConfirmed) {
      this.forcer(item.id);
         //if(i == 2) this.changementLivraison(item);
     
  }
})
   },
   exportExcel(){
    this.$store.dispatch('getFile', {//data to be sended
      nameRoute : '/api/export',
      ref:"_import",
      type:"GET",
      name:"Colis_Invalides",
      ext:'.xlsx'
      })
      .then( res => {
        console.log("yes");
       })
      .catch(err => {
        console.log(err);
   
      })  

   },
    showMessageConfirmation4Edit(item, i){
     Swal.fire({
      title: 'Attention!',
      text: "Etes vous sur de vouloir changer le mode de livraison?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: 'green',
      cancelButtonColor: '#d33',
      cancelButtonText: "Annuler",
      confirmButtonText: 'Ok'
      })
      .then((result) => {
       if (result.isConfirmed) {
        // if(i == 1) this.forcer(item.id);
          this.changementLivraison(item);
     
  }
})
   },

   importCsv(){
     this.modelUpload = true;

   },
 
   importFile(){
     this.showBtn=false;
     this.showProgress = true;
/*      console.mpg("eeeeee")
     const formData = new FormData();
    const imagefile = document.querySelector('#file');
    formData.append("file", this.ex);
      axios.post('https://bojibexp.jibliliexpress.ma/public/api/import', formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
      }) */
      console.log("*******");
     const formData = new FormData();
     const config = {
        //Choose upload
    
       onUploadProgress: event => {
          /*  this.videoDownloadPercentage = Math.round(
               (event.loaded * 100) / event.total
          ); */
          const {loaded, total} = event;
          this.videoDownloadPercentage = Math.floor((loaded * 100) / total);
          
          console.log(`${loaded}kb of ${total}kb | ${this.videoDownloadPercentage}%`);
          if(this.videoDownloadPercentage == 100) {this.showEncours = true;}
          
        },
    };
     
     formData.append('file', this.ex);
     formData.append('seller_id', this.sellerSelected);
     formData.append('warehouse_id', this.warehouseSelected);
          this.$store.dispatch('postFile', {//data to be sended
          nameRoute : '/api/import',
          formdata : formData,
          config : config
          })    
        .then(res => {
          console.log(res.data);
          if(res.data.success)
          //if(res ==  100 ) this.closeUpload();
          {
          console.log("yes");
          this.showMsg = true;
          this.showProgress = false;
          this.showEncours = false;
          this.notification();
          
          this.ImportedShipments = res.data.Shipments
          console.log("************1", this.ImportedShipments)
          //this.getMergePdf();
          }
          else
          {
            this.InvalidShipments = res.data;
            this.InvalidShipments.sort((t1,t2) => t1.row <= t2.row ? -1 : 1);
            console.log("errors");
            //this.exportExcel();
          this.showProgress = false;
          this.showEncours = false;
          this.showError = true;
          }
        })
        .catch(error => {

          this.notificationError();
          this.closeUpload();
              console.log(error)
           
        }) 
   
   },
   /*  onUploadProgress: function( progressEvent ) {
        this.uploadPercentage = parseInt( Math.round( ( progressEvent.loaded / progressEvent.total ) * 100 ) );
     // }
    }, */
   /*  uploadPercentage(progressEvent) 
    {
      return parseInt( Math.round( ( progressEvent.loaded / progressEvent.total ) * 100 ) );
    }, */

    testAction(){
      console.log("ttttttttt");

    },
    afficherSellers(){
     this.$store.dispatch('getItem', {//data to be sended
      nameRoute : '/api/getvendeursactived',
      })
      .then( res => {
        this.isLoading = false;
        this.vendeurs = res.data;
        //this.count = res.data.length;
       })
      .catch(err => {
        this.isLoading = false;
        if (!err.response) this.error = "Problème réseau, Impossible de joindre le serveur" 
        else this.error = "Une erreur s'est produite ! "
      })      
      },

      afficherDepots(){
     this.$store.dispatch('getItem', {//data to be sended
      nameRoute : '/api/getwarehouses',
      })
      .then( res => {
        this.isLoading = false;
        this.depots = res.data;
        //this.count = res.data.length;
       })
      .catch(err => {
        this.isLoading = false;
        if (!err.response) this.error = "Problème réseau, Impossible de joindre le serveur" 
        else this.error = "Une erreur s'est produite ! "
      })      
      },

   selecting(){
     this.selection = [];
     var checkAll = '';
     this.disableButton = true;
     this.all_s = false;
     if(!this.select_all)
     {
      checkAll = this.colis[0]["checked"]
      this.checkrow = checkAll;
      if(this.checkrow)
      {
         console.log("all is clicked")
        for(let i in this.colis)  {this.selection.push(this.colis[i].id); 
                                  //  if(this.colis[i]["latest_log"]["statut"] == "Arrive au centre de tri") this.bdlSelction.push(this.colis[i].id);
                                  //console.log("***********************",this.colis[i]["shipment_expired"] )
                                  if(!this.colis[i]["checked"]) {console.log("yes");continue;}
                                  if(this.colis[i]["checked"] != checkAll) {this.checkrow='';this.disableButton= false;}
                                  console.log("***********************",this.colis[i]["checked"] )

                                  }
      }

       
     }

   },
   searchShow(){
     this.reduire=!this.reduire;
   },


   changementLivraison(item){
     this.error = '',
     console.log(item.id)
   
      this.isLoading = true;
        this.$store.dispatch('idItem', {//data to be sended
      nameRoute : '/api/editshipment/'+item.id,
      })    
     .then(res => {
       

      console.log("yes");
      this.notification();
      //this.pdf(item.id);
      this.chercherColis();
     // this.parm = item.id;
     //this.$router.push({ name: 'pdfCode', params: {id:item.id}})



        
     })
     .catch(err => {
          // this.isLoading = false;
          this.notificationError();
          if (!err.response) this.error = "Problème réseau, Impossible de joindre le serveur" 
          else  this.error = "Une erreur s'est produite ! "

   })

   },
   reset(){
   this.shipment.n_exp = '',
   this.shipment.r_nom  = '',
   this.shipment.c_nom = '',
   this.shipment.v_nom  = '',
   this.shipment.w_nom  = '', 
   this.shipment.n_suivi  = '',
   this.shipment.type_exp  = '',
   this.shipment.date_exp_from  = '',
   this.shipment.date_exp_to  = '',
   this.shipment.date_enlv_from = '',
   this.shipment.date_enlv_to = '',
   this.shipment.cod  =''
   this.userIds = [];
   this.count = 0;
   this.colis = null;
   this.allSelected = false;



   },
       selectAll() {
            //test
             this.userIds = [];
            // this.allSelected = true;

            if (!this.allSelected) {
                for (let i = 0; i<this.users.length; i++) {
                    this.userIds.push(this.users[i]["id"]);
                }
            }
        },
     select() {
            this.allSelected = false;
            
        },
       changer(){
               //document.getElementById("selection").innerHTML = this.userIds;
              // this.userIds=["en cours de preparation"]
              console.log(this.c_nom);
              if(this.userIds.length<17) document.getElementById("selection").innerHTML=this.userIds.length+" selectionné (s)";
              if(this.userIds.length==17) document.getElementById("selection").innerHTML="Tous les statuts";
              if(this.userIds.length==0) document.getElementById("selection").innerHTML="Selectionner";
              


              this.chercherColis()

    },
      ajouterColis(){
          console.log("************clicked!");
         console.log("role",this.role)
         this.$router.push("/addshipment");
         },

      chercher(){
        console.log("yes");
        this.chercherColis();
      },
      getSeller(){
        console.log(event.target.value)

      },


      getMergePdf(){
        this.isLoading = true;
        console.log("getMergePDF");
        console.log("**********",this.ImportedShipments)
        this.$store.dispatch('getFile', {//data to be sended
      nameRoute : '/api/mergepdfs',
      type:"POST",
      ref:"",
      ids : this.ImportedShipments,
      name: "importedShipmenrs "+moment().format('YYYY-MM-DD'),//+this.sellerSelected+moment().format('MM/DD/YYYY'),
      ext:'.pdf'
            })
      .then( res => {
        //console.log(res.data);
        //console.log(res.data);
        //this.notification();
        this.isLoading = false;
        this.closeUpload();
       
       })
      .catch(err => {
        console.log(err);
        this.isLoading = false;
        //this.notificationError()
      }) 

      },


      testbdl(){
      let intersection = this.selection.filter(x => this.bdlSelction.includes(x));
    console.log("bdl*********",intersection);
     if(intersection.length > 0){
        this.$store.dispatch('getFile', {//data to be sended
      nameRoute : '/api/generate-bdl',
      type:"POST",
      ref:"",
      ids : intersection,
      name: "BL",
      ext:'.pdf'
            })
      .then( res => {
        console.log("yes");
        this.notification();
        this.bdlSelction = [];
       
       })
      .catch(err => {
        console.log(err);
        this.notificationError()
      })  

      }
      else console.log("**********No bdl detected!!")

      },

      edit(){
        this.error = '';
        this.dis = true;
        this.showEdit = true;
        this.t.ids = this.selection;
        this.t.checkrow = this.checkrow;
         this.$store.dispatch('postItem', {//data to be sended
      nameRoute : '/api/editstatuts',
      t : this.t
      })    
     .then(res => {
       
      // this.t = {};
       this.dis = false;

     // this.isLoading = false;
     //console.log(res.data)
       this.testbdl();
      console.log("yes");
      this.close();
      this.select_all= false;
      this.checkrow = "";
      console.log(this.t)
       this.notification();

      this.addActivity(this.t.ids)
      this.chercherColis();



        
     })
     .catch(err => {
       this.dis = false;
          // this.isLoading = false;
          this.notificationError();
          if (!err.response) this.error = "Problème réseau, Impossible de joindre le serveur" 
          else     this.error = "Une erreur s'est produite ! "

   })
      },

      annulerColis(id){
        var arr = [];
        arr.push(id);
        this.error = '';
        
        //this.showEdit = true;
         this.$store.dispatch('idItem', {//data to be sended
         nameRoute : '/api/shipmentcancelled/'+id,
      })    
     .then(res => {
      console.log("yes");
      this.notification();
      this.chercherColis();
      this.addActivity(arr);



        
     })
     .catch(err => {
          // this.isLoading = false;
          this.notificationError();
         if (!err.response) this.error = "Problème réseau, Impossible de joindre le serveur" 
        else  this.error = "Une erreur s'est produite ! "

   })

      },
      showMessageAnnulation(id){
        Swal.fire({
        title: 'Attention!',
      text: "Vous etes sur le point d'annuler ce colis, une fois annulé vous ne pouvez plus le restaurer!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: 'green',
      cancelButtonColor: 'black',
      cancelButtonText: "Annuler",
      confirmButtonText: 'Continuer'
      })
      .then((result) => {
       if (result.isConfirmed) {
         this.annulerColis(id);
     
  }
}) 

      },
      forcer(id){
        this.error = '';
        
        //this.showEdit = true;
        this.t.ids = this.selection;
         this.$store.dispatch('idItem', {//data to be sended
         nameRoute : '/api/forcerstatuts/'+id,
      })    
     .then(res => {
     // this.isLoading = false;
     //console.log(res.data)
     
      console.log("yes");
      this.chercherColis();



        
     })
     .catch(err => {
          // this.isLoading = false;
         if (!err.response) this.error = "Problème réseau, Impossible de joindre le serveur" 
        else  this.error = "Une erreur s'est produite ! "

   })
      },
      addActivity(items){
      this.activity.user_id= this.user.id;
      this.activity.action= "Change shipment status";
      this.activity.model = "Shipementlog"
      this.activity.url=this.$store.getters.get_link+'/api/editstatuts';
      this.activity.user_agent=navigator.appName+" "+navigator.appVersion ;
      this.activity.ids = items;
      console.log("activity..........", this.activity)
        this.$store.dispatch('postItem', {//data to be sended
          nameRoute : '/api/addactivity',
          t : this.activity
          })    
        .then(res => {
          console.log(res.data);
        })
        .catch(error => {
              console.log(error)
           
        }) 

      },
   
      tester(checked){
        console.log(this.selection.length)
        if(this.selection.length  == 0) this.checkrow = ''
        else this.checkrow = checked;
      
        
        /* if(checked == 1) {this.checknormal = true;this.driver=false;this.dispo=false;this.nonrecupere = false;}
        if(checked == 2) this.driver = true;
        if(checked == 3) this.dispo = true;
        if(checked == 4) this.nonrecupere = true; */
      },
      check(){
        //
        
        console.log("checked")
        
        for(let i in this.colis)
        {
          var statut = this.colis[i]["latest_log"]["statut"];
          var expired = this.colis[i]["shipment_expired"];
          if((statut == "En attente enlevement") || (statut == "Arrive au centre de tri" )  || (statut == "Refuse par le client") || (statut == "Reception retour client")  || (statut == "Retourne au centre de tri"))
          {
            this.colis[i]["checked"]=1;
            this.varChanged.driver = true;
            if(statut == "Arrive au centre de tri") this.bdlSelction.push(this.colis[i].id)
          }
          else
          
          if((statut == "Ramassage en cours") || (statut == "En cours de distribution" ) || (statut == "En cours de preparation" ) || (statut == "Livraison en cours")  || (statut == "Livre") || (statut == "Retour centre de tri en cours") || (statut == "Livraison en cours") || (statut == "Retour expediteur en cours"))

           {this.varChanged.check = true;this.colis[i]["checked"]=2;}
           else
                     if(statut == "Disponible au point relais") 
          {this.varChanged.dispo = true;this.colis[i]["checked"]=3;}

          else
            if(statut == "Non recupere") 
            {
            this.varChanged.nonrecupere = true;
            this.colis[i]["checked"]=4;
            }
          /* else if
          ((statut == "En cours de preparation") || (expired ==  1))
          this.colis[i]["checked"]=''; */

          

          }
        console.log(this.colis)

      },




    chercherColis(){
       this.error = '';
      this.selection = [];
      console.log("shipment*******", this.shipment)
      this.checkrow = '';
      console.log("chercher")
      this.shipment.items = this.userIds;
      this.isLoading = true;
      this.$store.dispatch('postItem', {//data to be sended
      nameRoute : '/api/search',
      t : this.shipment
      })    
     .then(res => {
      this.isLoading = false;
     console.log(res.data)
        this.colis = res.data;
        this.count = res.data.length;
        this.all = this.count;
        this.check();
     })
     .catch(err => {
          this.isLoading = false;
          if (!err.response) this.error = "Problème réseau, Impossible de joindre le serveur" 
          else    this.error = "Une erreur s'est produite ! "

   })


/* 
                axios.create({
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer '+localStorage.getItem("token")
        }
    })
       .post('http://127.0.0.1:8000/api/search',{
         items :this.userIds,
         n_exp : this.n_exp,
         r_nom  : this.r_nom,
         c_nom  : this.c_nom,
         v_nom  : this.v_nom, 
         n_suivi  : this.n_suivi,
         type_exp  : this.type_exp,
         date_exp_from  : this.date_exp_from,
         date_exp_to  : this.date_exp_to,
         date_enlv_from  : this.date_enlv_from,
         date_enlv_to  : this.date_enlv_to,
         cod  : this.cod, 

        // r_nom : "a",
         
       })
      .then( res => {
        console.log(res.data);
        this.colis = res.data;
        this.count = res.data.length;

        

        //this.$router.push("/d");
      })
      /*
      this.$store.dispatch('retrieveToken', {
        email: this.email,
        password: this.password,
      })*//*
      .catch(err => {
        this.error = "Une erreur s'est produite ! "
      }) */

    },
    selectaAllShipments(){
        axios.create({
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer '+localStorage.getItem("token")
        }
    })
     .get("http://127.0.0.1:8000/api/getallshipments")
     .then(res => {
       this.colis = res.data;
      //console.log(JSON.parse(JSON.stringify(this.res.data)))
    //   console.log(JSON.parse(res.data))
    this.count = res.data.length;
    this.all = this.count;

     })

    },
  
    afficherClientsArchives(){
                      axios.create({
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer '+localStorage.getItem("token")
        }
    })
       .get('http://127.0.0.1:8000/api/getclientsarchived',this.data)
      .then( res => {
        console.log(res.data);
        this.clients = res.data;
      // this.count = res.data.length;
       this.error = '';
       

        

        //this.$router.push("/d");
      })
      /*
      this.$store.dispatch('retrieveToken', {
        email: this.email,
        password: this.password,
      })*/
      .catch(err => {
        if (!err.response) this.error = "Problème réseau, Impossible de joindre le serveur" 
        else  this.error = "Une erreur s'est produite ! "
      })
      
    },
    changerStatut($event){
      this.t.shipment_statut = event.target.value

    },
    archiver(id){
      this.id = id;
      console.log(this.id);
      axios.create({
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer '+localStorage.getItem("token")
        }
    })
     .post("http://127.0.0.1:8000/api/archiverclient/"+this.id)
     .then(res => {
      console.log(res.data);
      //console.log(JSON.parse(JSON.stringify(this.res.data)))
    //   console.log(JSON.parse(res.data))
  //  this.count = res.data.length;

     })

    },
       format_date(value){
         if (value) {
           return moment(String(value)).format('DD-MM-YYYY hh:mm')
          }
      },

     openModel(item){
       console.log("clicked")
        console.log(item)
  // this.first_name = '';
  // application.last_name = '';
  this.t = item;
   
   this.actionButton = "Insert";
   this.dynamicTitle = "Add Data";
   this.myModel = true;
  
  },
   showing(){
       console.log("clicked")
  // this.first_name = '';
  // application.last_name = '';
   
   this.actionButton = "Insert";
   this.dynamicTitle = "Add Data";
   this.showEdit = true;
  },

  close(){
    this.showEdit = false;
    this.t.shipment_statut ='';
    this.t.driver_id = '';
  },

  closeUpload(){
    this.modelUpload = false;
    this.showBtn = true;
    this.showProgress = false;
    this.showMsg = false;
    this.nofiles = true;
    this.showEncours = false;
    this.videoDownloadPercentage = 0;
    this.sellerSelected = '';
    this.warehouseSelected = '';
    this.ex = null;
    this.showError = false;

  },

    clear(){
      $('#myform').trigger("reset");

    },

    historique(id){
      const route = this.$router.resolve({ name: 'historique', query: {id:id} }); window.open(route.href, '_blank');
      //this.$router.push({ name: 'historique', params: {id:id}})
    },

    liste(){
      this.error = '';
      this.isLoading = true;
      console.log(this.isLoading)
      this.$store.dispatch('getItem', {//data to be sended
      nameRoute : '/api/nonrecupere',
      })    
     .then(res => {
       
       this.isLoading = false;
      console.log(res.data);
      this.colis = res.data;
        this.count = res.data.length;
        this.all = this.count;
        this.check();
     })
     .catch(err => {
       this.isLoading = false;
       if (!err.response) this.error = "Problème réseau, Impossible de joindre le serveur" 
       else  this.error = "Une erreur s'est produite ! "
      }) 

    },
     notification(){
        console.log("notify")
        
         notify({ type: "success", text: "The operation completed", title: "DONE" });
      },

       notificationError(){
   console.log("notify")
   
notify({ type: "error", text: "the operation could not be completed", title: "WARNING" });
 },

   getFile(e){
    //  var files = e.target.files || e.dataTransfer.files;*
     this.ex = e.target.files[0];
     if(this.ex) this.nofiles = false;
         console.log(this.ex)


   /*  const reader = new FileReader();

    reader.readAsText(this.t.file);

    reader.onload = () => {
      console.log(reader.result);
      //this.t.file = reader.result; // contains the file content as a string
    };

    reader.onerror = () => {
    console.log(reader.error);
   }; */

  

   },
   
     pdf(id, ref){
       this.loaded = id;
    console.log("pdf");
     this.$store.dispatch('getFile', {//data to be sended
      nameRoute : '/api/generate-pdf/'+id,
      ref:ref,
      type:"GET",
      name:"BC",
      ext:".pdf"
      })
      .then( res => {
        this.loaded = '';
        console.log("yes");
        this.notification()
       
       })
      .catch(err => {
        this.loaded;
        console.log(err);
        this.isLoading = false;
        this.notificationError()
      })  
  },

  }
}
</script>
<style scoped lang="scss">
/* @import "../../node_modules/@syncfusion/ej2-base/styles/material.css";
@import "../../node_modules/@syncfusion/ej2-inputs/styles/material.css";
@import "../../node_modules/@syncfusion/ej2-vue-dropdowns/styles/material.css"; */

.home-section{
  position: relative;
  background: #E4E9F7;
  min-height: 100vh;
  top: 0;
  left: 78px;
  width: calc(100% - 78px);
  transition: all 0.5s ease;
  z-index: 2;
}
.sidebar.open ~ .home-section{
  left: 250px;
  width: calc(100% - 250px);
}




.home-section .text{
  display: inline-block;
  color: #11101d;
  font-size: 25px;
  font-weight: 500;
  margin: 0px;
}
/* serachbar */
 input[type="date"]:before {
    content: attr(placeholder) !important;
    color: gray;

    
  }
  input[type="date"]:focus:before,
  input[type="date"]:valid:before {
    content: "";
  }


.input-search{
  border: white;
  background-color: transparent;
  font-family: ROBOTO;
  color:black;
  font-weight: normal;
    box-shadow: 0px 15px 10px -15px #111;    
}

.input-search::placeholder{
  font-family: ROBOTO;
  color:black;
  font-weight: bolder;
  font-size: 14px;
}
input::placeholder{
  font-family: ROBOTO;
  font-style: normal;
  color:gray;
  font-size: 14px;
}
.input-search:-webkit-autofill:active{
  font-family: ROBOTO;
  color:darkslategray;
  font-weight: normal;
  font-size: 14px;
  background: darkslategray;
  
}


 


 
input[type=checkbox]:checked {
  /*CSS after CHECKED*/
  color: #ffd369;
}

.dropdown-toggle:hover, input[type=search].input-search:hover, .dropdown-toggle:active{
  background: #d7dbdc;
  font-weight: normal;
}
a{
  cursor : context-menu;
}
a:hover {
  color : rgb(255, 255, 255) !important;
}

.modal-mask {
     position: fixed;
     z-index: 9998;
     top: 0;
     left: 0;
     width: 100%;
     height: 100%;
     background-color: rgba(0, 0, 0, .5);
     display: table;
     transition: opacity .3s ease;
   }

   .modal-wrapper {
     display: table-cell;
     vertical-align: middle;
   }


   /* uploading */
   $theme:       #454cad;
   $dark-text:   #5f6982;

   h2 {
  font-family: "Roboto", sans-serif;
  font-size: 26px;
  line-height: 1;
  color: $theme;
  margin-bottom: 0;
}
p {
  font-family: "Roboto", sans-serif;
  font-size: 18px;
  color: $dark-text;
}
// Upload Demo
// 
.uploader {
  display: block;
  clear: both;
  width: 100%;

  label {
    float: left;
    clear: both;
    width: 100%;
    padding: 2rem 1.5rem;
    text-align: center;
    border-radius: 7px;
    transition: all .2s ease;
    user-select: none;


  }
  label.label{
    padding: 0% important;
    background: red;
  }

  #start {
    float: left;
    clear: both;
    width: 100%;
    i.fa {
      font-size: 50px;
      margin-bottom: 1rem;
      transition: all .2s ease-in-out;
    }
  }
  

  
  
  

  



 

  
}




</style>
import { Script } from "vm";