<template>
<h1>Testing with id = {{id}}</h1>
  <div id="facture"  >
      <div class="row">
          <div class="col1"  >
<p><span><b>{{shipment.point_enlevement}}</b></span>
<span>{{shipment.point_enlevement_adresse}}</span> 
<span>   {{shipment.point_enlevement_ville}}    </span></p>
              <p > Demande d'enlevement : <b>{{shipment.date_enlevement_demandee}}</b> </p>
              <table class="  table table-bordered" >
                  <tr>
                      <th >Destinataire</th>
                  </tr>
                  <tr>
                      <td >Nom : <br>
                          <b>{{shipment.nom}} {{shipment.prenom}}</b><br><br>
                          Adresse : <br>
                      <b>{{shipment.relais}}</b><br>
                      <b>{{shipment.relais_adresse}}</b><br>
                      <b>{{shipment.relais_ville}}</b>
                      </td>
                      
                  </tr>
              </table>
              <table class=" table table-bordered"  >
                  <tr>
                      <td>Poids</td>
                      <td> {{shipment.poids}} Kg</td>
                  </tr>
                  <tr>
                      <td>Numero d'expedition</td>
                      <td>{{shipment.n_expedition}}</td>
                  </tr>
                   <tr>
                      <td>Nombre de colis</td>
                      <td>1</td>
                  </tr>
                   <tr>
                      <td>Reference client</td>
                      <td>{{shipment.reference_client}}</td>
                  </tr>
                   <tr>
                      <td>Numero de suivi</td>
                      <td>{{shipment.id}}</td>
                  </tr>

              </table>

          </div>
          <div class="col2 part">
              <img class="image" src="../../public/images/jiblili.png">
              <div class="codeqr">
                  <vue-qrcode v-bind:value="qrValue" scale="2"/>
              </div>
              <div class="Last">
                <p>
                  Encaissement :
             <span class="span"> <b v-if="shipment.cod == 0" >Payé</b>
                <b v-else >A payer</b></span>

</p>

      <div class="form-check">
  <input class="form-check-input" type="checkbox" value="1" id="flexCheckDefault" style="font-size: 30px;">
  <label class="form-check-label font-weight-bold" for="flexCheckDefault">
    Retour
  </label>
</div>
              </div>
  

          </div>
      </div>
  </div>
    <br>
    <div>
      <button @click="makepdf()">  Makepdf </button>
    <button @click="printFacture()">  exportPdf </button>
    <button @click="tester()">  tester d'abord </button>
    <button @click="verifier()">  verifier ensuite </button>
    <button @click="uint()">  uint </button>
    <button @click="testingbase()">  base 64 </button>
    <button @click="passToId()">  qr value </button>
    <button @click="show()">  show </button>
     <button @click="playSound()">  Sound </button>
     <button @click="ord"> ord</button>

    </div>
    <br><br>
    <div class="stream" v-if="testVar">
     
  <qr-stream @decode="onDecode" class="mb">
    <div style="color: red;" class="frame"></div>
  </qr-stream>
</div>
<div class="result" v-if="testVar">
  Result: {{data}}
</div>
    


</template>


<script>
import VueQrcode from 'vue3-qrcode'
import html2canvas from "html2canvas"
import jspdf from "jspdf"
import axios from "axios"
import CryptoJS from 'crypto-js'
import swal from 'sweetalert2';






export default {
  components: {
    VueQrcode,
  },

   data(){
    return{
     // id : this.$route.params.id,
      id: 45,
      qrValue :  "",
      test : "s",
      correctionLevel:"H",
      shipment :{},
      val : false,
      random : "",
      salt : localStorage.getItem("Sa"),
      // key : 'AA74CDCC2BBRT935136HH7B63C27', // change to your key
      key : localStorage.getItem("kp"),
       iv :  localStorage.getItem("I") ,// change to your iv
      apiKey : '123xxxyyyzzz', // change to your api key
      user : null,
      stringEncrypte : "jPZYSlGrKDlMX99pvC/bnkvrPnNlrOzO/jtyvQMnw/ioMdrtfDBJe3drJziBELE78h8rgYKWg3nwxxA1bGKG3QXeH8seXavozQ97Hk3epr4lratDlnj8ReZZlkPW4NqV/4LkHHw7eJnZ+pOdf1dNLGPfuGnhQW1ff/V6gG62bwVxDl3P9ScJx6yfinV5xVU+0jZjbJtRd5GR+fUojCUCb0yisuIEiMahYhneZqj9iELM9zjD+BlkZ/GBC35UhvDsqeNtGZfbvPp+r6qiv/i8FCRiIvlltt7DtlozW8/Qf93bpcssKpAzOz93ABFysHBIjp7QF+WwhLESXAg7Xq2mHQ==",
      userEncrypte : "",
      testData : "",
      data : "",
    }
   },
   mounted(){
     //tes
     console.log("test");
       axios.create({
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer '+localStorage.getItem("token")
                  }
                 })
     .get("http://127.0.0.1:8000/api/getshipment/"+this.id)
     .then(res => {
       this.shipment = res.data[0];
       console.log(this.shipment);
       //console.log(this.shipment);

     })
     this.gettingValue();
     //Promise.resolve(this.testingvalue()).then(this.printFacture())
    //setTimeout(() => {  this.printFacture(); }, 2500);
    

   },



  methods: {
     show(){
         swal.fire(
          // 'Test!', 'Hello test message','success'
          {
  icon: 'success',
  title: 'Verified',
  text: 'Verified',
  showConfirmButton: false,
  timer: 1000
}
           ).then(function() {
     //this.data = "yes";
     console.log("yes")
});
      },
      playSound () {
     //
    },
    makepdf(){
      window.html2canvas = html2canvas;
      //var doc = new jspdf("p","pt","a4");
      var doc = new jspdf('p', 'mm', [950, 1189]);
      
      //var doc = new jspdf('p', 'mm', 'a4');
      doc.html(document.querySelector("#test"), {

       callback : function(pdf){
         pdf.save("mypdftesting.pdf");
       }
      });
    },

    passToId(){
      console.log("clicked!!!")
      this.qrValue = this.stringEncrypte;
      console.log(this.qrValue)
      var p = "nouheyla"
       p = p.split('').reverse().join('')
  console.log("***********************")
    console.log(p)
  //   this.$router.push(`/testId/${this.id}`);
   // this.$router.push({ path: '/testId', params: { id } }) // -> /user


    },
    gettingValue(){
        axios.create({
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer '+localStorage.getItem("token")
                  }
                 })
     .get("http://127.0.0.1:8000/api/sending/"+this.id)
     .then(res => {
       this.qrValue = res.data;
      // console.log(JSON.stringify(res.data));

     })
    },
    testingvalue(){
      if(this.qrValue != null){console.log("yes")}
    },
    printFacture() {
    var pdf = new jspdf();
    var element = document.getElementById('facture');
    var width= element.style.width;
    var height = element.style.height;
    html2canvas(element).then(canvas => {
        var image = canvas.toDataURL('../../public/images/jiblili.png');
        pdf.addImage(image, 'JPEG', 15, 40, width, height);
        pdf.save('facture.pdf');
    });
},
testingbase(){
console.log("test");

axios.create({
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer '+localStorage.getItem("token")
                  }
                 })
     .get("http://127.0.0.1:8000/api/storing/"+this.id)
     .then(response => {
    this.user = response.data.user;
    this.stringEncrypte = response.data.encrypted;

    this.userEncrypte = response.data.encrypted2;
           // console.log(this.userEncrypte)

    
    var key = 'IsU70iGcnFCwFD+wIxQ9UaMgm6kEo/+eIMawHMaS3pc='
    var encrypted_json = JSON.parse(atob(this.userEncrypte));
    var decrypted = CryptoJS.AES.decrypt(encrypted_json.value, CryptoJS.enc.Base64.parse(key), {
      iv: CryptoJS.enc.Base64.parse(encrypted_json.iv)
    });
    // Decrypt in string format
    console.log(decrypted.toString(CryptoJS.enc.Utf8));

    // Decrypt in Array, Object format
    console.log(JSON.parse(decrypted.toString(CryptoJS.enc.Utf8)));


  })

  
},
   aesEncrypt(txt) {
      const cipher = CryptoJS.AES.encrypt(txt, CryptoJS.enc.Utf8.parse(this.key), {
        iv: CryptoJS.enc.Utf8.parse(this.iv),
        mode: CryptoJS.mode.CBC
      })

      return cipher.toString()
    },
    aesDencrypt(txt) {
      const cipher = CryptoJS.AES.decrypt(txt, CryptoJS.enc.Utf8.parse(this.key), {
        iv: CryptoJS.enc.Utf8.parse(this.iv),
        mode: CryptoJS.mode.CBC
      })

      return CryptoJS.enc.Utf8.stringify(cipher).toString()
    },

str2ab(str) {
  var buf = new ArrayBuffer(str.length * 2); // 2 bytes for each char
  var bufView = new Uint16Array(buf);
  for (var i = 0, strLen = str.length; i < strLen; i++) {
    bufView[i] = str.charCodeAt(i);
  }
  return buf;
},
ord(){
    var chaine = "itndexvcmraplsyw";
    var arr = [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16]
    for(var i in arr)
    {
        console.log(arr[i]);
        console.log(chaine.charCodeAt(i));



       }
    return arr;

},
testingPako(){/*
   var b64Data     = 'H4sIAAAAAAAAAwXB2w0AEBAEwFbWl2Y0IW4jQmziPNo3k6TuGK0Tj/ESVRs6yzkuHRnGIqPB92qzhg8yp62UMAAAAA==';

// Decode base64 (convert ascii to binary)
var strData     = atob(b64Data);

// Convert binary string to character-number array
var charData    = strData.split('').map(function(x){return x.charCodeAt(0);});

// Turn number array into byte-array
var binData     = new Uint8Array(charData);

// Pako magic
var data        = pako.inflate(binData);

// Convert gunzipped byteArray back to ascii string:
strData     = String.fromCharCode.apply(null, new Uint16Array(data));

// Output to console
console.log(strData);*/
},
tester(){
  console.log("yes")
  axios.create({
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer '+localStorage.getItem("token")
                  }
                 })
     .get("http://127.0.0.1:8000/api/ITlv/"+this.id)
     .then(response => {
    this.stringEncrypte = response.data.ciphertext;
    console.log(this.stringEncrypte)
    this.key = response.data.pass;
    this.salt = response.data.salt;
    this.iv = response.data.iv;
   // console.log(this.key);


           // console.log(this.userEncrypte)

  
    });




},
verifier(){    console.log("verifier") ; 

console.log(this.CryptoJSAesDecrypt(this.key, "un88hb1gOCksagzQXBa1aBabFg3xdoHr9qYx0M0RJVBxaPS6k0JjRUgj8Av6VuXkTTPKoBuGGbb+p9g3yFD6iQYO2k57Qm0yOtjh8m6L+FUKyL7u2WyoXb+NyB+XFveRDPm1Z7WsJrRF1ANRdjOCyC9NFYTdMRIiMMzE7fHgQv4pTxkET3BIv2Il1dwSiodGNOk0K5zM0yuuhZLKodN9zcmI1tfKvcZ17OPDdxzTxHGUXdZVv+e7VxAzRbiVyB2NJlm/VwnsrsEZg6SO7aQWzTMVSYp+rjX5cAdKp09pNWpAXUCsbkiNAwifjrRJqGSv+ZaeJ6NPcbZ3jqHdxRMRFA=="));
//console.log(JSON.parse(this.CryptoJSAesDecrypt(this.key, this.stringEncrypte)));
var testing = JSON.parse(this.CryptoJSAesDecrypt(this.key, this.stringEncrypte));
console.log((testing));
console.log("***********************");
 var encodedString = String.fromCharCode.apply(null, testing[3]),
        decodedString = decodeURIComponent(encodedString);
    var str = decodedString;
    console.log("***********************")
    console.log(str)
     var p = str
       p = p.split('').reverse().join('')
  console.log("***********************")
    console.log(p)
 
    

},
uint(){
  var string = "nouheyla khallouki",
        charList = string.split(''),
        uintArray = [];
    for (var i = 0; i < charList.length; i++) {
        uintArray.push(charList[i].charCodeAt(0));
    }
    var Arr = new Uint8Array(uintArray);
    console.log(Arr);
    var encodedString = String.fromCharCode.apply(null, Arr),
        decodedString = decodeURIComponent(encodedString);
    var str = decodedString;
    console.log("***********************")
    console.log(str)

},
CryptoJSAesDecrypt(passphrase,encrypted_json_string){

    //var obj_json = JSON.parse(encrypted_json_string);

    var encrypted = encrypted_json_string;
    var salt = CryptoJS.enc.Hex.parse(this.salt);
    var iv = CryptoJS.enc.Hex.parse(this.iv);   

    var key = CryptoJS.PBKDF2(passphrase, salt, { hasher: CryptoJS.algo.SHA512, keySize: 64/8, iterations: 999});


    var decrypted = CryptoJS.AES.decrypt(encrypted, key, { iv: iv});

    return decrypted.toString(CryptoJS.enc.Utf8);
}

  



  }
}
</script>
<style scoped>
.row{
  background : transparent;
}
.col1{
  background: transparent;
  width: 15%;
  float: left;
  padding: 0% 2%;
}
.col2{
  background: transparent;
  width: 30%;
  display: inline-block;
}
table{
   width: 95%;
  border-collapse: collapse;
  margin: 7% 0%;
}
table, td, th{
  border: 1px solid black;
}
p span {
  display: block;
}
p span.span {
  display: inline-block;
}
.image{
  margin-top: -12%;
}
.codeqr{
  margin-top: -17%;
  margin-left: -7%;

}
.Last p {
  border: 1px solid black;
  padding: 2%;
  margin-top: -0%;
  margin-left: -2%;
  width: 30%;
  background: transparent;
  float: left;

}
div.Last .form-check{
  display: inline-block;
  margin-top: 2% ;
  margin-left: 1%;

}

 

 
 
 
 
 
</style>