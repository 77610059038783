

<template>
              

      <section class="home-section" >
         
         <div class="container-scroller" >
    <!-- partial:partials/_navbar.html -->
    <Header title="Ajout colis" />
    <!-- partial -->
    <div class="container-fluid page-body-wrapper bg-light"  >
      <!-- partial:partials/_sidebar.html -->
   
    
      <!-- partial -->
      <div class="main-panel" style="width : 100%; ">
        <!---content wrapper start--->
              
        <div class="content-wrapper">

                  <div class="row" >
            <div class="col-md-12 grid-margin">
              <div class="d-flex justify-content-between flex-wrap row">
                <div class="d-flex align-items-end flex-wrap col-lg-8">
                
               
                <div class="mt-3" >
                      <p class=" d-flex justify-content-center align-self-center " ><i class="mdi mdi-store icon-md mr-1"></i> 
                         <span class=" py-2 " >Horaires point relais</span>
                       </p>
                    </div>
      


                </div>
                <div class="col-lg-4 d-flex justify-content-end align-items-end flex-wrap">
                  

                  

                 
<!-- <button @click="t">tester</button> -->
                  
                  
                </div>
              </div>
            </div>
          </div>
    
   


       <!--------------->
         <div class="row " style="margin-top : 1%;">
                       <div class="col-12"> 
                               <div  v-if="error" class="alert alert-danger text-center" role="alert">
                       {{error}}
                </div>
                       </div>
                     </div>
          <div class="row">
            <div class=" stretch-card col-lg-12 m-0 p-0">
              <div class="card p-0 bg-transparent border-0 m-0">
                <div class="card-body p-0">
                  <h3 class="card-title text-center my-0"> Point relais {{ id }} </h3>
 
  <div class="row m-0 p-0">
        <div class="col-lg-12 grid-margin stretch-card">
              <div class="card bg-transparent border-0">
                <div class="card-body bg-transparent p-0">                 
                 <div class="row">
                    <div class="col-lg-12 grid-margin stretch-card">
              <div class="card bg-transparent border-0">
                <div class="card-body bg-transparent">
                  <form class="forms-sample card border-0 pt-3 bg-transparent"  @submit.prevent="edit">
                  <table class="table w-100 bg-white">
                    <tr v-for="(item, index) in jours" :key="item.id" v-bind:index="index" :id="item.name">
                      <td scope="row" class="text-capitalize">{{item.name}}</td>
                      <td>
                        <div class="float-end">
                          <div class="custom-control custom-switch">
                            <input type="checkbox" class="custom-control-input" :id="'switchMorning'+index"  v-model="jours[index].onMorning" @change="onMorningChange()" >
                            <label class="custom-control-label" :for="'switchMorning'+index" >
                              <span v-if="jours[index].onMorning == true">ON</span>
                              <span v-else>OFF</span>
                            </label>
                          </div>
                        </div>
                      </td>
                      <td v-if="jours[index].onMorning">
                        <input type="time" class="form-control" id="exampleInputUsername2" v-model="jours[index].hm_debut">
                      </td>
                      <td v-else>
                        
                      </td>
                      <td v-if="jours[index].onMorning">
                        <input type="time" class="form-control" id="exampleInputUsername2" v-model="jours[index].hm_fin">
                      </td>
                      <td v-else>
                        
                      </td>
                      <td>
                        <div class="float-end">
                          <div class="custom-control custom-switch">
                            <input type="checkbox" class="custom-control-input" :id="'switchAfternoon'+index"  v-model="jours[index].onAfternoon" @change="onAfternoonChange()">
                            <label class="custom-control-label" :for="'switchAfternoon'+index" >
                              <span v-if="jours[index].onAfternoon == true">ON</span>
                              <span v-else>OFF</span>
                            </label>
                          </div>
                        </div>
                      </td>
                      <td v-if="jours[index].onAfternoon">
                        <input type="time" class="form-control" id="exampleInputUsername2" v-model="jours[index].hs_debut">
                      </td>
                      <td v-else>
                      </td>
                      <td v-if="jours[index].onAfternoon">
                        <input type="time" class="form-control" id="exampleInputUsername2" v-model="jours[index].hs_debut">
                      </td>
                      <td v-else>
                      </td>
                      

                    </tr>
                  </table>
                  <div class="d-flex flex-row-reverse bd-highlight">
                    <button class="btn btn-primary px-3  float-right m-3"  @click="horaire">
                      <span v-if="!isLoading" >Valider</span>
                      <loading v-if="isLoading" :active="true" :width="10" :height="10" color="white"></loading>
                    </button>
                  </div>
                  </form>

                   <!-- <div class="inputGroup border border-secondar" v-for="(item, index) in jours" :key="item.id" v-bind:index="index" :id="item.name">
    <input :id="index" :name="item.name" type="checkbox" :value="item.name"  v-model="selection" @change="tester(index, item)" />
    <label :for="index" :class="item.name">{{item.id}}</label>
  </div> -->

                 </div>
                 </div>
                 </div>

                    <div class="col-lg-8 grid-margin stretch-card bg-transparent" v-if="show">
       

             <!--  <div class="">
                <div class="card-body ">
                  <table class="table mb-3 table-borderless">
  <thead class="bg-secondary text-white">
    <tr>
      <th scope="col" colspan="4">
        <div class="float-start">
          Matin
        </div>
        <div class="float-end">
          <div class="custom-control custom-switch">
  <input type="checkbox" class="custom-control-input" id="customSwitch1" v-model="matin" value="1">
  <label class="custom-control-label" for="customSwitch1" >
    <span v-if="matin == 1">ON</span>
    <span v-else>OFF</span>
  </label>
</div>
        </div>
      </th>
     
    </tr>
  </thead>
  <tbody >
   
    <tr  style="height : 120px; padding-top: 50px;" v-if="matin == 1"> 
      <td class="py-5"  >De</td>
      <td class="py-5" >                        <input type="time" class="form-control" id="exampleInputUsername2" v-model="hm_debut" required>
      </td>
      <td class="py-5 text-center" >A</td>
      <td class="py-5" >                       <input type="time" class="form-control" id="exampleInputUsername2" v-model="hm_fin" required>
     </td>
    </tr>
    
  </tbody>
</table>

                 <table class="table mt-2 mb-0 table-borderless">
  <thead class="bg-secondary text-white">
    <tr>
      <th scope="col" colspan="4">
        <div class="float-start">
          Soir
        </div>
        <div class="float-end">
          <div class="custom-control custom-switch">
  <input type="checkbox" class="custom-control-input" id="customSwitch2" v-model="soir" value="1" >
  <label class="custom-control-label" for="customSwitch2" >
    <span v-if="soir == 1">ON</span>
    <span v-else>OFF</span>
  </label>
</div>
        </div>
      </th>
     
    </tr>
  </thead>
  <tbody >
   
    <tr style="height : 120px; padding-top: 50px;" v-if="soir == 1"> 
      <td class="py-5"  >De</td>
      <td class="py-5" >                        <input type="time" class="form-control" id="exampleInputUsername2" v-model="hs_debut" required>
      </td>
      <td class="py-5 text-center" >A</td>
      <td class="py-5" >                       <input type="time" class="form-control" id="exampleInputUsername2" v-model="hs_fin" required>
     </td>
    </tr>
    
  </tbody>
</table>
               
               
<div class="d-flex flex-row-reverse bd-highlight p-0 m-0">

                                          <button type="submit" class="btn btn-primary  float-right m-3" ><i class="mdi mdi-plus icon-lg text-white"></i></button>
                </div>
 
                </div>

              </div> -->

            </div>
                 </div>
                 
                 
                 
                 
                 
                 
   
<!-- {{selection}} -->
<!-- {{option}}
 -->                </div>

 <div class="row m-0 p-0">
   <div class="card mx-2 my-0 bg-transparent border-0"  >
<!-- 
     <table class="table table-bordered">
  <thead>
    <tr>
      <th scope="col">#</th>
      <th scope="col">First</th>
      <th scope="col">Last</th>
      <th scope="col">Handle</th>
    </tr>
  </thead>
  <tbody>
    <tr  v-for="(item, index) in option" :key="item.name" v-bind:index="index" :id="item.name">
      <th scope="row" class="bg-primary py-3 text-white col-lg-2 text-center font-weight-bold">{{item.name}}</th>
      <td v-if="item.offm == 1" class="text-center col-lg-5 font-weight-bold"> OFF</td>
      <td v-else class="text-center font-weight-bold col-lg-5"> <input type="time" class="d-inline form-control" id="exampleInputUsername2" v-model="item.md" > - <input type="time" class="form-control d-inline" id="exampleInputUsername2" v-model="item.mf" ></td>
      <td v-if="item.offs == 1" class="text-center font-weight-bold col-lg-5" >OFF</td>
      <td v-else class="text-center font-weight-bold col-lg-5"> {{item.sd}} - {{item.sf}}</td>
    </tr>
   
   
  </tbody>
</table> -->
    
     
   </div>
 </div>

        
              </div>
            </div>
           
            
  
     </div>




                </div>
              </div>
            </div>
            
          </div>

   







        
        </div>

        <!-- content-wrapper ends -->
        <!-- partial:partials/_footer.html -->
      <Footer />
        <!-- partial -->
      </div>
      <!-- main-panel ends -->
    </div>
    <!-- page-body-wrapper ends -->
  </div>
        
      </section>

</template>

<script>
// import Menu from '@/components/Menu.vue'; 
import Header from '@/components/Header.vue'; 
import Footer from '@/components/Footer.vue'; 
import axios from 'axios';
import $ from 'jquery';
import Swal from 'sweetalert2';
import moment from 'moment';
import { notify } from "@kyvg/vue3-notification";
import Loading from 'vue3-loading-overlay';








export default {

  components : {
    // Menu,
    Header,
    Footer,
    Loading
  },
  data()  {
    return {
   selection : [],
   option : [],
   test: true,
   show : false,
   soir : 0,
   matin : 0,
   onMorning : [],
   onAfternoon : [],
   hm_debut:'',
   hm_fin:'12:00:00',
   hs_debut:'14:00:00',
   hs_fin:'18:00:00',
   t:{},
   id : this.$route.params.id,
   error : '',
   isLoading : false,
   //id: 10,

   //id : '',
   jours: [ 
            { "id": "Lundi", "name": "lundi", "hm_debut":"08:00:00", "hm_fin":'12:00:00', 'hs_debut':'14:00:00', 'hs_fin':'18:00:00', "onMorning":true, "onAfternoon":true }, 
            { "id": "Mardi", "name": "mardi", "hm_debut":"08:00:00", "hm_fin":'12:00:00', 'hs_debut':'14:00:00', 'hs_fin':'18:00:00', "onMorning":true, "onAfternoon":true }, 
            { "id": "Mercredi", "name": "mercredi", "hm_debut":"08:00:00", "hm_fin":'12:00:00', 'hs_debut':'14:00:00', 'hs_fin':'18:00:00', "onMorning":true, "onAfternoon":true }, 
            { "id": "Jeudi", "name": "jeudi", "hm_debut":"08:00:00", "hm_fin":'12:00:00', 'hs_debut':'14:00:00', 'hs_fin':'18:00:00', "onMorning":true, "onAfternoon":true }, 
            { "id": "Vendredi", "name": "vendredi", "hm_debut":"08:00:00", "hm_fin":'12:00:00', 'hs_debut':'14:00:00', 'hs_fin':'18:00:00', "onMorning":true, "onAfternoon":true  }, 
            { "id": "Samedi", "name": "samedi", "hm_debut":"08:00:00", "hm_fin":'12:00:00', 'hs_debut':'', 'hs_fin':'', "onMorning":true, "onAfternoon":false }, 
            { "id": "Dimanche" , "name": "dimanche", "hm_debut":"", "hm_fin":'', 'hs_debut':'', 'hs_fin':'', "onMorning":false, "onAfternoon":false}
          
        ],
 
    }
  },

mounted(){
 //test

      
    },


 methods: {

   

  
 
   
       selectAll() {
            //test
             this.userIds = [];
            // this.allSelected = true;

            if (!this.allSelected) {
                for (let i = 0; i<this.users.length; i++) {
                    this.userIds.push(this.users[i]["id"]);
                }
            }
        },
     select() {
            this.allSelected = false;
        },
    tester(index, item){
     // console.log(item.name)
        if(this.selection.length == 0)
        this.show = false;

        else
        {this.show = true; }

    },
    onMorningChange(){
      console.log(event.target.value)

    },

    edit(){
     // 
         for(let i in this.selection)
         
        
                { 
                  
                 
                
                    this.option.push({"name" : this.selection[i],  "offm": !this.matin, "md" : this.hm_debut, "mf" : this.hm_fin, "offs": !this.soir, "sd" : this.hs_debut, "sf" : this.hs_fin});
                  
                  console.log(this.option)
                  document.getElementById(this.selection[i]).style.display = "none";

                 // document.getElementsByName(this.selection[i]).style.background="black"

                             
                    


        
                }
                                  console.log(this.option.length)

        
   

        this.selection = [];
        this.show = false;
     
   

     
    },
     notification(){
        console.log("notify")
        
      notify({ type: "success", text: "The operation completed", title: "DONE" });
      },

       notificationError(){
   console.log("notify")
   
notify({ type: "error", text: "the operation could not be completed", title: "WARNING" });
 },
    horaire(){
      console.log(this.jours)
      this.error = '';
      this.isLoading = true;
      
      this.t.option = this.jours;
      this.t.id = this.id;
      //this.t.id = 55;
       this.$store.dispatch('postItem', {//data to be sended
      nameRoute : '/api/addhours',
      t : this.t
      })    
     .then(res => {
      this.isLoading = false;
      this.notification();
       this.$router.push("/relay");


      console.log("***********************",res.data);
     })
     .catch(error => {
       console.log(error);
       this.isLoading = false;
       
       if(!error.response) this.error = "Problèeme réseau, Impossible de joindre le serveur"
       else this.error = "Une erreur s'est produite"
       this.notificationError();
       //



          
     })
    }



  }
}
</script>
<style scoped lang="scss">
.home-section{
  position: relative;
  background: #E4E9F7;
  min-height: 100vh;
  top: 0;
  left: 78px;
  width: calc(100% - 78px);
  transition: all 0.5s ease;
  z-index: 2;
}
.sidebar.open ~ .home-section{
  left: 250px;
  width: calc(100% - 250px);
}




.home-section .text{
  display: inline-block;
  color: #11101d;
  font-size: 25px;
  font-weight: 500;
  margin: 0px;
}
/* serachbar */
 input[type="date"]:before {
    content: attr(placeholder) !important;
    color: gray;

    
  }
  input[type="date"]:focus:before,
  input[type="date"]:valid:before {
    content: "";
  }


.input-search{
  border: white;
  background-color: transparent;
  font-family: ROBOTO;
  color:black;
  font-weight: normal;
    box-shadow: 0px 15px 10px -15px #111;    
}

.input-search::placeholder{
  font-family: ROBOTO;
  color:black;
  font-weight: bolder;
  font-size: 14px;
}
input::placeholder{
  font-family: ROBOTO;
  font-style: normal;
  color:gray;
  font-size: 14px;
}
.input-search:-webkit-autofill:active{
  font-family: ROBOTO;
  color:darkslategray;
  font-weight: normal;
  font-size: 14px;
  background: darkslategray;
  
}

input[type=checkbox]:checked {
  /*CSS after CHECKED*/
  color: #ffd369;
}

.dropdown-toggle:hover, input[type=search].input-search:hover, .dropdown-toggle:active{
  background: #d7dbdc;
  font-weight: normal;
}
a{
  cursor : context-menu;
}
a:hover {
  color : rgb(255, 255, 255) !important;
}

.modal-mask {
     position: fixed;
     z-index: 9998;
     top: 0;
     left: 0;
     width: 100%;
     height: 100%;
     background-color: rgba(0, 0, 0, .5);
     display: table;
     transition: opacity .3s ease;
   }

   .modal-wrapper {
     display: table-cell;
     vertical-align: middle;
   }
   
.inputGroup {
    background-color: #fff;
    display: block;
    margin: 10px 0;
    position: relative;

    label {
      padding: 12px 30px;
      width: 100%;
      display: block;
      text-align: left;
      color: #3C454C;
      cursor: pointer;
      position: relative;
      z-index: 2;
      transition: color 200ms ease-in;
      overflow: hidden;

      &:before {
        width: 10px;
        height: 10px;
        border-radius: 50%;
        content: '';
        background-color: #007bff;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%) scale3d(1, 1, 1);
        transition: all 300ms cubic-bezier(0.4, 0.0, 0.2, 1);
        opacity: 0;
        z-index: -1;
      }

      &:after {
        width: 32px;
        height: 32px;
        content: '';
        border: 2px solid #D1D7DC;
        background-color: #fff;
        background-image: url("data:image/svg+xml,%3Csvg width='32' height='32' viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5.414 11L4 12.414l5.414 5.414L20.828 6.414 19.414 5l-10 10z' fill='%23fff' fill-rule='nonzero'/%3E%3C/svg%3E ");
        background-repeat: no-repeat;
        background-position: 2px 3px;
        border-radius: 50%;
        z-index: 2;
        position: absolute;
        right: 30px;
        top: 50%;
        transform: translateY(-50%);
        cursor: pointer;
        transition: all 200ms ease-in;
      }
    }

    input:checked ~ label {
      color: #fff;

      &:before {
        transform: translate(-50%, -50%) scale3d(56, 56, 1);
        opacity: 1;
      }

      &:after {
        background-color: #0275d8

;
        border-color: #0275d8

;
      }
    }

    input {
      width: 32px;
      height: 32px;
      order: 1;
      z-index: 2;
      position: absolute;
      right: 30px;
      top: 50%;
      transform: translateY(-50%);
      cursor: pointer;
      visibility: hidden;
    }
  }


// codepen formatting
.form {
  padding: 0 16px;
  max-width: 550px;
  margin: 50px auto;
  font-size: 18px;
  font-weight: 600;
  line-height: 36px;
}

body {
  background-color: #D1D7DC;
  font-family: 'Fira Sans', sans-serif;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

html {
  box-sizing: border-box;
}

code {
  background-color: #9AA3AC;
  padding: 0 8px;
}

.check {
  -webkit-appearance: none; /*hides the default checkbox*/
  height: 30px;
  width: 30px;
  text-align: center;
  font-weight: 600;
  color: black;
  border-radius: 50%;
  outline: none;
  border : 1px solid rgb(175, 170, 170);
}

.check:checked {
  background-color: #0E9700;

}


.check:checked:before {
  content: "✔";
}

.check:hover {
  cursor: pointer; 
  opacity: 0.8;
}



</style>
import { Script } from "vm";