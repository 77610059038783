<template>
      <section class="home-section" > 
         <div class="container-scroller" >
    <!-- partial:partials/_navbar.html -->
    <Header title="Ajout colis" />
    <!-- partial -->
    <div class="container-fluid page-body-wrapper bg-light"  >
      <!-- partial:partials/_sidebar.html -->
      <!-- partial -->
      <div class="main-panel" style="width : 100%;">
        <!---content wrapper start--->        
        <div class="content-wrapper">
                  <div class="row" >
            <div class="col-md-12 grid-margin">
              <div class="">
                <div class="d-flex align-items-start flex-row">
                    <div class="mr-auto p-2"> <div class="mt-3">
                        <p><i class="mdi mdi-checkbox-multiple-blank icon-md" style="color: #6c757d; font-size: 30px;"></i></p>
                        </div>
                    </div>
                </div>
                <div class="d-flex justify-content-center">
                    <div class="stretch-card m-0 p-0 border-0 col-lg-8">
                        <div class="card p-0 bg-transparent m-0 border-0">
                            <div class="card-body m-0 p-0 ">
                                <div class="container">
                                    <div class="row">
                                        <div class="col-lg">
                                             <div class="form-group">
                                                <label for="formGroupExampleInput">De</label>
                                                <input type="date" class="form-control bg-transparent" id="formGroupExampleInput" placeholder="Example input" v-model="items.from" @change="search()">
                                            </div>
                                        </div>
                                        <div class="col-lg">
                                             <div class="form-group">
                                                <label for="formGroupExampleInput">A</label>
                                                <input type="date" class="form-control bg-transparent" id="formGroupExampleInput" placeholder="Example input" v-model="items.to" @change="search()">
                                            </div>
                                        </div>
                                        <div class="col-lg">
                                            <div class="form-group">
                                                <label for="formGroupExampleInput">Séléctionner un fournisseur</label>
                                                <ejs-combobox required id='combobox' :dataSource='fournisseurs' v-model:value="items.provider_name" popupHeight="400px"   :fields='fields'  @change="search()"></ejs-combobox>
                                            </div>
                                        </div>
                                    </div>
                                </div>                               
                            </div>
                        </div>
                    </div>            
                </div>
              </div>
            </div>
          </div>
       <!----------->
       <div class="row">
           <div  v-if="errorDate" class="alert alert-danger text-center" role="alert">
                       {{errorDate}}
                </div>
       </div>
     
 
       <!--------------->
          <div class="row">
            <div class=" stretch-card col-lg-12 m-1 p-0">
              <div class="card p-0">
                <div class="card-body p-0">
                    <div class="d-flex justify-content-end py-2">
                        <button type="button" class="btn btn-outline-dark mr-2 btn-icon-text" v-on:click="listingPackages()"  >
                            <i class="mdi mdi-view-list btn-icon-prepend"></i>
                            Afficher les packages
                        </button>
                     </div>
    <p v-if="firstLoading" class="card-title text-center m-2 text-secondary">
        Chargement en cours ...
    </p>
    <p v-else class="card-title text-center m-2">
        <span v-show="!isLoading" class="text-danger">{{ count }}</span>
        <loading v-if="isLoading" :active="true" :width="20" :height="20" color="black"></loading> 
         resultat(s)
    </p>
  <div class="row m-3">
        <table class="table table-hover table-stripped">
  <tbody>
    <tr class="bg-white text-black" style="height : 50px;">
      <th scope="row" >Ref.</th>
      <td class="text-left font-weight-bold">Fournisseur</td>
      <td class="text-left font-weight-bold">Point relais</td>
      <td class="text-left font-weight-bold"> Ville </td>
      <td class="text-left font-weight-bold"> Date </td>
      <td class="text-left font-weight-bold"> Actions </td>
    </tr>
    <tr class="odd text-center" v-if="!count && !error" >
        <td valign = "top" colspan="6" class="dataTables_empty text-center text-primary bg-light font-weight-bold">
            No data available in table
        </td>
    </tr>
    <tr class="odd text-center" v-if="error" >
        <td valign = "top" colspan="6" class="dataTables_empty text-center text-danger bg-light font-weight-bold">
            {{error}}
        </td>
    </tr>
     <tr v-show="count && !error" class="text-center item-align-center" v-for="(item, index) in Packages" :key="item.id" v-bind:index="index" >
      <td class="text-left p-3" v-if="item.reference"> {{item.reference}}  </td>
      <td class="text-left p-3" v-else> *****  </td>
      <td class="text-left p-3">  {{item.fournisseur}}   </td>
      <td class="text-left p-3"> {{item.ville.nom}} </td>
      <td class="text-left p-3"> {{format_date(item.created_at)}} </td>
      <td class="text-left p-3"> {{item.ville.ville.city}} </td>
      <td class="text-left p-3">
              <a @click="openModel(item.shipments)" title="details" class="px-1" style="cursor: pointer;" ><i class="mdi mdi-package-variant icon-sm text-info" ></i>
               </a>
               
     </td>
    </tr>
  </tbody>
</table>
     </div>
     <div v-if="myModel">
    <transition name="model">
    <div class="modal-mask w-100">
                          <div class="modal-wrapper  w-100">
                            <div class="modal-dialog bg-transparent w-100 modal-dialog-centered modal-xl" style=" overflow-y: initial !important">
                              <div class="modal-content bg-light " style="">
                                <div class="modal-header bg-light">
                     <h4 class="modal-title text-center text-primary">Informations</h4>

          <button type="button"  @click="myModel=false"><span aria-hidden="true">&times;</span></button>
         </div>
         <div class="modal-body bg-light">
         
         <div class="table-responsive w-100 ">
                     <table class="table table-hover table-stripped">
  <tbody>
    <tr class="bg-white text-secondary">
      <th scope="row" >#</th>
      <td class="text-center font-weight-bold">Ref.</td>
      <td class="text-center font-weight-bold">Statut</td>
    </tr>
    <tr class="odd text-center" v-if="!countModal" >
        <td valign = "top" colspan="3" class="dataTables_empty text-center text-primary bg-light font-weight-bold">
            Aucun colis trouvé
        </td>
    </tr>
     <tr v-show="countModal" class="text-center align-middle" v-for="(item, index) in shipments" :key="item.id" v-bind:index="index" >
      <td class="text-left p-3  align-middle"> <img src="images/package.jpg" alt="package" width="30" height="30"> </td>
      <td class="text-left p-3  align-middle" v-if="item.reference"> {{item.ref}}  </td>
      <td class="text-left p-3  align-middle" v-else> *****  </td>
      <td class="text-left p-3  align-middle">  {{item.latest_log.statut}}   </td>
    </tr>
  </tbody>
</table>
                  
                  </div>
          <br />
          
         </div>
        </div>
       </div>
      </div>
     </div>
    </transition>
   </div>


                </div>
              </div>
            </div>
          </div>
        
        </div>

        <!-- content-wrapper ends -->
        <!-- partial:partials/_footer.html -->
      <Footer />
        <!-- partial -->
      </div>
      <!-- main-panel ends -->
    </div>
    <!-- page-body-wrapper ends -->
  </div>
        
      </section>

</template>

<script>
// import Menu from '@/components/Menu.vue'; 
import Header from '@/components/Header.vue'; 
import Footer from '@/components/Footer.vue'; 
import axios from 'axios';
import Swal from 'sweetalert2';
import moment, { now } from 'moment';
import Loading from 'vue3-loading-overlay';
import { notify } from "@kyvg/vue3-notification";

export default {

  components : {
    // Menu,
    Header,
    Footer,
    //detailsCh,
    Loading

},
  data()  {
    return {
        fournisseurs:null,
        items:{},
        shipments:{},
        fields:{ text: 'nom', value: 'id' },
        count : '',
        countModal : '',
        Packages:{},
        error:"",
        errorDate:"",
        isLoading: false,
        firstLoading:false,
        selected : null,
        selectedPaie:null,

        //
      load : false,
      liste : false,
  listearch :false,
   chauffeurs : null,
   users : [],
   clients : null,
   id : "test",
   detail : false,
   isDisabled : false,
   item : null,
   t : null,
   myModel:false,
  actionButton:'Insert',
  dynamicTitle:'Add Data',
   searchQuery:'',
   cl: {},
   activity:{},

   
    }
  },
    mounted(){
    this.afficherSellers();
        //
     this.testallseller(this.liste, this.listearch)

  },
 
    computed: {
       role(){
        
             return this.$store.getters.get_profile.type_user;
      },
       user(){
           // console.log(this.$store.getters.get_user)
        return this.$store.getters.get_user;
     },
    
    resultQuery(){
      if(this.searchQuery){
      return this.vendeurs.filter((item)=>{
       return this.searchQuery.toLowerCase().split(' ') 
       .every(v =>  item.nom.toLowerCase().includes(this.searchQuery)   ||  item.ville.city.toLowerCase().includes(this.searchQuery) || item.adresse.toLowerCase().includes(this.searchQuery) || item.mobile.toLowerCase().includes(this.searchQuery))
      
      })}
      else if(this.liste ) return this.vendeurs;
      else if(this.listearch) return this.vendeurs;
      else  return []
      
    },
    countSearch(){
      return this.resultQuery.length;
    },
    },

 methods: {
     afficherSellers(){
     this.firstLoading = true;
     this.$store.dispatch('getItem', {//data to be sended
      nameRoute : '/api/getvendeursactived',
      })
      .then( res => {
        this.firstLoading = false;
        this.fournisseurs = res.data;
       })
      .catch(err => {
        this.firstLoading = false;
        if (!err.response) this.error = "Problème réseau, Impossible de joindre le serveur" 
        else this.error = "Une erreur s'est produite ! "
      })      
      },

      payedInvoice(id){
          this.selectedPaie = id;
          this.$store.dispatch('getItem', {//data to be sended
      nameRoute : '/api/payementInvoice/'+id,
      })
     .then(res => {
         this.selectedPaie = null;
         this.notification()
     })
      .catch(err => {
                this.selectedPaie = null;
                this.notificationError();
            })

      },

      listingPackages(){
          this.items = {};
          this.items.all = 1;
          //this.items.month = moment().subtract(1,'months').startOf('month').format('YYYY-MM-DD');
          this.searchPackage();
      },
      search(){
          this.items.all = 0;
          if(this.items.from > this.items.to) this.errorDate = 'Erreur! La date de début est supérieure à la date de fin';
          //this.items.month = moment().subtract(1,'months').startOf('month').format('YYYY-MM-DD');
          else
          this.searchPackage();

      },

      searchPackage(){
          this.count= '';
          this.isLoading = true;
          console.log("yes");
          console.log("*************",this.items)
          this.$store.dispatch('postItem', {//data to be sended
          nameRoute : '/api/searchPackage',
            t : this.items
            })    
            .then(res => {
                this.isLoading = false;
                console.log(res.data);
                this.Packages = res.data;
                this.count = this.Packages.length;   
            })
            .catch(err => {
                this.isLoading = false;
                console.log(err);
                if (!err.response) this.error = "Problème réseau, Impossible de joindre le serveur" 
                else     this.error = "Une erreur s'est produite ! "
            })
      },
      notification(){
        console.log("notify")
        
         notify({ type: "success", text: "The operation completed", title: "DONE" });
      },

       notificationError(){
   console.log("notify")
   
notify({ type: "error", text: "the operation could not be completed", title: "WARNING" });
 },

      pdf(id, item){
   /*  this.items.seller_id = item.seller_id;
    this.items.month = item.month; */
    this.selected = id;
    console.log("pdf");
    this.$store.dispatch('getFile', {//data to be sended
      nameRoute : '/api/generate-fac/'+id,
      ref:item.num,
      type:"GET",
      name:"BC",
      ext:".pdf"
      })
      .then( res => {
        this.selected = '';
        console.log("yes");
        this.notification()
       
       })
      .catch(err => {
        this.selected = "";
        console.log(err);
        this.isLoading = false;
        this.notificationError()
      })  
  },
      //
   test(){
     console.log(this.resultQuery);

   },
    testallseller(liste, listarch){
     this.isLoading = true;
     this.error = '';
     this.$store.dispatch('getItem', {//data to be sended
      nameRoute : '/api/getclientsactived',
      })
      .then( res => {
        console.log("data",res.data)
        this.vendeurs = res.data;
        this.liste = liste;
        this.listearch = listarch;
        if(this.liste || this.listearch ) this.searchQuery = "";
        this.isLoading = false;
        //this.count = res.data.length;
      })   
      .catch(err => {
        this.isLoading = false;
        if (!err.response) this.error = "Problème réseau, Impossible de joindre le serveur" 
        else this.error = "Une erreur s'est produite ! "
      })

   },
   format_date(value){
         if (value) {
           return moment(String(value)).format('DD-MM-YYYY')
          }
      },
   openModel(item){
  // this.first_name = '';
  // application.last_name = '';
  if(item) {this.countModal = this.shipments.length;this.shipments = item;}
   
   this.actionButton = "Insert";
   this.dynamicTitle = "Add Data";
   this.myModel = true;
  },
    ajouterClient(){
          console.log("clicked!");
         this.$router.push("/addcustomer");
         },


    afficherClients(){
      this.isLoading = true;
     this.$store.dispatch('getItem', {//data to be sended
      nameRoute : '/api/getclientsactived',
      })
      .then( res => {
        this.isLoading = false;
        console.log(res.data);
        this.searchQuery = '';
        this.cl = res.data;
       // this.count = res.data.length;
       })
      .catch(err => {
       this.isLoading = false;

        this.error = "Une erreur s'est produite ! "
      })      
      },

    

     afficherClientsArchives(){
      // console.log(this.count)
      this.isLoading = true;
     this.$store.dispatch('getItem', {//data to be sended
      nameRoute : '/api/getclientsarchived',
      })
      .then( res => {
        this.error = '';
         this.vendeurs = res.data;
        this.searchQuery = '';
       this.liste = false;
       this.listearch = true;
        this.isLoading = false;
       })
      .catch(err => {
        this.isLoading = false;
        if (!err.response) this.error = "Problème réseau, Impossible de joindre le serveur" 
        else this.error = "Une erreur s'est produite ! "
      })      
      },

      archiver(id, index){
        this.load = true;
        this.selected = id;
      this.id = id;
      this.$store.dispatch('idItem', {//data to be sended
      nameRoute : '/api/archiverclient/'+this.id,
      })
     .then(res => {
      // this.$router.go()	// Refreshes page
      /* this.cl.splice(index, 1);
      this.count--; */
      this.isLoading = false;
      this.resultQuery.splice(index, 1);
      this.countSearch--; 
      this.selected = null;
      this.addActivity(id, "Archive client", '/api/archiverclient/'+this.id);

      console.log(res.data);
     })
    },

    desarchiver(id, index){
      this.load = true;
      this.selected = id;
      this.id = id;
      this.$store.dispatch('idItem', {//data to be sended
      nameRoute : '/api/desarchiverclient/'+this.id,
      })
     .then(res => {
       
      this.isLoading = false;
       
      // this.$router.go()	// Refreshes page
       //this.isDisabled=false;
       this.resultQuery.splice(index, 1);
      this.countSearch--; 
      this.addActivity(id, "Archive client", '/api/desarchiverclient/'+this.id);
      this.selected = null;
      console.log(res.data);
     })
    },

    details( json_data){  
 
      this.$router.push({ name: 'detailsCh', params: {data:JSON.stringify(json_data)}})
    },
    edit( json_data){  

      this.$router.push({ name: 'editC', params: {data:JSON.stringify(json_data)}})
      console.log(json_data)

    },
    addActivity(id, action, link){
      this.activity.user_id= this.user.id;
      this.activity.action= action;
      this.activity.model = "Client"
      this.activity.url=this.$store.getters.get_link+link;
      this.activity.user_agent=navigator.appName+" "+navigator.appVersion ;
      this.activity.item_id = id;
      console.log("activity..........", this.activity)
        this.$store.dispatch('postItem', {//data to be sended
          nameRoute : '/api/addactivity',
          t : this.activity
          })    
        .then(res => {
          console.log(res.data);
        })
        .catch(error => {
            console.log(error)
           
        }) 

      },

    showMessage(id, index){
      Swal.fire({
      title: 'Attention!',
      text: "Etes vous sur de vouloir archiver ce client?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: 'green',
      cancelButtonColor: '#d33',
      cancelButtonText: "Annuler",
      confirmButtonText: 'Ok'
      })
      .then((result) => {
       if (result.isConfirmed) {
         this.archiver(id, index);
     
  }
})
    },
    parentFire(id){
      console.log("parentFire works!!", id)
    }

  }
}
</script>
<style scoped>
tr {
   line-height: 25px;
   min-height: 25px;
   height: 25px;
}

input[type=search]:focus{
  outline: inherit;
  box-shadow: inherit;
  background: rgb(216, 215, 208) !important;

}
.home-section{
  position: relative;
  background: #E4E9F7;
  min-height: 100vh;
  top: 0;
  left: 78px;
  width: calc(100% - 78px);
  transition: all 0.5s ease;
  z-index: 2;
}
.sidebar.open ~ .home-section{
  left: 250px;
  width: calc(100% - 250px);
}
.home-section .text{
  display: inline-block;
  color: #11101d;
  font-size: 25px;
  font-weight: 500;
  margin: 0px;
}
/* serachbar */

.search-box{
  width: fit-content;
  height: fit-content;
  position: relative;
}
.input-search{
  height: 50px;
  width: 50px;
  border-style: none;
  padding: 10px;
  font-size: 18px;
  outline: none;
  border-radius: 25px;
  transition: all .5s ease-in-out;
  background-color: transparent;
  padding-right: 40px;
  color:black;
}
.input-search::placeholder{
  color: #6C757D;
  font-size: 18px;
  font-weight: 100;
  font-style: italic;
}
.btn-search{
  width: 50px;
  height: 50px;
  border-style: none;
  font-size: 25px;
  font-weight: bold;
  outline: none;
  cursor: pointer;
  border-radius: 50%;
  position: absolute;
  right: 0px;
  color:white ;
  pointer-events: painted;  
}
.btn-search ~ .input-search{
  width: 80%;
  border-radius: 0px;
  background-color: transparent;
  border-bottom:1px solid #6C757D;
  transition: all 500ms cubic-bezier(0, 0.110, 0.35, 2);
}
.input-search:focus{
  width: 80%;
  border-radius: 0px;
  background-color: transparent;
   box-shadow: 0  0.2rem #6C757D;
  transition: all 500ms cubic-bezier(0, 0.110, 0.35, 2);
}
.modal-mask {
     position: fixed;
     z-index: 9998;
     top: 0;
     left: 0;
     width: 100%;
     height: 100%;
     background-color: rgba(0, 0, 0, .5);
     display: table;
     transition: opacity .3s ease;
   }

   .modal-wrapper {
     display: table-cell;
     vertical-align: middle;
   }


</style>
import { Script } from "vm";