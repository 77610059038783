<template>
     <div class="content-wrapper" >
                  <div class="row">
            <div class="col-md-12 grid-margin">
              <div class="d-flex justify-content-between flex-wrap">
                <div class="d-flex align-items-end flex-wrap">
                  <div class="mr-md-3 mr-xl-5">
                    <h2>Ajouter des horaires pour ce point relais </h2>
                  </div>
                  <div class="d-flex">
                    <i class="mdi mdi-home text-muted hover-cursor"></i>
                    <p class="text-muted mb-0 hover-cursor">&nbsp;/&nbsp;Dashboard&nbsp;/&nbsp;</p>
                    <p class="text-primary mb-0 hover-cursor">Analytics</p>
                  </div>
                </div>
                <div class="d-flex justify-content-between align-items-end flex-wrap">
                  <button type="button" class="btn btn-light bg-white btn-icon mr-3 d-none d-md-block ">
                    <i class="mdi mdi-download text-muted"></i>
                  </button>
                  <button type="button" class="btn btn-light bg-white btn-icon mr-3 mt-2 mt-xl-0">
                    <i class="mdi mdi-clock-outline text-muted"></i>
                  </button>
                  <button type="button" class="btn btn-light bg-white btn-icon mr-3 mt-2 mt-xl-0">
                    <i class="mdi mdi-plus text-muted"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            

 
                  <form class="forms-sample" @submit.prevent="handlesubmit" style="width : 100%;">
              <div class="col-12 grid-margin stretch-card">
              <div class="card">
                <div class="card-body">
                  <h4 class="card-title"> Horaires  {{id}}</h4>


                  <!------------------>
                    <div class="card  text-white" style="margin-bottom:2%; background:darkred;" >
    <div class="card-body text-center font-weight-bold  p-1 ">Lundi </div>
  </div>

                  <div class="form-group row" style=" height:auto;">
                    <div class="col-lg-1"></div>
                      <label for="exampleInputUsername2" class="col-lg-2  col-form-label h4 " >Matin : </label>
                      <label for="exampleInputUsername2" class="col-lg-1  col-form-label " >De : </label>
                     
                      <div class=" col-lg-2  " >
                            <div class="form-group">
              <input type="time" class="form-control" id="inputtime" placeholder="nom de la societé" v-model="heuresmd[0]" required>
              
                      </div>

                      </div>
                      <div class="col-lg-1">

                      </div>
                      <label for="exampleInputUsername2" class="col-lg-1  col-form-label " >A : </label>
                     
                      <div class=" col-lg-2  " >
                            <div class="form-group">
              <input type="time" class="form-control" id="inputtime" placeholder="nom de la societé" v-model="heuresmf[0]" required>
              
                      </div>

                      </div>
                    </div>
                                      <div class="form-group row" style=" height:auto;">
                    <div class="col-lg-1"></div>
                      <label for="exampleInputUsername2" class="col-lg-2  col-form-label h4 " >Soir : </label>
                      <label for="exampleInputUsername2" class="col-lg-1  col-form-label " >De : </label>
                     
                      <div class=" col-lg-2  " >
                            <div class="form-group">
              <input type="time" class="form-control" id="inputtime" placeholder="nom de la societé" v-model="heuresd[0]" required>
              
                      </div>

                      </div>
                      <div class="col-lg-1">

                      </div>
                      <label for="exampleInputUsername2" class="col-lg-1  col-form-label " >A : </label>
                     
                      <div class=" col-lg-2  " >
                            <div class="form-group">
              <input type="time" class="form-control" id="inputtime" placeholder="nom de la societé" v-model="heuresf[0]" required>
              
                      </div>

                      </div>
                    </div>
                    <!------------------------------->
                                 <!------------------>
                    <div class="card  text-white" style="margin-bottom:2%; background:darkred;" >
    <div class="card-body text-center font-weight-bold  p-1 ">Mardi </div>
  </div>

                  <div class="form-group row" style=" height:auto;">
                    <div class="col-lg-1"></div>
                      <label for="exampleInputUsername2" class="col-lg-2  col-form-label h4 " >Matin : </label>
                      <label for="exampleInputUsername2" class="col-lg-1  col-form-label " >De : </label>
                     
                      <div class=" col-lg-2  " >
                            <div class="form-group">
              <input type="time" class="form-control" id="inputtime" placeholder="nom de la societé" v-model="heuresmd[1]" required>
              
                      </div>

                      </div>
                      <div class="col-lg-1">

                      </div>
                      <label for="exampleInputUsername2" class="col-lg-1  col-form-label " >A : </label>
                     
                      <div class=" col-lg-2  " >
                            <div class="form-group">
              <input type="time" class="form-control" id="inputtime" placeholder="nom de la societé" v-model="heuresmf[1]" required>
              
                      </div>

                      </div>
                    </div>
                                      <div class="form-group row" style=" height:auto;">
                    <div class="col-lg-1"></div>
                      <label for="exampleInputUsername2" class="col-lg-2  col-form-label h4 " >Soir : </label>
                      <label for="exampleInputUsername2" class="col-lg-1  col-form-label " >De : </label>
                     
                      <div class=" col-lg-2  " >
                            <div class="form-group">
              <input type="time" class="form-control" id="inputtime" placeholder="nom de la societé" v-model="heuresd[1]" required>
              
                      </div>

                      </div>
                      <div class="col-lg-1">

                      </div>
                      <label for="exampleInputUsername2" class="col-lg-1  col-form-label " >A : </label>
                     
                      <div class=" col-lg-2  " >
                            <div class="form-group">
              <input type="time" class="form-control" id="inputtime" placeholder="nom de la societé" v-model="heuresf[1]" required>
              
                      </div>

                      </div>
                    </div>
                    <!------------------------------->
             <!------------------>
                    <div class="card  text-white" style="margin-bottom:2%; background:darkred;" >
    <div class="card-body text-center font-weight-bold  p-1 ">Mercredi </div>
  </div>

                  <div class="form-group row" style=" height:auto;">
                    <div class="col-lg-1"></div>
                      <label for="exampleInputUsername2" class="col-lg-2  col-form-label h4 " >Matin : </label>
                      <label for="exampleInputUsername2" class="col-lg-1  col-form-label " >De : </label>
                     
                      <div class=" col-lg-2  " >
                            <div class="form-group">
              <input type="time" class="form-control" id="inputtime" placeholder="nom de la societé" v-model="heuresmd[2]" required>
              
                      </div>

                      </div>
                      <div class="col-lg-1">

                      </div>
                      <label for="exampleInputUsername2" class="col-lg-1  col-form-label " >A : </label>
                     
                      <div class=" col-lg-2  " >
                            <div class="form-group">
              <input type="time" class="form-control" id="inputtime" placeholder="nom de la societé" v-model="heuresmf[2]" required>
              
                      </div>

                      </div>
                    </div>
                                      <div class="form-group row" style=" height:auto;">
                    <div class="col-lg-1"></div>
                      <label for="exampleInputUsername2" class="col-lg-2  col-form-label h4 " >Soir : </label>
                      <label for="exampleInputUsername2" class="col-lg-1  col-form-label " >De : </label>
                     
                      <div class=" col-lg-2  " >
                            <div class="form-group">
              <input type="time" class="form-control" id="inputtime" placeholder="nom de la societé" v-model="heuresd[2]" required>
              
                      </div>

                      </div>
                      <div class="col-lg-1">

                      </div>
                      <label for="exampleInputUsername2" class="col-lg-1  col-form-label " >A : </label>
                     
                      <div class=" col-lg-2  " >
                            <div class="form-group">
              <input type="time" class="form-control" id="inputtime" placeholder="nom de la societé" v-model="heuresf[2]" required>
              
                      </div>

                      </div>
                    </div>
                    <!------------------------------->
             <!------------------>
                    <div class="card  text-white" style="margin-bottom:2%; background:darkred;" >
    <div class="card-body text-center font-weight-bold  p-1 ">Jeudi </div>
  </div>

                  <div class="form-group row" style=" height:auto;">
                    <div class="col-lg-1"></div>
                      <label for="exampleInputUsername2" class="col-lg-2  col-form-label h4 " >Matin : </label>
                      <label for="exampleInputUsername2" class="col-lg-1  col-form-label " >De : </label>
                     
                      <div class=" col-lg-2  " >
                            <div class="form-group">
              <input type="time" class="form-control" id="inputtime" placeholder="nom de la societé" v-model="heuresmd[3]" required>
              
                      </div>

                      </div>
                      <div class="col-lg-1">

                      </div>
                      <label for="exampleInputUsername2" class="col-lg-1  col-form-label " >A : </label>
                     
                      <div class=" col-lg-2  " >
                            <div class="form-group">
              <input type="time" class="form-control" id="inputtime" placeholder="nom de la societé" v-model="heuresmf[3]" required>
              
                      </div>

                      </div>
                    </div>
                                      <div class="form-group row" style=" height:auto;">
                    <div class="col-lg-1"></div>
                      <label for="exampleInputUsername2" class="col-lg-2  col-form-label h4 " >Soir : </label>
                      <label for="exampleInputUsername2" class="col-lg-1  col-form-label " >De : </label>
                     
                      <div class=" col-lg-2  " >
                            <div class="form-group">
              <input type="time" class="form-control" id="inputtime" placeholder="nom de la societé" v-model="heuresd[3]" required>
              
                      </div>

                      </div>
                      <div class="col-lg-1">

                      </div>
                      <label for="exampleInputUsername2" class="col-lg-1  col-form-label " >A : </label>
                     
                      <div class=" col-lg-2  " >
                            <div class="form-group">
              <input type="time" class="form-control" id="inputtime" placeholder="nom de la societé" v-model="heuresf[3]" required>
              
                      </div>

                      </div>
                    </div>
                    <!------------------------------->
                                          
             <!------------------>
                    <div class="card  text-white" style="margin-bottom:2%; background:darkred;" >
    <div class="card-body text-center font-weight-bold  p-1 ">Vendredi </div>
  </div>

                  <div class="form-group row" style=" height:auto;">
                    <div class="col-lg-1"></div>
                      <label for="exampleInputUsername2" class="col-lg-2  col-form-label h4 " >Matin : </label>
                      <label for="exampleInputUsername2" class="col-lg-1  col-form-label " >De : </label>
                     
                      <div class=" col-lg-2  " >
                            <div class="form-group">
              <input type="time" class="form-control" id="inputtime" placeholder="nom de la societé" v-model="heuresmd[4]" required>
              
                      </div>

                      </div>
                      <div class="col-lg-1">

                      </div>
                      <label for="exampleInputUsername2" class="col-lg-1  col-form-label " >A : </label>
                     
                      <div class=" col-lg-2  " >
                            <div class="form-group">
              <input type="time" class="form-control" id="inputtime" placeholder="nom de la societé" v-model="heuresmf[4]" required>
              
                      </div>

                      </div>
                    </div>
                                      <div class="form-group row" style=" height:auto;">
                    <div class="col-lg-1"></div>
                      <label for="exampleInputUsername2" class="col-lg-2  col-form-label h4 " >Soir : </label>
                      <label for="exampleInputUsername2" class="col-lg-1  col-form-label " >De : </label>
                     
                      <div class=" col-lg-2  " >
                            <div class="form-group">
              <input type="time" class="form-control" id="inputtime" placeholder="nom de la societé" v-model="heuresd[4]" required>
              
                      </div>

                      </div>
                      <div class="col-lg-1">

                      </div>
                      <label for="exampleInputUsername2" class="col-lg-1  col-form-label " >A : </label>
                     
                      <div class=" col-lg-2  " >
                            <div class="form-group">
              <input type="time" class="form-control" id="inputtime" placeholder="nom de la societé" v-model="heuresf[4]" required>
              
                      </div>

                      </div>
                    </div>
                    <!------------------------------->
               <!------------------>
                    <div class="card  text-white" style="margin-bottom:2%; background:darkred;" >
    <div class="card-body text-center font-weight-bold  p-1 ">Samedi </div>
  </div>

                  <div class="form-group row" style=" height:auto;" v-if="!off[1]">
                    <div class="col-lg-1"></div>
                      <label for="exampleInputUsername2" class="col-lg-2  col-form-label h4 " >Matin : </label>
                      <label for="exampleInputUsername2" class="col-lg-1  col-form-label " >De : </label>
                     
                      <div class=" col-lg-2  " >
                            <div class="form-group">
              <input type="time" class="form-control" id="inputtime" placeholder="nom de la societé" v-model="heuresmd[5]" required>
              
                      </div>

                      </div>
                      <div class="col-lg-1">

                      </div>
                      <label for="exampleInputUsername2" class="col-lg-1  col-form-label " >A : </label>
                     
                      <div class=" col-lg-2  " >
                            <div class="form-group">
              <input type="time" class="form-control" id="inputtime" placeholder="nom de la societé" v-model="heuresmf[5]" required>
              
                      </div>

                      </div>
                    </div>
                                      <div class="form-group row" style=" height:auto;" v-if="!off[1]">
                    <div class="col-lg-1"></div>
                      <label for="exampleInputUsername2" class="col-lg-2  col-form-label h4 " >Soir : </label>
                      <label for="exampleInputUsername2" class="col-lg-1  col-form-label " >De : </label>
                     
                      <div class=" col-lg-2  " >
                            <div class="form-group">
              <input type="time" class="form-control" id="inputtime" placeholder="nom de la societé" v-model="heuresd[5]" required>
              
                      </div>

                      </div>
                      <div class="col-lg-1">

                      </div>
                      <label for="exampleInputUsername2" class="col-lg-1  col-form-label " >A : </label>
                     
                      <div class=" col-lg-2  " >
                            <div class="form-group">
              <input type="time" class="form-control" id="inputtime" placeholder="nom de la societé" v-model="heuresf[5]" required>
              
                      </div>

                      </div>
                    </div>
                                          <div class="row" style="margin-left : 4%;">

                      <div class="col-lg-6">
                                         <div class="form-check">
  <input class="form-check-input" type="checkbox" value="1" id="flexCheckDefault" v-model="off[1]">
  <label class="form-check-label" for="flexCheckDefault">
    Off
  </label>
</div>
                         
                   
                  
                     
                      </div>
                   
                    </div>
                    <!------------------------------->
               <!------------------>
                    <div class="card  text-white" style="margin-bottom:2%; background:darkred;" >
    <div class="card-body text-center font-weight-bold  p-1 ">Dimanche </div>
  </div>

                  <div class="form-group row" style=" height:auto;" v-if="!off[0]">
                    <div class="col-lg-1"></div>
                      <label for="exampleInputUsername2" class="col-lg-2  col-form-label h4 " >Matin : </label>
                      <label for="exampleInputUsername2" class="col-lg-1  col-form-label " >De : </label>
                     
                      <div class=" col-lg-2  " >
                            <div class="form-group">
              <input type="time" class="form-control" id="inputtime" placeholder="nom de la societé" v-model="heuresmd[6]" required>
              
                      </div>

                      </div>
                      <div class="col-lg-1">

                      </div>
                      <label for="exampleInputUsername2" class="col-lg-1  col-form-label " >A : </label>
                     
                      <div class=" col-lg-2  " >
                            <div class="form-group">
              <input type="time" class="form-control" id="inputtime" placeholder="nom de la societé" v-model="heuresmf[6]" required>
              
                      </div>

                      </div>
                    </div>
                                      <div class="form-group row" style=" height:auto;" v-if="!off[0]">
                    <div class="col-lg-1"></div>
                      <label for="exampleInputUsername2" class="col-lg-2  col-form-label h4 " >Soir : </label>
                      <label for="exampleInputUsername2" class="col-lg-1  col-form-label " >De : </label>
                     
                      <div class=" col-lg-2  " >
                            <div class="form-group">
              <input type="time" class="form-control" id="inputtime" placeholder="nom de la societé" v-model="heuresd[6]" required>
              
                      </div>

                      </div>
                      <div class="col-lg-1">

                      </div>
                      <label for="exampleInputUsername2" class="col-lg-1  col-form-label " >A : </label>
                     
                      <div class=" col-lg-2  " >
                            <div class="form-group">
              <input type="time" class="form-control" id="inputtime" placeholder="nom de la societé" v-model="heuresf[6]" required>
              
                      </div>

                      </div>
                    </div>
                                  <div class="row" style="margin-left : 4%;">

                      <div class="col-lg-6">
                                         <div class="form-check">
  <input class="form-check-input" type="checkbox" value="1" id="flexCheckDefault" v-model="off[0]">
  <label class="form-check-label" for="flexCheckDefault">
    Off
  </label>
</div>
                         
                   
                  
                     
                      </div>
                   
                    </div>
                    <!------------------------------->
                                             <div class="row">
                     <div class="col-lg-3">

                     </div>
                     <div class="col-lg-3" >
                                           <button type="submit" class="btn btn-success mr-2 	" style="width : 100%;">Valider</button>


                     </div>
                     <div class="col-lg-3">
                                           <button class="btn btn-secondary" style="width : 100%;">Annuler</button>

                     </div>
                   </div>
                            <div class="row " style="margin-top : 1%;">
                       <div class="col-12">
                               <div  v-if="error" class="alert alert-danger text-center" role="alert">
                       {{error}}
                </div>
                       </div>
                     </div>



                </div>
              </div>
            </div>

      

   
         <!----- ---------------------->
                    <div class="col-12 grid-margin stretch-card">
              <div class="card">
                               <div class="card-body">
                  <h4 class="card-title">Activation du compte</h4>
                  

      
     

   

                  <!----->
                
                  <!------------------------->
      
                  <!------------------------->
              
                </div>
                
              </div>
            </div>
          </form>
 
         <!----------------------------------------->
 


 

    
          </div>
        </div>

</template>

<script>
import { defineComponent } from 'vue';
import axios from 'axios';
//import $ from 'jquery';






export default defineComponent({
  name: 'HelloWorld',
  props: {
    id: Number,
  },
  data()  {
    return{
      jour:["lundi", "mardi", "mercredi", "jeudi", "vendredi", "samedi", "dimanche"],
      heuresmd:[],
      heuresmf:[],
      heuresd:[],
      heuresf:[],
      j: '',
      hmd:'',
      hmf:'',
      hsd:'',
      hsf:'',
      error:'',
      off : [],

    }

  },

 

  methods : {
    handlesubmit(){
      for(let i=0; i<7; i++){
        console.log(i);
         this.j = this.jour[i],
         this.hmd = this.heuresmd[i],
         this.hmf = this.heuresmf[i],
         this.hsd = this.heuresd[i],
         this.hsf = this.heuresf[i],
        
       axios.create({
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer '+localStorage.getItem("token")
        }
    })
       .post('http://127.0.0.1:8000/api/addhours',{
         jour : this.j,
         h_ouverture_m : this.hmd,
         h_fermeture_m : this.hmf,
         h_ouverture_s : this.hsd,
         h_fermeture_s : this.hsf,
         relay_id : this.id,
    
      
       })
      .then( res => {
        console.log(res.data);
        
        

       this.$router.push("/relay");
      })
      /*
      this.$store.dispatch('retrieveToken', {
        email: this.email,
        password: this.password,
      })*/
      .catch(err => {
        console.log("error");
          
          this.error = "Une erreur s'est produite lors de la création de ce point relais. Veuillez contacter l'administrateur! "
        
     
     
        
      })

        
      }

    },
     tester(){
    console.log(this.id);
  }

  },
 
  
});
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
