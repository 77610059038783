<template>

      <section class="home-section" >
         
         <div class="container-scroller" >
    <!-- partial:partials/_navbar.html -->
    <Header title="Ajout colis" />
    <!-- partial -->
    <div class="container-fluid page-body-wrapper bg-light"  >
      <!-- partial:partials/_sidebar.html -->
   
    
      <!-- partial -->
      <div class="main-panel" style="width : 100%;">
        <!---content wrapper start--->
              
    <div class="content-wrapper">
                  
                  <div class="row">
            <div class="col-md-12 grid-margin">
              <div class="d-flex justify-content-between flex-wrap">
                <div class="d-flex align-items-end flex-wrap">
                
               
                <div class="mt-3" >
                      <p class=" d-flex justify-content-center align-self-center " ><i class="mdi mdi-store icon-md mr-1"></i> 
                         <span class=" py-2 " >Modifier centre de tri</span>
                       </p>
                    </div>


                </div>
                <!-- <div class="d-flex justify-content-between align-items-end flex-wrap">
                  <button type="button" class="btn btn-light bg-white btn-icon mr-3 d-none d-md-block ">
                    <i class="mdi mdi-download text-muted"></i>
                  </button>
                  <button type="button" class="btn btn-light bg-white btn-icon mr-3 mt-2 mt-xl-0">
                    <i class="mdi mdi-clock-outline text-muted"></i>
                  </button>
                  <button type="button" class="btn btn-light bg-white btn-icon mr-3 mt-2 mt-xl-0">
                    <i class="mdi mdi-plus text-muted"></i>
                  </button>
                </div> -->
              </div>
            </div>
          </div>
          <div class="row">
            

 
                  <form class="forms-sample" @submit.prevent="handlesubmit" style="width : 100%;">
              <div class="col-12 grid-margin stretch-card">
              <div class="card">
                <div class="card-body">
                  <h4 class="card-title"> Informations :  </h4>
                                                           <loading  class="d-inline" v-if="isLoading" :active="true" :width="20" :height="20" color="blue"></loading>



                  <!------------------>

                       <div class="form-group row" style="margin-left : 4%; height:auto;">
                      <label for="exampleInputUsername2" class="col-lg-2  col-form-label " >Nom : </label>
                      <div class=" col-lg-6  " >
                            <div class="form-group">
              <input type="text" class="form-control" id="exampleInputEmail2" placeholder="nom de la societé" v-model="t.nom" >
              
                      </div>

                      </div>
                    </div>
               
                  <!--------->
                                                
                                                 <div class="row">
                    <div class="col-12">
                       <div class="form-group row" style="margin-left : 4%;">
                      <label for="exampleInputUsername2" class="col-lg-2 col-form-label" >Adresse : </label>
                      <div class="col-lg-8">
                            <div class="form-group">
                  <textarea class="form-control" id="exampleFormControlTextarea1" rows="2" placeholder="adresse" v-model="t.adresse" ></textarea>

              

                      </div>

                      </div>
                    </div>
  
                      </div>
                     
                  </div>
                  <!----------------->
                      <div class="row">
                    <div class="col-12">
                       <div class="form-group row" style="margin-left : 4%;">
                      <label for="exampleInputUsername2" class="col-lg-2 col-form-label">Ville : </label>
                      <div class="col-lg-8">
                            <div class="form-group">
                           <select class="form-control form-select form-select-md mb-3" aria-label="Default select example" v-model="t.ville_id"  >
                     <option v-for="item in villes" :value="item.id" :key="item.id" >
      {{ item.city }}
    </option>
                      </select>
                      </div>

                      </div>
                    </div>
  
                      </div>
                     
                  </div>
                  <!------------>
                                                       <div class="row">
                    <div class="col-12">
              <!--          <div class="form-group row" style="margin-left : 4%;">
                      <label for="exampleInputUsername2" class="col-lg-2 col-form-label" >Pays : </label>
                      <div class="col-lg-6">
                            <div class="form-group">
              <input type="text" class="form-control" id="exampleInputEmail2" placeholder="pays" v-model="t.pays" >
              

                      </div>

                      </div>
                    </div> -->
  
                      </div>
                     
                  </div>
                  <!---------->
                 <div class="row">
                    <div class="col-12">
                       <div class="form-group row" style="margin-left : 4%;">
                      <label for="exampleInputUsername2" class="col-lg-2 col-form-label" >Telephone : </label>
                      <div class="col-lg-6">
                            <div class="form-group">
 <input type="text" v-on:keypress="isNumber(event)"  class="form-control" id="exampleInputEmail2" autocomplete="off" placeholder="ex : 06xxxxxxxx" :minlength="10" :maxlength = "10" v-model="t.telephone" >



                      </div>

                      </div>
                    </div>
  
                      </div>
                     
                  </div>
                  <!---------->
                      <div class="row">
                    <div class="col-12">
                       <div class="form-group row" style="margin-left : 4%;">
                      <label for="exampleInputUsername2" class="col-lg-2 col-form-label" >Responsable : </label>
                      <div class="col-lg-6">
                            <div class="form-group">
 <input type="text"  class="form-control" id="exampleInputEmail2" autocomplete="off" placeholder="nom responsable" v-model="t.nom_responsable" >



                      </div>

                      </div>
                    </div>
  
                      </div>
                     
                  </div>
          
                  <!------>


                </div>
              </div>
            </div>
            <!-------------------------------->
            <div class="row " style="margin-top : 1%;">
                       <div class="col-12"> 
                               <div  v-if="error" class="alert alert-danger text-center" role="alert">
                       {{error}}
                </div>
                       </div>
                     </div>
             
       
              <div class="row">
                     <div class="col-lg-3">

                     </div>
                      <div class="col-lg-3" >
                                           <button :disabled="isLoading" @click="showMessage()" class="btn btn-success mr-2" style="width : 100%;">
                                             <span v-if="!isLoading" >Valider</span>
                          <loading v-if="isLoading" :active="true" :width="10" :height="10" color="white"></loading>
                        </button>
                


                     </div>
                     <div class="col-lg-3">
                                           <button type="button" class="btn btn-secondary" style="width : 100%;" @click="back">Retour</button>

                     </div>
                   </div>

   
                 
          </form>
 
         <!----------------------------------------->

 

    
          </div>
        </div>  

        <!-- content-wrapper ends -->
        <!-- partial:partials/_footer.html -->
      <Footer />
        <!-- partial -->
      </div>
      <!-- main-panel ends -->
    </div>
    <!-- page-body-wrapper ends -->
  </div>
        
      </section>


</template>

<script>
import axios from "axios"
import Swal from 'sweetalert2';
// import Menu from '@/components/Menu.vue'; 
import Header from '@/components/Header.vue'; 
import Footer from '@/components/Footer.vue';  
import Loading from 'vue3-loading-overlay';
import { notify } from "@kyvg/vue3-notification";



export default {
   props: {
    idt: Number,
  },

  components : {
      // Menu,
      Header,
      Footer,
      Loading
  },
    data(){
        return{
            test : "oui",
           // idt : 8,
            t : {},
            user : {},
            error : "",
            isLoading: false,
            villes : [],
            activity:{},

        }
    },
    created(){
      this.isLoading = true;
     this.$store.dispatch('getItem', {//data to be sended
      nameRoute : '/api/getallcities',
      })    
     .then(res => {
       this.isLoading = false;
      console.log(res.data);
      this.villes = res.data;
     })
     .catch(err => {
       this.isLoading = false;
        if (!err.response) this.error = "Problème réseau, Impossible de joindre le serveur" 
       else this.error = "Une erreur s'est produite ! "
      })   
  },
     mounted(){
       this.t =JSON.parse(this.$route.params.data)
       console.log(this.user)
             },
    computed :{
          user_id(){
           // console.log(this.$store.getters.get_user)
        return this.$store.getters.get_user;
                    },
               },

  methods: {
    back(){
    // $('#myform').trigger("reset");
    //document.getElementsByClassName("forms-sample").reset();
    this.$router.go(-1);
  },
         addActivity(id){
   this.activity.user_id= this.user_id.id;
   this.activity.action= "Edit warehouse";
   this.activity.model = "Warehouse"
   this.activity.url=this.$store.getters.get_link+'/api/editW/'+id;
   this.activity.user_agent=navigator.appName+" "+navigator.appVersion ;
   this.activity.item_id = id;
   console.log("activity..........", this.activity)
     this.$store.dispatch('postItem', {//data to be sended
      nameRoute : '/api/addactivity',
      t : this.activity
      })    
     .then(res => {
      console.log(res.data);
     })
     .catch(error => {
        console.log(error)     
     }) 

 },
      edit(){
        this.error = '';
        this.isLoading = true;
      this.$store.dispatch('postItem', {//data to be sended
      nameRoute : '/api/editW/'+this.t.id,
      t : this.t
      })    
     .then(res => {
       this.isLoading = false;
      console.log(res.data);
      this.notification();
      this.$router.push("/warehouse");
     })
     .catch(err => {
       this.isLoading = false;
       this.notificationError();
       if (!err.response) this.error = "Problème réseau, Impossible de joindre le serveur" 
       else 
        this.error = "La page ne peut pas étre telechargée, veuillez réessayer plus tard si le probleme persiste, contacter l'admin"
        
     })
      },
      notification(){
        console.log("notify")
        
      notify({ type: "success", text: "The operation completed", title: "DONE" });
      },

       notificationError(){
        console.log("notify")
        notify({ type: "error", text: "the operation could not be completed", title: "WARNING" });
     },

 showMessage(){
      Swal.fire({
      title: 'Attention!',
      text: "Etes vous sur de vouloir modifier ce chauffeur?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: 'green',
      cancelButtonColor: '#d33',
      cancelButtonText: "Annuler",
      confirmButtonText: 'Ok'
      })
      .then((result) => {
       if (result.isConfirmed) {
         this.edit();
     
  }
})
    },
  }

}
</script>

<style>

</style>