<template>

      <section class="home-section" >
         
         <div class="container-scroller" >
    <!-- partial:partials/_navbar.html -->
    <Header title="Ajout colis" />
    <!-- partial -->
    <div class="container-fluid page-body-wrapper bg-light"  >
      <!-- partial:partials/_sidebar.html -->
   
    
      <!-- partial -->
      <div class="main-panel" style="width : 100%;">
        <!---content wrapper start--->
              
    <div class="content-wrapper">
                  
                  <div class="row">
            <div class="col-md-12 grid-margin">
              <div class="d-flex justify-content-between flex-wrap">
                <div class="d-flex align-items-end flex-wrap">
                
               
                <div class="mt-3">
                  <a href="#" class="link-secondary text-dark link-hover"><u>Chauffeur </u> </a> >
                   <a href="#" class="auth-link text-dark"><u>Liste chauffeurs</u> </a> >
                    <a href="#" class="auth-link text-dark"><u>Modifier chauffeur</u> </a> 

                    
           </div>


                </div>
               <!--  <div class="d-flex justify-content-between align-items-end flex-wrap">
                  <button type="button" class="btn btn-light bg-white btn-icon mr-3 d-none d-md-block ">
                    <i class="mdi mdi-download text-muted"></i>
                  </button>
                  <button type="button" class="btn btn-light bg-white btn-icon mr-3 mt-2 mt-xl-0">
                    <i class="mdi mdi-clock-outline text-muted"></i>
                  </button>
                  <button type="button" class="btn btn-light bg-white btn-icon mr-3 mt-2 mt-xl-0">
                    <i class="mdi mdi-plus text-muted"></i>
                  </button>
                </div> -->
              </div>
            </div>
          </div>
          <div class="row">
            

 
                  <form class="forms-sample" @submit.prevent="handlesubmit" style="width : 100%;">
              <div class="col-12 grid-margin stretch-card">
              <div class="card">
                <div class="card-body">
                  <h4 class="card-title"> Informations :  </h4>
                                                           <loading  class="d-inline" v-if="isLoading" :active="true" :width="20" :height="20" color="blue"></loading>



                  <!------------------>

                       <div class="form-group row" style="margin-left : 4%; height:auto;">
                      <label for="exampleInputUsername2" class="col-lg-2  col-form-label " >Nom : </label>
                      <div class=" col-lg-6  " >
                            <div class="form-group">
              <input type="text" class="form-control" id="exampleInputEmail2" placeholder="nom de la societé" v-model="t.nom" >
              
                      </div>

                      </div>
                    </div>
               
                  <!--------->
                                                
                                                 <div class="row">
                    <div class="col-12">
                       <div class="form-group row" style="margin-left : 4%;">
                      <label for="exampleInputUsername2" class="col-lg-2 col-form-label" >Adresse : </label>
                      <div class="col-lg-8">
                            <div class="form-group">
                  <textarea class="form-control" id="exampleFormControlTextarea1" rows="2" placeholder="adresse" v-model="t.adresse" ></textarea>

              

                      </div>

                      </div>
                    </div>
  
                      </div>
                     
                  </div>
                  <!----------------->
                       <div class="row">
                    <div class="col-12">
                       <div class="form-group row" style="margin-left : 4%;">
                      <label for="exampleInputUsername2" class="col-lg-2 col-form-label">Ville : </label>
                      <div class="col-lg-8">
                            <div class="form-group">
                           <select class="form-control form-select form-select-md mb-3" aria-label="Default select example" v-model="t.ville_id"  >
                     <option v-for="item in villes" :value="item.id" :key="item.id" >
      {{ item.city }}
    </option>
                      </select>
                      </div>

                      </div>
                    </div>  
                      </div>                 
                  </div>
                  <!------------>
                                                      <!--  <div class="row">
                    <div class="col-12">
                       <div class="form-group row" style="margin-left : 4%;">
                      <label for="exampleInputUsername2" class="col-lg-2 col-form-label" >Pays : </label>
                      <div class="col-lg-6">
                            <div class="form-group">
              <input type="text" class="form-control" id="exampleInputEmail2" placeholder="pays" v-model="t.pays" >
              

                      </div>

                      </div>
                    </div>
  
                      </div>
                     
                  </div> -->
                  <!---------->
                 <div class="row">
                    <div class="col-12">
                       <div class="form-group row" style="margin-left : 4%;">
                      <label for="exampleInputUsername2" class="col-lg-2 col-form-label" >Mobile : </label>
                      <div class="col-lg-6">
                            <div class="form-group">
 <input type="text" v-on:keypress="isNumber(event)"  class="form-control" id="exampleInputEmail2" autocomplete="off" placeholder="ex : 06xxxxxxxx" :minlength="10" :maxlength = "10" v-model="t.mobile" >



                      </div>

                      </div>
                    </div>
  
                      </div>
                     
                  </div>
          


                </div>
              </div>
            </div>
            <!-------------------------------->
           <!--     <div class="col-12 grid-margin stretch-card" v-if="user == null">
              <div class="card">
                               <div class="card-body" >
                  <h4 class="card-title">Activation du compte</h4>
                  

                    <div class="row" style="margin-left : 4%;">

                      <div class="col-lg-6">
                                         <div class="form-check">
  <input class="form-check-input" type="checkbox" value="1" id="flexCheckDefault" v-model="t.is_actived">
  <label class="form-check-label" for="flexCheckDefault">
    Créer un compte pour ce chauffeur
  </label>
</div>
                         
                   
                  
                     
                      </div>
                   
                    </div>
                    <div class="row" v-if="t.is_actived" >
                <p style="margin-left : 5.5%; font-style: italic;color: darkred; font-weight:bolder; " class="">
                     Le login et le mot de passe seront envoyés automatiquement à cet email aprés validation
                    </p>


                    <div class="col-12">
                       <div class="form-group row" style="margin-left : 4%;">
                      <label for="exampleInputUsername2" class="col-lg-2 col-form-label" >Email : </label>
                      <div class="col-lg-6">
                            <div class="form-group">
              <input type="email" class="form-control" id="exampleInputEmail2" placeholder="email" v-model="t.email" >
              

                      </div>

                      </div>
                    </div>
  
                      </div>
                     
                  </div>

   

                         <div class="row " style="margin-top : 1%;">
                       <div class="col-12"> 
                               <div  v-if="error" class="alert alert-danger text-center" role="alert">
                       {{error}}
                </div>
                       </div>
                     </div>
                             
              
                </div>
                
              </div>
            </div> -->

   
            <!---------------------------->

     <!--         <div class="col-12 grid-margin stretch-card" v-if="t.is_actived == 1 && user!=null &&user.is_archived ==0">
              <div class="card">
                               <div class="card-body" >
                  <h4 class="card-title">Désactivation du compte</h4>
                  

                    <div class="row" style="margin-left : 4%;">

                      <div class="col-lg-6">
                                         <div class="form-check">
  <input class="form-check-input" type="checkbox" value="1" id="flexCheckDefault" v-model="t.user_archived">
  <label class="form-check-label" for="flexCheckDefault">
    Désactiver le compte de ce chauffeur
  </label>
</div>
                         
                   
                  
                     
                      </div>
                   
                    </div>
                    <div class="row" >
                <p style="margin-left : 5.5%; font-style: italic;color: darkred; font-weight:bolder; " class="">
                     Une fois désactivé, le vendeur ne pourra plus accéder à l'ensemble de ses activités sur son compte.
                    </p>


                    <div class="col-12">
                       <div class="form-group row" style="margin-left : 4%;">
                      <label for="exampleInputUsername2" class="col-lg-2 col-form-label" >Email : </label>
                      <div class="col-lg-6">
                            <div class="form-group">
              <input type="email" disabled class="form-control" id="exampleInputEmail2" placeholder="email" v-model="user.email" >
              

                      </div>

                      </div>
                    </div>
  
                      </div>
                     
                  </div>

   

             
              
                </div>
                
              </div>
            </div> -->
            <!------------------------------->
           <!--     <div class="col-12 grid-margin stretch-card" v-if="user!=null && user.is_archived == 1">
              <div class="card">
                               <div class="card-body" >
                  <h4 class="card-title">Information du compte</h4>
                  

                    <div class="row" style="margin-left : 4%;">

                      <div class="col-lg-6">
                                         <div class="form-check">
  <input class="form-check-input" type="checkbox" value="1" id="flexCheckDefault" checked disabled>
  <label class="form-check-label" for="flexCheckDefault">
    Désactivé
  </label>
</div>
                         
                   
                  
                     
                      </div>
                   
                    </div>
                    <div class="row" >
                

                    <div class="col-12">
                  
  
                      </div>
                     
                  </div>

   

     
              
                </div>
                
              </div>
            </div> -->
                 <div class="row " style="margin-top : 1%;">
                       <div class="col-12"> 
                               <div  v-if="error" class="alert alert-danger text-center" role="alert">
                       {{error}}
                </div>
                       </div>
                     </div>
              <div class="row">
                     <div class="col-lg-3">

                     </div>
                     <div class="col-lg-3" >
                                           <button :disabled="isLoading" @click="showMessage()" class="btn btn-success mr-2" style="width : 100%;">
                                             <span v-if="!isLoading" >Valider</span>
                          <loading v-if="isLoading" :active="true" :width="10" :height="10" color="white"></loading>
                        </button>
                


                     </div>
                     <div class="col-lg-3">
                                           <button type="button" class="btn btn-secondary" style="width : 100%;" @click="back">Retour</button>

                     </div>
                   </div>

   
                 
          </form>
 
         <!----------------------------------------->

 

    
          </div>
        </div>  

        <!-- content-wrapper ends -->
        <!-- partial:partials/_footer.html -->
      <Footer />
        <!-- partial -->
      </div>
      <!-- main-panel ends -->
    </div>
    <!-- page-body-wrapper ends -->
  </div>
        
      </section>


</template>

<script>
import axios from "axios"
import Swal from 'sweetalert2';
 import Header from '@/components/Header.vue'; 
import Footer from '@/components/Footer.vue';  
import Loading from 'vue3-loading-overlay';


export default {
   props: {
    idt: Number,
  },

  components : {
    
      Header,
      Footer,
      Loading
  },
    data(){
        return{
            test : "oui",
           // idt : 8,
            t : {},
            user : {},
            activity:{},
            error : "",
            isLoading: false,
            villes:[],
            

        }
    },
   
     mounted(){
       this.isLoading = true;
       this.t =JSON.parse(this.$route.params.data)
       this.user = this.t.user;
       console.log(this.user);
     this.$store.dispatch('getItem', {//data to be sended
      nameRoute : '/api/getallcities',
      })    
     .then(res => {
       this.isLoading = false;
      console.log(res.data);
      this.villes = res.data;
     })
     .catch(err => {
       this.isLoading = false;
        if (!err.response) this.error = "Problème réseau, Impossible de joindre le serveur" 
       else this.error = "Une erreur s'est produite ! "
      })   
  
             },
      computed :{
          user_id(){
           // console.log(this.$store.getters.get_user)
        return this.$store.getters.get_user;
                    },
               },

  methods: {
    back(){
    // $('#myform').trigger("reset");
    //document.getElementsByClassName("forms-sample").reset();
    this.$router.go(-1);
  },
         addActivity(id){
   this.activity.user_id= this.user_id.id;
   this.activity.action= "Edit driver";
   this.activity.model = "Driver"
   this.activity.url=this.$store.getters.get_link+'/api/editD/'+id;
   this.activity.user_agent=navigator.appName+" "+navigator.appVersion ;
   this.activity.item_id = id;
   console.log("activity..........", this.activity)
     this.$store.dispatch('postItem', {//data to be sended
      nameRoute : '/api/addactivity',
      t : this.activity
      })    
     .then(res => {
      console.log(res.data);
     })
     .catch(error => {
        console.log(error)     
     }) 

 },
      edit(){
        this.error = '';
        this.isLoading = true;
      this.$store.dispatch('postItem', {//data to be sended
      nameRoute : '/api/editD/'+this.t.id,
      t : this.t
      })    
     .then(res => {
       this.isLoading = false;
      console.log(res.data);
      this.$router.push("/driver");
     })
     .catch(err => {
       this.isLoading = false;
         if (!err.response) this.error = "Problème réseau, Impossible de joindre le serveur" 
       else 
        this.error = "La page ne peut pas étre telechargée, veuillez réessayer plus tard si le probleme persiste, contacter l'admin"
        
     })
      },

 showMessage(){
      Swal.fire({
      title: 'Attention!',
      text: "Etes vous sur de vouloir modifier ce chauffeur?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: 'green',
      cancelButtonColor: '#d33',
      cancelButtonText: "Annuler",
      confirmButtonText: 'Ok'
      })
      .then((result) => {
       if (result.isConfirmed) {
         this.edit();
     
  }
})
    },
  }

}
</script>

<style>

</style>