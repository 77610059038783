<template>

    <nav class="navbar col-lg-12 col-12 p-0 fixed-top d-flex flex-row  bg-light" >
    
<!--       <div class="navbar-menu-wrapper d-flex align-items-center bg-dark" style="width : 100%; ">
 -->        <div class=" navbar-menu-wrapper d-flex align-items-center" style="width : 100%; ">
           <ul class="navbar-nav navbar-nav-right">
       
          <li class="nav-item nav-profile ">
            <div class="nav-link d-lg-inline d-md-none d-sm-none d-xs-none" href="#" data-toggle="dropdown" id="profileDropdown">
             
                          <img src="images/faces/logo.jpg" alt="profile"/>

            </div>
    
          </li>
        </ul>
        </div>
      

       
    </nav> 
</template>
<style >


</style>
<script>

import axios from 'axios';
export default {
  data() {
    return {
      token : null,
      id : null,
      login : null,
      user : null,
      isLogged : false,
      u : null,
    };
    
  },

  computed:{
    //A computed property will only re-evaluate when some of its dependencies have changed
 loggedIn(){
   return this.$store.getters.get_loggedIn;
 },

 utilisateur(){
   return this.$store.getters.get_user;
 },

 userAcc(){
   return this.$store.getters.get_userAcc;
 },
profile(){
   return this.$store.getters.get_profile;
 },
 //loggedin and utilisateur are porpreties not functions

 },

  // when i load the page 
  mounted(){
    //console.log(this.profile.permissions.split(""))
    
    //this.checkUserId();
  },
  methods: {
    checkUserId(){
     // console.log(localstorage.getItem("user"))
     //console.log(localStorage.getItem("userID"));
     this.id = localStorage.getItem("userID");
     //uqer Data
     axios.create({
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer '+localStorage.getItem("token")
        }
    })
     .get("http://127.0.0.1:8000/api/getData/"+this.id)
     .then(res => {
       this.user = res.data;
       this.login = this.user.login
       console.log(this.login)

     })
     
     
     //
      
    },
    logout(){
      localStorage.removeItem('token')
      localStorage.removeItem('userID')
      this.$router.push('/')
    }
  }
}
</script>
