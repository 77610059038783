<template>
<input type="text" v-model="qrValue1">
  <div id="a">
  <vue-qrcode v-bind:value="qrValue" scale="15"/>
  </div>
    <button @click="makepdf()">  Makepdf </button>
    <button @click="tester()">  tester et encoder </button>


</template>
<script>
import VueQrcode from 'vue3-qrcode'
import html2canvas from "html2canvas"
import jspdf from "jspdf"
//import axios from "axios"

export default {
  components: {
    VueQrcode,
  },

   data(){
    return{
      id : '',
      qrValue :  "test",
      test : "s",
      correctionLevel:"H"
    }
   },
   mounted(){
     this.id = 17;
     //console.log(this.test)
     //this.qrValue = this.test
     //console.log(this.unpack(this.qrValue));

 


   },


  methods: {
    makepdf(){
      window.html2canvas = html2canvas;
      var doc = new jspdf("p","pt","a4");
      doc.html(document.querySelector("#a"), {

       callback : function(pdf){
         pdf.save("mypdftesting.pdf");
       }
      });
    },
    tester(){
      
           this.test = Number(42).toString(2);
           console.log (5 >> 2)
           
           //this.test = btoa(this.test);
           //console.log(this.test * 16);
           //console.log(this.unpack("testé"));
           let binary = `1110100 1100101 1110011 1110100 11101001 10000000`;

let outputStr = binary.split(' ') //Split string in array of binary chars
   .map(bin => String.fromCharCode(parseInt(bin, 2))) //Map every binary char to real char
   .join(''); //Join the array back to a string

console.log(outputStr);
console.log("*************************");
//convert string to bytes
var myBuffer = [];
var str = 'testé';
var buffer = new Buffer(str, 'utf-8');
var code = '';
var encodeArr=[];
// encrypt bytes array by adding key
for (var i = 0; i < buffer.length; i++) {
    myBuffer.push(buffer[i] + 25 );
}
// encode resultat to base 64
console.log(myBuffer);
console.log(buffer);
for (var j = 0; j < myBuffer.length; j++) {
    code = code + btoa(myBuffer[j]) +" ";
}
console.log("the secret code is : "+code)
this.qrValue = code;
//testing decoding
const myArr = code.split(" ");
console.log(myArr)
for (var n = 0; n < (myArr.length) - 1; n++) {
    encodeArr.push(atob(myArr[n]) - 25);
}
console.log("testing decoding to utf-8 ");
console.log(encodeArr)
//console.log(atob(code))


//testing decoding
console.log("decoding****************")
 var encodedString = String.fromCharCode.apply(null, encodeArr);
 console.log(encodedString);
var t = "";
        for ( var k=0;k<buffer.length;k++ ) 
         {
           console.log("loop"+i);
         }



    },
    

         unpack(str) {
    var bytes = [];
    var t = "ac";
    console.log(t.toString(2))
    for(var i = 0; i < str.length; i++) {
        var char = str.charCodeAt(i);
        var code = char.toString(2);
        console.log("---------------------")
        console.log(char.toString(2))
        bytes.push(code);
        //bytes.push(char & 255, char >> 8);
       // bytes.push(char >>> 8);
        //bytes.push(char & 0xFF);
    }
    return bytes;
},
decoder(){
console.log("test");
}




  }
}
</script>
<style scoped>
 #a {
   width: 8.5in;
   height: 11 in;
   margin-top: 60px;

 }
</style>