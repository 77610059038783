

<template>
              

      <section class="home-section" >
         
         <div class="container-scroller" >
    <!-- partial:partials/_navbar.html -->
    <Header title="Ajout colis" />
    <!-- partial -->
    <div class="container-fluid page-body-wrapper bg-light"  >
      <!-- partial:partials/_sidebar.html -->
   
    
      <!-- partial -->
      <div class="main-panel" style="width : 100%;">
        <!---content wrapper start--->
              
        <div class="content-wrapper">

                  <div class="row" >
            <div class="col-md-12 grid-margin">
              <div class="d-flex justify-content-between flex-wrap row">
                <div class="d-flex align-items-end flex-wrap col-lg-8">
                
               
                <div class="mr-auto p-2"> <div class="mt-3">
                      <p><i class="mdi mdi-account-multiple icon-md" style="color: #6c757d; font-size: 30px;"></i></p>
                    </div>
                    </div>
      


                </div>
                <div class="col-lg-4 d-flex justify-content-between align-items-end flex-wrap">
                    <div class="input-group w-100 p-0 bg-light" style=" border-bottom: 1px solid black; ">
              <div class="input-group-prepend bg-light" style="height : 40px;">
                <span class="input-group-text bg-light" id="search">
                  <i class="mdi mdi-magnify text-dark"></i>
                </span>
              </div>
<input type="search" class="form-control bg-light " v-model="searchQuery"  @focus="testallseller(false, false)"  placeholder="Search now" aria-label="search" aria-describedby="search" style="height : 40px; border : none; ">
                       </div>
                  
                   

                  

                 
<!-- <button @click="t">tester</button> -->
                  
                  
                </div>
              </div>
            </div>
          </div>
          
       <!--------------->
     
 
       <!--------------->
          <div class="row">
            <div class=" stretch-card col-lg-12 m-1 p-0">
              <div class="card p-0">
                <div class="card-body p-0">
                    <div class="d-flex justify-content-end py-2">
                                   <loading v-if="isLoading" class="py-2" :active="true" :width="20" :height="20" color="green"></loading>

                      <!-- <div class="dropdown mr-2">
  <button class="btn btn-light btn-icon-text dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
    <i class="mdi mdi-view-list btn-icon-prepend"></i>
    Afficher les clients
  </button>
  <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
    <li><a class="dropdown-item" href="#" v-on:click="testallseller(true, false)">
      
      <i class="mdi mdi-check float-left icon-sm mr-2"></i>
      <p class="mb-0 ml-2 text-dark"> Clients dis</p>
      </a></li>
    <li><a class="dropdown-item" href="#" v-on:click="afficherClientsArchives()"> 
      <i class="mr-2 mdi mdi-close-circle-outline text-danger float-left icon-sm"></i>
      <p class="mb-0 ml-2 text-dark"> Archivés</p>
</a></li>
  </ul>
</div> -->
<button type="button" class="btn btn-outline-dark mr-2 btn-icon-text" v-on:click="testallseller(true, false)"  >
  <i class="mdi mdi-view-list btn-icon-prepend"></i>
  Afficher les clients
  </button>
<button type="button" class="btn btn-success mr-2 btn-icon-text" v-on:click="ajouterClient()"  v-show="role == 'admin' || role == 'back office' || role == 'sous back office'">
  <i class="mdi mdi-plus-circle btn-icon-prepend"></i>
  Ajouter un client
  </button>


                    
                     </div>
                    
                 
                 
                                                     
                   
                                    <!-- <p v-if="count >=0" class="card-title text-center m-2"> <span v-show="!isLoading" class="text-danger">{{ count }}</span>
                                                              <loading v-if="isLoading" :active="true" :width="10" :height="10" color="black"></loading> resultat(s)</p>
 -->
  <div class="row m-3">
   <!--  <button class="btn btn-success py-2" @click="test">tester</button> -->
    
                    <table class="table table-hover table-stripped">
  
  <tbody>
    <tr class="bg-white text-black" style="height : 50px;">
      <th scope="row" >Nom Prénom</th>
      <td class="text-center font-weight-bold">Adresse</td>
      <td class="text-center font-weight-bold">Ville</td>
      <td class="text-center font-weight-bold"> Mobile </td>
      <td class="text-center font-weight-bold"> Compte </td>
      <td class="text-center font-weight-bold"> Actions </td>
    </tr>
      <tr class="odd text-center" v-if="!countSearch && !error" >
                           <td valign = "top" colspan="7" class="dataTables_empty text-center text-primary bg-light font-weight-bold">
                             No data available in table
                           </td>
                        </tr>
    <tr class="odd text-center" v-if="error" >
                           <td valign = "top" colspan="8" class="dataTables_empty text-center text-danger bg-light font-weight-bold">
                             {{error}}
                           </td>
                        </tr>
    
     <tr v-show="countSearch && !error" class="text-center " v-for="(item, index) in resultQuery" :key="item.id" v-bind:index="index" >
      <td class="text-left py-3 text-capitalize"  scope="row"> {{item.nom}}   </td>
      <td class="text-left"> {{item.adresse}}  </td>
      <td class="" v-if="item.ville">  {{item.ville.city}}   </td>
      <td class="text-danger" v-else>  ****  </td>
      <td class=""> {{item.mobile}}  </td>

      <td v-if="item.is_actived ==0" class="text-center  text-white">
                                  <span class="badge badge-primary">Non inscrit</span>
                                </td>
      <td class="text-center  text-white" v-else-if="item.is_actived == 1 &&  item.user != null && item.user.is_archived == 0"> 
                                  <span class="badge badge-success">Activé</span>
                               </td>
      <td class="text-center text-white" v-else-if="item.is_actived == 1 &&  item.user != null && item.user.is_archived == 1"> 
                                  <span class="badge badge-danger">Désactivé</span>
                                 </td>
       <td class="text-center " v-else-if="item.is_actived == 1 &&  item.user == null "> 
                                <span class="badge badge-warning ">Not Found</span>
                                </td>

      <td class="text-center">
              <a @click="openModel(item)" title="details" class="px-1" style="cursor: pointer;" ><i class="mdi mdi-mdi mdi-eye icon-sm text-info" ></i></a>
              <a v-show="item.is_archived == 0 && role != 'manager'"  @click="edit(item)"   title="editer" class="px-1" style="cursor: pointer;"><i class="mdi mdi-pencil icon-sm text-success " ></i></a>
<!--               <a v-show="role != 'manager'" v-if="item.is_archived == 0 && selected!=item.id" title="archiver " class="px-1" v-on:click = "showMessage(item.id, index)" style="cursor: pointer;"><i class="mdi mdi-close-circle-outline icon-sm text-danger" ></i></a>
 --><!--               <a v-show="role != 'manager'" v-if="item.is_archived == 1 && selected!=item.id"  title="désarchiver " class="px-1" v-on:click = "desarchiver(item.id, index)" style="cursor: pointer;"  ><i  class=" mdi mdi-backup-restore icon-sm " style="color : darkred;" ></i></a>
 -->              <!-- <a href=""> <i v-show="selected==item.id" class=" mdi mdi-check icon-sm " style="color : green;" ></i></a> -->

            </td>
    </tr>

      <tr v-show="count" class="text-center " v-for="(item, index) in cl" :key="item.id" v-bind:index="index" >
      <td class="text-left py-3 text-capitalize"  scope="row"> {{item.nom}} {{item.prenom}}  </td>
      <td class=""> {{item.adresse}}  </td>
      <td class=""> <!-- {{item.ville.city}}  --> </td>
      <td class=""> {{item.mobile}}  </td>

      <td v-if="item.is_actived ==0" class="text-center  text-white">
                                  <span class="badge badge-primary">Non inscrit</span>
                                </td>
      <td class="text-center  text-white" v-else-if="item.is_actived == 1 &&  item.user != null && item.user.is_archived == 0"> 
                                  <span class="badge badge-success">Activé</span>
                               </td>
      <td class="text-center text-white" v-else-if="item.is_actived == 1 &&  item.user != null && item.user.is_archived == 1"> 
                                  <span class="badge badge-danger">Désactivé</span>
                                 </td>
       <td class="text-center " v-else-if="item.is_actived == 1 &&  item.user == null "> 
                                <span class="badge badge-dark ">Error</span>
                                </td>

      <td class="text-center">
              <a @click="openModel(item)" title="details" class="px-1" style="cursor: pointer;" ><i class="mdi mdi-mdi mdi-eye icon-sm text-info" ></i></a>
              <a v-show="item.is_archived == 0"  @click="edit(item)"   title="editer" class="px-1" style="cursor: pointer;"><i class="mdi mdi-pencil icon-sm text-success " ></i></a>
              <a v-if="item.is_archived == 0" title="archiver " class="px-1" v-on:click = "showMessage(item.id, index)" style="cursor: pointer;"><i class="mdi mdi-close-circle-outline icon-sm text-danger" ></i></a>
<!--               <a v-else  title="désarchiver " class="px-1" v-on:click = "desarchiver(item.id, index)" style="cursor: pointer;" v-show="!selected || selected != item.id" ><i  class=" mdi mdi-backup-restore icon-sm " style="color : darkred;" ></i></a>
 -->              <a href=""> <i v-show="selected==item.id" class=" mdi mdi-check icon-sm " style="color : green;" ></i></a>

            </td>
    </tr>
  </tbody>
</table>
     </div>

     <div v-if="myModel">
    <transition name="model">
     <div class="modal-mask">
      <div class="modal-wrapper">
       <div class="modal-dialog bg-transparent w-100 modal-dialog-centered modal-lg">
        <div class="modal-content bg-light">
         <div class="modal-header">
                     <h4 class="modal-title text-center text-primary">Informations</h4>

          <button type="button"  @click="myModel=false"><span aria-hidden="true">&times;</span></button>
         </div>
         <div class="modal-body bg-light">
         
         <div class="table-responsive   m-4 ">
                   <table class="table table-borderless ">

                      <tbody>
                        <tr class="" >
                            <th  style="padding-left : 10%; padding-bottom : 2%; width : 30%;">Réference </th>
                             <th   class="text-muted" style="padding-bottom : 2%;" v-if="t.ref"> {{t.ref}}  </th>
                             <th   class="text-muted text-danger" style="padding-bottom : 2%;" v-else> *****  </th>
                            
                          </tr >
                        
                        <tr class="" >
                            <th  style="padding-left : 10%; padding-bottom : 2%; width : 30%;">Nom  </th>
                             <th   class="text-muted" style="padding-bottom : 2%;"> {{t.nom}}  </th>
                            
                          </tr >
                        
                           <tr class="" >
                            <th  style="padding-left : 10%; padding-bottom : 2%; width : 30%;">Adresse  </th>
                             <th   class="text-muted" style="padding-bottom : 2%;"> {{t.adresse}}  </th>
                            
                          </tr >
                           <tr class="" >
                            <th  style="padding-left : 10%; padding-bottom : 2%; width : 30%;">Ville  </th>
                             <th   class="text-muted" style="padding-bottom : 2%;"> {{t.ville.city}}  </th>
                            
                          </tr >
                          <tr class="" >
                            <th  style="padding-left : 10%; padding-bottom : 2%; width : 30%;">Mobile  </th>
                             <th   class="text-muted" style="padding-bottom : 2%;"> {{t.mobile}}  </th>
                            
                          </tr >
                          <tr class="" >
                            <th  style="padding-left : 10%; padding-bottom : 2%; width : 30%;">Email de reception  </th>
                             <th   class="text-muted" style="padding-bottom : 2%;"> {{t.mail_reception}}  </th>
                            
                          </tr >
                           
                      
      
                            
                            
                      
                      </tbody>
                    
                    </table>
                   
                  </div>
          <br />
          
         </div>
        </div>
       </div>
      </div>
     </div>
    </transition>
   </div>


                </div>
              </div>
            </div>
          </div>
        
        </div>

        <!-- content-wrapper ends -->
        <!-- partial:partials/_footer.html -->
      <Footer />
        <!-- partial -->
      </div>
      <!-- main-panel ends -->
    </div>
    <!-- page-body-wrapper ends -->
  </div>
        
      </section>

</template>

<script>
// import Menu from '@/components/Menu.vue'; 
import Header from '@/components/Header.vue'; 
import Footer from '@/components/Footer.vue'; 
import axios from 'axios';
import Swal from 'sweetalert2';
import moment from 'moment';
 import Loading from 'vue3-loading-overlay';








export default {

  components : {
    // Menu,
    Header,
    Footer,
    //detailsCh,
    Loading

},
  data()  {
    return {
      load : false,
      liste : false,
  listearch :false,
   chauffeurs : null,
   users : [],
   clients : null,
   count : '',
   search : '',
   id : "test",
   detail : false,
   isDisabled : false,
   selected : null,
   item : null,
   t : null,
   myModel:false,
  actionButton:'Insert',
  dynamicTitle:'Add Data',
   isLoading: false,
   searchQuery:'',
   cl: {},
   activity:{},

   
    }
  },
    mounted(){
     this.testallseller(this.liste, this.listearch)

  },
 
    computed: {
       role(){
        
             return this.$store.getters.get_profile.type_user;
      },
       user(){
           // console.log(this.$store.getters.get_user)
        return this.$store.getters.get_user;
     },
    
    resultQuery(){
      if(this.searchQuery){
      return this.vendeurs.filter((item)=>{
       return this.searchQuery.toLowerCase().split(' ') 
       .every(v =>  item.nom.toLowerCase().includes(this.searchQuery)   ||  item.ville.city.toLowerCase().includes(this.searchQuery) || item.adresse.toLowerCase().includes(this.searchQuery) || item.mobile.toLowerCase().includes(this.searchQuery))
      
      })}
      else if(this.liste ) return this.vendeurs;
      else if(this.listearch) return this.vendeurs;
      else  return []
      
    },
    countSearch(){
      return this.resultQuery.length;
    },
    },

 methods: {
   test(){
     console.log(this.resultQuery);

   },
    testallseller(liste, listarch){
     this.isLoading = true;
     this.error = '';
     
     this.$store.dispatch('getItem', {//data to be sended
      nameRoute : '/api/getclientsactived',
      })
      .then( res => {
        console.log("data",res.data)
        this.vendeurs = res.data;
        this.liste = liste;
        this.listearch = listarch;
        if(this.liste || this.listearch ) this.searchQuery = "";
        this.isLoading = false;
        //this.count = res.data.length;
      })   
      .catch(err => {
        this.isLoading = false;
        if (!err.response) this.error = "Problème réseau, Impossible de joindre le serveur" 
        else this.error = "Une erreur s'est produite ! "
      })

   },
   format_date(value){
         if (value) {
           return moment(String(value)).format('DD-MM-YYYY hh:mm')
          }
      },
   openModel(item){
  // this.first_name = '';
  // application.last_name = '';
  this.t = item;
   
   this.actionButton = "Insert";
   this.dynamicTitle = "Add Data";
   this.myModel = true;
  },
  /*  t(){
     var arr = ["oui", "non"]
    this.$router.push({ name: 'detailsCh', params: {data:arr}})


   }, */
    ajouterClient(){
          console.log("clicked!");
         this.$router.push("/addcustomer");
         },

    searchclient(){
       this.$store.dispatch('getItem', {//data to be sended
      nameRoute : '/api/searchclient/'+this.search,
      })    
     .then(res => {
       this.clients = res.data;
       //this.count = res.data.length;
     })
    },

    afficherClients(){
      this.isLoading = true;
     this.$store.dispatch('getItem', {//data to be sended
      nameRoute : '/api/getclientsactived',
      })
      .then( res => {
        this.isLoading = false;
        console.log(res.data);
        this.searchQuery = '';
        this.cl = res.data;
       // this.count = res.data.length;
       })
      .catch(err => {
       this.isLoading = false;

        this.error = "Une erreur s'est produite ! "
      })      
      },

    

     afficherClientsArchives(){
      // console.log(this.count)
      this.isLoading = true;
     this.$store.dispatch('getItem', {//data to be sended
      nameRoute : '/api/getclientsarchived',
      })
      .then( res => {
        this.error = '';
         this.vendeurs = res.data;
        this.searchQuery = '';
       this.liste = false;
       this.listearch = true;
        this.isLoading = false;
       })
      .catch(err => {
        this.isLoading = false;
        if (!err.response) this.error = "Problème réseau, Impossible de joindre le serveur" 
        else this.error = "Une erreur s'est produite ! "
      })      
      },

      archiver(id, index){
        this.load = true;
        this.selected = id;
      this.id = id;
      this.$store.dispatch('idItem', {//data to be sended
      nameRoute : '/api/archiverclient/'+this.id,
      })
     .then(res => {
      // this.$router.go()	// Refreshes page
      /* this.cl.splice(index, 1);
      this.count--; */
      this.isLoading = false;
      this.resultQuery.splice(index, 1);
      this.countSearch--; 
      this.selected = null;
      this.addActivity(id, "Archive client", '/api/archiverclient/'+this.id);

      console.log(res.data);
     })
    },

    desarchiver(id, index){
      this.load = true;
      this.selected = id;
      this.id = id;
      this.$store.dispatch('idItem', {//data to be sended
      nameRoute : '/api/desarchiverclient/'+this.id,
      })
     .then(res => {
       
      this.isLoading = false;
       
      // this.$router.go()	// Refreshes page
       //this.isDisabled=false;
       this.resultQuery.splice(index, 1);
      this.countSearch--; 
      this.addActivity(id, "Archive client", '/api/desarchiverclient/'+this.id);
      this.selected = null;
      console.log(res.data);
     })
    },

    details( json_data){  
 
      this.$router.push({ name: 'detailsCh', params: {data:JSON.stringify(json_data)}})
    },
    edit( json_data){  

      this.$router.push({ name: 'editC', params: {data:JSON.stringify(json_data)}})
      console.log(json_data)

    },
    addActivity(id, action, link){
      this.activity.user_id= this.user.id;
      this.activity.action= action;
      this.activity.model = "Client"
      this.activity.url=this.$store.getters.get_link+link;
      this.activity.user_agent=navigator.appName+" "+navigator.appVersion ;
      this.activity.item_id = id;
      console.log("activity..........", this.activity)
        this.$store.dispatch('postItem', {//data to be sended
          nameRoute : '/api/addactivity',
          t : this.activity
          })    
        .then(res => {
          console.log(res.data);
        })
        .catch(error => {
            console.log(error)
           
        }) 

      },

    showMessage(id, index){
      Swal.fire({
      title: 'Attention!',
      text: "Etes vous sur de vouloir archiver ce client?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: 'green',
      cancelButtonColor: '#d33',
      cancelButtonText: "Annuler",
      confirmButtonText: 'Ok'
      })
      .then((result) => {
       if (result.isConfirmed) {
         this.archiver(id, index);
     
  }
})
    },
    parentFire(id){
      console.log("parentFire works!!", id)
    }

  }
}
</script>
<style scoped>
tr {
   line-height: 25px;
   min-height: 25px;
   height: 25px;
}

input[type=search]:focus{
  outline: inherit;
  box-shadow: inherit;
  background: rgb(216, 215, 208) !important;

}
.home-section{
  position: relative;
  background: #E4E9F7;
  min-height: 100vh;
  top: 0;
  left: 78px;
  width: calc(100% - 78px);
  transition: all 0.5s ease;
  z-index: 2;
}
.sidebar.open ~ .home-section{
  left: 250px;
  width: calc(100% - 250px);
}
.home-section .text{
  display: inline-block;
  color: #11101d;
  font-size: 25px;
  font-weight: 500;
  margin: 0px;
}
/* serachbar */

.search-box{
  width: fit-content;
  height: fit-content;
  position: relative;
}
.input-search{
  height: 50px;
  width: 50px;
  border-style: none;
  padding: 10px;
  font-size: 18px;
  outline: none;
  border-radius: 25px;
  transition: all .5s ease-in-out;
  background-color: transparent;
  padding-right: 40px;
  color:black;
}
.input-search::placeholder{
  color: #6C757D;
  font-size: 18px;
  font-weight: 100;
  font-style: italic;
}
.btn-search{
  width: 50px;
  height: 50px;
  border-style: none;
  font-size: 25px;
  font-weight: bold;
  outline: none;
  cursor: pointer;
  border-radius: 50%;
  position: absolute;
  right: 0px;
  color:white ;
  pointer-events: painted;  
}
.btn-search ~ .input-search{
  width: 80%;
  border-radius: 0px;
  background-color: transparent;
  border-bottom:1px solid #6C757D;
  transition: all 500ms cubic-bezier(0, 0.110, 0.35, 2);
}
.input-search:focus{
  width: 80%;
  border-radius: 0px;
  background-color: transparent;
   box-shadow: 0  0.2rem #6C757D;
  transition: all 500ms cubic-bezier(0, 0.110, 0.35, 2);
}
.modal-mask {
     position: fixed;
     z-index: 9998;
     top: 0;
     left: 0;
     width: 100%;
     height: 100%;
     background-color: rgba(0, 0, 0, .5);
     display: table;
     transition: opacity .3s ease;
   }

   .modal-wrapper {
     display: table-cell;
     vertical-align: middle;
   }


</style>
import { Script } from "vm";