

<template>
  <section class="home-section "  >
         
         <div class="container-scroller "  >
    <!-- partial:partials/_navbar.html -->
    <Header />
    <!-- partial -->
    <div class=" page-body-wrapper full-page-wrapper " >
      <!-- partial:partials/_sidebar.html -->
    
      <!-- partial -->
      <div class="main-panel" style="width : 100%; ">
         <div class="container-fluid page-body-wrapper full-page-wrapper bg-dark "  style="width : 100%; background : transparent;" v-if="!testVar">
      <div class="content-wrapper d-flex align-items-center  "  >
        <div class="row w-100" v-if="!testVar">
          <div class="col-lg-4 mx-auto">
            <div class="auth-form-transparent text-left p-5 text-center" >
              <form class="pt-5">
             
                <div class="mt-5">
                  <a class="btn btn-block btn-dark btn-lg font-weight-medium" @click="scanner"><img class="img-fluid" src="../../public/images/qr.png" alt="logo"/></a>
                </div>
                <div class="mt-3 text-center">
                  <a class="auth-link text-danger" @click="scannedShipment">Liste des colis scannées </a>
                </div>
              </form>
            </div>

                
          </div>
        </div>
        <!------>
        <!------->
                   
      </div>
      <!-- content-wrapper ends -->
    </div>



     <div class="content-wrapper bg-white" v-if="testVar">
                 <div class="col-md-12 grid-margin">
              <div class="d-flex justify-content-between flex-wrap">
                <div class="d-flex align-items-end flex-wrap">
                
               
         


                </div>
               
              </div>
            </div>
          <div class="row py-0 my-0">
            

 
              <div class="col-7 grid-margin stretch-card">
              <div class="card mx-0 py-0" style="height : auto; border : none;">
                <div class="card-body">
                  <h4 class="card-title"> Scann en cours :  </h4>

                  <!------------------>
              <div class="st">
                     <qr-stream @decode="onDecode" class="mb d-flex justify-content-center">
  </qr-stream>
              </div>
              

                                                 
              
                                                        

                </div>
              </div>

            
            </div>
               <div class="col-4 grid-margin stretch-card">
              <div class="card" style="height : auto; border : none;">
                <div class="card-body">
                  <h4 class="card-title"> Colis scannés :  </h4>
                     <div class="table-responsive  m-0">
                    <table id="recent-purchases-listing " class="table " >
                      <tbody >
                        <tr class="odd text-center text-white" v-if="count == 0">
                           <td valign = "top" colspan="2" class="dataTables_empty text-center text-danger">
                             Aucun colis n'a été scanné!
                           </td>
                        </tr>
                        <tr  class="text-center tewt-white" v-for="item in scanned" :key="item.id" >
                            <td class="text-black">{{item.id}}</td>
                            <td v-if="item.done==true"><i class="mdi mdi-check icon-sm text-success font-weight-bold" ></i></td>
                            <td v-else><i class="mdi mdi-close icon-sm text-danger" ></i></td>
                              
                     </tr>
                  
                     
                   
                      </tbody>
                    </table>
                   
                  </div>

                  <!------------------>
             
              

                                                 
              
                                                        

                </div>
              </div>

            
            </div>
            <!-------------------------------->

   
            <!---------------------------->
                 
            

 
         <!----------------------------------------->

 

    
          </div>

              <div class="row py-0" >
            

 
              <div class="col-12 grid-margin stretch-card">
              <div class="card" style="height : auto; border:none;">
                <div class="card-body">
               <div class="card-description font-weight-bold" style="font-family : Roboto; font-size : 14px;" >
    RESULTAT: <br><div class="text-dark font-weight-bold">
      {{data}}
    </div>

</div>
<button @click="close" class="btn btn-danger">fermer</button>
              

                                                 
              
                                                        

                </div>
              </div>

            
            </div>
            <!-------------------------------->

   
            <!---------------------------->
                 
            

 
         <!----------------------------------------->

 

    
          </div>
        </div>
        <!-- content-wrapper ends -->
        <!-- partial:partials/_footer.html -->
      <Footer />
        <!-- partial -->
      </div>
      <!-- main-panel ends -->
    </div>
    <!-- page-body-wrapper ends -->
  </div>
        
      </section>

</template>

<script>

import CryptoJS from 'crypto-js'
import { QrStream } from 'vue3-qr-reader';
//import Menu from '@/components/Menu.vue'; 
import Header from '@/components/Header.vue'; 
import Footer from '@/components/Footer.vue'; 
import swal from 'sweetalert2';









export default {
  components: {
   QrStream,
  // Menu,
   Header,
   Footer
  },

   data(){
    return{
        data : "",
        testVar : false,
        message : false,
     // id : this.$route.params.id,
     
      salt : localStorage.getItem("Sa"),
      // key : 'AA74CDCC2BBRT935136HH7B63C27', // change to your key
      key : localStorage.getItem("kp"),
       iv :  localStorage.getItem("I") ,// change to your iv
       obj : {
        
       },
       chaine : "itnevcmrapdlsywvfx",
       scanned: [],
       showScanned : false,
       count : 0,

   
    }
   },



  methods: {
      scanner(){
//test
console.log("clicked");
this.testVar = true;
    },
    onDecode(data){
      this.count = this.scanned.length;
      var result = [];
    //  var done = false,
      this.data = data;
        this.message = true;
        this.res = this.verifier();
       // console.log(this.res)
        var num = this.res[0][0];
     // console.log(num);
      var done = false;

        if(this.res[12] == 9) { done = true;console.log("yes");this.show();}
        else {console.log("no");done = false;this.showError(); }
        
         // this.scanned.push({id : num, done: true});
          var exists = this.scanned.some(function(field) {
  return field.id == num
});

if (!exists) {
  this.scanned.push({id : num, done: done});
}

    },

    itemsScanned(){
      //

    },
    close(){
      this.testVar = false;

    },
      show(){
         swal.fire(
          {
  icon: 'success',
  title: 'Verified',
  text: 'Verified',
  showConfirmButton: false,
  timer: 1000
}
           ).then(function() {
     //this.data = "yes";
     console.log("yes")
});
      },
       showError(){
         swal.fire(
           'ERROR', 'Chech please if this shipment is for you ','error');
        
      },

scannedShipment(){
  console.log("scanned;");
  this.showScanned = true;


},

 
verifier(){  
  console.log("verifier") ; 
var result = [];
//console.log(this.CryptoJSAesDecrypt(this.key, this.data));
console.log("result", JSON.parse(this.CryptoJSAesDecrypt(this.key, this.data)));
var json_data = JSON.parse(this.CryptoJSAesDecrypt(this.key, this.data));
//console.log("******************************",json_data)
//console.log("***************",json_data);
for(var i in json_data)
    {
     var c = this.Decl(json_data[i], this.chaine.charCodeAt(i-1));
     //console.log(this.chaine[i-1],c)
      var p = this.UTLV(c)
     // console.log(p)
      
     //  console.log(p);
       result.push([ p.split('').reverse().join('')]);
       }
       console.log("*********************",result)
       return result


},
Decl(arr, $k){
   for(var i in arr)
    {
      //arr[i] = arr[i] + 25;
      arr[i] = arr[i] - $k;

       }
    return arr;
     

},
UTLV(test){
      var encodedString = String.fromCharCode.apply(null, test),
      decodedString = decodeURIComponent(encodedString);
      var str = decodedString;
     // console.log(encodedString)
     return str;
      

},
base(){
  var json_data = JSON.parse(this.CryptoJSAesDecrypt(this.key, this.data));


 
},


CryptoJSAesDecrypt(passphrase,encrypted_json_string){

    //var obj_json = JSON.parse(encrypted_json_string);

    var encrypted = encrypted_json_string;
    var salt = CryptoJS.enc.Hex.parse(this.salt);
    var iv = CryptoJS.enc.Hex.parse(this.iv);   

    var key = CryptoJS.PBKDF2(passphrase, salt, { hasher: CryptoJS.algo.SHA512, keySize: 64/8, iterations: 999});


    var decrypted = CryptoJS.AES.decrypt(encrypted, key, { iv: iv});

    return decrypted.toString(CryptoJS.enc.Utf8);
}

  



  }
}
</script>
<style scoped>
.st{
  max-height: 70%;
  width : 80%;
}


.home-section{
  position: relative;
  background: #E4E9F7;
  min-height: 100vh;
  top: 0;
  left: 78px;
  width: calc(100% - 78px);
  transition: all 0.5s ease;
  z-index: 2;
}
.sidebar.open ~ .home-section{
  left: 250px;
  width: calc(100% - 250px);
}
.home-section .text{
  display: inline-block;
  color: #11101d;
  font-size: 25px;
  font-weight: 500;
  margin: 0px;
}
/* serachbar */

.search-box{
  width: fit-content;
  height: fit-content;
  position: relative;
}
.input-search{
  height: 50px;
  width: 50px;
  border-style: none;
  padding: 10px;
  font-size: 18px;
  outline: none;
  border-radius: 25px;
  transition: all .5s ease-in-out;
  background-color: transparent;
  padding-right: 40px;
  color:black;
}
.input-search::placeholder{
  color: #6C757D;
  font-size: 18px;
  font-weight: 100;
  font-style: italic;
}
.btn-search{
  width: 50px;
  height: 50px;
  border-style: none;
  font-size: 25px;
  font-weight: bold;
  outline: none;
  cursor: pointer;
  border-radius: 50%;
  position: absolute;
  right: 0px;
  color:white ;
  pointer-events: painted;  
}
.btn-search ~ .input-search{
  width: 80%;
  border-radius: 0px;
  background-color: transparent;
  border-bottom:1px solid #6C757D;
  transition: all 500ms cubic-bezier(0, 0.110, 0.35, 2);
}
.input-search:focus{
  width: 80%;
  border-radius: 0px;
  background-color: transparent;
   box-shadow: 0  0.2rem #6C757D;
  transition: all 500ms cubic-bezier(0, 0.110, 0.35, 2);
}
.stream{
  width: 90% !important;
}


</style>
import { Script } from "vm";