

<template>
      <section class="home-section" >
         
         <div class="container-scroller" >
    <!-- partial:partials/_navbar.html -->
    <Header title="Ajout colis" />
    <!-- partial -->
    <div class="container-fluid page-body-wrapper bg-light"  >
      <!-- partial:partials/_sidebar.html -->
    
      <!-- partial -->
      <div class="main-panel" style="width : 100%;">
        <!---content wrapper start--->
              
        <div class="content-wrapper">

                  <div class="row" >
            <div class="col-md-12 grid-margin">
              <div class="d-flex justify-content-between flex-wrap">
                <div class="d-flex align-items-end flex-wrap">
                
               
                <div class="mt-3">
                  <a href="#" class="link-secondary text-dark link-hover"><u>Vendeur</u> </a> >
                   <a href="#" class="auth-link text-dark"><u>Chercher un vendeur</u> </a> >
                   <a href="#" class="auth-link text-dark"><u>Details</u> </a> 
                    
           </div>


                </div>
                <div class="d-flex justify-content-between align-items-end flex-wrap">
                   <button type="button" class="btn btn-info  btn-rounded btn-icon mr-3 d-none d-md-block " title="Afficher tous les vendeurs" v-on:click="afficherVendeurs()">
                    <i class="mdi mdi-format-list-bulleted text-white"></i>
                  </button>
                
                
                  <button type="button" class="btn btn-light  btn-rounded btn-icon mr-3 mt-2 mt-xl-0" style="background : darkred;" title="Afficher les vendeurs archivés"  v-on:click="afficherVendeursArchives()">
                    <i class="mdi mdi-archive text-white"></i>
                  </button>
                 
                  <button type="button" class="btn btn-light bg-success btn-rounded btn-icon  mr-3 mt-2 mt-xl-0"  v-on:click="ajouterVendeur()" title="Ajouter un vendeur">
                    <i class="mdi mdi-plus text-white"></i>
                  </button>
                  
                </div>
              </div>
            </div>
          </div>
          
       <!--------------->
       <div class="row">
         <h1>TEST</h1>
         <div>
  <b-tabs content-class="mt-3">
    <b-tab title="First" active><p>I'm the first tab</p></b-tab>
    <b-tab title="Second"><p>I'm the second tab</p></b-tab>
    <b-tab title="Disabled" disabled><p>I'm a disabled tab!</p></b-tab>
  </b-tabs>
</div>
       </div>
       
   
 
       <!--------------->
          <div class="row">
            <div class=" stretch-card col-lg-12 m-1 p-0">
              <div class="card p-0">
                
                <div class="card-body p-0">
                                                     
                   
                                    <p v-if="count >=0" class="card-title text-center m-2"> <span class="text-danger">{{ count }}</span> resultat(s)</p>

                  <div class="table-responsive  m-0">
                    <table id="recent-purchases-listing" class="table table-bordered " >
                      <thead>
                        <tr class="text-center " >
                            <th scope="col">Nom </th>
                          </tr>
                          <tr class="text-center " >
                            <th scope="col">Raison sociale </th>
                          </tr>
                          <tr class="text-center " >
                            <th scope="col">Site web </th>
                          </tr>
                           <tr class="text-center " >
                            <th scope="col">RC </th>
                           </tr>
                            <tr class="text-center " >
                            <th scope="col">adresse</th>
                            </tr>
                             <tr class="text-center " >
                            <th scope="col">ville</th>
                             </tr>
                             <tr class="text-center " >
                            <th scope="col">pays</th>
                             </tr>
                             <tr class="text-center " >
                            <th scope="col">Nom responsable</th>
                             </tr>
                              <tr class="text-center " >
                            <th scope="col">mobile</th>
                             </tr>
                              <tr class="text-center " >
                            <th scope="col">telephone</th>
                             </tr>
                              <tr class="text-center " >
                            <th scope="col">fax</th>
                             </tr>
                             <tr class="text-center " >
                            <th scope="col">Compte</th>
                             </tr>
                             <tr class="text-center " >
                            <th scope="col">Mode paiement</th>
                             </tr>
                      
                      </thead>
                      <tbody >
                        <tr class="odd text-center" v-if="count == 0">
                           <td valign = "top" colspan="8" class="dataTables_empty text-center text-danger bg-dark">
                             No data available in table
                           </td>
                        </tr>
                        <tr  v-for="item in vendeurs" :key="item.id" >
                            <td class="text-center">{{item.nom}} </td>
                            <td class="text-center">{{item.rc}} </td>
                            <td class="text-center">{{item.adresse}} </td>
                            <td class="text-center">{{item.ville}}</td>
                            <td class="text-center">{{item.pays}}</td>
                            <td class="text-center">{{item.nom_responsable}}</td>
                                <td v-if="item.is_actived ==0" class="text-center">
                              <span class="badge badge-secondary p-2 text-white" style="font-size : 11px; width : 60%; ">Non inscrit</span>
                            </td>
                             <td class="text-center" v-else-if="item.is_actived == 1 &&  item.user != null && item.user.is_archived == 0">
                              <span class="badge badge-success p-2 text-white" style="font-size : 11px; width : 60%;" >Activé</span>
                            </td>
                            <td class="text-center" v-else-if="item.is_actived == 1 &&  item.user != null && item.user.is_archived == 1">
                              <span class="badge badge-danger p-2 text-white" style="font-size : 11px; width : 60%;">Désactivé</span>
                            </td>
                            <td class="text-center" v-else-if="item.is_actived == 1 &&  item.user == null ">
                              <span class="badge badge-warning p-2 text-white" style="font-size : 11px; width : 60%;" >Error</span>
                            </td>
                            <!--<td >{{item.is_actived}}</td>
                            <td v-if="item.user == null">error</td>
                            <td v-if="item.user != null && item.user.id <=0">  {{item.user.id}}  </td>
                            <td v-if="item.user != null && item.user.id > 47"> {{item.user.id}}</td>-->
           <td class="text-center">
              <router-link :to="{name: 'detailsVendeur', params: { id: item.id}}"  title="details" class="px-1" @click="details"><i class="mdi mdi-mdi mdi-eye icon-sm text-info" ></i></router-link>
               <a href="#" title="archiver " class="px-1" v-on:click = "archiver(item.id)"><i class="mdi mdi-archive icon-sm " style="color : darkred;"></i></a>
            </td>
            
             
                        </tr>
                              
                   
                         
           
                  
                     
                   
                      </tbody>
                    </table>
                   
                  </div>
                </div>
              </div>
            </div>
          </div>
        
        </div>

        <!-- content-wrapper ends -->
        <!-- partial:partials/_footer.html -->
      <Footer />
        <!-- partial -->
      </div>
      <!-- main-panel ends -->
    </div>
    <!-- page-body-wrapper ends -->
  </div>
        
      </section>

</template>

<script>
// import Menu from '@/components/Menu.vue'; 
import Header from '@/components/Header.vue'; 
import Footer from '@/components/Footer.vue'; 
import axios from 'axios';




export default {

  components : {
    // Menu,
    Header,
    Footer
  },
  data()  {
    return {
   vendeurs : null,
   users : [],
   count : '',
   search : '',
   id : '',
    }
  },


 methods: {
      ajouterVendeur(){
          console.log("clicked!");
         this.$router.push("/addseller");
         },

          searchvendeur(){
            console.log("clicked")
           axios.create({
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer '+localStorage.getItem("token")
        }
    })
     .get("http://127.0.0.1:8000/api/searchseller/"+this.search)
     .then(res => {
       this.vendeurs = res.data;
      //console.log(JSON.parse(JSON.stringify(this.res.data)))
    //   console.log(JSON.parse(res.data))
    this.count = res.data.length;

     })

    },

    afficherVendeurs(){
      console.log("clicked!")
                axios.create({
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer '+localStorage.getItem("token")
        }
    })
       .get('http://127.0.0.1:8000/api/getvendeursactived',this.data)
      .then( res => {
        console.log(res.data);
        this.vendeurs = res.data;
        this.count = res.data.length;

        

        //this.$router.push("/d");
      })
      /*
      this.$store.dispatch('retrieveToken', {
        email: this.email,
        password: this.password,
      })*/
      .catch(err => {
        this.error = "Une erreur s'est produite ! "
      })

    },

    afficherVendeursArchives(){
                      axios.create({
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer '+localStorage.getItem("token")
        }
    })
       .get('http://127.0.0.1:8000/api/getvendeursarchived',this.data)
      .then( res => {
        console.log(res.data);
        this.vendeurs = res.data;
       this.count = res.data.length;

        

        //this.$router.push("/d");
      })
      /*
      this.$store.dispatch('retrieveToken', {
        email: this.email,
        password: this.password,
      })*/
      .catch(err => {
        this.error = "Une erreur s'est produite ! "
      })
      
    },
    archiver(id){
      this.id = id;
      console.log(this.id);
      axios.create({
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer '+localStorage.getItem("token")
        }
    })
     .post("http://127.0.0.1:8000/api/archivervendeur/"+this.id)
     .then(res => {
      console.log(res.data);
      //console.log(JSON.parse(JSON.stringify(this.res.data)))
    //   console.log(JSON.parse(res.data))
  //  this.count = res.data.length;

     })

    }

  }
}
</script>
<style >
@import url("../../public/css/style.css");
@import url("https://unpkg.com/boxicons@2.0.7/css/boxicons.min.css");
@import url("../../public/vendors/mdi/css/materialdesignicons.min.css");
@import url("../../public/vendors/base/vendor.bundle.base.css");
@import url("../../public/vendors/datatables.net-bs4/dataTables.bootstrap4.css");
@import url("https://fonts.googleapis.com/css?family=Roboto:300,400&display=swap");
@import url("https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css");
@import url("../../public/login/css/style.css");
@import url("../../public/login/css/bootstrap.min.css");
@import url("../../public/login/css/style.css");
.home-section{
  position: relative;
  background: #E4E9F7;
  min-height: 100vh;
  top: 0;
  left: 78px;
  width: calc(100% - 78px);
  transition: all 0.5s ease;
  z-index: 2;
}
.sidebar.open ~ .home-section{
  left: 250px;
  width: calc(100% - 250px);
}
.home-section .text{
  display: inline-block;
  color: #11101d;
  font-size: 25px;
  font-weight: 500;
  margin: 0px;
}
/* serachbar */

.search-box{
  width: fit-content;
  height: fit-content;
  position: relative;
}
.input-search{
  height: 50px;
  width: 50px;
  border-style: none;
  padding: 10px;
  font-size: 18px;
  outline: none;
  border-radius: 25px;
  transition: all .5s ease-in-out;
  background-color: transparent;
  padding-right: 40px;
  color:black;
}
.input-search::placeholder{
  color: #6C757D;
  font-size: 18px;
  font-weight: 100;
  font-style: italic;
}
.btn-search{
  width: 50px;
  height: 50px;
  border-style: none;
  font-size: 25px;
  font-weight: bold;
  outline: none;
  cursor: pointer;
  border-radius: 50%;
  position: absolute;
  right: 0px;
  color:white ;
  pointer-events: painted;  
}
.btn-search ~ .input-search{
  width: 80%;
  border-radius: 0px;
  background-color: transparent;
  border-bottom:1px solid #6C757D;
  transition: all 500ms cubic-bezier(0, 0.110, 0.35, 2);
}
.input-search:focus{
  width: 80%;
  border-radius: 0px;
  background-color: transparent;
   box-shadow: 0  0.2rem #6C757D;
  transition: all 500ms cubic-bezier(0, 0.110, 0.35, 2);
}


</style>
import { Script } from "vm";