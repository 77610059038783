<template>
      <section class="home-section" >
         
         <div class="container-scroller" >
    <!-- partial:partials/_navbar.html -->
    <Header title="Ajout colis" />
    <!-- partial -->
    <div class="container-fluid page-body-wrapper bg-light"  >
      <!-- partial:partials/_sidebar.html -->
    
      <!-- partial -->
      <div class="main-panel" style="width : 100%;">
        <!---content wrapper start--->
              
        <div class="content-wrapper">

                  <div class="row">
            <div class="col-md-12 grid-margin">
              <div class="d-flex justify-content-between flex-wrap">
                <div class="d-flex align-items-end flex-wrap">
                
               
                <div class="mr-auto p-2"> <div class="mt-3">
                      <p><i class="mdi mdi-lock-open icon-md" style="color: #6c757d; font-size: 30px;"></i></p>
                    </div>
                    </div>


                </div>
                <!-- <div class="d-flex justify-content-between align-items-end flex-wrap">
                  <button type="button" class="btn btn-light bg-white btn-icon mr-3 d-none d-md-block ">
                    <i class="mdi mdi-download text-muted"></i>
                  </button>
                  <button type="button" class="btn btn-light bg-white btn-icon mr-3 mt-2 mt-xl-0">
                    <i class="mdi mdi-clock-outline text-muted"></i>
                  </button>
                  <button type="button" class="btn btn-light bg-white btn-icon mr-3 mt-2 mt-xl-0">
                    <i class="mdi mdi-plus text-muted"></i>
                  </button>
                </div> -->
              </div>
            </div>
          </div>

           <div class="row">
            <div class=" stretch-card col-lg-12 m-1 p-0">
              <div class="card p-0">
                    <div class="row">
            <div class="col-md-12 grid-margin stretch-card">
              <div class="card">
                <div class="card-body dashboard-tabs p-0">
                  <ul class="nav nav-tabs px-4" role="tablist">
                    <li class="nav-item">
                      <a class="nav-link active" id="overview-tab" data-toggle="tab" href="#overview" role="tab" aria-controls="overview" aria-selected="true">Role managament</a>
                    </li>
                    <li class="nav-item">
                      <a class="nav-link" id="sales-tab" data-toggle="tab" href="#sales" role="tab" aria-controls="sales" aria-selected="false">User types</a>
                    </li>
                    <li class="nav-item">
                      <a class="nav-link" id="res-tab" data-toggle="tab" href="#res" role="tab" aria-controls="res" aria-selected="false">Resource </a>
                    </li>
                   
                  </ul>
                  <div class="tab-content py-0 px-0">
                    <div class="tab-pane fade show active" id="overview" role="tabpanel" aria-labelledby="overview-tab">
                        <!---------------->
                      <div class="d-flex flex-wrap justify-content-xl-between">
                    <!--------------here------------------->
                  <form class="forms-sample  m-3" @submit.prevent="handlesubmit" style="width : 100%;">
                      <div class="row mx-3">
                            <div class="form-group row">
                        <label for="exampleInputUsername2" class="col-lg-2 col-form-label font-weight-bold" >Select profile type  : </label>
                            <div class="col-lg-3">
                            <div class="form-group">
                           <select  class="form-control form-select form-select-md mb-3" aria-label="Default select example" v-model="id" @change="manage()"  >
                     <option v-for="item in users" :value="item.id" :key="item.id" >
      {{ item.type_user }}
    </option>

                      </select>
                            </div>
                            </div>
                            <div class="col-lg-6"  v-if="isLoading">
                               <loading  :active="true" :width="20" :height="20" color="blue"></loading> 

                            </div>
                      </div>               
                  </div>

                  <div class="row mx-3">
                    <table class="table">
  
  <tbody>
    <tr class="bg-primary text-white">
      <th scope=" col">Resource name</th>
      <td class="text-center font-weight-bold ">None</td>
      <td class="text-center font-weight-bold">Read</td>
      <td class="text-center font-weight-bold">Write/Read</td>
    </tr>
    
    
     <tr  v-for="(item, index) in resources"  :key="item.id" v-bind:index="index">
      <th scope=" col"> {{item.name}} </th>
      <td class="text-center">
        <div class="form-check form-check-inline">
  <input  class="form-check-input" type="radio" value="0"  :checked="profile[index] == 0" :name="index" v-model="profile[index]" @change="displayButton" :disabled="!isShowedRadio">
</div>
      </td>
       <td class="text-center">
        <div class="form-check form-check-inline">
  <input  class="form-check-input" type="radio" value="1" :checked="profile[index] == 1" :name="index" v-model="profile[index]" @change="displayButton" :disabled="!isShowedRadio">
</div>
      </td> <td class="text-center">
        <div class="form-check form-check-inline">
  <input  class="form-check-input" type="radio"  value="2" :checked="profile[index] == 2" :name="index" v-model="profile[index]" @change="displayButton" :disabled="!isShowedRadio">
</div>
      </td>
    </tr>
  </tbody>
</table>

     </div>
 
     <div class="d-flex flex-row-reverse">
         <button class="btn btn-primary float-lg-right px-3" @click="valider" :disabled="!isShowed">
           <span v-if="!isLoading"> Valider</span>
           <span v-else><loading  :active="true" :width="20" :height="20" color="blue"></loading> </span>
           </button>

</div>



     
                  
                  </form>
 
         <!----------------------------------------->



    
                       
                       
                      
                      </div>
                    </div>
                    <div class="tab-pane fade" id="sales" role="tabpanel" aria-labelledby="sales-tab">
                      <div class="d-flex flex-wrap justify-content-xl-between ">
                       <div class="form-group row w-100 mx-2 my-4" >
                         <label for="exampleInputEmail2" class="font-weight-bold col-lg-2 col-form-label">Add new user ype</label>
                            <div class="col-lg-7 ">
              <input type="text" class=" d-inline form-control text-center col-lg-5 m-1" id="exampleInputEmail2" placeholder="admin " v-model="t.name">
              <button class="btn btn-success d-inline col-lg-2 m-1" @click="addType">OK</button>

                      </div>
                      <div class="col-lg-3 float-left">
                                      <loading v-if="isLoading" :active="true" :width="20" :height="20" color="green"></loading> 

                      </div>


                      </div>
  
                     
                        <table class="table table-striped m-2">

  <tbody>
    <tr v-for="(item, index) in users"  :key="item.id" :v-bind="index">
      <th scope="row"> {{index}} </th>
      <td> {{item.type_user}} </td>
      <td class="text-center px-3"><a href="#" @click="removeType(item.id, index, item.type_user)" title="delete" class="px-1" ><i class="mdi mdi-close icon-sm text-danger" ></i></a></td>
    </tr>
   
  </tbody>
</table>                 
                      
                      
                      </div>
                    </div>
                   
                    <!---------------------here--------------->

                      <div class="tab-pane fade" id="res" role="tabpanel" aria-labelledby="res-tab">
                        <div class="d-flex flex-wrap justify-content-xl-between ">
                       <div class="form-group row w-100 mx-2 my-4" >
                         <label for="exampleInputEmail2" class="font-weight-bold col-lg-2 col-form-label">Add new resource</label>
                            <div class="col-lg-6 ">
              <input type="text" class=" d-inline form-control text-center col-lg-5 m-1" id="exampleInputEmail2" placeholder="resource " v-model="t.name">
              <button class="btn btn-success d-inline col-lg-1 m-1" @click="addResource">OK</button>

                      </div>


                      </div>
  
                     
                        <table class="table table-striped m-2">

  <tbody>
    <tr v-for="(item, index) in resources"  :key="item.id" v-bind:index="index">
      <th scope="row"> {{index}} </th>
      <td> {{item.name}} </td>
      <td class="text-center px-3"><a href="#" @click="removeResource(index, item)" title="delete" class="px-1" ><i class="mdi mdi-close icon-sm text-danger" ></i></a></td>
    </tr>
  </tbody>
</table>   
                      
                      
                      </div>
                    </div>
                                      
                  </div>
                </div>
              </div>
            </div>
          </div>
                
                <div class="card-body p-0">
                                                     
                   

                 
                </div>
              </div>
            </div>
          </div>

       
    
        
        </div>

        <!-- content-wrapper ends -->
        <!-- partial:partials/_footer.html -->
      <Footer />
        <!-- partial -->
      </div>
      <!-- main-panel ends -->
    </div>
    <!-- page-body-wrapper ends -->
  </div>
        
      </section>

</template>
<script>
// import Menu from '@/components/Menu.vue'; 
import Header from '@/components/Header.vue'; 
import Footer from '@/components/Footer.vue'; 
import axios from 'axios';
import Swal from 'sweetalert2';
import Loading from 'vue3-loading-overlay';
import { notify } from "@kyvg/vue3-notification";
import $ from 'jquery';





export default {
  components : {
    Loading,
    // Menu,
    Header,
    Footer,
  },
    data() {
    return {
     selectedItem : "Select user type",
     users: null,
     type: '',
     t:{},
     resource: '',
     resources:{},
     text : '',
     picked : [],
     p : {},
     id : "",
     profile: [],
     r:[],
     role:"",
     activity:{},
     isLoading:false,
     isShowed:false,
     isShowedRadio:false,

    }
  },
  computed: {
        user(){
        return this.$store.getters.get_user;
              },
        link(){
        return this.$store.getters.get_link;
              },   
    },

mounted(){
  $(document).ready(function(){
  console.log("****************jquery");
});
 this.getAllProfiles();  
 this.getAllResources();
 //this.getAllResources();
 console.log("************RESSOURCES",this.resources)
},
 
    methods: {

      getAllProfiles(){
        this.isLoading = true;
         this.$store.dispatch('getItem', {//data to be sended
      nameRoute : '/api/getprofiles',
      })    
     .then(res => {
       this.isLoading = false;
      console.log(res.data);
      this.users = res.data;
     })
     .catch(err => {
       this.isLoading = false
        this.error = "Une erreur s'est produite ! "
      }) 
      },

      getAllResources(){
        console.log('*******TEST RESOURCES');
        this.isLoading = true;
         this.$store.dispatch('getItem', {//data to be sended
      nameRoute : '/api/getallresources',
      })    
     .then(res => {
       this.isLoading = false;
      console.log(res.data);
      this.resources = res.data;
     })
     .catch(err => {
       this.isLoading = false
        this.error = "Une erreur s'est produite ! "
      }) 
      },

      displayButton(){
        console.log("test");
        this.isShowed = true;
        console.log(this.isShowed);

      },

      getOneProfile(id){
        this.isLoading = true;
         this.$store.dispatch('getItem', {//data to be sended
      nameRoute : '/api/getprofilebyid/'+id,
      })    
     .then(res => {
       this.isLoading = false;
       this.role = res.data.type_user;
      this.profile = res.data.permissions.split("");
      // console.log(this.profile.split(""));
     })
     .catch(err => {
       this.isLoading = false;
        this.error = "Une erreur s'est produite ! "
      }) 
      },




      addType(){
            if(this.type.length > 0) {
                //this.users.push(this.type)
               // console.log(this.users)
                this.$store.commit('addType ', this.type);
                this.add(this.type);
            //console.log("add")
            console.log("yes"+this.$store.state.types)
            } 
            this.type = '';
            
      },

      removeType(id, index, item){
        //this.$store.commit('removeType', index);
      this.$store.dispatch('deleteItem', {//data to be sended
      nameRoute : '/api/deleteprofile/'+id,
      })    
     .then(res => {
       this.addActivity("delete profile ", this.link+'/api/deleteprofile/id', item)
     console.log(res.data);
     })
     this.users.splice(index, 1)

      },
      addResource(){
      this.$store.dispatch('postItem', {//data to be sended
      nameRoute : '/api/addresource',
      t : this.t
      })    
     .then(res => {
      this.isLoading = false;
      this.getAllResources();
      this.addActivity("Add resource "+this.resource, "");
      this.resource = '';
     })
     .catch(err => {
       this.resource = '';
       this.isLoading = false;
       if (!err.response) this.error = "Problème réseau, Impossible de joindre le serveur" 
       else 
           
        this.error = "La page ne peut pas étre telechargée, veuillez réessayer plus tard si le probleme persiste, contacter l'admin"
        
     })
            
      },

      removeResource(index, item){
        //this.$store.commit('removeResource', index);
        //this.addActivity("Remove resource "+item, "")
        this.$store.dispatch('deleteItem', {//data to be sended
      nameRoute : '/api/removeresource/'+item.id,
      })    
     .then(res => {
       this.notification();
       this.getAllResources();
       this.addActivity("delete resource ", this.link+'/api/removeresource/id', item)
     console.log(res.data);
     })
        //this.resources.splice(index, 1)

      },

      perm(){
        var rights = new Array(this.resources.length);
        rights.fill(0);
       // console.log(this.rights.join(''));
        return rights.join('')

      },
      valider(){
        this.showMessage();

      },
      showMessage(){
      this.close;
      Swal.fire({
      title: 'Attention!',
      text: "Vous etes sur le point de modifier la permission pour ce profil",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: 'green',
      cancelButtonColor: '#d33',
      cancelButtonText: "Annuler",
      confirmButtonText: 'Continuer'
      })
      .then((result) => {
       if (result.isConfirmed) {
         this.update();
     
  }
})
    },
      addActivity(action, link){
      console.log("add activity")
      this.activity.user_id= this.user.id;
      this.activity.action= action;
      this.activity.model = "User"
      this.activity.url=this.$store.getters.get_link+link;
      this.activity.user_agent=navigator.appName+" "+navigator.appVersion ;
      this.activity.item_id = this.user.id;
      console.log("activity..........", this.activity)
        this.$store.dispatch('postItem', {//data to be sended
          nameRoute : '/api/addactivity',
          t : this.activity
          })    
        .then(res => {
          console.log(res.data);
        })
        .catch(error => {
            console.log(error)
           
        }) 

      },



    add(type){
      this.isLoading = true;
      this.p.type_user= type;
         this.p.permissions = this.perm();
         console.log(this.p.permissions)
    
     // this.isLoading = true;
      this.$store.dispatch('postItem', {//data to be sended
      nameRoute : '/api/addprofile',
      t : this.p,
      })    
     .then(res => {
      this.isLoading = false;
      this.addActivity("Add prfoile"+this.p.type_user, this.link+'/api/addprofile/'+this.user.id )
      this.getAllProfiles();
     // console.log(res.data.id);
     // console.log("reussi");
        
      

      // this.$router.push(`/p/${this.parm}`);
     })
     .catch(err => {
           this.isLoading = false;
           this.msg = "Une erreur s'est produite lors de l'ajout de ce colis. Veuillez contacter l'administrateur ! "
     
     })
 },

  manage(){
    this.isShowedRadio = true;
    console.log(this.id);
    this.getOneProfile(this.id);
  },
   notification(){
        console.log("notify")
        
      notify({ type: "success", text: "The operation completed", title: "DONE" });
      },

       notificationError(){
   console.log("notify")
   
notify({ type: "error", text: "the operation could not be completed", title: "WARNING" });
 },
 
 
  update(){
    this.isLoading = true;
    var rights = this.profile.join("");
    
    this.p.permissions = rights;

     this.$store.dispatch('postItem', {//data to be sended
      nameRoute : '/api/editP/'+this.id,
      t : this.p
      })    
     .then(res => {
       this.isLoading = false;
       this.isShowed=false;
      console.log(res.data);
      this.addActivity("Edit permissions for "+this.role, this.link+'/api/editP/'+this.id )
      this.notification();
      this.id = null;
      this.profile=[];
      //this.getOneProfile(this.id);
      // window.location.reload();
     })
     .catch(err => {
       this.isLoading = false;
       console.log(err)
       this.notificationError();
        this.error = "La page ne peut pas étre telechargée, veuillez réessayer plus tard si le probleme persiste, contacter l'admin"
        
     }) 

  }

  


     

     
      
    
    },
    

   
    
  
}
</script>
<style scoped>
.pointer {cursor: pointer;}

.home-section{
  position: relative;
  background: #E4E9F7;
  min-height: 100vh;
  top: 0;
  left: 78px;
  width: calc(100% - 78px);
  transition: all 0.5s ease;
  z-index: 2;
}
.sidebar.open ~ .home-section{
  left: 250px;
  width: calc(100% - 250px);
}
.home-section .text{
  display: inline-block;
  color: #11101d;
  font-size: 25px;
  font-weight: 500;
  margin: 0px;
}
i{
   color: #6c757d;
   font-size: 30px;


}


a:hover {
  color: rgb(93, 0, 0) !important;
  font-weight: bolder;
    font-weight: inherit;

}






</style>
import { Script } from "vm";