<template>
  <div class="row">
      <div class="">
          <p v-if="!k" class="text-center">Chargement en cours</p>
          <div  v-if="k" class="p-1" style="height:300px;width: 300px; " >
  
    <vue3-chart-js v-bind="{ ...pieChart }"    :data="data"
 />
  </div>
      </div>
  </div>

 
</template>

<script>
import Vue3ChartJs from '@j-t-mcc/vue3-chartjs'
import zoomPlugin from 'chartjs-plugin-zoom'


export default {
  name: 'App',
  components: {
    Vue3ChartJs,
  },

   setup() {
    const pieChart = {
      type: "pie",
   
    };

    return {
      pieChart,
    };
  },


   data(){
    return {
     // select : 30,
        data: {
        //labels: ['Livre', 'En cours de traitement', 'Retour apres réception', 'Non récupéré', 'Retourné', 'Perdu/Annulé'],
        datasets: [
          {
             backgroundColor: [
              '#DBE2E9',
              '#80A1A6',
              '#7BB2DD',
              '#4B68B8',
              '#002F6C',
              '#424B52'
            ],
            data: [],
          },
        ],
      },

      statut : "",
      t:{},
      show:false,
      k:false,
      date:[],
      p:[],
      count: [],
      r:[],
 


    }
  },
  props: {
    entred_data:{} ,
  },

  beforeMount(){


    this.t= this.entred_data;

  this.$store.dispatch('postItem', {//data to be sended
  nameRoute : '/api/chart2',
  t : this.t
      })    
     .then(res => {
            this.r = res.data[0];
            this.r = Object.values(this.r)
            var s = this.r.shift();
            this.count = this.r.slice(1).map(x => this.percentage(x,s))
            this.showDonut();

            //this.getAllAxis();


     })
     .catch(err => {
          // this.isLoading = false;
          this.error = "Une erreur s'est produite ! "

   })

 },


 methods : {
     percentage(partialValue, totalValue) {
            if(totalValue == 0) return 0;
            return ((100 * partialValue) / totalValue).toFixed(2);
            },
    showDonut(){
            this.data.datasets[0].data = this.count
            this.k = true;
    }
 }

}
</script>
