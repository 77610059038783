<template>

  <p class="text-center" v-if="!k">
    Chargement en cours ...
  </p>
  <div class="chart ">
    <vue3-chart-js v-if="k" v-bind="{ ...lineChart }" type= "line" :data="data"/>
  </div>
</template>

<script>
import Vue3ChartJs from "@j-t-mcc/vue3-chartjs";
import zoomPlugin from "chartjs-plugin-zoom";
import dataLabels from "chartjs-plugin-datalabels";
import {toRefs} from "vue"


// globally registered and available for all charts
Vue3ChartJs.registerGlobalPlugins([zoomPlugin]);

export default {
  components: {
    Vue3ChartJs,
  },
  data(){
    return {
     // select : 30,
      statut : "",
      t:{},
      k:false,
      date:[],
      p:[],
      count: [],
      count2: [],
      r:{},
      test :  [65, 59, 80, 81, 56, 55, 40],
        data: {
        labels: [
        ],
        datasets: [
          {
            label: "Colis livrés",
            data:  [],
            fill: false,
            borderColor: "#41B883",
            backgroundColor: "black",
          },
          {
            label: "Colis retourné",
            data: [],
            fill: false,
            borderColor: "#00D8FF",
            tension: 0.5,
            backgroundColor: "blue",
          },
        ],
      },


    }
  },
    props: {
    entred_data:{} ,
  },
  
  setup(context) {
    //console.log("date setup  "+toRefs(context.test))
    const lineChart = {
      
      // locally registered and available for this chart
      plugins: [dataLabels],
    
      options: {
        plugins: {
          zoom: {
            zoom: {
              wheel: {
                enabled: true,
              },
              pinch: {
                enabled: true,
              },
              mode: "y",
            },
          },
          datalabels: {
            backgroundColor: function (context) {
              return context.dataset.backgroundColor;
            },
            borderRadius: 4,
            color: "white",
            font: {
              weight: "bold",
            },
            formatter: Math.round,
            padding: 6,
          },
        },
      },
    };
          //console.log(lineChart)


    return {
      lineChart,
            

    };
  },
  
  created(){
    //
  
  },
   beforeMount(){


    this.t= this.entred_data;

  this.$store.dispatch('postItem', {//data to be sended
  nameRoute : '/api/chart',
  t : this.t
      })    
     .then(res => {
            this.r = res.data;
            console.log(this.r); 
            this.getAllAxis();


     })
     .catch(err => {
          // this.isLoading = false;
          console.log(err)
          this.error = "Une erreur s'est produite ! "

   })

 },

  mounted(){
    //
      //  console.log("p:", this.p)



  },

  methods : {
    getAllAxis(){
     var res = this.r;

      for(var i in res)
    {
      this.date.push(res[i].date);
      this.count.push(res[i].livre);
      this.count2.push(res[i].retourne)
    
    } 
    this.data.labels = this.date;
    this.data.datasets[0].data = this.count,
    this.data.datasets[1].data = this.count2,
    this.k = true;


    },
   

  
    

  }
};
</script>
