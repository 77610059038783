<template>

      <section class="home-section" >
         
         <div class="container-scroller" >
    <!-- partial:partials/_navbar.html -->
    <Header title="Ajout colis" />
    <!-- partial -->
    <div class="container-fluid page-body-wrapper bg-light"  >
      <!-- partial:partials/_sidebar.html -->
   
    
      <!-- partial -->
      <div class="main-panel" style="width : 100%;">
        <!---content wrapper start--->
              
    <div class="content-wrapper">
                  
                  <div class="row">
            <div class="col-md-12 grid-margin">
              <div class="d-flex justify-content-between flex-wrap bg-light">
                <div class="d-flex align-items-end flex-wrap">
                
               
               <div class="mt-3" >
                      <p class=" d-flex justify-content-center align-self-center " ><i class="mdi mdi-map-marker icon-md mr-1"></i> 
                         <span class=" py-2 " >Modification point relais</span>
                       </p>
                    </div>


                </div>
               
              </div>
            </div>
          </div>
          <div class="row">
            

 
                  <form class="forms-sample" @submit.prevent="handlesubmit" style="width : 100%;">
              <div class="col-12 grid-margin stretch-card">
              <div class="card">
                <div class="card-body">
                  <h4 class="card-title"> Informations :  </h4>
                                                           <loading  class="d-inline" v-if="isLoading" :active="true" :width="20" :height="20" color="blue"></loading>



                <div class="row">
                      <div class="form-group col-lg-6">
                      <label for="exampleInputUsername1">Nom</label>
                      <input type="text" class="form-control" id="exampleInputEmail2" placeholder="nom" v-model="t.nom" >

                    </div>
                    <div class="form-group col-lg-6">
                      <label for="exampleInputUsername1">Raison sociale</label>
                      <input type="text" class="form-control" id="exampleInputEmail2" placeholder="raison sociale" v-model="t.raison_sociale" >
                    </div>
                    </div>

                     <div class="row">
                      <div class="form-group col-lg-6">
                      <label for="exampleInputUsername1">Responsable</label>
                    <input type="text" class="form-control" id="exampleInputEmail2" placeholder="responsable" v-model="t.nom_responsable" >

                    </div>
                     <div class="form-group col-lg-6">
                      <label for="exampleInputUsername1">RC</label>
                    <input type="text" class="form-control" id="exampleInputEmail2" placeholder="RC" maxlength="8" v-model="t.rc" >
                    </div> 
                    </div>


                    
                     <div class="row">
                       <div class="form-group col-lg-4">
                      <label for="exampleInputEmail1">Adresse</label>
                  <textarea class="form-control" id="exampleFormControlTextarea1" rows="2" placeholder="adresse" v-model="t.adresse" ></textarea>
                    </div>

                      <div class="form-group col-lg-4">
                      <label for="exampleInputUsername1">Ville</label>
 <select class="form-control form-select form-select-md " aria-label="Default select example" v-model="t.ville_id"   @change="changer">
                     <option v-for="item in villes" :value="item.id" :key="item.id" >
      {{ item.city }}
    </option>
                      </select>
                    </div>
                      <div class="form-group col-lg-4">
                      <label for="exampleInputUsername1">Prix </label>
        <input type="number" class="form-control" id="exampleInputEmail2" placeholder="Prix relais " required step="O.01" v-model="t.prix" >

                    </div>
                  <!--   <div class="form-group col-lg-4">
                      <label for="exampleInputUsername1">Pays</label>
              <input type="text" class="form-control" id="exampleInputEmail2" placeholder="pays" v-model="t.pays" required>
                    </div> -->
                    </div> 

                       <div class="row">
                      <div class="form-group col-lg-4">
                      <label for="exampleInputUsername1">Mobile</label>
 <input type="text" v-on:keypress="isNumber(event)"  class="form-control" id="exampleInputEmail2" autocomplete="off" placeholder="ex : 06xxxxxxxx" :minlength="10" :maxlength = "10" v-model="t.mobile" >

                    </div>
                    <div class="form-group col-lg-4">
                      <label for="exampleInputUsername1">Telephone</label>
 <input type="text" v-on:keypress="isNumber(event)"  class="form-control" id="exampleInputEmail2" autocomplete="off" placeholder="ex : 05xxxxxxxx" :minlength="10" :maxlength = "10" v-model="t.telephone" >
                    </div>
                     <div class="form-group col-lg-4">
                      <label for="exampleInputUsername1">Fax</label>
 <input type="text" v-on:keypress="isNumber(event)"  class="form-control" id="exampleInputEmail2" autocomplete="off" placeholder="ex : 05xxxxxxxx" :minlength="10" :maxlength = "10" v-model="t.fax" >
                    </div>
                    </div>

                    <div class="form-group">
                      <label for="exampleInputEmail1">Site web</label>
                  <textarea class="form-control" id="exampleFormControlTextarea1" rows="2" placeholder="ex: www.jiblili.ma" v-model="t.site_web" ></textarea>
                    </div> 

                      <div class="row">
                      <div class="form-group col-lg-4">
                      <label for="exampleInputUsername1">Montant max</label>
<input type="number" class="form-control" id="exampleInputEmail2" placeholder="Montant en Dirhams" step="0.01" v-model="t.montant_max" >
                    </div>
                    <div class="form-group col-lg-4">
                      <label for="exampleInputUsername1">Nombre colis</label>
 <input type="number" class="form-control" id="exampleInputEmail2" placeholder="Nombre de colis maximum" step="1" v-model="t.nbr_colis_max" >                    </div>
                     <div class="form-group col-lg-4">
                      <label for="exampleInputUsername1">Volume max</label>

              <input type="number" class="form-control" id="exampleInputEmail2" placeholder="Volume maximum" step="0.01" v-model="t.volume_max" >                    </div>
                    </div>


                </div>
              </div>
            </div>
   
            <!---------------------------->

<!--              <div class="col-12 grid-margin stretch-card" v-if="t.is_actived == 1 && user!=null &&user.is_archived ==0">
              <div class="card">
                               <div class="card-body" >
                  <h4 class="card-title">Désactivation du compte</h4>
                  

                    <div class="row" style="margin-left : 4%;">

                      <div class="col-lg-6">
                                         <div class="form-check">
  <input class="form-check-input" type="checkbox" value="1" id="flexCheckDefault" v-model="t.user_archived">
  <label class="form-check-label" for="flexCheckDefault">
    Désactiver le compte de ce chauffeur
  </label>
</div>
                         
                   
                  
                     
                      </div>
                   
                    </div>
                    <div class="row" >
                <p style="margin-left : 5.5%; font-style: italic;color: darkred; font-weight:bolder; " class="">
                     Une fois désactivé, le vendeur ne pourra plus accéder à l'ensemble de ses activités sur son compte.
                    </p>


                    <div class="col-12">
                       <div class="form-group row" style="margin-left : 4%;">
                      <label for="exampleInputUsername2" class="col-lg-2 col-form-label" >Email : </label>
                      <div class="col-lg-6">
                            <div class="form-group">
              <input type="email" disabled class="form-control" id="exampleInputEmail2" placeholder="email" v-model="user.email" >
              

                      </div>

                      </div>
                    </div>
  
                      </div>
                     
                  </div>

   

           
              
                </div>
                
              </div>
            </div> -->
            <!------------------------------->
         <!--       <div class="col-12 grid-margin stretch-card" v-if="user!=null && user.is_archived == 1">
              <div class="card">
                               <div class="card-body" >
                  <h4 class="card-title">Information du compte</h4>
                  

                    <div class="row" style="margin-left : 4%;">

                      <div class="col-lg-6">
                                         <div class="form-check">
  <input class="form-check-input" type="checkbox" value="1" id="flexCheckDefault" checked disabled>
  <label class="form-check-label" for="flexCheckDefault">
    Désactivé
  </label>
</div>
                         
                   
                  
                     
                      </div>
                   
                    </div>
                    <div class="row" >
                

                    <div class="col-12">
                  
  
                      </div>
                     
                  </div>

   

                 
              
                </div>
                
              </div>
            </div> -->
            <div class="col-12 grid-margin stretch-card">
              <div class="card">
                <div class="card-body">
                  <h4 class="card-title"> Horaires :  </h4>
                  <div v-if="countHour === 0" id="notHoraireRelay"> 
                  <div class="row " style="margin-top : 1%;">
                       <div class="col-12"> 
                               <div  class="alert alert-primary text-center" role="alert">
                       Aucun horaire n'est associé à ce point relais
                </div>
                       </div>
                     </div>
                     <div class="d-flex flex-row-reverse">
                        <div class="p-2">
                          <button type="button" class="btn btn-primary" @click="addHoraire">
                            <i class="mdi mdi-plus icon-md mr-1"></i>
                            Ajouter horaire
                          </button>
                        </div>
                      </div>
                </div>
                   <div v-if="countHour > 0">
                  <table class="table w-100 bg-white">
                    <tr v-for="(item, index) in hours" :key="item.id" v-bind:index="index" :id="item.name">
                      <td scope="row" class="text-capitalize">{{item.jour}}</td>
                      <td>
                        <div class="float-end">
                          <div class="custom-control custom-switch" >
                            <input type="checkbox" class="custom-control-input" :id="'switchMorning'+index"  v-model="item.off_matin" @change="off_matinChange" >
                            <label class="custom-control-label" :for="'switchMorning'+index" >
                              <span v-if="item.off_matin">ON</span>
                              <span v-else>OFF</span>
                            </label>
                          </div>

                        </div>
                      </td>
                      <td v-if="item.off_matin">
                        <input type="time" class="form-control" id="exampleInputUsername2" v-model="item.h_ouverture_m">
                      </td>
                      <td v-else>
                        
                      </td>
                      <td v-if="item.off_matin">
                        <input type="time" class="form-control" id="exampleInputUsername2" v-model="item.h_fermeture_m">
                      </td>
                      <td v-else>
                        
                      </td>
                      <td>
                        <div class="float-end">
                          <div class="custom-control custom-switch">
                            <input type="checkbox" class="custom-control-input" :id="'switchAfternoon'+index"  v-model="item.off_soir" >
                            <label class="custom-control-label" :for="'switchAfternoon'+index" >
                              <span v-if="item.off_soir == true">ON</span>
                              <span v-else>OFF</span>
                            </label>
                          </div>
                        </div>
                      </td>
                      <td v-if="item.off_soir">
                        <input type="time" class="form-control" id="exampleInputUsername2" v-model="item.h_ouverture_s">
                      </td>
                      <td v-else>
                      </td>
                      <td v-if="item.off_soir">
                        <input type="time" class="form-control" id="exampleInputUsername2" v-model="item.h_fermeture_s">
                      </td>
                      <td v-else>
                      </td>
                      

                    </tr>
                  </table>
                </div>
               

                  
                  
                </div>
              </div>
              </div>

              <div class="col-12 grid-margin stretch-card">
              <div class="card border-0">
                <div class="card-body">
                  <div class="row " style="margin-top : 1%;">
                       <div class="col-12"> 
                               <div  v-if="error" class="alert alert-danger text-center" role="alert">
                       {{error}}
                </div>
                       </div>
                     </div>
              <div class="row">
                     <div class="col-lg-3">

                     </div>
                     <div class="col-lg-3" >
                                           <button :disabled="isLoading" @click="showMessage()" class="btn btn-success mr-2" style="width : 100%;">
                                             <span v-if="!isLoading" >Valider</span>
                          <loading v-if="isLoading" :active="true" :width="10" :height="10" color="white"></loading>
                        </button>
                


                     </div>
                     <div class="col-lg-3">
                                           <button type="button" class="btn btn-secondary" style="width : 100%;" @click="back()">Retour</button>

                     </div>
                   </div>
                </div>
                </div>
                </div>

            


                   

   
                 
          </form>
 
         <!----------------------------------------->

 

    
          </div>
        </div>  

        <!-- content-wrapper ends -->
        <!-- partial:partials/_footer.html -->
      <Footer />
        <!-- partial -->
      </div>
      <!-- main-panel ends -->
    </div>
    <!-- page-body-wrapper ends -->
  </div>
        
      </section>


</template>

<script>
import axios from "axios"
import Swal from 'sweetalert2';
// import Menu from '@/components/Menu.vue'; 
import Header from '@/components/Header.vue'; 
import Footer from '@/components/Footer.vue';  
import Loading from 'vue3-loading-overlay';
import { notify } from "@kyvg/vue3-notification";



export default {
   props: {
    idt: Number,
  },

  components : {
      // Menu,
      Header,
      Footer,
      Loading
  },
    data(){
        return{
            test : true,
           // idt : 8,
            t : {},
            user : {},
            error : "",
            isLoading: false,
            ville : {},
            villes : {},
            activity:{},
            countHour:'',
            hours:{},

        }
    },
    created(){
       console.log('created')
      //this.isLoading = true;
     this.$store.dispatch('getItem', {//data to be sended
      nameRoute : '/api/getallcities',
      })    
     .then(res => {
      console.log('succes')
       this.isLoading = false;
      this.villes = res.data;
     })
     .catch(err => {
      console.log('error')
       this.isLoading = false;
        if (!err.response) this.error = "Problème réseau, Impossible de joindre le serveur" 
       else this.error = "Une erreur s'est produite ! "
      })   
  },
    
     mounted(){
       this.t =JSON.parse(this.$route.params.data)
       //console.log(this.t.id);
       this.afficherHorraires(this.t.id);

       this.ville = this.t.ville;
       this.user = this.t.user
       //console.log(this.user)

       
             },
    computed :{
          user_id(){
           // console.log(this.$store.getters.get_user)
        return this.$store.getters.get_user;
                    },
               },

  methods: {
    afficherHorraires(id){
      console.log('****************');
         this.$store.dispatch('getItem', {//data to be sended
      nameRoute : '/api/gethours/'+id,
      })
      .then( res => {
        var data  = res.data;
        for (var key in data) {
     if (data[key].off_matin == 1) data[key].off_matin=true;
     if (data[key].off_soir == 1) data[key].off_soir=true;
} 
        this.hours = res.data
        console.log(this.hours)
        this.countHour = res.data.length;
      })   
      .catch(err => {
        this.errorHour = "Une erreur s'est produite lors du chargement des horraires! "
      })

  },
    back(){
    // $('#myform').trigger("reset");
    //document.getElementsByClassName("forms-sample").reset();
    this.$router.go(-1);
  },
         addActivity(id){
   this.activity.user_id= this.user_id.id;
   this.activity.action= "Edit Relay";
   this.activity.model = "Relay"
   this.activity.url=this.$store.getters.get_link+'/api/editR/'+id;
   this.activity.user_agent=navigator.appName+" "+navigator.appVersion ;
   this.activity.item_id = id;
   //console.log("activity..........", this.activity)
     this.$store.dispatch('postItem', {//data to be sended
      nameRoute : '/api/addactivity',
      t : this.activity
      })    
     .then(res => {
      //console.log(res.data);
     })
     .catch(error => {
        //console.log(error)     
     }) 

 },
      edit(){
        this.error = '';
        this.isLoading = true;
  //  this.t.ville_id = this.ville.city;
      this.$store.dispatch('postItem', {//data to be sended
      nameRoute : '/api/editR/'+this.t.id,
      t : this.t
      })    
     .then(res => {
       this.isLoading = false;
      //console.log(res.data);
      this.editHoraire();
      this.notification();
      this.$router.push("/relay");

      this.addActivity(this.t.id);
     })
     .catch(err => {
       this.isLoading = false;
       this.notificationError();
          if (!err.response) this.error = "Problème réseau, Impossible de joindre le serveur" 
       else
        this.error = "La page ne peut pas étre telechargée, veuillez réessayer plus tard si le probleme persiste, contacter l'admin"
        
     })
      },
  notification(){
       // console.log("notify")
        
      notify({ type: "success", text: "The operation completed", title: "DONE" });
      },

       notificationError(){
       // console.log("notify")
        notify({ type: "error", text: "the operation could not be completed", title: "WARNING" });
     },

 showMessage(){
      Swal.fire({
      title: 'Attention!',
      text: "Etes vous sur de vouloir modifier ce point relais?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: 'green',
      cancelButtonColor: '#d33',
      cancelButtonText: "Annuler",
      confirmButtonText: 'Ok'
      })
      .then((result) => {
       if (result.isConfirmed) {
         this.edit();
     
  }
})
    },
    addHoraire(){
      this.$router.push({ name: 'planning', params: {id:this.t.id}})
    },
    editHoraire(){
      this.error = '';
      this.isLoading = true;
      
      this.t.option = this.hours;
      //this.t.id = 55;
       this.$store.dispatch('postItem', {//data to be sended
      nameRoute : '/api/edithours',
      t : this.t
      })    
     .then(res => {
      this.isLoading = false;
      this.notification();
       this.$router.push("/relay");


      console.log("***********************",res.data);
     })
     .catch(error => {
       console.log(error);
       this.isLoading = false;
       
       if(!error.response) this.error = "Problèeme réseau, Impossible de joindre le serveur"
       else this.error = "Une erreur s'est produite"
       this.notificationError();
       //



          
     })
    },
    off_matinChange(item){
      console.log(!!item)

    },

    
  }

}
</script>

<style>

</style>