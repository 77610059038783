<template>

      <section class="home-section" >
         
         <div class="container-scroller" >
    <!-- partial:partials/_navbar.html -->
    <Header title="Ajout colis" />
    <!-- partial -->
    <div class="container-fluid page-body-wrapper bg-light"  >
      <!-- partial:partials/_sidebar.html -->
   
    
      <!-- partial -->
      <div class="main-panel" style="width : 100%;">
        <!---content wrapper start--->
              
    <div class="content-wrapper">
                  
                  <div class="row">
            <div class="col-md-12 grid-margin">
              <div class="d-flex justify-content-between flex-wrap">
                <div class="d-flex align-items-end flex-wrap">
                
               
                <div class="mt-3">
                  <a href="#" class="link-secondary text-dark link-hover"><u>Chauffeur  </u> </a> >
                   <a href="#" class="auth-link text-dark"><u>Chercher chauffeur</u> </a> >
                    <a href="#" class="auth-link text-dark"><u>modifier</u> </a> 

                    
           </div>


                </div>
               
              </div>
            </div>
          </div>
          <!-- <div class="row">
            

 
                  <form class="forms-sample" @submit.prevent="handlesubmit" style="width : 100%;">
              <div class="col-12 grid-margin stretch-card">
              <div class="card">
                <div class="card-body"> -->
                  <!-- <div class="container px-1 px-md-4 py-5 mx-auto">
    <div class="card">
        <div class="row d-flex justify-content-between px-3 top">
            <div class="d-flex">
                <h5>ORDER <span class="text-primary font-weight-bold">#Y34XDHR</span></h5>
            </div>
            <div class="d-flex flex-column text-sm-right">
                <p class="mb-0">Expected Arrival <span>01/12/19</span></p>
                <p>USPS <span class="font-weight-bold">234094567242423422898</span></p>
            </div>
        </div>  Add class 'active' to progress 
        <div class="row d-flex justify-content-center">
            <div class="col-12">
                <ul id="progressbar" class="text-center">
                    <li class="active step0"></li>
                    <li class="active step0"></li>
                    <li class="active step0"></li>
                    <li class="step0"></li>
                </ul>
            </div>
        </div>
        <div class="row justify-content-between top">
            <div class="row d-flex icon-content"> <img class="icon" src="https://i.imgur.com/9nnc9Et.png">
                <div class="d-flex flex-column">
                    <p class="font-weight-bold">Order<br>Processed</p>
                </div>
            </div>
            <div class="row d-flex icon-content"> <img class="icon" src="https://i.imgur.com/u1AzR7w.png">
                <div class="d-flex flex-column">
                    <p class="font-weight-bold">Order<br>Shipped</p>
                </div>
            </div>
            <div class="row d-flex icon-content"> <img class="icon" src="https://i.imgur.com/TkPm63y.png">
                <div class="d-flex flex-column">
                    <p class="font-weight-bold">Order<br>En Route</p>
                </div>
            </div>
            <div class="row d-flex icon-content"> <img class="icon" src="https://i.imgur.com/HdsziHP.png">
                <div class="d-flex flex-column">
                    <p class="font-weight-bold">Order<br>Arrived</p>
                </div>
            </div>
        </div>
    </div>
</div> -->


                <!-- </div>
              </div>
            </div>
          



   
                 
          </form>
 

 

    
          </div> -->
          <div class="container  mb-1 ">
        <div class="card mb-3 ">
          <div class="p-4 text-center font-weight-bold  text-lg bg-light rounded-top text-primary" ><span class="text-uppercase">Tracking Order No - {{shipment.ref}} </span><span class="text-medium"> {{$route.query.id}}</span></div>
          <div class="d-flex flex-wrap flex-sm-nowrap justify-content-between py-3 px-2 bg-secondary">
            <div class="w-100 text-center py-1 px-2"><span class="text-medium">Shipped Via:</span> {{warehouse.nom}}</div>
            <div class="w-100 text-center py-1 px-2"><span class="text-medium">Status:</span> Checking Quality</div>
            <div class="w-100 text-center py-1 px-2"><span class="text-medium">Expired Date:</span > <span v-if="shipment.date_expiration" >{{format_date2(shipment.date_expiration)}}</span> <span v-else>****</span></div>
          </div>
          <div class="card-body">
            <div class="steps d-flex flex-wrap flex-sm-nowrap justify-content-between padding-top-2x padding-bottom-1x">
               <!--  [isActive ? activeClass : '', errorClass] -->
              <div v-bind:class="[statuts.is_active1 ?  'completed' :'', 'step'] ">
                <div class="step-icon-wrap">
                  <div  class="step-icon"><i v-if="statuts.is_active1" class="mdi mdi-check"></i></div>
                </div>
                <h4 class="step-title">En préparation chez le vendeur</h4>
              </div>
              <div v-bind:class="[statuts.is_active2 ?  'completed' :'', 'step'] ">
                <div class="step-icon-wrap">
                  <div class="step-icon"><i v-if="statuts.is_active2" class="mdi mdi-check"></i></div>
                </div>
                <h4 class="step-title">Pris en charge par Jiblili</h4>
              </div>
              <!-- <div class="step completed"> -->
                  <div v-bind:class="[statuts.is_active3 ?  'completed' :'', 'step'] ">
                <div class="step-icon-wrap">
                  <div class="step-icon"><i v-if="statuts.is_active3" class="mdi mdi-check"></i></div>
                </div>
                <h4 class="step-title">En cours de distribution</h4>
              </div>
              <div v-bind:class="[statuts.is_active4 ?  'completed' :'', 'step'] ">
                <div class="step-icon-wrap" >
                  <div class="step-icon"><i v-if="statuts.is_active4" class="mdi mdi-check"></i></div>
                </div>
                <h4 class="step-title">Disponible au point relais</h4>
              </div>
              <div v-bind:class="[statuts.is_active9 ?  'completed' :'', 'step'] " v-show="shipment.type_expedition == 'domicile'">
                <div class="step-icon-wrap" >
                  <div class="step-icon"><i v-if="statuts.is_active9" class="mdi mdi-check"></i></div>
                </div>
                <h4 class="step-title">Livraison en cours</h4>
              </div>
               <div class="step completed" v-if="statuts.is_active6">
                <div class="step-icon-wrap">
                  <div class="step-icon"><i class="mdi mdi-check"></i></div>
                </div>
                <h4 class="step-title">Non récupéré</h4>
              </div>
              <div v-bind:class="[statuts.is_active5 ?  'completed' :'', 'step'] " v-if=" (!statuts.is_active10) ">
                <div class="step-icon-wrap">
                  <div class="step-icon"><i v-if="statuts.is_active5" class="mdi mdi-check"></i></div>
                </div>
                <h4 class="step-title">Livré</h4>
              </div>

              <div class="step completed" v-if="statuts.is_active7" >
                <div class="step-icon-wrap">
                  <div class="step-icon"><i class="mdi mdi-check"></i></div>
                </div>
                <h4 class="step-title">Réception retour client</h4>
              </div>
              <div class="step completed" v-if="statuts.is_active10" >
                <div class="step-icon-wrap">
                  <div class="step-icon"><i class="mdi mdi-check"></i></div>
                </div>
                <h4 class="step-title">Refusé par le client</h4>
              </div>
             
              <div class="step completed" v-if="statuts.is_active8">
                <div class="step-icon-wrap">
                  <div class="step-icon"><i class="mdi mdi-check"></i></div>
                </div>
                <h4 class="step-title">Retourné</h4>
              </div>

            </div>
          </div>
        </div>
        <div class="d-flex flex-wrap flex-md-nowrap justify-content-center justify-content-sm-between align-items-center">
          <div class="custom-control custom-checkbox mr-3">
            <!-- <input class="custom-control-input" type="checkbox" id="notify_me" checked="">
            <label class="custom-control-label" for="notify_me">Notify me when order is delivered</label> -->
          </div> 
          <div class="text-left text-sm-right"><a class="btn btn-outline-primary btn-rounded btn-sm" href="#" @click="details=true" data-toggle="modal" data-target="#orderDetails">View Order Details</a></div>
        </div>
        <!-- <button @click="tester">test</button> -->
      </div>


      <div class="row" v-if="details">
            

 
              <div class="col-12 grid-margin stretch-card">
              <div class="card">
                <div class="card-body">
                            <table class="table">
  
  <tbody>
    <tr class="bg-primary text-white">
      <th scope=" col">Statut</th>
      <td class="text-center font-weight-bold ">Type</td>
      <td class="text-center font-weight-bold">Date</td>
      <td class="text-center font-weight-bold">Chauffeur</td>
    </tr>
    
    
     <tr  v-for="(item, index) in logs"  v-bind:key="index">
      <th scope=" col" > {{item.statut}} </th>
    
       <td class="text-center">
           {{item.type}}
        
      </td> <td class="text-center">
          {{format_date(item.date)}}
       
      </td>
      <td class="text-center text-capitalize" v-if="item.driver_id">
          {{item.driver.nom}} {{item.driver.prenom}}
       
      </td>
      <td class="text-center" v-else>
          ---
       
      </td>
    </tr>
  </tbody>
</table>
                </div>
              </div>
              </div>
      </div>
        </div>  

        <!-- content-wrapper ends -->
        <!-- partial:partials/_footer.html -->
      <Footer />
        <!-- partial -->
      </div>
      <!-- main-panel ends -->
    </div>
    <!-- page-body-wrapper ends -->
  </div>
        
      </section>


</template>

<script>
import axios from "axios"
import Swal from 'sweetalert2';
import Header from '@/components/Header.vue'; 
import Footer from '@/components/Footer.vue';  
import moment from 'moment';

// import Loading from 'vue3-loading-overlay';


export default {
   props: {
    idt: Number,
  },

  components : {
      Header,
      Footer,
    //   Loading
  },
    data(){
        return{
            test : "oui",
           // idt : 8,
            t : {},
            user : {},
            error : "",
            isLoading: false,
            villes:[],
            shipment :{},
      warehouse : {},
      client : {},
      seller : {},
       relay : {},
       logs : [],
       details : false,
       statuts : new Array(10),

        }
    },
    created(){
    //  this.statusVerification()
    this.statuts.fill(false);
    
  },
     mounted(){
         this.$store.dispatch('getItem', {//data to be sended
      nameRoute : '/api/getshipment/'+this.$route.query.id,
      // nameRoute : '/api/getshipment/1',
      })    
     .then(res => {
         console.log(res.data)
        this.shipment = res.data;
         this.client = res.data["client"];
         this.relay = res.data["relay"];
         this.warehouse = res.data["warehouse"];
         this.logs = res.data["logs"];
         this.statusVerification();
     })
      
       

   },

  methods: {
    tester(){
       this.$store.dispatch('getItem', {//data to be sended
      nameRoute : '/api/getshipment/'+this.$route.params.id,
      // nameRoute : '/api/getshipment/1',
      })    
     .then(res => {
         console.log(res.data)
        this.shipment = res.data;
         this.client = res.data["client"];
         this.relay = res.data["relay"];
         this.warehouse = res.data["warehouse"];
         this.logs = res.data["logs"];
         this.statusVerification();
     })
    },
      format_date(value){
         if (value) {
           return moment(String(value)).format('DD-MM-YYYY hh:mm')
          }
      },
      format_date2(value){
         if (value) {
           return moment(String(value)).format('DD-MM-YYYY')
          }
      },
      edit(){
      this.$store.dispatch('postItem', {//data to be sended
      nameRoute : '/api/editS/'+this.t.id,
      t : this.t
      })    
     .then(res => {
      console.log(res.data);
      this.$router.push("/seller");
     })
     .catch(err => {
          if(err == 401) this.error = "Le mail ou le mot de passe est incorrect";
        else
        if (err == 500) this.error = "Une erreur s'est produite lors de la connexion au serveur, veuillez ressayer plus tard";
        else
        this.error = "La page ne peut pas étre telechargée, veuillez réessayer plus tard si le probleme persiste, contacter l'admin"
        
     })
      },
      statusVerification(){
          console.log("verification des status")
          for(let i in this.logs)
          {
              var statut = this.logs[i]["statut"];
              if( statut == "En cours de preparation") this.statuts.is_active1=true;
              if( statut == "Arrive au centre de tri") this.statuts.is_active2=true;
              if( statut == "En cours de distribution") this.statuts.is_active3=true;
              if( statut == "Disponible au point relais") this.statuts.is_active4=true;
              if( statut == "Livraison en cours") this.statuts.is_active9=true;
              if( statut == "Livre") this.statuts.is_active5=true;
              if( statut == "Non recupere") this.statuts.is_active6=true;
              if( statut == "Reception retour client") this.statuts.is_active7=true;
              if( statut == "Refuse par le client") this.statuts.is_active10=true;
              if( statut == "Retourne") this.statuts.is_active8=true;
              }
          
      },

  

 showMessage(){
      Swal.fire({
      title: 'Attention!',
      text: "Etes vous sur de vouloir modifier ce vendeur?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: 'green',
      cancelButtonColor: '#d33',
      cancelButtonText: "Annuler",
      confirmButtonText: 'Ok'
      })
      .then((result) => {
       if (result.isConfirmed) {
         this.edit();
     
  }
})
    },
  }

}
</script>

<style scoped lang="scss">
.card {
    z-index: 0;
    background-color: #ECEFF1;
    padding-bottom: 20px;
    margin-top: 90px;
    margin-bottom: 90px;
    border-radius: 10px
}

.top {
    padding-top: 40px;
    padding-left: 13% !important;
    padding-right: 13% !important
}

#progressbar {
    margin-bottom: 30px;
    overflow: hidden;
    color: #455A64;
    padding-left: 0px;
    margin-top: 30px
}

#progressbar li {
    list-style-type: none;
    font-size: 13px;
    width: 25%;
    float: left;
    position: relative;
    font-weight: 400
}

#progressbar .step0:before {
    font-family: FontAwesome;
    content: "\f10c";
    color: #fff
}

#progressbar li:before {
    width: 40px;
    height: 40px;
    line-height: 45px;
    display: block;
    font-size: 20px;
    background: #C5CAE9;
    border-radius: 50%;
    margin: auto;
    padding: 0px
}

#progressbar li:after {
    content: '';
    width: 100%;
    height: 12px;
    background: #C5CAE9;
    position: absolute;
    left: 0;
    top: 16px;
    z-index: -1
}

#progressbar li:last-child:after {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    position: absolute;
    left: -50%
}

#progressbar li:nth-child(2):after,
#progressbar li:nth-child(3):after {
    left: -50%
}

#progressbar li:first-child:after {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    position: absolute;
    left: 50%
}

#progressbar li:last-child:after {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px
}

#progressbar li:first-child:after {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px
}

#progressbar li.active:before,
#progressbar li.active:after {
    background: #651FFF
}

#progressbar li.active:before {
    font-family: FontAwesome;
    content: "\f00c"
}

.icon {
    width: 60px;
    height: 60px;
    margin-right: 15px
}

.icon-content {
    padding-bottom: 20px
}

@media screen and (max-width: 992px) {
    .icon-content {
        width: 50%
    }
}

/* test */
.steps .step {
    display: block;
    width: 100%;
    margin-bottom: 35px;
    text-align: center
}

.steps .step .step-icon-wrap {
    display: block;
    position: relative;
    width: 100%;
    height: 80px;
    text-align: center
}

.steps .step .step-icon-wrap::before,
.steps .step .step-icon-wrap::after {
    display: block;
    position: absolute;
    top: 50%;
    width: 50%;
    height: 3px;
    margin-top: -1px;
    background-color: #e1e7ec;
    content: '';
    z-index: 1
}

.steps .step .step-icon-wrap::before {
    left: 0
}

.steps .step .step-icon-wrap::after {
    right: 0
}

.steps .step .step-icon {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
    border: 1px solid #e1e7ec;
    border-radius: 50%;
    background-color: #f5f5f5;
    color: #374250;
    font-size: 38px;
    line-height: 81px;
    z-index: 5
}

.steps .step .step-title {
    margin-top: 16px;
    margin-bottom: 0;
    color: #606975;
    font-size: 14px;
    font-weight: 500
}

.steps .step:first-child .step-icon-wrap::before {
    display: none
}

.steps .step:last-child .step-icon-wrap::after {
    display: none
}

.steps .step.completed .step-icon-wrap::before,
.steps .step.completed .step-icon-wrap::after {
    background-color: #0da9ef
}

.steps .step.completed .step-icon {
    border-color: #0da9ef;
    background-color: #0da9ef;
    color: #fff
}

@media (max-width: 576px) {
    .flex-sm-nowrap .step .step-icon-wrap::before,
    .flex-sm-nowrap .step .step-icon-wrap::after {
        display: none
    }
}

@media (max-width: 768px) {
    .flex-md-nowrap .step .step-icon-wrap::before,
    .flex-md-nowrap .step .step-icon-wrap::after {
        display: none
    }
}

@media (max-width: 991px) {
    .flex-lg-nowrap .step .step-icon-wrap::before,
    .flex-lg-nowrap .step .step-icon-wrap::after {
        display: none
    }
}

@media (max-width: 1200px) {
    .flex-xl-nowrap .step .step-icon-wrap::before,
    .flex-xl-nowrap .step .step-icon-wrap::after {
        display: none
    }
}

.bg-faded, .bg-secondary {
    background-color: #f5f5f5 !important;
}

</style>