<template>
      <section class="home-section" >
         
         <div class="container-scroller" >
    <!-- partial:partials/_navbar.html -->
    <Header />
    <!-- partial -->
    <div class="container-fluid page-body-wrapper"  >
      <!-- partial:partials/_sidebar.html -->
    
      <!-- partial -->
      <div class="main-panel" style="width : 100%;">
        <!---content wrapper start--->
              
        <div class="content-wrapper">
                  <div class="row">
            <div class="col-md-12 grid-margin">
              <div class="d-flex justify-content-between flex-wrap">
                <div class="d-flex align-items-end flex-wrap">
                  <div class="mr-md-3 mr-xl-5">
                    <h2>Ajouter un Colis</h2>
                  </div>
                  <div class="d-flex">
                    <i class="mdi mdi-home text-muted hover-cursor"></i>
                    <p class="text-muted mb-0 hover-cursor">&nbsp;/&nbsp;Dashboard&nbsp;/&nbsp;</p>
                    <p class="text-primary mb-0 hover-cursor">Analytics</p>
                  </div>
                </div>
                <div class="d-flex justify-content-between align-items-end flex-wrap">
                  <button type="button" class="btn btn-light bg-white btn-icon mr-3 d-none d-md-block ">
                    <i class="mdi mdi-download text-muted"></i>
                  </button>
                  <button type="button" class="btn btn-light bg-white btn-icon mr-3 mt-2 mt-xl-0">
                    <i class="mdi mdi-clock-outline text-muted"></i>
                  </button>
                  <button type="button" class="btn btn-light bg-white btn-icon mr-3 mt-2 mt-xl-0">
                    <i class="mdi mdi-plus text-muted"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            

 
                  <form class="forms-sample" @submit.prevent="handlesubmit" style="width : 100%;">
              <div class="col-12 grid-margin stretch-card">
              <div class="card">
                <div class="card-body">
                  <h4 class="card-title"> Expédition :  </h4>


                  <div class="row">
                    <div class="col-12">
                       <div class="form-group row" style="margin-left : 4%;">
                      <div class="col-lg-8">
                                            <div class="form-group">
                             <div class="btn-group" style="width : 100%;">
                            <button type="button" class="btn btn-outline-secondary dropdown-toggle required" data-toggle="dropdown" id="selection" >Selectionner un client</button>
                            <div class="dropdown-menu" style="width : 100%;" aria-required="true">
               
                          <div class=" form-check">
  <input class="form-check-input" type="checkbox"  id="flexCheckDefault"  @click="selectAll" v-model="allSelected" >
  <label class="form-check-label" for="flexCheckDefault">
    Select all
  </label>
                          </div>
                               <div class=" form-check">
  <input class="form-check-input" type="checkbox" value="2" id="flexCheckDefault"  v-model="checkedNames" @change="changer()">
  <label class="form-check-label" for="flexCheckDefault">
    En cours de préparation
  </label>
                          </div>
                               <div class="form-check">
  <input class="form-check-input" type="checkbox" value="3" id="flexCheckDefault"  v-model="checkedNames" @change="changer()">
  <label class="form-check-label" for="flexCheckDefault">
    En attente d'enlèvement
  </label>
                          </div>
                      
                    

                      
                           
                            </div>                          
                          </div>
                        </div>
                      </div>

                    </div>
                
  
                      </div>
                     
                  </div>
                  <!----------------->
                          <div class="row">
                    <div class="col-12">
                       <div class="form-group row" style="margin-left : 4%;">
                      <div class="col-lg-8">
                                            <div class="form-group">
                             <div class="btn-group" style="width : 100%;">
                            <button type="button" class="btn btn-outline-secondary dropdown-toggle required" data-toggle="dropdown" id="selection" >Selectionner un client</button>
                            <div class="dropdown-menu" style="width : 100%;" aria-required="true">
               
                        
                          <div>
          <h4>User</h4>
        <div>
            <table>
                <tr>
                    <th>Name</th>
                    <th>Select All<input type="checkbox" @click="selectAll" v-model="allSelected" @change="changer()"></th>
                </tr>
                <tr v-for="user in users" :key="user.id">
                    <td>{{ user.name }}</td>
                    <td><input type="checkbox" v-model="userIds" @click="select" :value="user.id" @change="changer()"></td>
                </tr>
            </table>
        </div>

        <span>Selected Ids: {{ userIds }}</span>
        </div>
                             
                      
                    

                      
                           
                            </div>                          
                          </div>
                        </div>
                      </div>

                    </div>
                
  
                      </div>
                     
                  </div>
       
                         <!--------------------->
                                 
                      
                            
                                    
                             
  


           
                  <!---------------->

                </div>
              </div>
            </div>
            <!-------------------------------->

            <!---------------------------->
      
          </form>
 
         <!----------------------------------------->

 

    
          </div>
        </div>

        <!-- content-wrapper ends -->
        <!-- partial:partials/_footer.html -->
      <Footer />
        <!-- partial -->
      </div>
      <!-- main-panel ends -->
    </div>
    <!-- page-body-wrapper ends -->
  </div>
        
      </section>

</template>
<script>
// import Menu from '@/components/Menu.vue'; 
import Header from '@/components/Header.vue'; 
import Footer from '@/components/Footer.vue'; 
import axios from 'axios';




export default {
  components : {
    // Menu,
    Header,
    Footer
  },
    data() {
    return {
      checkedNames: [],
       users: [ 
            { "id": "Shad", "name": "Shad" }, 
            { "id": "Duane", "name": "Duane" }, 
            { "id": "Myah", "name": "Myah" }, 
            { "id": "Kamron", "name": "Kamron" }, 
            { "id": "Brendon", "name": "Brendon" }
        ],
        selected: [],
        allSelected: false,
        userIds: []
     
    }
  },

 


 
    methods: {
    selectAll() {
            //test
             this.userIds = [];
            // this.allSelected = true;

            if (!this.allSelected) {
                for (let i = 0; i<this.users.length; i++) {
                    this.userIds.push(this.users[i]["id"]);
                }
            }
        },
     select() {
            this.allSelected = false;
        },
    handlesubmit(){
      if(this.varclient == false || this.varvendeur == false || this.varrelais == false){console.log("not clicked !!"), this.error=false; console.log(this.error)}
     else{
       this.error = true;
        console.log(this.data);
     // console.log("Clicked!");
      // console.log(this.lien+'/login');
        axios.create({
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer '+localStorage.getItem("token")
        }
    })
       .post('http://127.0.0.1:8000/api/addshipment',{
         type_expedition : this.type_expedition,
         date_exp_demandee: this.date_exp_demandee,
         valeur : this.valeur,
         cod : this.cod,
         poids : this.poids,
         assurance : this.assurance,
         taxe : this.taxe,
         montant_retour : this.montant_retour,
         date_enlevement_demandee : this.date_enlevement_demandee,
         client_id: this.client_id,
         relay_id : this.relay_id,
           seller_id : this.seller_id,
           warehouse_id : this.depot_id,
       })
      .then( res => {
        console.log(res.data);
        console.log("reussi");

        

        this.$router.push("/qr");
      })
      /*
      this.$store.dispatch('retrieveToken', {
        email: this.email,
        password: this.password,
      })*/
      .catch(err => {
        this.msg = "Une erreur s'est produite lors de l'ajout de ce colis. Veuillez contacter l'administrateur ! "
      })

     }
      
    
    },




    getInfos(id, nom, prenom){
      this.varclient = true;
      
        this.client_id=id;
      this.nom = nom;
      this.prenom = prenom;
      
      document.getElementById("selection").innerHTML = this.nom+" "+this.prenom;
      console.log(this.client_id);
     // this.client_id=id;
      
      
    
    },
    getInfosrelais(id, nom){
       this.varrelais = true;
      
        this.relay_id=id;
      this.relay_nom = nom;
      console.log(this.relay_id);
      
      document.getElementById("selection2").innerHTML = this.relay_nom;
     // this.client_id=id;

    },
        getInfosvendeur(id, nom){
       this.varvendeur = true;
      
        this.seller_id=id;
      this.seller_nom = nom;
      console.log(this.seller_id);
      document.getElementById("selection3").innerHTML = this.seller_nom;
     // this.client_id=id;

    },
    changer(){
               document.getElementById("selection").innerHTML = this.userIds;

    }


   

    
  }
}
</script>
<style scoped>
.home-section{
  position: relative;
  background: #E4E9F7;
  min-height: 100vh;
  top: 0;
  left: 78px;
  width: calc(100% - 78px);
  transition: all 0.5s ease;
  z-index: 2;
}
.sidebar.open ~ .home-section{
  left: 250px;
  width: calc(100% - 250px);
}
.home-section .text{
  display: inline-block;
  color: #11101d;
  font-size: 25px;
  font-weight: 500;
  margin: 0px;
}
i{
   color: #6c757d;
   font-size: 30px;


}





</style>
import { Script } from "vm";