import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import Login from '../views/Login.vue'
import Dashboard from '../views/Dashboard.vue'
import Finance from '../views/Finance.vue'
import codeQr from  '../views/codeQr.vue'
import Colis from '../views/shipment.vue'
import Package from '../views/package.vue'
import ajoutColis from '../views/ajoutColis.vue'
import detailsClient from '../components/detailsClient.vue'

import qr from '../components/testqr.vue'
import scannqr from '../components/testscann.vue'
import test from '../components/test.vue'
import qr3 from '../components/testqr3.vue'
import testId from '../components/testId.vue'
import Pdf from '../components/pdf.vue'
import PdfCode from '../components/pdfCode.vue'

import Auto from '../components/autocomplete.vue'




import Client from '../views/Client.vue'
import editC from '../components/editClient.vue'
import ajoutClient from '../views/ajoutClient.vue'
import Vendeur from '../views/Vendeur.vue'
import ajoutVendeur from '../views/ajoutVendeur.vue'
import detailsVendeur from '../views/detailsVendeur.vue'
import detailsV from '../components/detailsVendeur.vue'
import editV from '../components/editVendeur.vue'

import facture from '../views/facture.vue'

import Relais from '../views/Relais.vue'
import ajoutRelais from '../views/ajoutRelais.vue'
import ajoutHeures from '../components/ajoutHeures.vue'
import editR from '../components/editRelais.vue'
import planning from '../components/planning.vue'



import Depot from '../views/Depot.vue'
import ajoutDepot from '../views/ajoutDepot.vue'
import editD from '../components/editDepot.vue'


import Shipping from '../components/Shipping.vue'
import C_Shipping from '../views/codeshipping.vue'



import Chauffeur from '../views/Chauffeur.vue'
import Fournisseur from '../views/Fournisseur.vue'
import ajoutChaufffeur from '../views/ajoutChauffeur.vue'
import ajoutFournisseur from '../views/ajoutFournisseur.vue'
import detailsCh from '../components/detailsChauffeur.vue'
import editCh from '../components/editChauffeur.vue'
import detailsF from '../components/detailsFournisseur.vue'
import editF from '../components/editFournisseur.vue'

import historique from '../components/historique.vue'

import Profil from '../views/profil.vue'
import Compte from '../views/Compte.vue'

import Menu from '../components/Menu.vue'
import store from '../store'

import Chart from '../components/chartejs.vue'
import Donut from '../components/donutchart.vue'
import Bar from '../components/barchart.vue'


import Map from '../components/map.vue'
import Code from '../components/pcode.vue'

import notfoundpage from '../components/notfoundpage.vue'

import Setting from '../views/settings.vue'
import Access from '../views/access.vue'



const role = store.getters.get_profile;
let keep  = true;
const routes: Array<RouteRecordRaw> = [
  {
    path: '/d',
    name: 'Dashboard',
    component: Dashboard,
    meta: {
     secure : true,
    
    }
  },
  {
    path: '/finance',
    name: 'Finance',
    component: Finance,
    beforeEnter(to, from, next) {
      if(role.type_user == "admin" || role.type_user == "manager") next()
      else
      next({path:'/d'})
    },
    meta: {
     secure : true,
    
    }
  },
  {
    path: '/testId',
    name: 'testId',
    component: testId,
    meta: {
     secure : true
    }
  },
  {
    path: '/settings',
    name: 'settings',
    component: Setting,
    meta: {
     secure : true
    }
  },
  {
    path: '/auto',
    name: 'auto',
    component: Auto,
    meta: {
     secure : true
    }
  },
  {
    path: '/chart',
    name: 'chart',
    component: Chart,
    beforeEnter(to, from, next) {
      if(role.type_user == "admin") next()
      else
      next({path:'/d'})
    },
    meta: {
     secure : true
    }


  },
  {
    path: '/donutchart',
    name: 'donutchart',
    component: Donut,
    beforeEnter(to, from, next) {
      if(role.type_user == "admin") next()
      else
      next({path:'/d'})
    },
    meta: {
     secure : true
    }


  },
  {
    path: '/barchart',
    name: 'bar',
    component: Bar,
    beforeEnter(to, from, next) {
      if(role.type_user == "admin") next()
      else
      next({path:'/d'})
    },
    meta: {
     secure : true
    }


  },
  {
    path: '/map',
    name: 'map',
    component: Map,
    meta: {
     secure : true
    }


  },
  {
    path: '/cp',
    name: 'cp',
    component: Code,
    meta: {
     secure : true
    }


  },
  {
    path: '/p/:id',
    name: 'Pdf',
    component: Pdf,
    meta: {
     secure : true
    }
  },
  {
    path: '/pdf',
    name: 'qr',
    component: qr,
    meta: {
     secure : true
    }
  },
  {
    path: '/shipping',
    name: 'shipping',
    component: Shipping,
    meta: {
     secure : true
    }
  },
  {
    path: '/c_shipping',
    name: 'c_shipping',
    component: C_Shipping,
    meta: {
     secure : true
    }
  },
  {
    path: '/qrscann',
    name: 'qrscann',
    component: scannqr,
    beforeEnter(to, from, next) {
      if(role.type_user == "admin") next()
      else
      next({path:'/d'})
    },
    meta: {
     secure : true
    }
  },
  {
    path: '/test',
    name: 'test',
    component: test,
    meta: {
     secure : true
    }
  },
  {
  path: '/qr3',
  name: 'qr3',
  component: qr3,
  meta: {
   secure : true
  }
},
  {
  path: '/m',
  name: 'm',
  component: Menu,
  meta: {
   secure : true
  }
},  
{
  path: '/pdfCode',
  name: 'pdf',
  component: codeQr,
  meta: {
   secure : true
  }
},
{
  path: '/pCode',
  name: 'pdfCode',
  component: PdfCode,
  meta: {
   secure : true
  }
},
  {
    path: '/shipment',
    name: 'colis',
    component: Colis,
    meta: {
     secure : true,
     keepAlive : true
    }
  },
  {
    path: '/packages',
    name: 'package',
    component: Package,
    meta: {
     secure : true,
     keepAlive : true
    }
  },
  {
    path: '/historique',
    name: 'historique',
    component: historique,
    meta: {
     secure : true
    }
  },
  {
    path: '/addShipment',
    name: 'ajoutColis',
    component: ajoutColis,
    beforeEnter(to, from, next) {
      if(role.type_user == "admin" || role.type_user == "back office" || role.type_user == "sous back office") next()
      else
      next({path:'/d'})
    },
    meta: {
     secure : true,
    }
  },
  {
    path: '/factures',
    name: 'facture',
    component: facture,
    beforeEnter(to, from, next) {
      if(role.type_user == "admin" || role.type_user == "back office" || role.type_user == "sous back office") next()
      else
      next({path:'/d'})
    },
    meta: {
     secure : true,
    }
  },
  {
    path: '/customer',
    name: 'client',
    component: Client,
    meta: {
     secure : true
    }
  },
  { 
  path: '/addcustomer',
  name: 'ajoutclient',
  component: ajoutClient,
  beforeEnter(to, from, next) {
    if(role.type_user == "admin" || role.type_user == "back office" || role.type_user == "sous back office") next()
    else
    next({path:'/d'})
  },
  meta: {
   secure : true,
  }
},
{ 
  path: '/editcustomer',
  name: 'editC',
  component: editC,
  beforeEnter(to, from, next) {
    if(role.type_user == "admin" || role.type_user == "back office" || role.type_user == "sous back office") next()
    else
    next({path:'/d'})
  },
  meta: {
   secure : true,
  }
},

  {
    path: '/seller',
    name: 'vendeur',
    component: Vendeur,
    meta: {
     secure : true
    }
  },
  {
    path: '/addseller',
    name: 'ajoutvendeur',
    component: ajoutVendeur,
    beforeEnter(to, from, next) {
      if(role.type_user == "admin" || role.type_user == "back office" || role.type_user == "sous back office") next()
      else
      next({path:'/d'})
    },
    meta: {
     secure : true,
    }
  },
  {
    path: '/detailsseller/:id',
    name: 'detailsVendeur',
    component: detailsVendeur,
    meta: {
     secure : true
    }
  },
  {
    path: '/details',
    name: 'detailsV',
    component: detailsV,
    meta: {
     secure : true
    }
  },
  {
    path: '/editV',
    name: 'editV',
    component: editV,
    beforeEnter(to, from, next) {
      if(role.type_user == "admin" || role.type_user == "back office" || role.type_user == "sous back office") next()
      else
      next({path:'/d'})
    },
    meta: {
     secure : true,
    }
  },
  {
    path: '/warehouse',
    name: 'depot',
    component: Depot,
    meta: {
     secure : true
    }
  },
  {
    path: '/addwarehouse',
    name: 'ajoutdepot',
    component: ajoutDepot,
    beforeEnter(to, from, next) {
      if(role.type_user == "admin" || role.type_user == "back office" || role.type_user == "sous back office") next()
      else
      next({path:'/d'})
    },
    meta: {
     secure : true,
    }
  },
  {
    path: '/editwarehouse',
    name: 'editDepot',
    component: editD,
    beforeEnter(to, from, next) {
      if(role.type_user == "admin" || role.type_user == "back office" || role.type_user == "sous back office") next()
      else
      next({path:'/d'})
    },
    meta: {
     secure : true,
    }
  },
 
  {
    path: '/relay',
    name: 'relais',
    component: Relais,
    meta: {
     secure : true
    }
  },
  {
    path: '/addrelay',
    name: 'ajoutrelais',
    component: ajoutRelais,
    beforeEnter(to, from, next) {
      if(role.type_user == "admin" || role.type_user == "back office" || role.type_user == "sous back office") next()
      else
      next({path:'/d'})
    },
    meta: {
     secure : true,
    }
  },
  {

    path: '/editrelay',
    name: 'editR',
    component: editR,
    beforeEnter(to, from, next) {
      if(role.type_user == "admin" || role.type_user == "back office" || role.type_user == "sous back office") next()
      else
      next({path:'/d'})
    },
    meta: {
     secure : true,
    }
  },
  {

    path: '/planning',
    name: 'planning',
    component: planning,
    beforeEnter(to, from, next) {
      if(role.type_user == "admin" || role.type_user == "back office" || role.type_user == "sous back office") next()
      else
      next({path:'/d'})
    },
    meta: {
     secure : true,
    }
  },
  {
    path: '/addhours',
    name: 'ajoutheures',
    component: ajoutHeures,
    beforeEnter(to, from, next) {
      if(role.type_user == "admin" || role.type_user == "back office" || role.type_user == "sous back office") next()
      else
      next({path:'/d'})
    },
    meta: {
     secure : true,
    }

  },
  {
    path: '/driver',
    name: 'chauffeur',
    component: Chauffeur,
    meta: {
     secure : true
    },
   /*  children: [
      {
          path: '/detailsDriver/:id',
          name: "detailsCh",  
          component: detailsCh
      } 
  ]*/
    
  },
  {
    path: '/provider',
    name: 'fournisseur',
    component: Fournisseur,
    meta: {
     secure : true
    },
   /*  children: [
      {
          path: '/detailsDriver/:id',
          name: "detailsCh",  
          component: detailsCh
      } 
  ]*/
    
  },
  {
    path: '/adddriver',
    name: 'ajoutchauffeur',
    component: ajoutChaufffeur,
    beforeEnter(to, from, next) {
      if(role.type_user == "admin" || role.type_user == "back office" || role.type_user == "sous back office") next()
      else
      next({path:'/d'})
    },
    meta: {
     secure : true,
    }
  },
  {
    path: '/addprovider',
    name: 'ajoutFournisseur',
    component: ajoutFournisseur,
    beforeEnter(to, from, next) {
      if(role.type_user == "admin" || role.type_user == "back office" || role.type_user == "sous back office") next()
      else
      next({path:'/d'})
    },
    meta: {
     secure : true,
    }
  },
   {
    path: '/detailsDriver',
    name: 'detailsCh',
    component: detailsCh,
    meta: {
     secure : true
    }
  }, 
  {
    path: '/editDriver',
    name: 'editCh',
    component: editCh,
    beforeEnter(to, from, next) {
      if(role.type_user == "admin" || role.type_user == "back office" || role.type_user == "sous back office") next()
      else
      next({path:'/d'})
    },
    meta: {
     secure : true,
    }
  },
  {
    path: '/editProvider',
    name: 'editF',
    component: editF,
    beforeEnter(to, from, next) {
      if(role.type_user == "admin" || role.type_user == "back office" || role.type_user == "sous back office") next()
      else
      next({path:'/d'})
    },
    meta: {
     secure : true,
    }
  },
  {
    path: '/permissions',
    name: 'profil',
    component: Profil,
    beforeEnter(to, from, next) {
      if(role.type_user == "admin") next()
      else
      next({path:'/d'})
    },
    meta: {
     secure : true
    }
  },
  {
    path: '/comptes',
    name: 'Compte',
    component: Compte,
    beforeEnter(to, from, next) {
      if(role.type_user == "admin") next()
      else
      next({path:'/d'})
    },
    meta: {
     secure : true
    }
  },
  {
    path: '/access',
    name: 'access',
    component: Access,
    beforeEnter(to, from, next) {
      if(role.type_user == "admin") next()
      else
      next({path:'/d'})
    },
    meta: {
     secure : true
    }
  },

  {
    path: '/',
    name: 'Login',
    component: Login,
    
    meta: {
      guest : true
    }
    
  },
  {
    path: '/detailsClient',
    name: 'detailsClient',
    component: detailsClient,
    meta: {
      secure : true
    }
  },
  {
    path: '/:pathMatch(.*)*',
    component: notfoundpage,
   
  },
  {
    path: '/testt',
    component: notfoundpage,
   
  },
 
  
  
  //{
   // path: '/about',
   // name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
  //  component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  //}
]


const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
  
});
router.beforeEach((to, from, next) => {
  console.log(to);
  console.log("permissions",store.state.permissions)
  
  //console.log(role.type_user);
    if(to.matched.some(record => record.meta.secure)){
    // token verification 
   // if(localStorage.getItem('token')== null){
    if(!store.state.loggedIn ){
      keep = false;

      next({
        path : '/'
      })
    }
    else{
     // if(role.type_user == "admin") 
      next();
    

    }

  }
  else if (to.matched.some(record => record.meta.guest)){
   // if(localStorage.getItem("token")==null){
    if(!store.state.loggedIn){

      next();
    }
    else{
      console.log("no token");
      next({
        path:'/d'
      });
    }
  }
  else{
    next();
  }

})


export default router
