<template>

      <button @click="makepdf()">  Makepdf </button>
    <button @click="printFacture()">  exportPdf </button>
    <button @click="tester()">  tester d'abord </button>
    <button class="btn-success " @click="verifier()">  verifier ensuite </button>
    <button @click="uint()">  uint </button>
    <button @click="testingbase()">  base 64 </button>
    <button @click="passToId()">  qr value </button>
    <div class="card">
      <h1> TEST QR </h1>
 <button @click="test">
       {{ !testVar ? 'Scan a qr code' : 'Close qr scann'}}
 </button>
  <div class="stream" v-if="testVar">
     
  <qr-stream @decode="onDecode" class="mb">
    <div style="color: red;" class="frame"></div>
  </qr-stream>
</div>
<div class="result" v-if="testVar">
  Result: {{data}}
</div>

    </div>
    
    


</template>


<script>

import CryptoJS from 'crypto-js'
import { QrStream } from 'vue3-qr-reader';






export default {
  components: {
   QrStream,
  },

   data(){
    return{
        data : "",
        testVar : false,
     // id : this.$route.params.id,
     
      salt : localStorage.getItem("Sa"),
      // key : 'AA74CDCC2BBRT935136HH7B63C27', // change to your key
      key : localStorage.getItem("kp"),
       iv :  localStorage.getItem("I") ,// change to your iv
   
    }
   },



  methods: {
      test(){
//test
//test
console.log("clicked");
this.testVar = !this.testVar;
    },
    onDecode(data){
        this.data = data;


    },


 
verifier(){    console.log("verifier") ; 

console.log(this.CryptoJSAesDecrypt(this.key, this.data));
//console.log(JSON.parse(this.CryptoJSAesDecrypt(this.key, this.stringEncrypte)));
/*var testing = JSON.parse(this.CryptoJSAesDecrypt(this.key, this.stringEncrypte));
console.log((testing));
console.log("***********************");
 var encodedString = String.fromCharCode.apply(null, testing[3]),
        decodedString = decodeURIComponent(encodedString);
    var str = decodedString;
    console.log("***********************")
    console.log(str)
     var p = str
       p = p.split('').reverse().join('')
  console.log("***********************")
    console.log(p)*/
 
    

},

CryptoJSAesDecrypt(passphrase,encrypted_json_string){

    //var obj_json = JSON.parse(encrypted_json_string);

    var encrypted = encrypted_json_string;
    var salt = CryptoJS.enc.Hex.parse(this.salt);
    var iv = CryptoJS.enc.Hex.parse(this.iv);   

    var key = CryptoJS.PBKDF2(passphrase, salt, { hasher: CryptoJS.algo.SHA512, keySize: 64/8, iterations: 999});


    var decrypted = CryptoJS.AES.decrypt(encrypted, key, { iv: iv});

    return decrypted.toString(CryptoJS.enc.Utf8);
}

  



  }
}
</script>
<style scoped>
.row{
  background : transparent;
}
.col1{
  background: transparent;
  width: 15%;
  float: left;
  padding: 0% 2%;
}
.col2{
  background: transparent;
  width: 30%;
  display: inline-block;
}
table{
   width: 95%;
  border-collapse: collapse;
  margin: 7% 0%;
}
table, td, th{
  border: 1px solid black;
}
p span {
  display: block;
}
p span.span {
  display: inline-block;
}
.image{
  margin-top: -12%;
}
.codeqr{
  margin-top: -17%;
  margin-left: -7%;

}
.Last p {
  border: 1px solid black;
  padding: 2%;
  margin-top: -0%;
  margin-left: -2%;
  width: 30%;
  background: transparent;
  float: left;

}
div.Last .form-check{
  display: inline-block;
  margin-top: 2% ;
  margin-left: 1%;

}

 

 
 
 
 
 
</style>