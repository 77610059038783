

<template>
              

      <section class="home-section" >
         
         <div class="container-scroller" >
    <!-- partial:partials/_navbar.html -->
    <Header title="Ajout colis" />
    <!-- partial -->
    <div class="container-fluid page-body-wrapper bg-light"  >
      <!-- partial:partials/_sidebar.html -->
   
    
      <!-- partial -->
      <div class="main-panel" style="width : 100%;">
        <!---content wrapper start--->
              
        <div class="content-wrapper">

                  <div class="row" >
            <div class="col-md-12 grid-margin">
              <div class="d-flex justify-content-between flex-wrap row">
                <div class="d-flex align-items-end flex-wrap col-lg-8">
                
               
                <div class="mr-auto p-2"> <div class="mt-3">
                      <p><i class="mdi mdi-currency-usd icon-md" style="color: #6c757d; font-size: 30px;"></i></p>
                    </div>
                    </div>
      


                </div>
     
              </div>
            </div>
          </div>
          
       <!--------------->
       
     
 
       <!--------------->
          <div class="row m-0 p-0">
            <div class=" stretch-card col-lg-12 m-0 p-0">
              <div class="card p-0 m-0 bg-transparent border-0 ">
                <div class="card-body  p-0 m-0">
    
                 
                                                     
 <div class="row m-3">
       <div class="d-flex flex-wrap justify-content-xl-between">
                        <!-- <div class="d-none d-xl-flex border-md-right flex-grow-1 align-items-center justify-content-center p-3 item">
                          <i class="mdi mdi-calendar-heart icon-lg mr-3 text-primary"></i>
                          <div class="d-flex flex-column justify-content-around">
                            <small class="mb-1 text-muted">Start date</small>
                            <div class="dropdown">
                              <a class="btn btn-secondary dropdown-toggle p-0 bg-transparent border-0 text-dark shadow-none font-weight-medium" href="#" role="button" id="dropdownMenuLinkA" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <h5 class="mb-0 d-inline-block">26 Jul 2018</h5>
                              </a>
                              <div class="dropdown-menu" aria-labelledby="dropdownMenuLinkA">
                                <a class="dropdown-item" href="#">12 Aug 2018</a>
                                <a class="dropdown-item" href="#">22 Sep 2018</a>
                                <a class="dropdown-item" href="#">21 Oct 2018</a>
                              </div>
                            </div>
                          </div>
                        </div> -->
<div class="d-none d-xl-flex border-md-right flex-grow-1 align-items-center justify-content-center p-3 item">
                          <i class="mdi mdi-calendar icon-lg mr-3" style="color : #DB3E79;"></i>         
                              <div class="dropdown ">
    <a class="btn btn-secondary dropdown-toggle p-0 bg-transparent border-0 text-dark shadow-none font-weight-medium"  href="#" role="button" id="dropdownMenuLinkA" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <h5 class="mb-0 d-inline-block">Selectioner une date</h5>
                              </a>
  <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1" >
        <li><a class="dropdown-item" href="#" > 

  <input class="mr-2" type="radio" name="flexRadioDefault" id="flexRadioDefault1" value=7 v-model="select" :checked="select == 7" @change="changer">
  <label class="form-check-label font-weight-bold" for="flexRadioDefault1" >
    Last 7 days (default)
  </label>
  </a>
        </li>
     <li><a class="dropdown-item" href="#"  > 

  <input class="mr-2" type="radio" name="flexRadioDefault" id="flexRadioDefault1" value=30 v-model="select" @change="changer">
  <label class="form-check-label font-weight-bold" for="flexRadioDefault1" >
    Last Month
  </label>
  </a>
        </li>
         <li><a class="dropdown-item" href="#" > 

  <input class="mr-2" type="radio" name="flexRadioDefault" id="flexRadioDefault1" value=365 v-model="select" @change="changer">
  <label class="form-check-label font-weight-bold" for="flexRadioDefault1" >
    Last Year
  </label>
  </a>
        </li>
         <li><a class="dropdown-item" href="#" > 

  <input class="mr-2" type="radio" name="flexRadioDefault" id="flexRadioDefault1" value=1 v-model="select" @change="changer">
  <label class="form-check-label font-weight-bold" for="flexRadioDefault1" >
    Custome date
  </label>
  </a>
        </li>
  </ul>
</div>
                               </div>
    <!------------->
    
                        <div class="d-flex border-md-right flex-grow-1 align-items-center justify-content-center p-3 item">
                          <i class="mdi mdi-currency-usd mr-3 icon-lg " style="color: #008AD8;"></i>
                          <div class="d-flex flex-column justify-content-around">
                            <small class="mb-1 text-muted">Chiffre d'affaire</small>
                            <h5 class="mr-2 mb-0">{{chAffaire}} DH</h5>
                          </div>
                        </div>
                        
                        <div class="d-flex border-md-right flex-grow-1 align-items-center justify-content-center p-3 item">
                          <i class="mdi mdi-cart mr-3 icon-lg " style="color: #FEDD00;"></i>
                          <div class="d-flex flex-column justify-content-around">
                            <small class="mb-1 text-muted" >Vendeurs</small>
                            <h5 class="mr-2 mb-0">{{vendeurs}}</h5>
                          </div>
                        </div>
                        <div class="d-flex border-md-right flex-grow-1 align-items-center justify-content-center p-3 item">
                          <i class="mdi mdi-map-marker-multiple mr-3 icon-lg " style="color : #00873E;"></i>
                          <div class="d-flex flex-column justify-content-around">
                            <small class="mb-1 text-muted">Poins relais</small>
                            <h5 class="mr-2 mb-0">{{relais}}</h5>
                          </div>
                        </div>
                        <div class="d-flex py-3 border-md-right flex-grow-1 align-items-center justify-content-center p-3 item">
                          <i class="mdi mdi-truck mr-3 icon-lg " style="color: #3E505C;"></i>
                          <div class="d-flex flex-column justify-content-around">
                            <small class="mb-1 text-muted">Chauffeurs</small>
                            <h5 class="mr-2 mb-0">{{chauffeurs}}</h5>
                          </div>
                        </div>
                      </div>
                  
  </div>

   <div class="row m-0 p-0" v-show="select == 1">
                         <form class="forms-sample d-flex justify-content-center p-0 m-0 "> 
                <div class="form-group  p-0 mx-1 col-lg-3">
<!--                   <label for="input_from" class="p-0 m-0 text-primary font-weight-bold" > From </label>
 -->                  <input type="text" onfocus="(this.type='date')" class="form-control d-inline bg-white " id="input_from" placeholder="Start date " v-model="from" @change="changerDate">
                </div>
                <div class="form-group  p-0 mx-1 col-lg-3">
<!--                   <label for="input_to" class="p-0 m-0 text-primary font-weight-bold" > To </label>
 -->                  <input type="text" onfocus="  (this.type='date')" class="form-control d-inline bg-white" id="input_to" placeholder="End date" v-model="to" @change="changerDate">
                </div>


          </form>
  
    
     </div>
       <div class="row">
       
                       <p class="alert alert-danger text-center font-weight-bold" v-show="error">{{error}}</p>

     </div>
  
    <div class="row mt-2">
    <div class="col-lg-12 ">
    <div class="row px-3">
     
        <!--  <div class="col-lg-8">
            
            <div class="card  bg-transparent border border-0 ">
                     <div class="card  bg-transparent border border-0 ">
                     <div class="card-body col-lg-12">
                  <p class="card-title m-1">Bilan 2021   </p>
                
<chart  :entred_data="data" />

                </div>
              </div>
              </div>
        </div> -->
      <!--     <div class="col-lg-4">
            <div class="card l-bg-cherry">
                     <a class="card-statistic-3 p-4 data-card" v-if=" resultat> 0">
                    <div class="mb-0">
                        <h5 class="card-title mb-0" > 
                        <i class="mdi mdi-transfer text-success"></i>

                          Bénefice
                          </h5>
                        <p > Résultat dégagé</p>
                    </div>
                    <div class="row align-items-center mb-2 d-flex">
                        <div class="col-8">
                            <h2 class="d-flex align-items-center mb-0 text-success">
                                {{resultat}}
                            </h2>
                        </div>
                        <div class="col-4 text-right">
                           <span> +{{percentage(resultat, chiffreAff)}}%<i class="fa fa-arrow-up text-success"></i></span>
                   </div>
                    </div>
                    <div class="progress mt-1 " data-height="8" style="height: 8px;">
  <div class="progress-bar bg-success" role="progressbar" v-bind:style="{ width: percentage(resultat, chiffreAff)+ '%'}" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"></div>
                  </div>
                </a>
                       <a class="card-statistic-3 p-4 data-card" v-else>
                 
                    <div class="mb-0">
                        <h5 class="card-title mb-0" > 
                        <i class="mdi mdi-transfer " style="color:  #8031A7;"></i>

                          Perte
                          </h5>
                        <p > Résultat dégagé</p>
                    </div>
                    <div class="row align-items-center mb-2 d-flex">
                        <div class="col-8">
                            <h2 class="d-flex align-items-center mb-0 " style="color:  #8031A7;">
                                {{resultat}}
                            </h2>
                        </div>
                        <div class="col-4 text-right">
                           <span> {{percentage(resultat, chAffaire)}}% <i class="fa fa-arrow-down " style="color:  #8031A7;"></i></span>
                   </div>
                    </div>
                    <div class="progress mt-1 " data-height="8" style="height: 8px;">
   <div class="progress-bar " style="background:  #8031A7;" role="progressbar" v-bind:style="{ width: percentage(resultat, chAffaire) + '%'}" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"></div>
                   </div>
                </a>
            </div>
        </div> -->
   <!--      <div class="col-lg-4">
            <div class="card l-bg-cherry">
               <a class="card-statistic-3 p-4 data-card" @click="changerDash(arr7)">
                    <div class="card-icon card-icon-large">
                      <i class="mdi mdi-check-circle"></i></div> 
                    <div class="mb-0">
                        <h5 class="card-title mb-0"> 
                        <i class="mdi mdi-close-circle text-danger"></i>
                          Charges
                          </h5>
                        <p >incluent les comissions des relais et chauffeurs ainsi que l'indemnité des colis perdus</p>
                    </div>
                    <div class="row align-items-center mb-2 d-flex">
                        <div class="col-8">
                            <h2 class="d-flex align-items-center mb-0 text-danger">
                           {{charge}}
                       </h2>
                        </div>
                        <div class="col-4 text-right">
                             <span class="d-block">{{percentage(charge, chAffaire) }} % <i class="fa fa-arrow-down text-danger"></i></span>
                             <span class="d-block">{{percentage(chargepre, chAffPre) }} % <i class="fa fa-arrow-down text-danger"></i></span>
                        </div>
                    </div>
                    <div class="progress mt-1 " data-height="8" style="height: 8px;">
  <div class="progress-bar bg-danger" role="progressbar" v-bind:style="{ width: percentage(charge, chAffaire) + '%'}" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"></div>
                    </div>
                </a>
            </div>
        </div> -->
           
    </div>
</div>
  </div>


<!-- <div class="row mx-2 mt-3 mb-0" >
    

              <div class=" stretch-card col-lg-6 p-0" >
              <div class="card  bg-transparent border border-0 ">
                     <div class="card  bg-transparent border border-0 ">
                     <div class="card-body col-lg-12">
                  <p class="card-title m-4">Statistques colis</p>
                <div class="row">
                                       <p for="" class="text-muted font-weight-bold col-lg-3">
 Domicile
</p>
<div class="progress col-lg-9">
  <div class="progress-bar" role="progressbar" v-bind:style="{ width: compt.d + '%'}" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">{{compt.d}}%</div>
</div>

                        <p for="" class="text-muted font-weight-bold col-lg-3">
 Point relais
</p>
<div class="progress col-lg-9">
  <div class="progress-bar" role="progressbar"  v-bind:style="{ width:  compt.r + '%'}" aria-valuenow="50" aria-valuemin="0" aria-valuemax="100"> {{compt.r}}%</div>
</div>      
                   
                </div>


                </div>
              </div>
              </div>
            </div>
          </div> -->

      
          <!------->

         
          

  


                </div>
              </div>
            </div>
          </div>


          <!----------->
           <div class="row">
             
              <div class="col-md-4 grid-margin stretch-card m-0">
              <div class="card m-0">
                 <div class="card-body border pb-0 m-1">
                   <a class="card-statistic-3  data-card row mb-0" @click="changerDash(arr5)">
                     <div class="mb-0">
                        <h5 class="card-title mb-0"> 
                        <i class="mdi mdi-package-variant text-secondary"></i>
                          Chiffre d'affaire previsionnel
                          </h5>
                    </div>
                    <div class="row align-items-center mb-2 d-flex">
                        <div class="col-8">
                            <h2 class="d-flex align-items-center mb-0 " style="color: #80A1A6;">
                                {{chAffPre}} DH
                            </h2>
                        </div>
                        <div class="col-4 text-right" v-if="(chAffPre - chAffaire) >=0">
                            <span>  +{{(chAffPre - chAffaire)}} <i class="fa fa-arrow-up " style="color: #80A1A6;"></i></span>
                        </div>
                      
                    </div>
                    <div class="progress mt-1 " data-height="8" style="height: 8px;">
  <div class="progress-bar"  role="progressbar" style="width: 100%; background: #80A1A6;" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"></div>
                    </div>
                </a>
                 
                </div>
               
                 <div class="card-body border border-light">
                  <p class="card-title">Statistiques statuts</p>
                  <div class="d-flex flex-row w-100">
                    <div class="float-left" style="width: 60%;">
                      <!-- <donut  v-bind:entred_data="dataEntr"  style="bg-dark"/> -->
                      <component :is="currentComponent" v-bind:entred_data="data" >  </component>

                    </div>
                    <div class="float-right " style="width: 40%;">
                       <ul class="list-group list-group-flush row">
  <li class="list-group-item col-lg-12"><i class="mdi mdi-checkbox-blank d-inline mr-2" style="color :#78BE21;"></i> <p class="text-dark d-inline font-weight-bold">Livré</p></li>
  <li class="list-group-item col-lg-12"><i class="mdi mdi-checkbox-blank d-inline mr-2" style="color :#CF578A;"></i> <p class="text-dark d-inline font-weight-bold">Traitement en cours</p></li>
  <li class="list-group-item col-lg-12"><i class="mdi mdi-checkbox-blank d-inline mr-2" style="color :#7BB2DD;"></i> <p class="text-dark d-inline font-weight-bold">Retour aprés reception</p></li>
  <li class="list-group-item col-lg-12"><i class="mdi mdi-checkbox-blank d-inline mr-2" style="color :#888B8D;"></i> <p class="text-dark d-inline font-weight-bold">Non recupéré</p></li>
  <li class="list-group-item col-lg-12"><i class="mdi mdi-checkbox-blank d-inline mr-2" style="color :#CD001A;"></i> <p class="text-dark d-inline font-weight-bold">Retourné</p></li>
  <li class="list-group-item col-lg-12"><i class="mdi mdi-checkbox-blank d-inline mr-2" style="color :#1F2022;"></i> <p class="text-dark d-inline font-weight-bold">Perdu/Annulé</p></li>
</ul>
                    </div>
                 
                  </div>
                  

                </div>
              </div>
                
            </div>
             
            <div class="col-md-8 grid-margin stretch-card m-0">
              <div class="card m-0">
                <div class="card-body">
                  <p class="card-title">Colis livrés et retournés</p>
                  <chart  :entred_data="dataEntr" />

                </div>
              </div>
            </div>
           
          </div>

          <!---------------->
            <div class="row">
            <div class="col-lg-6 grid-margin stretch-card">
              <div class="card">
                <div class="card-body">
                  <p class="card-title">Cash deposits</p>
                  <p class="mb-4">To start a blog, think of a topic about and first brainstorm party is ways to write details</p>
                  <div id="cash-deposits-chart-legend" class="d-flex justify-content-center pt-3"></div>
                   <table class="table">
  <thead class=" text-white" style="background : #00873E;">
    <tr>
      <th scope="col">#</th>
      <th scope="col">Point relais</th>
      <th scope="col">Ville</th>
      <th scope="col">Comission actuelle</th>
      <th scope="col">%</th>
    </tr>
  </thead>
  <tbody>
    <tr v-for="(item, index) in r" :key="item.id" v-bind:index="index">
      <th scope="row"> {{index + 1}} </th>
      <td class="text-capitalize">{{item.relay.nom}}</td>
      <td>{{item.relay.ville.city}}</td>
      <td>{{item.relay.prix}}</td>
      <td>{{percentage(item.count, totalR)}}</td>
    </tr>
 
  </tbody>
</table>
                </div>
              </div>
            </div>
            <div class="col-lg-6 grid-margin stretch-card">
              <div class="card">
                <div class="card-body">
                  <p class="card-title">Cash deposits</p>
                  <p class="mb-4">To start a blog, think of a topic about and first brainstorm party is ways to write details</p>
                  <div id="cash-deposits-chart-legend" class="d-flex justify-content-center pt-3"></div>
                   <table class="table">
  <thead class=" text-secondary" style="background : #FEDD00;">
    <tr>
      <th scope="col">#</th>
      <th scope="col">Vendeur</th>
      <th scope="col">Prix actuel</th>
      <th scope="col">Prix actuel (hors casa)</th>
      <th scope="col">%</th>
    </tr>
  </thead>
  <tbody>
    <tr v-for="(item, index) in s" :key="item.id" v-bind:index="index">
      <th scope="row"> {{index + 1}} </th>
      <td class="text-capitalize">{{item.seller.nom}}</td>
      <td>{{item.seller.prix}}</td>
      <td>{{item.seller.prix}}</td>
      <td>{{percentage(item.count, totalS)}}</td>
    </tr>
 
  </tbody>
</table>
                 
                </div>
              </div>
            </div>
          </div>

         <!-- <div class="row">
             <div class="col-lg-12 grid-margin stretch-card">
              <div class="card">
                <div class="card-body">
                  <p class="card-title">Cash deposits</p>
                  <p class="mb-4">To start a blog, think of a topic about and first brainstorm party is ways to write details</p>
                  <div id="cash-deposits-chart-legend" class="d-flex justify-content-center pt-3"></div>
                   <table class="table">
  <thead class=" text-white" style="background : #3E505C;">
    <tr>
      <th scope="col">#</th>
      <th scope="col">Chauffeur</th>
      <th scope="col">Prix actuel</th>
      <th scope="col">Prix actuel (hors casa)</th>
      <th scope="col">%</th>
    </tr>
  </thead>
  <tbody>
    <tr v-for="(item, index) in d" :key="item.id" v-bind:index="index">
      <th scope="row"> {{index + 1}} </th>
      <td class="text-capitalize">{{item.driver.nom}}</td>
      <td>{{item.driver.prix_casa}}</td>
      <td>{{item.driver.prix_hors_casa}}</td>
      <td>{{percentage(item.count, totalD)}}</td>
    </tr>
 
  </tbody>
</table>
                 
                </div>
              </div>
            </div>
          </div>
          -->
          
        
        </div>

        <!-- content-wrapper ends -->
        <!-- partial:partials/_footer.html -->
      <Footer />
        <!-- partial -->
      </div>
      <!-- main-panel ends -->
    </div>
    <!-- page-body-wrapper ends -->
  </div>
        
      </section>

</template>

<script>
// import Menu from '@/components/Menu.vue'; 
import Header from '@/components/Header.vue'; 
import Footer from '@/components/Footer.vue'; 
import chart from '@/components/chartejs.vue'; 
import donut from '@/components/donutchart.vue'; 
import axios from 'axios';
import Swal from 'sweetalert2';
import moment from 'moment';
// import 'vue3-date-time-picker/dist/main.css'








export default {

  components : {
    // Menu,
    Header,
    Footer,
    chart,
    //bar
    donut,
    //detailsCh,
    // Loading,
    
  },
  data()  {
    return {
    chAffaire : 0,
    chAffPre: 0,
    vendeurs:0,
    relais:0,
    chauffeurs:0,
    from : '',
    to : '',
    select: 30,
    t:{},
    result : 0,
    r:{},
    s:{},
    d:{},
    prix_r:0,
    prix_d:0,
    charge:0,
    resultat:0,
    chargepre:0,
    resultatpre:0,
    totalS: 0,
    totalR: 0,
    totalD: 0,
    l:{},
    somme:0,
    compt:{},
    prix_r_pre:0,
    prix_d_pre:0,
    reponse:{},
    count:[],
    id:"",
    data:{},
    error:"",
    chAffaireRetourne:0,
    chAffaireRetournePre:0,
    currentComponent : '',

   
    }
  


 

 },
created(){
     this.chiffreAff();
     this.chiffreAffPre();
     this.dash("relay_id", "relay");
     this.dash("seller_id", "seller");
     this.drivers();
     this.relaisPrix();
     this.driverPrix();
     this.relaisPrixPre();
     this.driverPrixPre();
     this.livraison();
     this.respre();
    

},
mounted(){
  this.error = '';
  //this.send();
},
computed:{

  dataEntr(){
  this.send();
  return this.data;
  },
  

},
 


 methods: {
   changer(){
    this.chiffreAff();
     this.chiffreAffPre();
     this.dash("relay_id", "relay");
     this.dash("seller_id", "seller");
     this.drivers();
     this.relaisPrix();
     this.driverPrix();
     this.relaisPrixPre();
     this.driverPrixPre();
     this.livraison();
     this.respre();
     this.send();
    
 },



     chiffreAff(){
       this.chAffaire = 0;
  this.t.select = this.select;
  this.t.from = this.from;
  this.t.to = this.to;
   this.$store.dispatch('postItem', {//data to be sended
  nameRoute : '/api/financeretourne',
  t : this.t
      })    
     .then(res => {
     this.chAffaireRetourne = res.data;
     })
     .catch(err => {
          // this.isLoading = false;
          console.log(err.response)
          if (!err.response) this.error = "Problème réseau, Impossible de joindre le serveur" 
          else  this.error = "Une erreur s'est produite ! "

   })
  this.$store.dispatch('postItem', {//data to be sended
  nameRoute : '/api/finance',
  t : this.t
      })    
     .then(res => {
     this.chAffaire = this.chAffaireRetourne + res.data;
     })
     .catch(err => {
          // this.isLoading = false;
          if (!err.response) this.error = "Problème réseau, Impossible de joindre le serveur" 
          else    this.error = "Une erreur s'est produite ! " 

   })
     },
     chiffreAffPre(){
  this.chAffPre = 0;
  this.t.select = this.select;
  this.t.from = this.from;
  this.t.to = this.to;
   this.$store.dispatch('postItem', {//data to be sended
  nameRoute : '/api/financeretournepre',
  t : this.t
      })    
     .then(res => {
     this.chAffaireRetournePre = res.data;
     })
     .catch(err => {
          // this.isLoading = false;
           if (!err.response) this.error = "Problème réseau, Impossible de joindre le serveur" 
           else  this.error = "Une erreur s'est produite ! " 

   })
  this.$store.dispatch('postItem', {//data to be sended
  nameRoute : '/api/financepre',
  t : this.t
      })    
     .then(res => {
     this.chAffPre = this.chAffaireRetournePre + res.data;
     })
     .catch(err => {
          // this.isLoading = false;
          if (!err.response) this.error = "Problème réseau, Impossible de joindre le serveur" 
          else      this.error = "Une erreur s'est produite ! " 

   })
     },
      relaisPrix(){
  this.prix_r = 0;
  this.t.select = this.select;
  this.t.from = this.from;
  this.t.to = this.to;
  this.$store.dispatch('postItem', {//data to be sended
  nameRoute : '/api/relaisprix',
  t : this.t
      })    
     .then(res => {
     this.prix_r = res.data;
     //console.log("prix relais"+this.prix_r)
     })
     .catch(err => {
          // this.isLoading = false;
          if (!err.response) this.error = "Problème réseau, Impossible de joindre le serveur" 
          else this.error = "Une erreur s'est produite ! " 

   })
     },
     relaisPrixPre(){
  this.prix_r_pre = 0;
  this.t.select = this.select;
  this.t.from = this.from;
  this.t.to = this.to;
  this.$store.dispatch('postItem', {//data to be sended
  nameRoute : '/api/relaisprixpre',
  t : this.t
      })    
     .then(res => {
      // console.log("prix relais pre", res.data)
     this.prix_r_pre = res.data;
     })
     .catch(err => {
          // this.isLoading = false;
          if (!err.response) this.error = "Problème réseau, Impossible de joindre le serveur"  
          else this.error = "Une erreur s'est produite ! " 

   })
     },
     driverPrix(){
  this.prix_d = 0;
  this.t.select = this.select;
  this.t.from = this.from;
  this.t.to = this.to;

  this.$store.dispatch('postItem', {//data to be sended
  nameRoute : '/api/driverprix',
  t : this.t
      })    
     .then(res => {
     this.prix_d = res.data;
    // console.log("prix relais"+this.prix_d)
     this.res();
     })
     .catch(err => {
          // this.isLoading = false;
          if (!err.response) this.error = "Problème réseau, Impossible de joindre le serveur" 
          else   this.error = "Une erreur s'est produite ! " 

   })
     },
     driverPrixPre(){
  this.prix_d_pre = 0;
  this.t.select = this.select;
  this.t.from = this.from;
  this.t.to = this.to;

  this.$store.dispatch('postItem', {//data to be sended
  nameRoute : '/api/driverprixpre',
  t : this.t
      })    
     .then(res => {
     this.prix_d_pre = res.data;
          //  console.log("yes"+this.prix_d_pre)

     this.res();
     })
     .catch(err => {
          // this.isLoading = false;
           if (!err.response) this.error = "Problème réseau, Impossible de joindre le serveur" 
          else     this.error = "Une erreur s'est produite ! " 

   })
     },

     dash(search, table){
       this.totalS = 0;
       this.totalR = 0;
       this.vendeurs = 0;
       this.relais = 0;
    this.t.select = this.select;
    this.t.from = this.from;
    this.t.to = this.to;
    // this.t.items = this.items;
    this.t.search = search;
    this.t.table = table
    this.$store.dispatch('postItem', {//data to be sended
    nameRoute : '/api/dash',
    t : this.t
      })    
     .then(res => {
     // this.isLoading = false;
    // console.log(res.data)
     var resul = res.data.length;
        if(search == "seller_id") {this.s =res.data ;this.vendeurs = resul; this.totalS = res.data.reduce( ( sum, obj ) => sum + obj.count , 0)}
        if(search == "relay_id") {this.r =res.data ;this.relais = resul; this.totalR = res.data.reduce( ( sum, obj ) => sum + obj.count , 0)}

       
     })
     .catch(err => {
          // this.isLoading = false;
           if (!err.response) this.error = "Problème réseau, Impossible de joindre le serveur" 
          else       this.error = "Une erreur s'est produite ! " 

   })
 },
 
 drivers(){
   this.chauffeurs = 0;
    this.t.select = this.select;
    this.t.from = this.from;
    this.t.to = this.to;

    this.$store.dispatch('postItem', {//data to be sended
    nameRoute : '/api/drivercount',
    t : this.t
      })    
     .then(res => {
     // this.isLoading = false;
     console.log(res.data)
     var resul = res.data.length;
        this.d =res.data ;
        this.chauffeurs = resul;
         this.totalD = res.data.reduce( ( sum, obj ) => sum + obj.count , 0)

       
     })
     .catch(err => {
          // this.isLoading = false;
           if (!err.response) this.error = "Problème réseau, Impossible de joindre le serveur" 
          else       this.error = "Une erreur s'est produite ! "
 
   })
 },
 res(){
      this.charge = this.prix_r + this.prix_d;
      this.resultat = this.chAffaire - this.charge;
 },
 respre(){
      //console.log(this.prix_d_pre+" et "+ this.prix_r_pre)
      this.chargepre = this.prix_r_pre + this.prix_d_pre;
      //console.log("charge pre"+this.chargepre)
      this.resultatpre = this.chAffPre - this.chargepre;
 },
 livraison(){
   //console.log("livraison")
  this.somme= 0;
   this.t.select = this.select;
  this.t.from = this.from;
  this.t.to = this.to;
  this.t.items = ["Livre"];
  this.$store.dispatch('postItem', {//data to be sended
  nameRoute : '/api/livraison',
  t : this.t
      })    
     .then(res => {
     // this.isLoading = false;
    // console.log(res.data)
     this.somme = res.data.reduce( ( sum, obj ) => sum + obj.count , 0)
     this.l=res.data
     this.veriflivraison(this.somme);
     })
     .catch(err => {
          // this.isLoading = false;
          if (!err.response) this.error = "Problème réseau, Impossible de joindre le serveur" 
          else     this.error = "Une erreur s'est produite ! " 

   })
 },
 veriflivraison(s){
   this.compt.d = 0;
   this.compt.r = 0;
   var l = this.l;
     for(let i in l)
          {
              var type = l[i]["type_expedition"];
              if( type == "domicile") this.compt.d= this.percentage(l[i]["count"], s);
              if( type == "point relais") this.compt.r= this.percentage(l[i]["count"], s);
 
         
          }

 },
     
   format_date(value){
         if (value) {
           return moment(String(value)).format('DD-MM-YYYY hh:mm')
          }
      },
percentage(partialValue, totalValue) {
   if(totalValue == 0) return 0;
   return ((100 * partialValue) / totalValue).toFixed(2);
},
send( ){  
  this.data.select = this.select;
  this.data.from = this.from;
  this.data.to = this.to;
 this.currentComponent = donut;
  console.log("data",this.data )

    },
    changerDate(){
  this.error="";
  var to = this.to;
  var from = this.from;
  if(to){
    if(!from) this.error = "Selectionnez une date de début";
    else if(from>to){
      this.error = "Erreur !! La date de début est supérieure de la date de fin!";
    }
    else{
      this.changer();
    }
  }
}


  

  }
}
</script>
<style scoped>
tr {
   line-height: 25px;
   min-height: 25px;
   height: 25px;
}

input[type=search]:focus{
  outline: inherit;
  box-shadow: inherit;
  background: rgb(216, 215, 208) !important;

}
.home-section{
  position: relative;
  background: #E4E9F7;
  min-height: 100vh;
  top: 0;
  left: 78px;
  width: calc(100% - 78px);
  transition: all 0.5s ease;
  z-index: 2;
}
.sidebar.open ~ .home-section{
  left: 250px;
  width: calc(100% - 250px);
}
.home-section .text{
  display: inline-block;
  color: #11101d;
  font-size: 25px;
  font-weight: 500;
  margin: 0px;
}
/* serachbar */

.search-box{
  width: fit-content;
  height: fit-content;
  position: relative;
}
.input-search{
  height: 50px;
  width: 50px;
  border-style: none;
  padding: 10px;
  font-size: 18px;
  outline: none;
  border-radius: 25px;
  transition: all .5s ease-in-out;
  background-color: transparent;
  padding-right: 40px;
  color:black;
}
.input-search::placeholder{
  color: #6C757D;
  font-size: 18px;
  font-weight: 100;
  font-style: italic;
}
.btn-search{
  width: 50px;
  height: 50px;
  border-style: none;
  font-size: 25px;
  font-weight: bold;
  outline: none;
  cursor: pointer;
  border-radius: 50%;
  position: absolute;
  right: 0px;
  color:white ;
  pointer-events: painted;  
}
.btn-search ~ .input-search{
  width: 80%;
  border-radius: 0px;
  background-color: transparent;
  border-bottom:1px solid #6C757D;
  transition: all 500ms cubic-bezier(0, 0.110, 0.35, 2);
}
.input-search:focus{
  width: 80%;
  border-radius: 0px;
  background-color: transparent;
   box-shadow: 0  0.2rem #6C757D;
  transition: all 500ms cubic-bezier(0, 0.110, 0.35, 2);
}
.modal-mask {
     position: fixed;
     z-index: 9998;
     top: 0;
     left: 0;
     width: 100%;
     height: 100%;
     background-color: rgba(0, 0, 0, .5);
     display: table;
     transition: opacity .3s ease;
   }

   .modal-wrapper {
     display: table-cell;
     vertical-align: middle;
   }
 
input:focus{
  border: none;
  box-shadow: inherit;
}

</style>
import { Script } from "vm";