<template>
      <section class="home-section" >
         
         <div class="container-scroller" >
    <!-- partial:partials/_navbar.html -->
    <Header title="Ajout colis" />
    <!-- partial -->
    <div class="container-fluid page-body-wrapper bg-light"  >
      <!-- partial:partials/_sidebar.html -->
    
      <!-- partial -->
      <div class="main-panel" style="width : 100%;">
        <!---content wrapper start--->
              
        <div class="content-wrapper">

                  <div class="row">
            <div class="col-md-12 grid-margin">
              <div class="d-flex justify-content-between flex-wrap">
                <div class="d-flex align-items-end flex-wrap">
                
               
                <div class="mr-auto p-2"> <div class="mt-3">
                      <p><i class="mdi mdi-account-box icon-md" style="color: #6c757d; font-size: 30px;"></i></p>
                    </div>
                    </div>


                </div>
                <!-- <div class="d-flex justify-content-between align-items-end flex-wrap">
                  <button type="button" class="btn btn-light bg-white btn-icon mr-3 d-none d-md-block ">
                    <i class="mdi mdi-download text-muted"></i>
                  </button>
                  <button type="button" class="btn btn-light bg-white btn-icon mr-3 mt-2 mt-xl-0">
                    <i class="mdi mdi-clock-outline text-muted"></i>
                  </button>
                  <button type="button" class="btn btn-light bg-white btn-icon mr-3 mt-2 mt-xl-0">
                    <i class="mdi mdi-plus text-muted"></i>
                  </button>
                </div> -->
              </div>
            </div>
          </div>
         <!-- Default dropup button -->

<!-- Split dropup button -->

<div class="row">
  <div class="accordion " id="accordionPanelsStayOpenExample">
  <div class="accordion-item m-2 bg-light ">
    <h2 class="accordion-header" id="panelsStayOpen-headingOne">
      <button class="accordion-button bg-light btn btn-dark" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseOne" aria-expanded="true" aria-controls="panelsStayOpen-collapseOne">
        Add new account
      </button>
    </h2>
    <div id="panelsStayOpen-collapseOne" class="accordion-collapse collapse " aria-labelledby="panelsStayOpen-headingOne">
      <div class="accordion-body">

         <form class="forms-sample  p-4" @submit.prevent="handlesubmit" style="width : 100%;">
                 
                   <div class="row m-3">
                    <div class="col-12">
                       <div class="form-group row">
                      <label for="exampleInputUsername2" class="col-lg-1 col-form-label font-weight-bold" >Profile  : </label>
                      <div class="col-lg-4 ">
                            <div class="form-group">
<!-- <select  class="form-control form-select form-select-md mb-3" aria-label="Default select example" v-model="p" @change="changerProfiles(event)">
 -->  <select  required class="form-control form-select form-select-md mb-3" aria-label="Default select example" v-model="p" @change="changerProfiles(event)">

                     <option v-for="item in profiles" :value="item" :key="item.id" >
      {{ item.type_user }}
    </option>
                      </select>
                      </div>

                      </div>
                      <div class="col-lg-6"  v-if="isLoading">
                               <loading  :active="true" :width="20" :height="20" color="blue"></loading> 

                            </div>
                    
                      <!---->
                   
                    </div>
                      </div>                                
                  </div>
                             <div class="row m-3">
                    <div class="col-12">
                       <div class="form-group row" v-if="p.type_user == 'seller' || p.type_user == 'relay' || p.type_user == 'driver'  || p.type_user == 'warehouse' ">
                     
                           <label  for="exampleInputUsername2" class="col-lg-1 col-form-label font-weight-bold" >User  : </label>
                      <div class="col-lg-4 ">
                            <div class="form-group">
                                   <ejs-combobox  required id='combobox' :dataSource='arr' v-model:value="search" popupHeight="400px"   :fields='fields' ></ejs-combobox>
                                  

                      </div>

                      </div>
                      <!---->
                   
                    </div>
                      </div>                                
                  </div>
 
                   <div class="row m-3">
                    <div class="col-12">
                       <div class="form-group row">
                      <label for="exampleInputUsername2" class="col-lg-1 col-form-label font-weight-bold" >Email  : </label>
                      <div class="col-lg-4 ">
                            <div class="form-group">
                                             <input type="email" class="form-control" id="exampleInputEmail2" placeholder="mail@mail.ma" v-model="t.email" required>


                      </div>

                      </div>
                  
                      <!---->
                   
                    </div>
                      </div>                                
                  </div>
                 <div  v-if="error" class="alert alert-secondary text-center" role="alert">
                       {{error}}
                </div>

 
     <div class="d-flex flex-row-reverse">
         <button v-if="!isLoading" class="btn btn-primary float-lg-right px-4 font-weight-bold text-white" type="submit" >Valider
         </button>

</div>



     
                  
                  </form>
  

      </div>
    </div>
  </div>
  
  <div class="accordion-item m-2">
    <h2 class="accordion-header" id="panelsStayOpen-headingThree">
      <button class="accordion-button collapsed bg-light btn btn-dark" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseThree" aria-expanded="false" aria-controls="panelsStayOpen-collapseThree">
        Accounts list
      </button>
    </h2>
    <div id="panelsStayOpen-collapseThree" class="accordion-collapse collapse show" aria-labelledby="panelsStayOpen-headingThree">
      <div class="accordion-body">

         <form class="forms-sample" @submit.prevent="handlesubmit" style="width : 100%;">
                     <div class="row m-3">
                    <div class="col-12">
                       <div class="form-group row">
                     <!--  <label for="exampleInputUsername2" class="col-lg-6 col-form-label font-weight-bold" >Select account type </label> -->
                      <div class="col-lg-6">
                            <div class="form-group">
               <select class="form-select form-control" aria-label="Default select example" @change="changer($event)">
                 <option selected>Select account state</option>
  <option value="3">All</option>
  <option value="1">Not actived</option>
  <option value="0">Actived</option>
  <option value="2">Archived</option>
</select>
                      </div>

                      </div>
                      <div class="col-lg-6"  v-if="isLoading">
                               <loading  :active="true" :width="20" :height="20" color="blue"></loading> 

                            </div>
                    </div>
                      </div>                                
                  </div>
         </form>
                     
                  <div class="row px-2">
                    <table class="table">
                      <thead>
                        <tr class="bg-primary text-white">
      <th scope=" col">Email</th>
      <th scope=" col">Created at</th>
      <th class="text-left font-weight-bold">Profile</th>
      <th class="text-center">State</th>
      <th class="text-center">Last Connexion</th>
      <th class="text-center">Archived at </th>
      <th class="text-center"> Actions </th>
    </tr>
                      </thead>
  
  <tbody>
     <tr class="odd text-center" v-if="total == 0">
                           <td valign = "top" colspan="7" class="dataTables_empty text-center text-primary bg-light font-weight-bold">
                             No data available in table
                           </td>
                        </tr>
    
    
     <tr class="text-center p-0"  v-for="(item, index) in users" :key="item.id" v-bind:index="index" >
      <td class="text-left" > {{item.email}} </td>
      <td class="text-success text-left"> {{format_date(item.created_at)}}  </td>
      <td class="text-left "> {{item.profil.type_user}}  </td>
      <td class=" text-danger" v-if="item.is_archived == 1"> Archived</td>
      <td class="font-weight-bold text-success" v-else-if="item.is_actived == 1"> Actived</td>
      <td class="font-weight-bold text-warning" v-else-if="item.is_actived == 0"> Not Actived</td>
      <td class="" v-if="item.last_cnx"> {{format_date(item.last_cnx)}}  </td>
      <td class="" v-else> **** </td>
      <td class="text-danger" v-if="item.is_archived == 1"> {{format_date(item.updated_at)}}  </td>
      <td class="text-danger" v-else> *****  </td>
      <td  class="text-center p-0">
        
                      <a  v-if="item.is_archived == 0 && item.is_actived ==1 && selected!=item.id" @click="desactiver(item.id, index, item)" title="remove " class="" style="cursor: pointer;" ><i class="mdi mdi-account-off icon-sm text-danger"></i></a>
                      <a  v-if="item.is_archived == 1  && selected!=item.id" @click="desarchiver(item.id, index, item)" title="restore " class="" style="cursor: pointer;" ><i class="mdi mdi-account-convert icon-sm text-secondary"></i></a>
                       <a  v-if="item.is_actived == 0 && selected!=item.id" @click="activer(item.id, index, item)" title="activer " class="" style="cursor: pointer;" ><i class="mdi mdi-account-check icon-sm text-success"></i></a>
                       <a v-show="selected==item.id" > <i  class=" mdi mdi-check icon-sm  text-primary"  ></i></a>


       <!--  <a class="" href="#" v-if="item.is_archived == 0"  >
      <span class="float-left"> Désactiver</span>
      <i class="mdi mdi-close float-right" style="font-size : 19px;"></i>
    </a>  -->
<!--        <div class="btn-group dropright">
  <button class="btn p-0" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="background : transparent; border:none;">
    <i class="mdi mdi-dots-horizontal"></i>
  </button>
  <div class="dropdown-menu" aria-labelledby="dropMenuButton">
    <a class="dropdown-item mb-3 " href="#" v-if="item.is_archived == 0" @click="activer(item.id, index)">
      <span class="float-right" > Activer</span>
       <i class="mdi mdi-check float-right" style="font-size : 19px;"></i>
      </a> 
    <a class="dropdown-item mb-3" href="#" @click="openModel(item)">
      <span class="float-left"> Details</span>
      <i class="mdi mdi-eye float-right" style="font-size : 19px;"></i>
    </a>
    <div class="dropdown-divider my-1"></div>
    <a class="dropdown-item mb-3" href="#" v-if="item.is_archived == 0"  @click="d(item.id, index, item)">
      <span class="float-left"> Désactiver</span>
      <i class="mdi mdi-close float-right" style="font-size : 19px;"></i>
    </a>  </div>
</div> -->
    </td>
    </tr>
  </tbody>
</table>
     </div>
 
     <div class="d-flex flex-row-reverse">
         <pagination v-model="page" :records="total" :per-page="per" @paginate="myCallback" />


</div>



     
                  


      </div>
    </div>
  </div>
</div>
</div>
<div v-if="myModel">
    <transition name="model">
     <div class="modal-mask">
      <div class="modal-wrapper">
               <div class="modal-dialog  modal-dialog-centered modal-lg">

        <div class="modal-content">
         <div class="modal-header bg-light">
                     <h4 class="modal-title text-center text-primary">Informations</h4>

          <button type="button"  @click="myModel=false"><span aria-hidden="true">&times;</span></button>
         </div>
         <div class="modal-body bg-light mx-4">
         
         <div class="table-responsive  "  style="margin : 10% 15%;">
                   <table class="table table-borderless ">

                      <tbody>
                         <tr class="" >
                            <th  style="padding-left : 5%; padding-bottom : 2%; width : 30%;">Référence : </th>
                             <th   class="text-muted" style="padding-bottom : 2%;" > {{u.id}}  </th>
                            
                          </tr >
                          <tr class="" v-if="u.userable" >
                            <th  style="padding-left : 5%; padding-bottom : 2%; width : 30%;">Nom : </th>
                             <th   class="text-muted" style="padding-bottom : 2%;" > {{u.userable.nom}}  </th>
                            
                          </tr >
                          <tr class="" >
                            <th  style="padding-left : 5%; padding-bottom : 2%; width : 30%;">Email : </th>
                             <th   class="text-muted" style="padding-bottom : 2%;"> {{u.email}}  </th>
                            
                          </tr >
                          <tr class="" >
                            <th  style="padding-left : 5%; padding-bottom : 2%; width : 30%;">Role : </th>
                             <th   class="text-muted" style="padding-bottom : 2%;"> {{u.profil.type_user}}  </th>
                            
                          </tr >
                        
                          <tr class="" >
                            <th  style="padding-left : 5%; padding-bottom : 2%; width : 30%;">Dernière connexion : </th>
                             <th   class="text-muted" style="padding-bottom : 2%;" v-if="u.last_cnx"> {{u.last_cnx}}  </th>
                             <th   class="text-muted" style="padding-bottom : 2%;" v-else> Never  </th>
                            
                          </tr > 
                         
               
                   
                            
                            
                      
                      </tbody>
                    
                    </table>
                   
                  </div>
          <br />
          
         </div>
        </div>
       </div>
      </div>
     </div>
    </transition>
   </div>

 
    

           <div class="row">
            <div class=" stretch-card col-lg-12 m-1 p-0">
              <div class="card p-0">
               




                 
                
              
              </div>
            </div>
          </div>

       
    
        
        </div>

        <!-- content-wrapper ends -->
        <!-- partial:partials/_footer.html -->
      <Footer />
        <!-- partial -->
      </div>
      <!-- main-panel ends -->
    </div>
    <!-- page-body-wrapper ends -->
  </div>
        
      </section>

</template>
<script>
// import Menu from '@/components/Menu.vue'; 
import Header from '@/components/Header.vue'; 
import Footer from '@/components/Footer.vue'; 
import axios from 'axios';
import Swal from 'sweetalert2';
import Pagination from 'v-pagination-3';
//import { notify } from "notiwind";
// import Notifications from "vt-notifications";
import { notify } from "@kyvg/vue3-notification";
import Loading from 'vue3-loading-overlay';
import moment from 'moment';












export default {
  components : {
    Pagination,
    Loading,
    // Menu,
    Header,
    Footer,
  },
    data() {
    return {
      profil : "",
     selectedItem : "Select user type",
     users: {},
     profiles:{},
     type: '',
     resource: '',
     resources : this.$store.state.ressource,
     text : '',
     picked : [],
     p : {},
     id : "",
     profile: [],
     r:[],
     page: 1,
     total : "",
     per:"",
     current : "",
     t:{},
     selected:null,
     vendeurs:{},
     relais:{},
     chauffeurs:{},
     centres:{},
     searchVendeur:'',
     searchRelais:'',
     searchChauffeur:'',
     searchCentre:'',
     fields:{ text: 'nom', value: 'id' },
     search: '',
     arr:{},
     error:'',
     activity:{},
     isLoading : false,
     myModel: false,
     u:{},

    }
  },

created(){
  this.getAllusers(this.page);
  this.getAllSellers();
  this.getAllDrivers();
  this.getAllRelays();
   this.getAllProfiles();
   this.getAllWarehouses();


},
 computed: {
        user(){
        return this.$store.getters.get_user;
              },
        link(){
        return this.$store.getters.get_link;
              },

      
    },
 

mounted(){
 
 //this.getAllusers();  
 console.log(this.resources)
},
 
    methods: {
      myCallback(event){
        /* this.getAllusers(event); */
        this.page = event;
        this.getAllusers(this.page);
      },
     
      getAllusers(page){
        this.isLoading = true;
         this.$store.dispatch('getItem', {//data to be sended
      nameRoute : `/api/getusers?page=${this.page}`,
      })    
     .then(res => {
       this.isLoading = false;
      console.log("all users",res.data);
      this.users = res.data.data;
      this.per = res.data.per_page;
      this.total = res.data.total;
     })
     .catch(err => {
       this.isLoading = false;
       // this.error = "Une erreur s'est produite ! "
      }) 
      },
       getAllProfiles(){
         this.isLoading = true;
         this.$store.dispatch('getItem', {//data to be sended
      nameRoute : '/api/getprofiles',
      })    
     .then(res => {
       this.isLoading = false;
      console.log("profiles",res.data);
      this.profiles = res.data;
     })
     .catch(err => {
       this.isLoading = false;
       // this.error = "Une erreur s'est produite ! "
      }) 
      },
      handlesubmit(){
        this.error = "";
          Swal.fire({
      title: 'Attention!',
      text: "Vous etes sur le point d'ajouter cet utilisateur",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: 'green',
      cancelButtonColor: '#d33',
      cancelButtonText: "Annuler",
      confirmButtonText: 'Continuer'
      })
      .then((result) => {
       if (result.isConfirmed) {
         this.register();
     
  }
})    
      },
      format_date(value){
         if (value) {
           return moment(String(value)).format('DD-MM-YYYY hh:mm')
          }
      },
       getAllSellers(){
         this.$store.dispatch('getItem', {//data to be sended
      nameRoute : '/api/getvendeursactivedAccount',
      })    
     .then(res => {
      console.log(res.data);
      this.vendeurs = res.data;
     })
     .catch(err => {
        //this.error = "Une erreur s'est produite ! "
      }) 
      },
       getAllDrivers(){
     // this.isLoading = true;
         this.$store.dispatch('getItem', {//data to be sended
      nameRoute : '/api/getchauffeursactivedAccount',
      })
      .then( res => {
        console.log(res.data);
        this.chauffeurs = res.data;
      })   
      .catch(err => {
       // this.error = "Une erreur s'est produite ! "
      })
    },
    getAllWarehouses(){
        this.$store.dispatch('getItem', {//data to be sended
      nameRoute : '/api/getwarehouses',
      })    
     .then(res => {
       this.isLoading = false;
      console.log(res.data);
      this.centres = res.data;
     })
     .catch(err => {
       this.isLoading = false;
       if (!err.response) this.error = "Problème réseau, Impossible de joindre le serveur" 
       else this.error = "Une erreur s'est produite ! "
      })  

    },
    getAllRelays(){
      //this.isLoading = true;
         this.$store.dispatch('getItem', {//data to be sended
      nameRoute : '/api/getrelaisactivedAccount',
      })
      .then( res => {
        console.log(res.data);
        this.relais = res.data;
      })   
      .catch(err => {
       // this.error = "Une erreur s'est produite ! "
      })
    },

      getOneProfile(id){
         this.$store.dispatch('getItem', {//data to be sended
      nameRoute : '/api/getprofilebyid/'+id,
      })    
     .then(res => {
      this.profile = res.data.permissions.split("");
      // console.log(this.profile.split(""));
     })
     .catch(err => {
       // this.error = "Une erreur s'est produite ! "
      }) 
      },
         openModel(item){
  // this.first_name = '';
  // application.last_name = '';
  console.log("clicked!!!")
     this.u = item;
   
     this.actionButton = "Insert";
     this.dynamicTitle = "Add Data";
     this.myModel = true;
  },

      changer(event){
    
        var value = event.target.value;
        var name = "";
        if(value == 3) this.getAllusers();
        else{
        if(value == 2) name = "getusersdesactived";
        if(value == 1) name = "getusersnotactived";
        if(value == 0) name = "getusersactived";
         this.$store.dispatch('getItem', {//data to be sended
          nameRoute : `/api/`+name+`?page=${this.page}`,
          })    
        .then(res => {
          console.log(res.data);
          this.users = res.data.data;
          this.per = res.data.per_page;
          this.total = res.data.total;
        })
        .catch(err => {
         //   this.error = "Une erreur s'est produite ! "
          }) 
        }
      },
       desactiver(id, index, item){
      this.selected = id;
      this.isLoading = true;
      this.$store.dispatch('idItem', {//data to be sended
      nameRoute : '/api/desactiver/'+id,
      })
     .then(res => {
       this.selected = null;
       this.isLoading = true;
       this.addActivity("Desactivate user "+item.email, this.link+'/api/desactiver/'+id)
       this.notification();
       this.getAllusers();
       
      // this.$router.go()	// Refreshes page
    
     })
     .catch(err =>{
       this.selected = null;
       this.isLoading = true;
       console.log(err)
       this.notificationError();
     })

    },
    activer(id, index, item){
      this.selected = id;
      this.isLoading = true;
      this.$store.dispatch('idItem', {//data to be sended
      nameRoute : '/api/activer/'+id,
      })
     .then(res => {
       this.selected = null;
       this.isLoading = false;
       this.addActivity("Activate user "+item.email, this.link+'/api/activer/'+id)
       this.notification();
       this.getAllusers();
       
      // this.$router.go()	// Refreshes page
    
     })
     .catch(err =>{
       this.selected = null;
       this.isLoading = false;
       console.log(err)
       this.notificationError();
     })

    },

    desarchiver(id, index, item){
      this.selected = id;
      this.isLoading = true;
      this.$store.dispatch('idItem', {//data to be sended
      nameRoute : '/api/desarchiver/'+id,
      })
     .then(res => {
       this.selected = null;
       this.isLoading = false;
       this.addActivity("Restore user "+item.email, this.link+'/api/desarchiver/'+id)
       this.notification();
       this.getAllusers();
       
      // this.$router.go()	// Refreshes page
    
     })
     .catch(err =>{
       this.selected = null;
       this.isLoading = false;
       console.log(err)
       this.notificationError();
     })

    },

    a(id, index){
       this.$store.dispatch('idItem', {//data to be sended
      nameRoute : '/api/activer/'+id,
      })
     .then(res => {
       this.notification();
       
      this.users.splice(index, 1);
      this.total--;
     })
     .catch(err =>{
       console.log(err)
       this.notificationError();
     })

    }, 
          addActivity(action, link){
      console.log("add activity")
      this.activity.user_id= this.user.id;
      this.activity.action= action;
      this.activity.model = "User"
      this.activity.url=this.$store.getters.get_link+link;
      this.activity.user_agent=navigator.appName+" "+navigator.appVersion ;
      this.activity.item_id = this.user.id;
      console.log("activity..........", this.activity)
        this.$store.dispatch('postItem', {//data to be sended
          nameRoute : '/api/addactivity',
          t : this.activity
          })    
        .then(res => {
          console.log(res.data);
        })
        .catch(error => {
            console.log(error)
           
        }) 

      },
     register(){

    this.isLoading = true;
   this.t.profil_id = this.p.id;
   this.t.userable_id = this.search;
   console.log("ttttttttttttt",this.t)
      this.$store.dispatch('postItem', {//data to be sended
      nameRoute : '/api/registerationsec',
      t : this.t
      })    
     .then(res => {
      this.isLoading = false;
      this.addActivity("Add user "+this.t.email, this.link+'/api/registerationsec');
      console.log(res.data);
      this.notification();
      //window.location.reload();
     })
     .catch(error => {
           this.isLoading = false;
      /* console.log(error.response.data);
      console.log(error.response.status);
      console.log(error.response.headers); */
      this.notificationError();
      if(error.response.data.errors.email)
      this.error = error.response.data.errors.email[0];
     })
 },

 changerProfiles(evenet)
 {
   console.log("**************", this.p.id)
   this.profil = this.p.type_user;
   if(this.profil == "seller") {this.arr = this.vendeurs; this.search = this.searchVendeur; this.t.userable_type = 'App\\Models\\Seller'; console.log(this.searchVendeur)}
   if(this.profil == "relay") {this.arr = this.relais; this.search = this.searchRelais;this.t.userable_type = 'App\\Models\\Relay'}
   if(this.profil == "driver") {this.arr = this.chauffeurs; this.search = this.searchChauffeur;this.t.userable_type = 'App\\Models\\Driver'}
   if(this.profil == "warehouse") {this.arr = this.centres; this.search = this.searchCentre;this.t.userable_type = 'App\\Models\\Warehouse'}
   
   
   //console.log(profil)
 },

      notification(){
        console.log("notify")
        
      notify({ type: "success", text: "The operation completed", title: "DONE" });
      },

       notificationError(){
   console.log("notify")
   
notify({ type: "error", text: "the operation could not be completed", title: "WARNING" });
 }
 
 
    },
   

   

   
    
  
}
</script>
<style scoped>
.pointer {cursor: pointer;}


.home-section{
  position: relative;
  background: #E4E9F7;
  min-height: 100vh;
  top: 0;
  left: 78px;
  width: calc(100% - 78px);
  transition: all 0.5s ease;
  z-index: 2;
}
.sidebar.open ~ .home-section{
  left: 250px;
  width: calc(100% - 250px);
}
.home-section .text{
  display: inline-block;
  color: #11101d;
  font-size: 25px;
  font-weight: 500;
  margin: 0px;
}
i{
   color: #6c757d;
   font-size: 30px;


}


a:hover {
  color:orange !important;
  font-weight: bolder;
    font-weight: inherit;


}


.search:focus{
  background: transparent !important;
  outline: none !important;
  box-shadow: none !important;
}



.showLeft{
                    background-color: #0d77b6 !important;
                    border:1px solid #0d77b6 !important;
                    text-shadow: none !important;
                    color:#fff !important;
                    padding:10px;
                }

                .icons li {
                    background: none repeat scroll 0 0 #fff;
                    height: 7px;
                    width: 7px;
                    line-height: 0;
                    list-style: none outside none;
                    margin-right: 15px;
                    margin-top: 3px;
                    vertical-align: top;
                    border-radius:50%;
                    pointer-events: none;
                }

                .btn-left {
                    left: 0.4em;
                }

                .btn-right {
                    right: 0.4em;
                }

                .btn-left, .btn-right {
                    position: absolute;
                    top: 0.24em;
                }

                .dropbtn {
                    background-color: #4CAF50;
                    position: fixed;
                    color: white;
                    font-size: 16px;
                    border: none;
                    cursor: pointer;
                }

                .dropbtn:hover, .dropbtn:focus {
                    background-color: #3e8e41;
                }

                .dropdown {
                    position: absolute;
                    display: inline-block;
                    right: 0.4em;
                }

                .dropdown-content {
                    display: none;
                    position: relative;
                    margin-top: 60px;
                    background-color: #f9f9f9;
                    min-width: 160px;
                    overflow: auto;
                    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
                    z-index: 1;
                }

                .dropdown-content a {
                    color: black;
                    padding: 12px 16px;
                    text-decoration: none;
                    display: block;
                }

                .dropdown a:hover {background-color: #f1f1f1}

                .show {display:block;}


 




</style>
import { Script } from "vm";