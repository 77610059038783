

<template>
              

      <section class="home-section" >
         
         <div class="container-scroller" >
    <!-- partial:partials/_navbar.html -->
    <Header title="Ajout colis" />
    <!-- partial -->
    <div class="container-fluid page-body-wrapper bg-light"  >
      <!-- partial:partials/_sidebar.html -->
   
    
      <!-- partial -->
      <div class="main-panel" style="width : 100%;">
        <!---content wrapper start--->
              
        <div class="content-wrapper">

                  <div class="row" >
            <div class="col-md-12 grid-margin">
              <div class="d-flex justify-content-between flex-wrap row">
                <div class="d-flex align-items-end flex-wrap col-lg-8">
                
               
               <div class="mr-auto p-2"> <div class="mt-3">
                      <p><i class="mdi mdi-history icon-md" style="color: #6c757d; font-size: 30px;"></i></p>
                    </div>
                    </div>
      


                </div>
                <div class="col-lg-2 d-flex justify-content-between align-items-end flex-wrap">
                    <div class="input-group w-100 p-0 bg-light" style=" border-bottom: 1px solid black; ">
                      
            
              <input type="date" class="form-control bg-light " v-model="mydate"  aria-label="search" aria-describedby="search" style="height : 40px; border : none; box-shadow: none !important;" @change="show">
            </div>
                   

                  

                 
<!-- <button @click="t">tester</button> -->
                  
                  
                </div>
              </div>
            </div>
          </div>
          
       <!--------------->
     
 
       <!--------------->
       <div class="row justify-content-left mb-0 pb-0 fw-bold">
          <p  v-if="!mydate" > Date :  {{today}}         </p>
          <p  v-else>Date : {{format_date(mydate)}} </p>
       </div>
          <div class="row mt-0 pt-0">
               
            <div class=" stretch-card col-lg-12 mt-0 m-1 p-0 bg-light border-0">
              <div class="card p-0 mt-0 bg-light border-0 ">
              
                <div class="card-body bg-light p-0 border-0">
                   
                    
                 
                 
                                                     
                   
                                    <p  class="card-title text-center m-2" v-if="isLoading"> 
                                                              <loading  :active="true" :width="10" :height="10" color="black"></loading> Chargement en cours</p>
 
  <div class="row mx-4">
      <table class="table   w-100 table-striped p-2">
  <thead class=""> 
     <tr class=" text-left text-white text-sm pb-1" style="background : darkred !important;">
      <th class="" scope="col" >Date</th>
      <th class=""  scope="col">Account</th>
      <th  class="" scope="col">Profile</th>
      <th class=""  scope="col">Change</th>
      <th class=" text-left"  scope="col">Url</th>
      <th class=" text-left"  scope="col">Item</th>
      <th class="text-left" scope="col">details</th>
      <!-- <th scope="col">User agent</th> -->
    </tr>
  </thead>
  <tbody class="bg-light text-left">
    <tr class="odd text-center" v-if="!count">
                           <td valign = "top" colspan="7" class="py-4 dataTables_empty text-center text-dark bg-light font-weight-bold">
                             No activity available to show
                           </td>
                        </tr>
    <tr class="bg-white text-left " v-for="(item, index) in op" :key="item.id" v-bind:index="index"> 
      <td class="py-3 text-left">  {{format_date2(item.created_at)}}</td> 
      <td   > {{item.acesslog.user.email}}  </td>
      <td class="py-3 text-left"> {{item.acesslog.user.profil.type_user}} </td>
      <td class="py-3 text-left"> {{item.acesslog.action}} </td>
      <td class="py-3 text-left"> {{item.acesslog.url}} </td>
      <td class="py-3 text-left" >   
      <span v-if="item.operationable_type == 'App\\Models\\Shipementlog' && item.operationable">{{item.operationable.shipment_id}} </span>
      <span v-else>{{item.operationable_id}}</span>
      </td>
      <td class="py-3 text-left" v-if=" item.operationable"> 
        <span v-if="item.operationable_type == 'App\\Models\\Shipementlog'">{{item.operationable.statut}}</span>
        <span v-else-if="item.operationable_type == 'App\\Models\\Shipment'">{{item.operationable.ref}}</span>
        
        <span v-else> {{item.operationable.nom}}</span>
      </td>
      <td class="py-3 text-left" v-else> **** </td>

    <!--  <td class=""> {{item.acesslog.user_agent}} </td> -->
    </tr>
  </tbody>
</table>
    
  <div class="d-flex flex-row-reverse text-dark">
         <pagination v-model="page" :records="count" :per-page="per" @paginate="myCallback" />


</div>
 
     </div>
                        

     <!-- <div v-if="myModel">
    <transition name="model">
     <div class="modal-mask">
      <div class="modal-wrapper">
       <div class="modal-dialog">
        <div class="modal-content">
         <div class="modal-header">
                     <h4 class="modal-title text-center text-danger">Informations</h4>

          <button type="button"  @click="myModel=false"><span aria-hidden="true">&times;</span></button>
         </div>
         <div class="modal-body">
         
         <div class="table-responsive   m-4 ">
                   <table class="table table-borderless ">

                      <tbody>
                        <tr class="" >
                            <th  style="padding-left : 5%; padding-bottom : 2%; width : 30%;">Nom : </th>
                             <th   class="text-muted" style="padding-bottom : 2%;"> {{t.nom}}  </th>
                            
                          </tr >
                        
                           <tr class="" >
                            <th  style="padding-left : 5%; padding-bottom : 2%; width : 30%;">Adresse  :</th>
                             <th   class="text-muted" style="padding-bottom : 2%;"> {{t.adresse}}  </th>
                            
                          </tr >
                           <tr class="" >
                            <th  style="padding-left : 5%; padding-bottom : 2%; width : 30%;">Ville : </th>
                             <th   class="text-muted" style="padding-bottom : 2%;"> {{t.ville.city}}  </th>
                            
                          </tr >
                           <tr class="" >
                            <th  style="padding-left : 5%; padding-bottom : 2%; width : 30%;">Pays  :</th>
                             <th   class="text-muted" style="padding-bottom : 2%;"> {{t.pays}}  </th>
                            
                          </tr >
                                         <tr class="" >
                            <th  style="padding-left : 5%; padding-bottom : 2%; width : 30%;">Telephone : </th>
                             <th   class="text-muted" style="padding-bottom : 2%;"> {{t.telephone}}  </th>
                            
                          </tr >
                            <tr class="" >
                            <th  style="padding-left : 5%; padding-bottom : 2%; width : 30%;">Responsable : </th>
                             <th   class="text-muted" style="padding-bottom : 2%;"> {{t.nom_responsable}}  </th>
                            
                          </tr >
                      
      
                            
                            
                      
                      </tbody>
                    
                    </table>
                   
                  </div>
                   <div class="d-flex flex-row-reverse">
         <pagination v-model="page" :records="count" :per-page="per" @paginate="myCallback" />


</div>
          <br />
          
         </div>
        </div>
       </div>
      </div>
     </div>
    </transition>
   </div> -->


                </div>
              </div>
            </div>
          </div>
        
        </div>

        <!-- content-wrapper ends -->
        <!-- partial:partials/_footer.html -->
      <Footer />
        <!-- partial -->
      </div>
      <!-- main-panel ends -->
    </div>
    <!-- page-body-wrapper ends -->
  </div>
        
      </section>

</template>

<script>
// import Menu from '@/components/Menu.vue'; 
import Header from '@/components/Header.vue'; 
import Footer from '@/components/Footer.vue'; 
import axios from 'axios';
import Swal from 'sweetalert2';
import moment from 'moment';
import Loading from 'vue3-loading-overlay';
import Pagination from 'v-pagination-3';










export default {

  components : {
    // Menu,
    Header,
    Footer,
    Loading,
    Pagination
    //detailsCh,

},
  data()  {
    return {
   count : '',
   op : {},
   t:{},
   mydate:'',
   isLoading:false,
   page: 1,
   per:"",
 

   
    }
  },

    mounted(){
      this.showActivities(this.page);
    

  },
 
    computed: {
         today(){
           return moment().format('D MMMM YYYY');
       }
    

      
    },

    

 methods: {
   
    myCallback(event){
        /* this.getAllusers(event); */
        this.page = event;
        this.showActivities(this.page)
      },
     

   format_date(value){
         if (value) {
           return moment(String(value)).format('D MMMM YYYY')
          }
      },
    format_date2(value){
         if (value) {
           return moment(String(value)).format('DD-MM-YYYY hh:mm a')
          }
    },
      show(){
       this.t.date = this.mydate;
       this.showActivities(this.page);
      },

      showActivities(page){
        this.isLoading = true;
          console.log(this.mydate)
     this.$store.dispatch('postItem', {//data to be sended
     nameRoute : `/api/getalloperations?page=${this.page}`,
    //  nameRoute : '/api/getalloperations',
      t : this.t
      })
      .then( res => {
        this.isLoading = false;
        console.log("operations",res.data)
        this.op = res.data.data;
        this.per = res.data.per_page;
        this.count = res.data.total;
      })   
      .catch(err => {
        this.isLoading = false;
        this.error = "Une erreur s'est produite ! "
      })

      },
   openModel(item){
  // this.first_name = '';
  // application.last_name = '';
  this.t = item;
   
   this.actionButton = "Insert";
   this.dynamicTitle = "Add Data";
   this.myModel = true;
  },
  /*  t(){
     var arr = ["oui", "non"]
    this.$router.push({ name: 'detailsCh', params: {data:arr}})


   }, */
    ajouterClient(){
          console.log("clicked!");
         this.$router.push("/addcustomer");
         },

    searchclient(){
       this.$store.dispatch('getItem', {//data to be sended
      nameRoute : '/api/searchclient/'+this.search,
      })    
     .then(res => {
       this.clients = res.data;
       this.count = res.data.length;
     })
    },

    afficherClients(){
      this.isLoading = true;
     this.$store.dispatch('getItem', {//data to be sended
      nameRoute : '/api/getclientsactived',
      })
      .then( res => {
        this.isLoading = false;
        console.log(res.data);
        this.searchQuery = '';
        this.cl = res.data;
        this.count = res.data.length;
       })
      .catch(err => {
       this.isLoading = false;

        this.error = "Une erreur s'est produite ! "
      })      
      },

    

     afficherClientsArchives(){
       console.log(this.count)
      this.isLoading = true;
     this.$store.dispatch('getItem', {//data to be sended
      nameRoute : '/api/getclientsarchived',
      })
      .then( res => {
        this.isLoading = false;
        console.log(res.data);
        this.searchQuery = '';
        this.cl = res.data;
        this.count = res.data.length;
       })
      .catch(err => {
        this.isLoading = false;
        this.error = "Une erreur s'est produite ! "
      })      
      },

      archiver(id, index){
      this.id = id;
      this.$store.dispatch('idItem', {//data to be sended
      nameRoute : '/api/archiverclient/'+this.id,
      })
     .then(res => {
      // this.$router.go()	// Refreshes page
      this.cl.splice(index, 1);
      this.count--;
      console.log(res.data);
     })
    },

    desarchiver(id, index){
      this.selected = id;
      this.id = id;
      this.$store.dispatch('idItem', {//data to be sended
      nameRoute : '/api/desarchiverclient/'+this.id,
      })
     .then(res => {
       
      // this.$router.go()	// Refreshes page
       this.isDisabled=false;
      this.cl.splice(index, 1);
      this.count--;
      this.selected = null;
      console.log(res.data);
     })
    },

    details( json_data){  
 
      this.$router.push({ name: 'detailsCh', params: {data:JSON.stringify(json_data)}})
    },
    edit( json_data){  

      this.$router.push({ name: 'editC', params: {data:JSON.stringify(json_data)}})
      console.log(json_data)

    },

    showMessage(id, index){
      Swal.fire({
      title: 'Attention!',
      text: "Etes vous sur de vouloir archiver ce client?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: 'green',
      cancelButtonColor: '#d33',
      cancelButtonText: "Annuler",
      confirmButtonText: 'Ok'
      })
      .then((result) => {
       if (result.isConfirmed) {
         this.archiver(id, index);
     
  }
})
    },
    parentFire(id){
      console.log("parentFire works!!", id)
    }

  }
}
</script>
<style scoped>
tr {
   line-height: 25px;
   min-height: 25px;
   height: 25px;
}

input[type=search]:focus{
  outline: inherit;
  box-shadow: inherit;
  background: rgb(216, 215, 208) !important;

}
.home-section{
  position: relative;
  background: #E4E9F7;
  min-height: 100vh;
  top: 0;
  left: 78px;
  width: calc(100% - 78px);
  transition: all 0.5s ease;
  z-index: 2;
}
.sidebar.open ~ .home-section{
  left: 250px;
  width: calc(100% - 250px);
}
.home-section .text{
  display: inline-block;
  color: #11101d;
  font-size: 25px;
  font-weight: 500;
  margin: 0px;
}
/* serachbar */

.search-box{
  width: fit-content;
  height: fit-content;
  position: relative;
}
.input-search{
  height: 50px;
  width: 50px;
  border-style: none;
  padding: 10px;
  font-size: 18px;
  outline: none;
  border-radius: 25px;
  transition: all .5s ease-in-out;
  background-color: transparent;
  padding-right: 40px;
  color:black;
}
.input-search::placeholder{
  color: #6C757D;
  font-size: 18px;
  font-weight: 100;
  font-style: italic;
}
.btn-search{
  width: 50px;
  height: 50px;
  border-style: none;
  font-size: 25px;
  font-weight: bold;
  outline: none;
  cursor: pointer;
  border-radius: 50%;
  position: absolute;
  right: 0px;
  color:white ;
  pointer-events: painted;  
}
.btn-search ~ .input-search{
  width: 80%;
  border-radius: 0px;
  background-color: transparent;
  border-bottom:1px solid #6C757D;
  transition: all 500ms cubic-bezier(0, 0.110, 0.35, 2);
}
.input-search:focus{
  width: 80%;
  border-radius: 0px;
  background-color: transparent;
   box-shadow: 0  0.2rem #6C757D;
  transition: all 500ms cubic-bezier(0, 0.110, 0.35, 2);
}
.modal-mask {
     position: fixed;
     z-index: 9998;
     top: 0;
     left: 0;
     width: 100%;
     height: 100%;
     background-color: rgba(0, 0, 0, .5);
     display: table;
     transition: opacity .3s ease;
   }

   .modal-wrapper {
     display: table-cell;
     vertical-align: middle;
   }


</style>
import { Script } from "vm";