<template>
 <h1> TEST QR </h1>
  <div class="stream">
     
  <qr-stream @decode="onDecode" class="mb">
    <div style="color: red;" class="frame"></div>
  </qr-stream>
</div>
<div class="result">
  Result: {{data}}
</div>

</template>

<script >
import { defineComponent } from 'vue';
//import { QrcodeStream } from 'vue-qrcode-reader';
//import QrReader from 'vue3-qr-reader';
import { QrStream } from 'vue3-qr-reader';
import { reactive } from 'vue';
import { toRefs } from 'vue';




export default defineComponent({
  name: 'pageQr',
  components: {
    QrStream,
  },
  data()
{
    return {
        //isShowingCamera:false,
    }
},
  setup() {
    const state = reactive({
      data: null,
      //test: '',
    })
    function onDecode(data) {
      state.data = data
      console.log("clicked!!!!!!")
     // test = atob(state.data);
      
    //  console.log(test)
     // test = atob(state.data);
     // console.log(test.split("...."));



    }
    return {
      ...toRefs(state),
      onDecode
    }
  },

methods : {
   /* onDecode(test ){
        console.log(test)
    },
     async onInit(promise){
         try{
             console.log(" at onint function")
            await promise
             console.log("init", promise);
         }
         catch(error){
             console.log(error);
             if(error.name === 'NotAllowedError'){
                 //user denied camera access
                 console.log("user denied camera acces");
                 
             }
             else if(error.name === 'NotFoundError'){
                 // no sutable camera device detected
                 console.log("no camera detected");
             }
             else if(error.name === 'NotSupportedError'){
                 // page is not served over https or localhost
                 console.log("page is not served over https or localhost");
             }
             else if(error.name === 'NotReadableError'){
                 // no sutable camera device detected
                 console.log("no camera detected");
             }
             else if(error.name === 'OverconstrainedError'){
                 // did you request the front camera although ther is none
                 console.log("error overconstrained");
             }
             else if(error.name === 'StreamApiNotSupportedError'){
                 // browser seems to be lacking features
                 console.log("error browser");
             }

         }
         finally{
             //hide lading indicator
         }
     },*/
    
    
}
  
});
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
