<template>

      <section class="home-section" >
         
         <div class="container-scroller" >
    <!-- partial:partials/_navbar.html -->
    <Header title="Ajout colis" />
    <!-- partial -->
    <div class="container-fluid page-body-wrapper bg-light"  >
      <!-- partial:partials/_sidebar.html -->
   
    
      <!-- partial -->
      <div class="main-panel" style="width : 100%;">
        <!---content wrapper start--->
              
    <div class="content-wrapper">
                  
                  <div class="row">
            <div class="col-md-12 grid-margin">
              <div class="d-flex justify-content-between flex-wrap">
                <div class="d-flex align-items-end flex-wrap">
                
               
                <div class="mt-3">
                  <a href="#" class="link-secondary text-dark link-hover"><u>Client </u> </a> >
                   <a href="#" class="auth-link text-dark"><u>Chercher client</u> </a> >
                    <a href="#" class="auth-link text-dark"><u>modifier</u> </a> 

                    
           </div>


                </div>
               
              </div>
            </div>
          </div>
          <div class="row d-flex justify-content-center" id="pdf">
               <div class="col-lg-5 grid-margin stretch-card " >
              <div class="card border-0 ">
                <div class="card-body   " style="height : 12%;" >
                  <div class="row" >
                    <div class="col-md-12 " style="width : 100%;">
                        <!----------->
                            <div class="row">
                          <div class="col-md-12">
                          <address>
                        <p class="font-weight-bold  text-dark text-capitalize" v-if="warehouse">{{warehouse.nom}}</p>
                        <p class=" text-dark" v-if="warehouse">
                         {{warehouse.adresse}},
                        </p>
                        <p class=" text-dark">
                            {{villes[0]}}
                       
                        </p>
                      </address>
                      </div>
                     
                 </div>
                        <!-------------->
              {{shipment.id}}
               {{client.nom}}
               {{relay.nom}}
               {{warehouse.nom}}
               <vue-qrcode v-bind:value="qrValue"  scale="3"/>
                    </div>
                    </div>
                    </div>
                    </div>
                    </div>
                
            
               

            

 
               

 

    
          </div>
        </div>  

        <!-- content-wrapper ends -->
        <!-- partial:partials/_footer.html -->
      <Footer />
        <!-- partial -->
      </div>
      <!-- main-panel ends -->
    </div>
    <!-- page-body-wrapper ends -->
  </div>
        
      </section>


</template>

<script>
import axios from "axios"
import Swal from 'sweetalert2';
import Header from '@/components/Header.vue'; 
import Footer from '@/components/Footer.vue';  
//import Loading from 'vue3-loading-overlay';
import VueQrcode from 'vue3-qrcode';
import html2canvas from "html2canvas";

import jspdf from "jspdf"


export default {
   props: {
    idt: Number,
  },

  components : {
      Header,
      Footer,
      VueQrcode
      //Loading
  },
    data(){
        return{
            test : "oui",
           // idt : 8,
            t : {},
            user : {},
            error : "",
            isLoading: false,
           // villes:[],
            qrValue :  "",
      shipment :{},
      warehouse : {},
      client : {},
      seller : {},
       relay : {},
       villes:[],
       id: 7,

        }
    },
   created(){
     //this.id = 1;
             this.getCode();
         this.$store.dispatch('getItem', {//data to be sended
      nameRoute : '/api/getshipment/'+this.id,
     // nameRoute : '/api/getshipment/1',
      })    
     .then(res => {
       console.log("created")
        this.shipment = res.data;
        console.log(res.data)
         this.client = res.data["client"];
         this.relay = res.data["relay"];
         this.warehouse = res.data["warehouse"];
         this.villes[0] = res.data["warehouse"]["ville"].city;
     })
      
       

   },
     mounted(){
         //console.log("mounted")
       /* this.t =JSON.parse(this.$route.params.data)
       this.user = this.t.user; */
      // console.log(this.t)
              setTimeout(() => {  this.exportPdf(); }, 3000);  

             },

  methods: {
      /* edit(){
      this.$store.dispatch('postItem', {//data to be sended
      nameRoute : '/api/editC/'+this.t.id,
      t : this.t
      })    
     .then(res => {
      console.log(res.data);
      this.$router.push("/customer");
     })
     .catch(err => {
          if(err == 401) this.error = "Le mail ou le mot de passe est incorrect";
        else
        if (err == 500) this.error = "Une erreur s'est produite lors de la connexion au serveur, veuillez ressayer plus tard";
        else
        this.error = "La page ne peut pas étre telechargée, veuillez réessayer plus tard si le probleme persiste, contacter l'admin"
        
     })
      }, */
        getCode(){
               this.$store.dispatch('getItem', {//data to be sended
      nameRoute : '/api/ITlv1/'+this.id,
      })    
     .then(response => {
       this.qrValue = response.data.ciphertext;
       const k =  localStorage.setItem('kp',response.data.pass);//response.data.pass;
       const sa = localStorage.setItem('Sa',response.data.salt);//response.data.salt;
       const i =localStorage.setItem('I',response.data.iv);// response.data.iv;
      // this.exportPdf();
       
//console.log(this.qrValue);
     })
     .catch(err =>{
         this.error = true;
     })
       },
         exportPdf(){
          // var pdf = new jspdf('p', 'mm', [950, 1189]);
          var pdf = new jspdf('p', 'pt', [1000, 1189]);
    var element = document.getElementById('pdf');
    var width= element.style.width;
    var height = element.style.height;
    html2canvas(element).then(canvas => {
        var image = canvas.toDataURL('../../public/images/jiblili.png');
        pdf.addImage(image, 'JPEG', 15, 40, width, height);
       pdf.save('bondecommande'+this.id+'.pdf');
    });
    this.$router.push("/d");
    // this.$router.go(-1);
   // window.location.reload(this.$router.push("/d"));
       },

 showMessage(){
      Swal.fire({
      title: 'Attention!',
      text: "Etes vous sur de vouloir modifier ce client?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: 'green',
      cancelButtonColor: '#d33',
      cancelButtonText: "Annuler",
      confirmButtonText: 'Ok'
      })
      .then((result) => {
       if (result.isConfirmed) {
         this.edit();
     
  }
})
    },

 
  }

}
</script>

<style>

</style>