<template>
 <div class="container-xl">
	<div class="row">
		<div class="col-md-10 mx-auto">
			<div id="myCarousel" class="carousel slide" data-ride="carousel" data-interval="0">
			<!-- Carousel indicators -->
			<ol class="carousel-indicators">
				<li data-target="#myCarousel" data-slide-to="0" class="active"></li>
				<li data-target="#myCarousel" data-slide-to="1"></li>
				<li data-target="#myCarousel" data-slide-to="2"></li>
				<li data-target="#myCarousel" data-slide-to="3"></li>

            </ol>   
			<!-- Wrapper for carousel items -->
			<div class="carousel-inner">
				<div class="carousel-item active">
					<div class="row ">
						<div class="col-sm-4 ">
							<div class="thumb-wrapper" >
							
								<div class="thumb-content">
                                     <a href="#" class="data-card">
    <h3 class="text-success">270</h3>
    <h4 class="text-right text-success">Livré</h4>
    <p>Aenean lacinia bibendum nulla sed consectetur.</p>
  
  </a>
                                    
									</div>						
							</div>
						</div>
						<div class="col-sm-4">
							<div class="thumb-wrapper">
								<div class="thumb-content">
                                      <a href="#" class="data-card">
    <h3 class="text-danger">0</h3>
    <h4 class="text-right text-danger">Non récupéré</h4>
    <p>Aenean lacinia bibendum nulla sed consectetur.</p>
  
  </a>
									</div>						
							</div>
						</div>				
						<div class="col-sm-4">
							<div class="thumb-wrapper">
								<div class="thumb-content">
                                      <a href="#" class="data-card">
    <h3 class="text-warning">0</h3>
    <h4  class="text-right text-warning">Reception retour client</h4>
    <p>Aenean lacinia bibendum nulla sed consectetur.</p>
  
  </a>
									</div>						
							</div>					
						</div>
                        
					</div>
				</div>
				<div class="carousel-item">
						<div class="row ">
						<div class="col-sm-4 ">
							<div class="thumb-wrapper" >
							
								<div class="thumb-content">
                                     <a href="#" class="data-card">
    <h3 class="text-info">270</h3>
    <h4 class="text-right text-info">Reception vendeur</h4>
    <p>Aenean lacinia bibendum nulla sed consectetur.</p>
  
  </a>
                                    
									</div>						
							</div>
						</div>
						<div class="col-sm-4">
							<div class="thumb-wrapper">
								<div class="thumb-content">
                                      <a href="#" class="data-card">
    <h3 class="text-info">0</h3>
    <h4 class="text-right text-info">Au point relais</h4>
    <p>Aenean lacinia bibendum nulla sed consectetur.</p>
  
  </a>
									</div>						
							</div>
						</div>				
						<div class="col-sm-4">
							<div class="thumb-wrapper">
								<div class="thumb-content">
                                      <a href="#" class="data-card">
    <h3 class="text-info">0</h3>
    <h4  class="text-right text-info">Au centre de tri</h4>
    <p>Aenean lacinia bibendum nulla sed consectetur.</p>
  
  </a>
									</div>						
							</div>					
						</div>
                        
					</div>
				</div>
				<div class="carousel-item">
						<div class="row ">
						<div class="col-sm-4 ">
							<div class="thumb-wrapper" >
							
								<div class="thumb-content">
                                     <a href="#" class="data-card">
    <h3 class="text-primary">270</h3>
    <h4 class="text-right text-primary">En cours d'enlevement</h4>
    <p>Aenean lacinia bibendum nulla sed consectetur.</p>
  
  </a>
                                    
									</div>						
							</div>
						</div>
						<div class="col-sm-4">
							<div class="thumb-wrapper">
								<div class="thumb-content">
                                      <a href="#" class="data-card">
    <h3 class="text-primary">0</h3>
    <h4 class="text-right text-primary">En cours de distribution</h4>
    <p>Aenean lacinia bibendum nulla sed consectetur.</p>
  
  </a>
									</div>						
							</div>
						</div>				
						<div class="col-sm-4">
							<div class="thumb-wrapper">
								<div class="thumb-content">
                                      <a href="#" class="data-card">
    <h3 class="text-primary">0</h3>
    <h4  class="text-right text-primary">Retour en cours</h4>
    <p>Aenean lacinia bibendum nulla sed consectetur.</p>
  
  </a>
									</div>						
							</div>					
						</div>
                        
					</div>
				</div>
                <div class="carousel-item">
                    <div class="row">
                        <div class="col-sm-4">
							<div class="thumb-wrapper">
								<div class="thumb-content">
                                      <a href="#" class="data-card">
    <h3 class="text-secondary">0</h3>
    <h4  class="text-right text-secondary">Préparation en cours</h4>
    <p>Aenean lacinia bibendum nulla sed consectetur.</p>
  
  </a>
									</div>						
							</div>					
						</div>
                        <div class="col-sm-4">
							<div class="thumb-wrapper">
								<div class="thumb-content">
                                      <a href="#" class="data-card">
    <h3 class="text-secondary">0</h3>
    <h4  class="text-right text-secondary">en attente d'enlevement</h4>
    <p>Aenean lacinia bibendum nulla sed consectetur.</p>
  
  </a>
									</div>						
							</div>					
						</div>
                        <div class="col-sm-4">
							<div class="thumb-wrapper">
								<div class="thumb-content">
                                      <a href="#" class="data-card">
    <h3 class="text-secondary">0</h3>
    <h4  class="text-right text-secondary"> Perdu/Annulé</h4>
    <p>Aenean lacinia bibendum nulla sed consectetur.</p>
  
  </a>
									</div>						
							</div>					
						</div>
                       
                        

                    </div>
                </div>

                
			</div>
			<!-- Carousel controls -->
			<a class="carousel-control-prev" href="#myCarousel" data-slide="prev">
				<i class="fa fa-angle-left"></i>
			</a>
			<a class="carousel-control-next" href="#myCarousel" data-slide="next">
				<i class="fa fa-angle-right"></i>
			</a>
		</div>
		</div>
	</div>
</div>
</template>
<style scoped lang="scss">
.carousel {
	margin: 30px auto 60px;
	padding: 0 80px;
}
.carousel .carousel-item {
	text-align: center;
	overflow: hidden;
}
.carousel .carousel-item h4 {
	font-family: 'Varela Round', sans-serif;
}
.carousel .carousel-item img {
	max-width: 100%;
	display: inline-block;
}
.carousel .carousel-item .btn {
	border-radius: 0;
	font-size: 12px;
	text-transform: uppercase;
	font-weight: bold;
	border: none;
	background: #a177ff;
	padding: 6px 15px;
	margin-top: 5px;
}
.carousel .carousel-item .btn:hover {
	background: #8c5bff;
}
.carousel .carousel-item .btn i {
	font-size: 14px;
	font-weight: bold;
	margin-left: 5px;
}
.carousel .thumb-wrapper {
	margin: 5px;
	text-align: left;
	background: #fff;
	box-shadow: 0px 2px 2px rgba(0,0,0,0.1);   
}
.carousel .thumb-content {
	padding: 15px;
	font-size: 13px;
}
.carousel-control-prev, .carousel-control-next {
	height: 44px;
	width: 44px;
	background: none;	
	margin: auto 0;
	border-radius: 50%;
	border: 3px solid rgba(0, 0, 0, 0.8);
}
.carousel-control-prev i, .carousel-control-next i {
	font-size: 36px;
	position: absolute;
	top: 50%;
	display: inline-block;
	margin: -19px 0 0 0;
	z-index: 5;
	left: 0;
	right: 0;
	color: rgba(0, 0, 0, 0.8);
	text-shadow: none;
	font-weight: bold;
}
.carousel-control-prev i {
	margin-left: -3px;
}
.carousel-control-next i {
	margin-right: -3px;
}
.carousel-indicators {
	bottom: -50px;
}
.carousel-indicators li, .carousel-indicators li.active {
	width: 10px;
	height: 10px;
	border-radius: 50%;
	margin: 4px;
	border: none;
}
.carousel-indicators li {	
	background: #ababab;
}
.carousel-indicators li.active {	
	background: #555;
}
.data-card {
  display: flex;
  flex-direction: column;
  min-height: 50px;
  overflow: hidden;
  border-radius: .5em;
  text-decoration: none;
  background: white;
  margin: 1em;
  padding: 2.75em 2.5em;
  box-shadow: 0 1.5em 2.5em -.5em rgba(#000000, .1);
  transition: transform .45s ease, background .45s ease;
  
  h3 {
    color: #2E3C40;
    font-size: 3.5em;
    font-weight: 600;
    line-height: 1;
    padding-bottom: .5em;
    margin: 0 0 0.142857143em;
    border-bottom: 2px solid #2E3C40;
    transition: color .45s ease, border .45s ease;
  }

  h4 {
    color: #627084;
    text-transform: uppercase;
    font-size: 1.125em;
    font-weight: 700;
    line-height: 1;
    letter-spacing: 0.1em;
    margin: 0 0 1.777777778em;
    transition: color .45s ease;
  }

  p {
    opacity: 0;
    color: #FFFFFF;
    font-weight: 600;
    line-height: 1.8;
    margin: 0 0 1.25em;
    transform: translateY(-1em);
    transition: opacity .45s ease, transform .5s ease;
  }

  .link-text {
    display: block;
    color: orangered;
    font-size: 1.125em;
    font-weight: 600;
    line-height: 1.2;
    margin: auto 0 0;
    transition: color .45s ease;

    svg {
      margin-left: .5em;
      transition: transform .6s ease;
      
      path {
        transition: fill .45s ease;
      }
    }
  }
  
  &:hover {
    background: #2E3C40;
    transform: scale(1.02);
    
    h3 {
      color: #FFFFFF;
      border-bottom-color: white;
    }

    h4 {
      color: #FFFFFF;
    }

    p {
        color: rgb(153, 153, 153);
      opacity: 1;
      transform: none;
    }

    .link-text {
      color: #FFFFFF;

      svg {
        animation: point 1.25s infinite alternate;
        
        path {
          fill: #FFFFFF;
        }
      }
    }
  }
}

@keyframes point {
  0% {
   transform: translateX(0);
  }
  100% {
    transform: translateX(.125em);
  }
}
</style>

