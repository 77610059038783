<template>
  <div id="facture" >
      <div class="row">
          <div class="col-3" style="background : transparent;" >
              <table style="width : 150px; background: transparent; margin-bottom : 2%;" class="t"> 
                  <th class="td"><b>{{shipment.point_enlevement}}</b></th>

                  <tr><td class="td"> {{shipment.point_enlevement_adresse}} </td></tr>
                  <tr><td class="td">  {{shipment.point_enlevement_ville}}  </td></tr>       
              </table>

              <p class="font-weight-bold text-dark"> Demande d'enlevement : {{shipment.date_enlevement_demandee}} </p>
              <table class="  table table-bordered" style="width : 90%; margin-bottom : 5%;">
                  <tr>
                      <th >Destinataire</th>
                  </tr>
                  <tr>
                      <td >Nom : <br>
                          <b>{{shipment.nom}} {{shipment.prenom}}</b><br><br>
                          Adresse : <br>
                      <b>{{shipment.relais}}</b><br>
                      <b>{{shipment.relais_adresse}}</b><br>
                      <b>{{shipment.relais_ville}}</b>
                      </td>
                      
                  </tr>
              </table>
              <table class=" table table-bordered"  style="width : 90%;">
                  <tr>
                      <td>Poids</td>
                      <td> {{shipment.poids}} Kg</td>
                  </tr>
                  <tr>
                      <td>Numero d'expedition</td>
                      <td>{{shipment.n_expedition}}</td>
                  </tr>
                   <tr>
                      <td>Nombre de colis</td>
                      <td>1</td>
                  </tr>
                   <tr>
                      <td>Reference client</td>
                      <td>{{shipment.reference_client}}</td>
                  </tr>
                   <tr>
                      <td>Numero de suivi</td>
                      <td>{{shipment.id}}</td>
                  </tr>

              </table>

          </div>
          <div class="col-3 part">
              <img class="image" src="../../public/images/jiblili.png">
              <div class="qrcode">
                  <vue-qrcode v-bind:value="qrValue" scale="12"/>
              </div>
              <div class="Last">
                <div class="row">
                      <div class="text-center card p-2">
                  Encaissement :
                  <b v-if="shipment.cod == 0" class="text-center">Payé</b>
                  <b v-else class="text-center">A payer</b>
              </div>
              <div class="form-check ">
  <input class="form-check-input" type="checkbox" value="1" id="flexCheckDefault" >
  <label class="form-check-label" for="flexCheckDefault" style="margin-left : -10%;">
    Retour
  </label>
</div>
                </div>
              </div>
  

          </div>
      </div>
  </div>
    <button @click="makepdf()">  Makepdf </button>
    <button @click="printFacture()">  exportPdf </button>
    <button @click="tester()">  tester et encoder </button>
    <button @click="passToId()">  tester id </button>
    


</template>
<script>
import VueQrcode from 'vue3-qrcode'
import html2canvas from "html2canvas"
import jspdf from "jspdf"
import axios from "axios"

export default {
  components: {
    VueQrcode,
  },

   data(){
    return{
      id : 22,
      qrValue :  "",
      test : "s",
      correctionLevel:"H",
      shipment :{},
      val : false,
    }
   },
   mounted(){
     //test
     console.log("test");
       axios.create({
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer '+localStorage.getItem("token")
                  }
                 })
     .get("http://127.0.0.1:8000/api/getshipment/"+this.id)
     .then(res => {
       this.shipment = res.data[0];
       console.log(this.shipment);
       //console.log(this.shipment);

     })
     this.gettingValue();
     //Promise.resolve(this.testingvalue()).then(this.printFacture())
     setTimeout(() => {  this.printFacture(); }, 1000);
    

   },



  methods: {
    makepdf(){
      window.html2canvas = html2canvas;
      //var doc = new jspdf("p","pt","a4");
      var doc = new jspdf('p', 'mm', [950, 1189]);
      
      //var doc = new jspdf('p', 'mm', 'a4');
      doc.html(document.querySelector("#test"), {

       callback : function(pdf){
         pdf.save("mypdftesting.pdf");
       }
      });
    },

    passToId(){
      console.log("clicked!!!")
     this.$router.push(`/testId/${this.id}`);
   // this.$router.push({ path: '/testId', params: { id } }) // -> /user


    },
    gettingValue(){
        axios.create({
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer '+localStorage.getItem("token")
                  }
                 })
     .get("http://127.0.0.1:8000/api/getvalue/"+this.id)
     .then(res => {
       this.qrValue = res.data;
       //console.log(this.shipment);

     })
    },
    testingvalue(){
      if(this.qrValue != null){console.log("yes")}
    },
    printFacture() {
    var pdf = new jspdf();
    var element = document.getElementById('facture');
    var width= element.style.width;
    var height = element.style.height;
    html2canvas(element).then(canvas => {
        var image = canvas.toDataURL('../../public/images/jiblili.png');
        pdf.addImage(image, 'JPEG', 15, 40, 592, 842);
        pdf.save('facture.pdf');
    });
}
    

  



  }
}
</script>
<style scoped>
 #a {
   width: 8.5in;
   height: 11 in;
   margin-top: 60px;

 }

 .qrcode{
     margin-left: -13%;
     margin-top: -21%;
 }
 
 .card{
    margin-left : -5%;
    margin-top : -10%;

     width: 60%;
 }
 .form-check{
   background: transparent;
     margin-left : 2%;
     margin-top : -3%;
 }
 
 #test{
     font-family: Helvetica,Arial,sans-serif;
 }
 table.table, th, td, .card{
     border : 1px solid black;
 }
 table.t, .th, .td{
     border : none;
 }
 .part{
   background: transparent;
 }
 .image{
   margin-top: -10%;
 }
 
 
 
</style>