<template>
      <section class="home-section" >
         
         <div class="container-scroller" >
    <!-- partial:partials/_navbar.html -->
    <Header />
    <!-- partial -->
    <div class="container-fluid page-body-wrapper bg-light"  >
      <!-- partial:partials/_sidebar.html -->
    
      <!-- partial -->
      <div class="main-panel" style="width : 100%;">
        <!---content wrapper start--->
              
        <div class="content-wrapper">
                 <div class="row">
            <div class="col-md-12 grid-margin">
              <div class="d-flex justify-content-between flex-wrap">
                <div class="d-flex align-items-end flex-wrap">

                  <div class="mt-3" >
                      <p class=" d-flex justify-content-center align-self-center " ><i class="mdi mdi-account-multiple-plus icon-md mr-1"></i> 
                         <span class=" py-2 " >Nouveau client</span>
                       </p>
                    </div>
                
               
             


                </div>
                <!-- <div class="d-flex justify-content-between align-items-end flex-wrap">
                  <button type="button" class="btn btn-light bg-white btn-icon mr-3 d-none d-md-block ">
                    <i class="mdi mdi-download text-muted"></i>
                  </button>
                  <button type="button" class="btn btn-light bg-white btn-icon mr-3 mt-2 mt-xl-0">
                    <i class="mdi mdi-clock-outline text-muted"></i>
                  </button>
                  <button type="button" class="btn btn-light bg-white btn-icon mr-3 mt-2 mt-xl-0">
                    <i class="mdi mdi-plus text-muted"></i>
                  </button>
                </div> -->
              </div>
            </div>
          </div>
          <div class="row">
            

 
                  <form class="forms-sample" @submit.prevent="handlesubmit" style="width : 100%;" >
              <div class="col-12 grid-margin stretch-card">
              <div class="card">
                <div class="card-body">
                  <h4 class="card-title"> Informations :  </h4>
                                                           <loading  class="d-inline" v-if="isLoading" :active="true" :width="20" :height="20" color="blue"></loading>


                  <!------------------>
                   <div class="row">
                      <div class="form-group col-lg-6 ">
                      <label for="exampleInputUsername1">Nom</label>
                      <input type="text" class="form-control" id="exampleInputEmail2" placeholder="nom" autocomplete="off" v-model="t.nom" required>
                    </div>
                    <div class="form-group col-lg-6">
                      <label for="exampleInputUsername1">Prénom</label>
                    <input type="text" class="form-control" id="exampleInputEmail2" placeholder="prenom" autocomplete="off" v-model="t.prenom" required>                    </div>
                    </div>



                    <div class="form-group">
                      <label for="exampleInputEmail1">Adresse</label>
                     <textarea class="form-control" id="exampleFormControlTextarea1" rows="2" placeholder="adresse" autocomplete="off" v-model="t.adresse" required></textarea>
                    </div>

                     <div class="row">
                      <div class="form-group col-lg-6">
                      <label for="exampleInputUsername1">Ville</label>
 <select class="form-control form-select form-select-md " aria-label="Default select example" v-model="t.ville_id"   @change="changer">
                     <option v-for="item in villes" :value="item.id" :key="item.id" >
      {{ item.city }}
    </option>
                      </select>
                    </div>
                    </div> 

                    <div class="row">
                      <div class="form-group col-lg-6">
                            <label for="exampleInputUsername1">Email</label>
                            <input type="email"   class="form-control" id="exampleInputEmail2" autocomplete="off" placeholder="mail@exemple.com"  v-model="t.mail_reception" >
                      </div>
                    </div>
                    

                       <div class="row">
                      <div class="form-group col-lg-6">
                      <label for="exampleInputUsername1">Mobile</label>
 <input type="text" v-on:keypress="isNumber(event)"  class="form-control" id="exampleInputEmail2" autocomplete="off" placeholder="ex : 06xxxxxxxx" :minlength="10" :maxlength = "10" required v-model="t.mobile" >

                    </div>
                      
                   
                   
                    </div>

                    <!--  <div class="row">
                    
                       <div class="form-group col-lg-3 ml-3">
                      <label for="exampleInputUsername1"></label>
                        <div class="form-check">
  <input class="form-check-input" type="checkbox" value="1" id="flexCheckDefault" v-model="is_actived">
  <label class="form-check-label" for="flexCheckDefault">
    Créer un compte pour ce client
  </label>
</div>
                    </div>
                     <div class="form-group col-lg-3" v-if="is_actived">
                      <label for="exampleInputUsername1">Email</label>
              <input type="email" class="form-control" id="exampleInputEmail2" placeholder="email" v-model="t.email" required>
                    </div>
                   
                   
                    </div> -->

                  
                      <div class="row " style="margin-top : 1%;">
                       <div class="col-12">
                               <div  v-if="error" class="alert alert-danger text-center" role="alert">
                       {{error}}
                </div>
                       </div>
                     </div>


                      <div class="row my-4">
                     <div class="col-lg-3">

                     </div>
                     <div class="col-lg-3" >
                                           <button type="submit" class="btn btn-success mr-2" style="width : 100%;" :disabled="isLoading">
                                              <span v-if="!isLoading" >Valider</span>
                          <loading v-if="isLoading" :active="true" :width="10" :height="10" color="white"></loading>
                                           </button>


                     </div>
                     <div class="col-lg-3">
                                           <button class="btn btn-secondary" style="width : 100%;" @click="back()">Annuler</button>

                     </div>
                   </div>

                  <!---------->

                </div>
              </div>
            </div>
            <!-------------------------------->

   
         
          </form>
<!--                               <button class="btn btn-danger" @click="addActivity(1)">test activity </button>
 -->
 
         <!----------------------------------------->

 

    
          </div>
        </div>

        <!-- content-wrapper ends -->
        <!-- partial:partials/_footer.html -->
      <Footer />
        <!-- partial -->
      </div>
      <!-- main-panel ends -->
    </div>
    <!-- page-body-wrapper ends -->
  </div>
        
      </section>

</template>
<script>
// import Menu from '@/components/Menu.vue'; 
import Header from '@/components/Header.vue'; 
import Footer from '@/components/Footer.vue'; 
import axios from 'axios';
import Swal from 'sweetalert2';
import Loading from 'vue3-loading-overlay';

import { notify } from "@kyvg/vue3-notification";






export default {
  components : {
    // Menu,
    Header,
    Loading,
    Footer
  },
  data() {
    return{
        max: 2,
        text: '',
         nom : '',
         prenom: '',
         adresse : '',
         ville :'',
         pays : '',
         mobile : '',
         email: '',
         t : {},
         villes: [],
         isLoading: false,
      
        oldNum:0,
        error:'',
        activity:{},
    }
  },
  beforeMount(){
    this.isLoading = true;
     this.$store.dispatch('getItem', {//data to be sended
      nameRoute : '/api/getallcities',
      })    
     .then(res => {
       this.isLoading = false;
      console.log(res.data);
      this.villes = res.data;
     })
     .catch(err => {
       this.isLoading = false;
        if (!err.response) this.error = "Problème réseau, Impossible de joindre le serveur" 
       else this.error = "Une erreur s'est produite ! "
      }) 
    
  },
    computed :{
     role(){
        
             return this.$store.getters.get_profile;
      },
          user(){
           // console.log(this.$store.getters.get_user)
        return this.$store.getters.get_user;
 },
     },
 methods : {
    isNumber: function(evt) {
      evt = (evt) ? evt : window.event;
      var charCode = (evt.which) ? evt.which : evt.keyCode;
      if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
        evt.preventDefault();
      } else {
        return true;
      }
    },

  handlesubmit(){
             Swal.fire({
      title: 'Attention!',
      text: "Etes vous sur de vouloir ajouter ce client?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: 'green',
      cancelButtonColor: '#d33',
      cancelButtonText: "Annuler",
      confirmButtonText: 'Ok'
      })
      .then((result) => {
       if (result.isConfirmed) {
         this.add();
     
  }
})    
 },
 addActivity(val){
   console.log(val)
   this.activity.user_id= this.user.id;
   this.activity.action= "Add client";
   this.activity.model = "Client"
   this.activity.url=this.$store.getters.get_link+'/api/addclient';
   this.activity.user_agent=navigator.appName+" "+navigator.appVersion ;
   this.activity.item_id = val;
   console.log("activity..........", this.activity)
     this.$store.dispatch('postItem', {//data to be sended
      nameRoute : '/api/addactivity',
      t : this.activity
      })    
     .then(res => {
      console.log(res.data);
     })
     .catch(error => {
        console.log(error)
  

          
     }) 

 },
  notification(){
        console.log("notify")
        
      notify({ type: "success", text: "The operation completed", title: "DONE" });
      },

       notificationError(){
   console.log("notify")
   
notify({ type: "error", text: "the operation could not be completed", title: "WARNING" });
 },

 add(){
  console.log(this.t);
   this.error = "";
      this.t.nom = this.t.nom+" "+this.t.prenom;
      this.isLoading = true;
      this.$store.dispatch('postItem', {//data to be sended
      nameRoute : '/api/addclient',
      t : this.t
      })    
     .then(res => {
      this.isLoading = false;
      console.log("yes")
      console.log(res.data)
      this.addActivity(res.data.id)

      this.notification();
      this.$router.push("/customer");
     })
     .catch(err => {
       console.log("error add")
       console.log(this.t)
           this.isLoading = false;
           this.notificationError();

      /* console.log(error.response.data);
      console.log(error.response.status);
      console.log(error.response.headers); */
      if (!err.response) this.error = "Problème réseau, Impossible de joindre le serveur" 
       else this.error = "Une erreur s'est produite ! "
     })
 },
 back(){
    this.t = null;
    this.$router.go(-1);
  },
 }
  
  
}
</script>
<style scoped>
.home-section{
  position: relative;
  background: #E4E9F7;
  min-height: 100vh;
  top: 0;
  left: 78px;
  width: calc(100% - 78px);
  transition: all 0.5s ease;
  z-index: 2;
}
.sidebar.open ~ .home-section{
  left: 250px;
  width: calc(100% - 250px);
}
.home-section .text{
  display: inline-block;
  color: #11101d;
  font-size: 25px;
  font-weight: 500;
  margin: 0px;
}
i{
   color: #6c757d;
   font-size: 30px;


}





</style>
import { Script } from "vm";