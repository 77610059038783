<template>
          <section class="home-section" >
          <h1 > test </h1>
              <ejs-combobox id='combobox' :dataSource='sportsData' v-model:value="selected" @change="onChange(value)" :fields='fields'></ejs-combobox>
          selected value is {{selected}}
          <br>

          <select name="LeaveType" @change="onChange($event)" class="form-control" v-model="key">
   <option value="1">Annual Leave/ Off-Day</option>
   <option value="2">On Demand Leave ok</option>
</select>
<button @click="pdf()">download pdf </button>


          </section>
</template>

<script>
import axios from 'axios';

export default {
  data(){
    return {
      selected: null, 
      id: 1,
       sportsData: [
    { Id: 'game1', Game: 'Badminton' },
    { Id: 'game2', Game: 'Football' },
    { Id: 'game3', Game: 'Tennis' }
  ],
  fields: { text: 'Game', value: 'Id' },
  images : ["a", "b", "c"],
  key:"",
    }
  },
  methods : {
    test(event){
      console.log("The value is: " + event.target.value);

    },
    onChange(event) {
            console.log(event)
            console.log(this.selected)
        },
        pdf(){
    console.log("pdf");
     this.$store.dispatch('getPdf', {//data to be sended
      nameRoute : '/api/generate-pdf/'+this.id,
      })
      .then( res => {
        console.log("yes");
       
       })
      .catch(err => {
        console.log(err)
      })  
  
  }

  },
  
}
</script>